import { SalesOfficeAccount, SalesOrgModal } from "@Models";

export enum PlanStatus {
  OPENING = "OPENING", //nháp
  CREATED = "CREATED", //đã tạo
  CHANGING = "CHANGING", //cần điều chỉnh
  ADAPTED = "ADAPTED", //đáp ứng
  NOT_ADAPTED = "NOT_ADAPTED", //không đáp ứng
  CONFIRMED = "CONFIRMED", //đã xác nhận
  PROCESSING = "PROCESSING", //đang xử lý
  REJECTED = "REJECTED", //từ chối
  CLOSED = "CLOSED", //đã đóng
}

export enum PlanDetailStatus {
  CHANGING = "CHANGING", //yêu cầu chỉnh sửa
  APPROVED = "APPROVED", //đã xác nhận
  REJECTED = "REJECTED", //đã từ chối
  PENDING = "PENDING", //chờ duyệt
}

export type CampOutputModel = {
  code: string;
  createdAt: string;
  id: number;
  name: string;
  source: string;
  updatedAt: string;
};

export type DistributionChannelOutputModel = {
  code: number;
  createdAt: string;
  description: string;
  id: number;
  source: string;
  updatedAt: string;
};

export type CustomerPlanOutputModel = {
  address: string;
  businessRegistration: string;
  code: string;
  company: string;
  contactName: string;
  createdAt: string;
  email: null;
  fullName: string;
  id: number;
  idCard: string;
  idIssueDate: string;
  idIssuePlace: string;
  isActive: boolean;
  payTerm: string;
  phoneNumber: string;
  source: string;
  updatedAt: string;
};

export type DivisionItemsPlanOutputModel = {
  code: number;
  createdAt: string;
  description: string;
  id: number;
  source: string;
  updatedAt: string;
};

export type ItemPlanOutputModel = {
  baseUoM: string;
  code: string;
  conditionQuantity: null;
  createdAt: string;
  dimension: string;
  id: number;
  materialGroup: string;
  materialGroupName: string;
  name: string;
  note: string;
  saleUnit: string;
  shortName: string;
  source: string;
  updatedAt: string;
};

export type ItemsPlanOutputModel = {
  baseUoM: string;
  createdAt: string;
  dimension: string;
  division: DivisionItemsPlanOutputModel;
  item: ItemPlanOutputModel;
  quantity: string;
  saleUnit: string;
  subTotal: string;
  unitPrice: string;
  updatedAt: string;
};

export type DetailItemPlanOutputModel = {
  createdAt: string;
  date: string;
  id: number;
  items: ItemsPlanOutputModel[];
  label: string;
  status: string;
  updatedAt: string;
  orderGroup: {
    id: number;
    code: string;
    orderType: string;
    status: string;
    isProcessing: boolean;
    totalSample: null;
    totalAmount: string;
    totalDiscount: string;
    preDiscount: string;
    commerceDiscount: string;
    totalVat: string;
    personalVat: string;
    totalDeliveryFee: string;
    reasonStatus: null;
    reason: null;
    createdBy: number;
    shipPhone: null;
    sentSampleDate: null;
    receivedSampleDate: null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type PlanOutputModel = {
  camp: CampOutputModel;
  code: string;
  createdAt: string;
  creatorId: number;
  customer: CustomerPlanOutputModel;
  description: string;
  details: DetailItemPlanOutputModel[];
  distributionChannel: DistributionChannelOutputModel;
  end: string;
  endReason: null;
  id: number;
  isDraft: boolean;
  name: string;
  saleOrg: SalesOrgModal;
  start: string;
  status: string;
  updatedAt: string;
  salesOffice: SalesOfficeAccount;
  changingReason: string | null;
  rejectedReason: string | null;
  closedReason: string | null;
};

export type ItemPlanInputModel = {
  quantity: number;
  itemId: number;
  divisionId: number;
  unitPrice: string;
  saleUnit: string;
  baseUoM: string;
  dimension: string;
  subTotal: number;
};

export type DetailItemPlanInputModel = {
  date: string;
  label: string;
  items: ItemPlanInputModel[];
};

export type PlanInputModel = {
  name: string;
  customerId: number;
  salesOrgId: number;
  distributionChannelId: number;
  campId: number;
  description: string;
  start: string;
  end: string;
  details: DetailItemPlanInputModel[];
  isDraft: boolean;
};
