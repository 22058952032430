import React from "react";
import "./index.scss";
import Logo from "@Assets/images/logo.png";
import Phone from "@Assets/images/downapp/phone.png";
import Banner from "@Assets/images/downapp/banner.png";
import Icon2 from "@Assets/images/downapp/ic-ap.png";
import Icon1 from "@Assets/images/downapp/ic-gg.png";

const DownApp: React.FC = () => {
  return (
    <div className="down-app">
      <div className="container">
        <img src={Logo} alt="logo" style={{ objectFit: "cover" }} />
        <div className="img-phone">
          <img src={Phone} alt="phone" />
        </div>
        <div className="test-1">
          TRẢI NGHIỆM ĐẶT HÀNG
          <br />
          CÙNG GREENFEED VỚI ỨNG DỤNG
        </div>
        <div className="test-2">G SALES PORTAL</div>
        <div className="test-3">
          Tiếp tục mua sắm với GREENFEED.
          <br />
          Ứng dụng sẵn sàng trên hệ điều hành
          <br />
          Android và iOS
        </div>
        <div style={{ display: "flex" }}>
          <button
            onClick={() =>
              (window.location.href =
                "https://play.google.com/store/apps/details?id=com.greenfeed.salesportal")
            }>
            <img src={Icon1} alt="ic1" style={{ width: "2.5rem", height: "2.9rem" }} />
            <div>
              GET IT ON
              <br />
              <span>Google Play</span>
            </div>
          </button>
          <button
            onClick={() =>
              (window.location.href = "https://apps.apple.com/vn/app/g-sales-portal/id1597610112")
            }>
            <img src={Icon2} alt="ic2" style={{ width: "2.3rem", height: "2.8rem" }} />
            <div>
              Download on the
              <br />
              <span>App Store</span>
            </div>
          </button>
        </div>
        <img src={Banner} alt="banner" className="banner" />
      </div>
    </div>
  );
};

export default DownApp;
