/* eslint-disable react-hooks/exhaustive-deps */
import Banner from "@Assets/images/mock/banner-login.png";
import LogoTitle from "@Assets/images/mock/logo-title.png";
import Notification from "@Components/Notification";
import { APP_ROUTES, BASE_ADMIN_URL } from "@Constants";
import { AuthService } from "@Services";
import { FormUtil, RequestUtil, loadCallback } from "@Utils";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import {
  ClearRounded as ClearRoundedIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useHistory } from "react-router-dom";
import { setTimeout } from "timers";
import "./index.scss";
import { useTranslation } from "react-i18next";

const SignInForm: React.FC = (props) => {
  const { t } = useTranslation(["translation"]);
  const [showPw, setShowPw] = useState<boolean>(false);
  const history = useHistory();
  const LOGIN_PASSED = "Đăng nhập thành công ";
  const NOT_EXIST = "Tài khoản không tồn tại ";
  const { enqueueSnackbar } = useSnackbar();
  let submit: any;
  const [submitError, setSubmitError] = useState<boolean>(false);
  const [notiActivated, setNotiActivated] = useState<boolean>(false);

  useEffect(() => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("admin-access-token");
  }, []);

  const submitForm = React.useCallback(async (values) => {
    const fpPromise = FingerprintJS.load();

    // Get the visitor identifier when you need it.
    const fp = await fpPromise;
    const result = await fp.get();

    // This is the visitor identifier (deviceId):
    const visitorId = result.visitorId;
    localStorage.setItem("deviceID", visitorId);
    const isNeed = localStorage.getItem("deviceID");

    await AuthService.signIn({
      user: values.user,
      password: values.password,
      deviceId: visitorId,
      platform: "webCustomer",
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          enqueueSnackbar(LOGIN_PASSED, {
            variant: "success",
          });
          RequestUtil.updateHeader();
          setTimeout(() => {
            if (isNeed === null || res.data.isNewDevice === true) {
              setTimeout(() => {
                history.push(APP_ROUTES.VERIFY_OTP);
                window.location.reload();
              }, 100);
              localStorage.setItem("requestToken", res.data.token);
              localStorage.setItem("phoneNumber", res.data.phoneNumber);
            } else {
              if (res.data.isEmployee === true) {
                window.location.href = `${BASE_ADMIN_URL + `/redirect/${res.data.token}`}`;
              } else {
                history.push(APP_ROUTES.HOME);
                window.location.reload();
                localStorage.setItem("accessToken", res.data.token);
              }
            }
          }, 200);
        } else {
          // do something
        }
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 400) {
          if (err.response.data.message === "INCORRECT_CREDENTIAL") {
            enqueueSnackbar(
              "Đăng nhập không thành công. Vui lòng kiểm tra tài khoản hoặc mật khẩu",
              {
                variant: "error",
              }
            );
          } else if (err.response.data.message === "CUSTOMER_NOT_ACTIVATED") {
            setNotiActivated(true);
          } else if (err.response.data.message === "EXCEEDING_THE_LIMIT") {
            history.push(APP_ROUTES.IS_BLOCKED);
          } else {
            //validate form
          }
        } else if (
          err.response?.status === 404 &&
          err.response.data.message === "ACCOUNT_NOT_FOUND"
        ) {
          enqueueSnackbar(NOT_EXIST, {
            variant: "error",
          });
        } else if (err.response?.data.message === "ACCOUNT_BLOCKED") {
          history.push(APP_ROUTES.IS_BLOCKED);
        } else if (err.response?.status === 500) {
          enqueueSnackbar("Lỗi hệ thống", {
            variant: "error",
          });
        } else {
          enqueueSnackbar("Đăng nhập không thành công. Vui lòng kiểm tra tài khoản hoặc mật khẩu", {
            variant: "error",
          });
        }
      });
  }, []);

  return (
    <div className="auth-form">
      <img src={Banner} alt="banner" />
      <div className="auth-form__content">
        <div className="auth-form__content__logo">
          <img src={LogoTitle} alt="greenfeed-logo" />
        </div>
        <Form
          initialValuesEqual={() => true}
          onSubmit={submitForm}
          render={({ handleSubmit }) => {
            submit = handleSubmit;
            return (
              <form onSubmit={handleSubmit} className="auth-form__content__form">
                <div className="auth-form__content__form__title">Tài khoản</div>
                <Field name="user" validate={FormUtil.Rule.required("Nhập tài khoản của bạn")}>
                  {({ input, meta, ...rest }) => {
                    return (
                      <TextField
                        {...input}
                        {...rest}
                        fullWidth
                        type="text"
                        autoComplete="text"
                        placeholder={"Nhập tài khoản của bạn..."}
                        className={"text-border-style"}
                        inputProps={{ className: "input" }}
                        variant="outlined"
                        helperText={submitError === true ? meta.error || meta.submitError : ""}
                        error={submitError === true ? meta.error || meta.submitError : ""}
                        onChange={(e) => {
                          input.onChange(e.target.value);
                          setSubmitError(true);
                        }}
                        onBlur={(e) => {
                          input.onChange(e.target.value.replace(/\s/g, ""));
                          setSubmitError(true);
                        }}
                      />
                    );
                  }}
                </Field>
                <div className="auth-form__content__form__title">Mật khẩu</div>
                <Field name="password" validate={FormUtil.Rule.required("Nhập mật khẩu của bạn")}>
                  {({ input, meta, ...rest }) => {
                    return (
                      <TextField
                        {...input}
                        {...rest}
                        fullWidth
                        type="password"
                        autoComplete="current-password"
                        placeholder={"Nhập mật khẩu..."}
                        className={"text-border-style"}
                        inputProps={{ className: "input" }}
                        variant="outlined"
                        InputProps={{
                          type: showPw ? "text" : "password",
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={() => setShowPw((show) => !show)}>
                                {showPw ? (
                                  <VisibilityIcon style={{ fontSize: "2rem", color: "#000000" }} />
                                ) : (
                                  <VisibilityOffIcon
                                    style={{ fontSize: "2rem", color: "#000000" }}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => input.onChange(e.target.value)}
                        helperText={meta.touched ? meta.error : ""}
                        error={meta.error && meta.touched}
                      />
                    );
                  }}
                </Field>
                <div className="forgetpw">
                  <a href={APP_ROUTES.FORGETPASSWORD}>Quên mật khẩu?</a>
                </div>
                <button
                  className="btn-confirm-style"
                  onClick={(e) => {
                    e.preventDefault();
                    loadCallback(submit, e);
                  }}>
                  Đăng nhập
                </button>
              </form>
            );
          }}
        />

        <div className="description">
          Chưa có tài khoản?
          <a style={{ color: "#0a6836", marginLeft: "0.5rem" }} href={APP_ROUTES.SIGNUP}>
            Đăng ký
          </a>
        </div>
        <div className="text">
          Điều khoản sử dụng và bảo mật
          <br />
          Phiên bản 1.2.21 • Bản quyền thuộc về
          <a
            style={{ color: "#0A6836", fontWeight: 700 }}
            rel="noreferrer"
            href={"https://www.greenfeed.com.vn/"}
            target="_blank">
            {" "}
            GREENFEED VIETNAM
          </a>
        </div>
      </div>

      <Notification
        visible={notiActivated}
        title={"Tài khoản chưa được kích hoạt"}
        subTitle={
          <span
            dangerouslySetInnerHTML={{
              __html: t("CONTACT_STAFF_GF_ACTIVE_ACCOUNT"),
            }}
          />
        }
        icon={
          <div className={`notification-icon-box red`}>
            <ClearRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
          </div>
        }
        actions={
          <button
            className="btn-confirm-style"
            onClick={() => {
              setNotiActivated(false);
            }}>
            {t("GO_BACK")}
          </button>
        }
        onClose={() => {
          setNotiActivated(false);
        }}
      />
    </div>
  );
};

export default SignInForm;
