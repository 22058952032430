import { IconBase, IconComponent } from "@Core/Icons";

export const SignatureIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        d="M22 21H0V18.7895H22V21ZM4.62 15.8053L3.08 14.2579L4.62 12.7105L3.85 11.9368L2.31 13.4842L0.77 11.9368L0 12.7105L1.54 14.2579L0 15.8053L0.77 16.5789L2.31 15.0316L3.85 16.5789L4.62 15.8053ZM20.35 0L21.67 4.86316L20.57 5.19474C20.02 4.2 19.58 3.31579 19.03 2.76316C18.37 2.21053 17.71 2.21053 17.05 2.21053H14.3V13.8158C14.3 14.3684 14.3 14.9211 14.63 15.1421C14.96 15.3632 15.73 15.3632 16.5 15.3632V16.4684H9.9V15.3632C10.67 15.3632 11.33 15.3632 11.77 15.1421C12.1 14.9211 12.1 14.3684 12.1 13.8158V2.21053H9.35C8.69 2.21053 8.03 2.21053 7.37 2.65263C6.82 3.09474 6.27 4.08947 5.83 5.08421L4.73 4.75263L6.05 0H20.35Z"
        fill={props.color}
      />
    </IconBase>
  );
};
