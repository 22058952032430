import { IconBase, IconComponent } from "@Core/Icons";

export const DashboardIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <g clipPath="url(#clip0_16924_5285)">
        <path
          d="M12.4358 5.13086C10.3719 5.13069 8.35429 5.74258 6.63813 6.88912C4.92198 8.03566 3.58438 9.66538 2.79447 11.5722C2.00457 13.4789 1.79786 15.5771 2.20047 17.6014C2.60308 19.6256 3.59691 21.485 5.05632 22.9445C6.51573 24.4039 8.37516 25.3977 10.3994 25.8003C12.4237 26.2029 14.5219 25.9962 16.4287 25.2063C18.3354 24.4164 19.9651 23.0788 21.1117 21.3626C22.2582 19.6465 22.8701 17.6289 22.8699 15.565H12.4358V5.13086Z"
          stroke={props.color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.5659 12.4341H26C26 9.66682 24.9007 7.01285 22.9439 5.05607C20.9872 3.0993 18.3332 2 15.5659 2V12.4341Z"
          stroke={props.color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_16924_5285">
          <rect width="28" height="28" fill={props.color} />
        </clipPath>
      </defs>
    </IconBase>
  );
};
