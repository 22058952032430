import React from "react";
import { Redirect, Route } from "react-router-dom";
//import { useSelector } from "react-redux";
import { APP_ROUTES } from "@Constants";
import SignInForm from "@Containers/SignInForm";
import { WithChildren } from "@Models/shared";

const PrivateRoute = ({ children }: WithChildren<{}>) => {
  // const [token, setToken] = useState<string | undefined>("");
  const token2 =
    localStorage.getItem("requestToken")?.toString() ||
    localStorage.getItem("accessToken")?.toString();
  // useEffect(() => {
  //   setToken(
  //     localStorage.getItem("requestToken")?.toString() ||
  //       localStorage.getItem("accessToken")?.toString()
  //   );
  // }, []);
  return (
    <Route
      render={() =>
        token2 ? (
          children
        ) : (
          <Route exact component={SignInForm} render={() => <Redirect to={APP_ROUTES.SIGNIN} />} />
        )
      }
    />
  );
};

export default React.memo(PrivateRoute);
