import { IconBase, IconComponent } from "@Core/Icons";

export const HomeIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        d="M5.5859 14.6919H6.5859C6.5859 14.1396 6.13818 13.6919 5.5859 13.6919V14.6919ZM22.8348 14.6919V13.6919C22.2825 13.6919 21.8348 14.1396 21.8348 14.6919H22.8348ZM3.16234 13.8121L3.84486 14.543H3.84486L3.16234 13.8121ZM13.4275 4.22611L12.7449 3.49524V3.49524L13.4275 4.22611ZM14.5725 4.22611L15.2551 3.49524V3.49524L14.5725 4.22611ZM24.8377 13.8121L24.1551 14.543L24.8377 13.8121ZM6.5859 23.1538V14.6919H4.5859V23.1538H6.5859ZM5.5859 13.6919H3.50586V15.6919H5.5859V13.6919ZM23.8348 23.1538V14.6919H21.8348V23.1538H23.8348ZM22.8348 15.6919H24.4941V13.6919H22.8348V15.6919ZM3.84486 14.543L14.11 4.95698L12.7449 3.49524L2.47982 13.0813L3.84486 14.543ZM13.89 4.95698L24.1551 14.543L25.5202 13.0813L15.2551 3.49524L13.89 4.95698ZM6.42731 25H11.1552V23H6.42731V25ZM12.4758 23.6776V19.7691H10.4758V23.6776H12.4758ZM16.8448 25H21.9934V23H16.8448V25ZM15.5242 19.7691V23.6776H17.5242V19.7691H15.5242ZM16.8448 23C17.2254 23 17.5242 23.3087 17.5242 23.6776H15.5242C15.5242 24.4026 16.1101 25 16.8448 25V23ZM14 18.2305C14.8365 18.2305 15.5242 18.914 15.5242 19.7691H17.5242C17.5242 17.8201 15.9517 16.2305 14 16.2305V18.2305ZM12.4758 19.7691C12.4758 18.914 13.1635 18.2305 14 18.2305V16.2305C12.0483 16.2305 10.4758 17.8201 10.4758 19.7691H12.4758ZM11.1552 25C11.8899 25 12.4758 24.4026 12.4758 23.6776H10.4758C10.4758 23.3087 10.7746 23 11.1552 23V25ZM3.50586 13.6919C3.96663 13.6919 4.16301 14.2459 3.84486 14.543L2.47982 13.0813C1.4892 14.0064 2.12742 15.6919 3.50586 15.6919V13.6919ZM14.11 4.95698C14.0486 5.01434 13.9515 5.01434 13.89 4.95698L15.2551 3.49524C14.548 2.83492 13.452 2.83492 12.7449 3.49524L14.11 4.95698ZM21.8348 23.1538C21.8348 23.0742 21.9005 23 21.9934 23V25C23.0157 25 23.8348 24.1681 23.8348 23.1538H21.8348ZM24.4941 15.6919C25.8726 15.6919 26.5108 14.0064 25.5202 13.0813L24.1551 14.543C23.837 14.2459 24.0334 13.6919 24.4941 13.6919V15.6919ZM4.5859 23.1538C4.5859 24.1681 5.40499 25 6.42731 25V23C6.52022 23 6.5859 23.0742 6.5859 23.1538H4.5859Z"
        fill={props.color}
      />
    </IconBase>
  );
};
