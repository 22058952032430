/* eslint-disable no-useless-escape */
const NUMBER_ONLY = /^-?\d*(\.\d*)?$/;
const PHONE_NUMBER = /^\d+$/;
// eslint-disable-next-line
const EMAIL =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const UPPERCASE = /[A-Z]/;
const DIGITS = /[0-9]/;
const SPECIAL_CHARACTER = /[!@#$%^&*(),.?":{}|<>]/;
const CHARACTER_LENGTH = /.{8,}/;
const PASSWORD = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/;
const PHONE = /(84|0[0|3|5|7|8|9])+([0-9]{8})\b/;
const NUMBER_CHARACTER = /^[0-9\s*,-]*$/;

export const REGEX = {
  NUMBER_ONLY,
  PHONE_NUMBER,
  EMAIL,
  UPPERCASE,
  DIGITS,
  SPECIAL_CHARACTER,
  CHARACTER_LENGTH,
  PASSWORD,
  PHONE,
  NUMBER_CHARACTER,
};
