import { BASE_API_DOMAIN, BASE_IMG_DOMAIN } from "./config.constant";

export const DEFAULT_FETCHING = {
  LIMIT: 25,
};

export const TEXT = { NOT_AVAILABLE: "N/A", EMPTY: "––/––" };

// Image
export const IMG_BASE_URL = {
  CUSTOMER: `${BASE_IMG_DOMAIN}/uploads/customers`,
};

// File
export const FILE_BASE_URL = {
  ORDER: `${BASE_API_DOMAIN}/uploads/orders`,
};

export const DEFAULT_IMG_ALT = "green feed picture";
