import { TEXT_ASTERISK, TEXT_HOLDER } from "@Constants";
import { Divider } from "@material-ui/core";
import { ArrowDropDown as ArrowDropDownIcon } from "@mui/icons-material";
import { Accordion, AccordionSummary, Dialog, Grid, Tooltip } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CustomerService, OrderService } from "@Services";
import {
  checkLinkImage,
  currencyFormat,
  PermissionUtil,
  renderErrorImageItem,
  renderImageProNull,
  StatusUIUtil,
} from "@Utils";
import React, { useEffect, useRef, useState } from "react";
import Loading from "../Loading";
import SimpleModalHeader from "../SimpleModalHeader";
import "./index.scss";
import { useTranslation } from "react-i18next";
import { ItemPermission } from "@Models";
import _ from "lodash";

export type P_Props = {
  closeForm: () => void;
  id: number;
};

const DO: React.FC<P_Props> = (props) => {
  const { t } = useTranslation(["translation"]);
  const mounted = useRef(false);
  const [order, setOrder] = useState<any>();
  const [profile, setProfile] = useState<any>([]);

  const loadProfileData = _.debounce(async () => {
    const response = await CustomerService.getAuthProfile();
    setProfile(response.data);
  }, 100);

  useEffect(() => {
    loadProfileData();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const fetchData: () => Promise<void> = async () => {
    try {
      const result = await OrderService.getDeliveryOrderById({
        orderId: props.id,
      });
      if (mounted.current) {
        setOrder(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const theme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: "1.3rem",
            lineHeight: "1.9rem",
            color: " #FFFFFF",
            backgroundColor: "#312F30",
            fontFamily: "SVN-Gotham",
            fontWeight: 400,
            boxShadow: "0rem 0.4rem 1.2rem rgba(111, 125, 145, 0.16)",
            borderRadius: "0.4rem",
          },
        },
      },
    },
  });

  const gridSize: any = 12 / (order?.orders?.length + 2);

  const totalDO = (title: string) => {
    return order?.deliveryOrders.reduce((sum: any, item: any) => {
      return sum + +item[`${title}`];
    }, 0);
  };

  //permission
  const checkPermissionViewPrice = PermissionUtil.check(
    profile?.role?.permissions,
    ItemPermission.MANAGE_ITEM_VIEW_PRICE,
    true
  );

  return (
    <Dialog fullScreen open={true}>
      <div className="do-listing">
        <SimpleModalHeader
          title={`Phiếu giao hàng #${order?.orderGFId}`}
          onClose={() => {
            props.closeForm();
          }}
        />
        <div className="do-listing-container">
          {!!order ? (
            <div className="content">
              <div className="list">
                {order?.deliveryOrders.map((deli: any, index: number) => {
                  return (
                    <div key={index} style={{ margin: "0 4.6rem" }}>
                      <div className="do-listing-header">
                        <div className="prod">Mã DO: {deli?.code}</div>
                        <div className="quan">{t("QUANTITY")}</div>
                        <div className="weight">{t("TOTAL_WEIGHT")}</div>
                        <div className="price">{t("UNIT_PRICE")}</div>
                        <div className="additionValue">{t("STOCK_FEE_EXCESS_PORTION")}</div>
                        <div className="pay">{t("TOTAL_PRICE")}</div>
                        <div className="chip">
                          {StatusUIUtil.renderBgStatus(deli?.status, "farm")}
                        </div>
                      </div>
                      {deli?.dolines.map((dolines: any, idx: number) => {
                        return (
                          <div key={idx} className="item">
                            <div className="prod">
                              <div className="name">
                                <img
                                  src={
                                    checkLinkImage(dolines?.alterItem?.item.image?.picture) ||
                                    renderImageProNull("farm")
                                  }
                                  alt={order?.alterItem?.item.shortName}
                                  onError={renderErrorImageItem("farm")}
                                />
                                <div>
                                  {dolines?.alterItem?.item.name || TEXT_HOLDER}
                                  <div className="code">
                                    {dolines?.alterItem?.code || TEXT_HOLDER}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="quan">
                              {dolines?.deliveryQuantity
                                ? parseFloat(dolines?.deliveryQuantity)
                                : TEXT_HOLDER}
                            </div>
                            <div className="weight">
                              {currencyFormat(dolines?.deliveryWeight)}&ensp;kg
                              {/* {dolines?.alterItem?.item?.baseUoM} */}
                            </div>
                            <div className="price">
                              {checkPermissionViewPrice
                                ? currencyFormat(dolines?.unitPrice)
                                : TEXT_ASTERISK}
                              &ensp;đ/
                              <span style={{ color: "#41ad49" }}>
                                {dolines?.alterItem?.item?.baseUoM}
                              </span>
                            </div>
                            <div className="additionValue">
                              {checkPermissionViewPrice
                                ? dolines?.attachPrice
                                  ? currencyFormat(dolines?.attachPrice)
                                  : TEXT_HOLDER
                                : TEXT_ASTERISK}{" "}
                              đ
                            </div>
                            <div className="pay">
                              {checkPermissionViewPrice
                                ? currencyFormat(dolines?.subTotal)
                                : TEXT_ASTERISK}
                              &ensp;đ
                            </div>
                            <div className="chip"></div>
                          </div>
                        );
                      })}
                      <Divider style={{ color: "#ccc", width: "100%" }} />
                      <Grid container className="ship">
                        <Grid item xs={2}>
                          <div className="label">{t("DELIVERY_DATE")}</div>
                          {order?.requiredDate}
                        </Grid>
                        <Grid item xs={2}>
                          <div className="label">Trang trại</div>
                          {deli?.dolines[0]?.alterItem?.site?.name || TEXT_HOLDER}
                        </Grid>
                        <Grid item xs={2}>
                          <div className="label">{t("VEHICLE_NUMBER")}</div>
                          {deli?.vehicleNumber || TEXT_HOLDER}
                        </Grid>
                        <Grid item xs={3}>
                          <div className="label">{t("NOTES")}</div>
                          <div className="order-note">
                            <ThemeProvider theme={theme}>
                              <Tooltip arrow title={<span>{order?.note || TEXT_HOLDER}</span>}>
                                <span
                                  style={{
                                    color:
                                      order?.note !== "" &&
                                      order?.note !== null &&
                                      order?.note !== undefined
                                        ? "#0A6836"
                                        : "#5A5959",
                                  }}>
                                  {order?.note || TEXT_HOLDER}
                                </span>
                              </Tooltip>
                            </ThemeProvider>
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div className="label">Nơi nhận hàng</div>
                          <div className="order-note">
                            <ThemeProvider theme={theme}>
                              <Tooltip arrow title={<span>{order?.address || TEXT_HOLDER}</span>}>
                                <span>{order?.address || TEXT_HOLDER}</span>
                              </Tooltip>
                            </ThemeProvider>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  );
                })}
              </div>
              <div>
                <div className="footer">
                  <div className="footer-invoice">
                    {order.deliveryOrders.some((f: any) => f?.invoiceKey !== null) && (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div className="title">{t("INVOICE_LIST")}</div>
                        {order.deliveryOrders
                          .filter((f: any) => f?.invoiceKey !== null)
                          .map((i: any) => {
                            return (
                              <div style={{ display: "flex", paddingBottom: 0, height: "2.5rem" }}>
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href={i?.invoiceUrl}
                                  style={{ color: "#41AD49", cursor: "pointer" }}>
                                  #{i.code}
                                  <br />
                                  <br />
                                </a>
                                &ensp;
                                <div>
                                  &#8226;&ensp;
                                  <span style={{ fontWeight: "bold" }}>
                                    {t("LOOKUP_CODE")} {i?.invoiceKey}
                                  </span>
                                  <br />
                                  <br />
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </div>
                  <div className="total-gr">
                    <Grid
                      container
                      className="summary"
                      style={{ width: order?.orders?.length > 1 ? "55vw" : "30vw" }}>
                      <Grid item xs={gridSize}>
                        <div className="lb">{t("DISCOUNT")} </div>
                        <div className="lb">{t("PROMOTION")}</div>
                        <div className="lb">{t("WITHHOLDING_PIP")}</div>
                        <div className="lb">{t("VAT")}</div>
                        <div className="lb">{t("SHIPPING_FEE")}</div>
                        <div className="text-bold">{t("TOTAL")}</div>
                      </Grid>
                      <Grid item xs={gridSize} className="right">
                        <div className="lb">
                          {checkPermissionViewPrice
                            ? currencyFormat(parseInt(totalDO("preDiscount")))
                            : TEXT_ASTERISK}
                          &ensp;đ
                        </div>
                        <div className="lb">
                          {checkPermissionViewPrice
                            ? currencyFormat(parseInt(totalDO("commerceDiscount")))
                            : TEXT_ASTERISK}
                          &ensp;đ
                        </div>
                        <div className="lb">
                          {checkPermissionViewPrice
                            ? currencyFormat(parseInt(totalDO("personalVat")))
                            : TEXT_ASTERISK}
                          &ensp;đ
                        </div>
                        <div className="lb">
                          {checkPermissionViewPrice
                            ? currencyFormat(parseInt(totalDO("totalVat")))
                            : TEXT_ASTERISK}
                          &ensp;đ
                        </div>
                        <div className="lb">
                          {checkPermissionViewPrice
                            ? currencyFormat(parseInt(totalDO("shipFee")))
                            : TEXT_ASTERISK}
                          &ensp;đ
                        </div>
                        <div className="text-bold" style={{ color: "#0A6836" }}>
                          {checkPermissionViewPrice
                            ? currencyFormat(totalDO("totalPay"))
                            : TEXT_ASTERISK}
                          &ensp;đ
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <Accordion className="update-history" disabled>
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon style={{ color: "#272B2F" }} />}
                    className="title">
                    {t("UPDATE_HISTORY")}
                  </AccordionSummary>
                  <Divider style={{ marginBottom: "1.2rem" }} />
                </Accordion>
              </div>
            </div>
          ) : (
            <Loading style={{ margin: "2rem 0", height: "80vh" }} />
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default DO;
