/* eslint-disable react-hooks/exhaustive-deps */
import Avatar from "@Assets/images/avt.png";
import Img from "@Assets/images/empty.png";
import Excel from "@Assets/images/icons/excel.png";
import Search from "@Assets/images/icons/img-search.png";
import EmptyList from "@Components/EmptyList";
import { ArrowDownIcon } from "@Components/Icons/ArrowDownIcon";
import { Calendar1Icon } from "@Components/Icons/Calendar1";
import Loading from "@Components/Loading";
import NotiExportExcel from "@Components/NotiExportExcel";
import { Month, TEXT_ASTERISK, TEXT_HOLDER } from "@Constants";
import _debounce from "lodash/debounce";
// import { LoadMe, loadProfile } from "@Core/store/profile";
import { Action, FetchAction, ItemPermission, ReportModal, ReportPermission } from "@Models";
import { PaginateFetchReducer } from "@Models/shared";
import { CustomerService, ExportService, ReportService } from "@Services";
import { PermissionUtil, YearList, convertTv, currencyFormat } from "@Utils";
import {
  ArrowDropDown as ArrowDropDownIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  Close as CloseIcon,
  Search as SearchIcon,
  TuneRounded as TuneRoundedIcon,
} from "@mui/icons-material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import {
  Checkbox,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Popover,
  Select,
  TextField,
} from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import _ from "lodash";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import "./index.scss";

function reducerReport(state: PaginateFetchReducer<ReportModal>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: [...state.data, ...action.payload.data],
        loading: false,
      };
    case Action.RESET:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload.data,
        loading: false,
      };
    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload, loading: false };
    default:
      return { ...state };
  }
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "1.1rem", color: "#41AD49" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "#ffffff",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const Report: React.FC = () => {
  const { t } = useTranslation(["translation", "order", "customer"]);
  const checkExportRef = useRef<any>(null);
  const [month, setMonth] = useState<string>(("0" + (new Date().getMonth() + 1)).slice(-2));
  const [year, setYear] = useState<string>(new Date().getFullYear().toString());
  const [time, setTime] = useState<string>(
    new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2)
  );
  const [expanded, setExpanded] = useState<number>(0);
  const [openAll, setOpenAll] = useState<boolean>(false);
  const [checked, setChecked] = useState<any>([]);
  const [anchorFilterEl, setAnchorFilterEl] = useState<HTMLElement | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [searchReport, setSearchReport] = useState<string>();
  const [visibleSearch, setVisibleSearch] = useState<boolean>(false);
  const [searchEmp, setSearchEmp] = useState<string>("");
  const [searchEmpList, setSearchEmpList] = useState<any>();
  const [notiExport, setNotiExport] = useState<boolean>(false);
  const [profile, setProfile] = useState<any>([]);

  const loadProfileData = _debounce(async () => {
    const response = await CustomerService.getAuthProfile();
    setProfile(response.data);
  }, 100);

  useEffect(() => {
    loadProfileData();
  }, []);

  const open = Boolean(anchorEl);

  const handleOpenFieldFilter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorFilterEl(event.currentTarget);
  };

  const handleChange = (panel: any) => (event: any, newExpanded: any) => {
    setExpanded(newExpanded ? panel : !expanded);
  };

  const handleCloseFieldFilter = () => {
    setAnchorFilterEl(null);
  };

  const [report, dispatchReport] = useReducer(reducerReport, {
    page: 0,
    totalPages: 0,
    limit: 0,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
    loading: true,
  });

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const fetchData = async (query: any, isReset: boolean = false) => {
    if (report.fetching) return;
    dispatchReport({ type: Action.FETCH, payload: {} });
    try {
      const result = await await ReportService.getAllReport(query);
      if (isReset)
        dispatchReport({
          type: Action.RESET,
          payload: result.data,
        });
      else if (mounted.current)
        dispatchReport({
          type: Action.SUCCESS,
          payload: result.data,
        });
    } catch (err) {
      if (mounted)
        dispatchReport({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
    }
  };

  const [isExport, setIsExport] = useState<any>();
  const [success, setSuccess] = useState<any>();

  useEffect(() => {
    fetchData(
      {
        page: 1,
        limit: 50,
        source: "feed",
        period: time,
        searchString: searchReport,
        employeeIds: undefined,
      },
      true
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchReport, time, checked, success?.isExport === !isExport]);

  const hasMore = report.data?.length < report.totalRecords && report.page < report.totalPages;

  const onSearch = _.debounce((value) => {
    setSearchReport(value);

    value ? setVisibleSearch(true) : setVisibleSearch(false);
  }, 1000);

  const onSearchEmp = _.debounce((value) => {
    setSearchEmp(value);
  }, 500);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const submitForm = async () => {
    await fetchData(
      {
        page: 1,
        limit: 50,
        source: "feed",
        period: time,
        employeeIds: checked.length > 0 ? checked : undefined,
      },
      true
    );
  };

  const data = [...checked];

  const onCheckExport = async () => {
    try {
      const response = await ExportService.getRefreshStatus({
        type: "salesVolume",
        source: "feed",
      });
      if (mounted.current) {
        setSuccess(() => response.data);
        if (response.data.isExport === false) {
          setIsExport(false);
          clearInterval(checkExportRef.current);
        } else {
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setIsExport(report?.isExport);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchReport, time, checked, report.loading, success?.isExport === !isExport]);

  const exportReport = async () => {
    ExportService.getExportReport({
      period: time,
    })
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          setIsExport(res.data.isExport);
          checkExportRef.current = setInterval(() => {
            onCheckExport();
          }, 5000);
        } else {
          // do something
        }
      })
      .catch((err) => {});
  };

  const checkPermissionExportExcel = PermissionUtil.check(
    profile?.role?.permissions,
    [ReportPermission.MANAGE_REPORT_SALES_VOLUMES_EXPORT],
    true
  );

  useEffect(() => {
    time && report && setSearchEmpList(report?.sales);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time, checked, success?.isExport === !isExport, report]);

  const searchEmploy = () => {
    if (searchEmp !== "") {
      return setSearchEmpList(
        report.sales?.filter((f: any) => {
          const x = convertTv(f.fullName.toUpperCase());
          const y = convertTv(searchEmp.toUpperCase());
          return x.includes(y);
        })
      );
    }
    return setSearchEmpList(report.sales);
  };

  useEffect(() => {
    searchEmploy();
  }, [searchEmp]);

  //permission
  const checkPermissionViewPrice = PermissionUtil.check(
    profile?.role?.permissions,
    ItemPermission.MANAGE_ITEM_VIEW_PRICE,
    true
  );

  return (
    <div className="container">
      <div className="header">
        <div className="left">
          <TextField
            placeholder={t("ENTER_SEARCH")}
            variant="outlined"
            className="search-style"
            inputProps={{ className: "input" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="large" className="icon" />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              onSearch(e.target.value);
            }}
          />
          <button onClick={handleClick} className="btn-white">
            <div style={{ display: "flex", alignItems: "center" }}>
              <Calendar1Icon color="#272b2f" size={[20, 20]} viewBox={[20, 20]} />
              &emsp;Tháng {month}/{year}
            </div>
            <ArrowDropDownIcon />
          </button>
          <div className="filter-ic" onClick={handleOpenFieldFilter}>
            <TuneRoundedIcon />
            Chọn lọc
          </div>
          {!isExport && checkPermissionExportExcel && (
            <button
              className="btn-excel"
              onClick={() => {
                exportReport();
                setNotiExport(true);
                setSuccess(undefined);
              }}>
              <img src={Excel} alt="icon-excel" />
              {t("EXPORT_DATA")}
            </button>
          )}
        </div>
        <div className="right">
          <div className="total-prod">
            {checkPermissionViewPrice
              ? report?.totalValue
                ? currencyFormat(report.totalValue)
                : TEXT_HOLDER
              : TEXT_ASTERISK}
            đ<div className="text">Tổng doanh số</div>
          </div>
          <div className="total-value">
            {checkPermissionViewPrice
              ? report.totalWeight
                ? currencyFormat(report.totalWeight)
                : TEXT_HOLDER
              : TEXT_ASTERISK}
            kg
            <div className="text">Tổng sản lượng</div>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="content-header">
          <div className="see-all">
            <span
              className={openAll === false ? "text" : "text-active"}
              onClick={() => {
                setOpenAll(!openAll);
                !openAll && setExpanded(0);
              }}>
              {/* Xem tất cả */}
              {openAll === false ? "Danh sách" : t("DETAIL")}
              {openAll === false ? (
                <ArrowForwardIosSharpIcon
                  sx={{ fontSize: "1.1rem", color: "#41AD49", marginLeft: "1rem" }}
                />
              ) : (
                <span style={{ marginLeft: "1rem" }}>
                  <ArrowDownIcon size={[20, 15]} viewBox={[20, 20]} color="#41AD49" />
                </span>
              )}
            </span>
          </div>
          <div className="code">Mã khách hàng</div>
          <div className="customer">{t("CUSTOMER")}</div>
          <div className="production">Tổng sản lượng</div>
          <div className="value">Tổng giá trị</div>
        </div>

        {report.loading || report.fetching || searchReport ? (
          <Loading style={{ margin: "2rem 0", height: "60vh" }} />
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {report.data ? (
              report.data.length > 0 ? (
                <InfiniteScroll
                  useWindow={false}
                  pageStart={1}
                  initialLoad={false}
                  hasMore={hasMore}
                  // style={{ maxHeight: "62vh", overflow: "auto" }}
                  loadMore={() =>
                    fetchData({
                      page: report.page + 1,
                      limit: 50,
                      source: "feed",
                      period: time,
                      searchString: searchReport,
                      employeeIds: checked.length > 0 ? checked : undefined,
                    })
                  }>
                  {report.data &&
                    report.data?.map((r: any, idx: number) => {
                      return (
                        <Accordion
                          key={idx}
                          expanded={openAll ? openAll : expanded === r.id}
                          onChange={handleChange(openAll ? openAll : r.id)}
                          style={{ fontFamily: "SVN-Gotham" }}>
                          <AccordionSummary
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                            style={{ fontFamily: "SVN-Gotham" }}>
                            <Typography style={{ fontFamily: "SVN-Gotham" }}>
                              <div className="content__item">
                                <div className="see-all"></div>
                                <div className="code">{r.customer.code}</div>
                                <div className="customer">
                                  <div className="avt">
                                    <img src={Avatar} alt="avatar" />
                                  </div>
                                  <div className="name">{r.customer.fullName}</div>
                                </div>
                                <div className="production">{currencyFormat(r.totalWeight)} kg</div>
                                <div className="value">
                                  {checkPermissionViewPrice
                                    ? currencyFormat(parseFloat(r.totalValue))
                                    : TEXT_ASTERISK}{" "}
                                  đ
                                </div>
                              </div>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails style={{ fontFamily: "SVN-Gotham" }}>
                            <Typography style={{ fontFamily: "SVN-Gotham" }}>
                              {r.periodReports?.map((per: any, perIndex: number) => {
                                return (
                                  <div className="content__content" key={perIndex}>
                                    <div className="content__content__header">
                                      <div className="ref-code">
                                        {t("order:REFERENCE_CODE")}: {per.refCode}
                                      </div>
                                      <div className="verhicle-number">
                                        {t("VEHICLE_NUMBER")}: {per.licensePlate}
                                      </div>
                                      <div className="sale">
                                        Nhân Viên:
                                        <div className="sale-name">{per.salesMan.fullName}</div> |
                                        <div className="sale-name">{per.salesAss.fullName}</div> |
                                        <div className="sale-name">{per.salesSup.fullName}</div> |
                                        <div className="sale-name">{per.salesRef.fullName}</div>
                                      </div>
                                    </div>
                                    <div className="product-list">
                                      <div className="product-list__header">
                                        <div className="code">{t("PRODUCT_CODE")}</div>
                                        <div className="name">{t("PRODUCT")}</div>
                                        <div className="production">Sản lượng</div>
                                        <div className="value">Giá trị</div>
                                      </div>
                                      {per.periodReportDetails?.map(
                                        (prd: any, prdIndex: number) => {
                                          return (
                                            <div key={prdIndex}>
                                              <Divider />
                                              <div className="product-list__content">
                                                <div className="code">{prd.item.code}</div>
                                                <div className="name">{prd.item.name}</div>
                                                <div className="production">
                                                  {currencyFormat(prd.weight)} kg
                                                </div>
                                                <div className="value">
                                                  {checkPermissionViewPrice
                                                    ? currencyFormat(parseFloat(prd.value))
                                                    : TEXT_ASTERISK}{" "}
                                                  đ
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                </InfiniteScroll>
              ) : visibleSearch === true ? (
                <EmptyList img={Search} title={t("NO_RESULT_FOUND")} />
              ) : (
                <EmptyList img={Img} title={t("EMPTY_LIST")} />
              )
            ) : (
              <EmptyList img={Img} title={t("EMPTY_LIST")} />
            )}
          </>
        )}
      </div>

      <Popover
        open={Boolean(anchorFilterEl)}
        anchorEl={anchorFilterEl}
        onClose={handleCloseFieldFilter}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}>
        <Form onSubmit={submitForm}>
          {({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="filter-listing">
                  <div className="title">Lọc</div>
                  <div onClick={handleCloseFieldFilter}>
                    <CloseIcon
                      style={{
                        fontSize: "2.2rem",
                        position: "absolute",
                        top: "3.3rem",
                        right: "4.7rem",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  <div className="label">Nhân viên</div>
                  <TextField
                    placeholder="Tìm kiếm nhân viên..."
                    variant="outlined"
                    color="success"
                    className="form-text-field"
                    inputProps={{ className: "input", style: { height: "1rem" } }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon fontSize="large" className="icon" />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      onSearchEmp(e.target.value);
                      searchEmploy();
                    }}
                  />
                  <div className="customer-list">
                    <div
                      className={
                        report && data.length === report.sales?.length
                          ? "customer-name__active"
                          : "customer-name"
                      }>
                      <Checkbox
                        className="app-check-box"
                        checked={report && data.length === report.sales?.length}
                        onChange={(e, value) => {
                          if (value === true) {
                            data.push(...(report.sales?.map((a: any) => a.id) || []));
                            Array.from(new Set(data));
                            setChecked(Array.from(new Set(data)));
                          } else {
                            setChecked([]);
                          }
                        }}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{
                              fontSize: "2.2rem",
                            }}
                          />
                        }
                        checkedIcon={
                          <CheckBoxIcon
                            style={{
                              fontSize: "2.2rem",
                            }}
                          />
                        }
                      />
                      <span>Tất cả nhân viên</span>
                    </div>

                    {searchEmpList?.map((rp: any, index: number) => {
                      return (
                        <div
                          className={
                            data.includes(rp.id) ? "customer-name__active" : "customer-name"
                          }
                          key={rp.id + index}>
                          <Checkbox
                            className="app-check-box"
                            value={rp.id}
                            checked={data.includes(rp.id)}
                            onChange={(e, value) => {
                              if (value === true) {
                                data.push(rp.id);
                                setChecked(data);
                              } else {
                                setChecked(
                                  data.filter((id) => {
                                    return id !== rp.id;
                                  })
                                );
                              }
                            }}
                            icon={<CheckBoxOutlineBlankIcon style={{ fontSize: "2.2rem" }} />}
                            checkedIcon={<CheckBoxIcon style={{ fontSize: "2.2rem" }} />}
                          />
                          <img
                            style={{ width: "3.2rem", height: "3.2rem" }}
                            src={Avatar}
                            alt="avatar"
                          />
                          {rp.fullName}
                        </div>
                      );
                    })}
                  </div>

                  <div className="btn-group">
                    <div
                      className="delete"
                      onClick={() => {
                        setChecked([]);
                      }}>
                      Xoá lọc
                    </div>
                    <button className="confirm" onClick={handleCloseFieldFilter}>
                      Áp dụng
                    </button>
                  </div>
                </div>
              </form>
            );
          }}
        </Form>
      </Popover>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}>
        <div className="popover-date">
          <Grid container spacing={1} style={{ paddingBottom: 17 }} className="popover-date-select">
            <Grid item xs={6}>
              <Select
                value={month}
                onChange={(e) => {
                  setMonth(e.target.value);
                }}
                input={<OutlinedInput />}
                fullWidth
                color="success"
                MenuProps={{
                  className: "select-menu-list popover-date-menu-list",
                  anchorOrigin: {
                    vertical: 40,
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}>
                {Month.map((d: any, idx: number) => {
                  return (
                    <MenuItem
                      key={idx}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                      value={d.month}>
                      {d.month}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <Select
                value={year}
                onChange={(e) => {
                  setYear(e.target.value);
                }}
                input={<OutlinedInput />}
                fullWidth
                color="success"
                MenuProps={{
                  className: "select-menu-list popover-date-menu-list",
                  anchorOrigin: {
                    vertical: 40,
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}>
                {YearList().map((y: any, idx: number) => {
                  return (
                    <MenuItem
                      key={idx}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                      value={y}>
                      {y}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          </Grid>
          <div style={{ display: "flex" }}>
            <button className="btn-cancel-style" onClick={handleClose}>
              {t("CANCEL")}
            </button>
            <button
              className="btn-confirm-style"
              onClick={() => {
                setTime(year + "-" + month);
                handleClose();
              }}>
              {t("CONFIRM")}
            </button>
          </div>
        </div>
      </Popover>

      <NotiExportExcel
        notiExport={notiExport}
        title="Báo cáo sản lượng"
        closeForm={() => {
          setNotiExport(false);
          clearInterval(checkExportRef.current);
        }}
        success={success?.isExport === false ? success?.fileName : undefined}
      />
    </div>
  );
};

export default Report;
