import { IconBase, IconComponent } from "@Core/Icons";

export const AccountSubIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1312 4.30614C12.6052 4.30614 11.3681 5.59676 11.3681 7.18882C11.3681 8.78088 12.6052 10.0715 14.1312 10.0715C15.6573 10.0715 16.8944 8.78088 16.8944 7.18882C16.8944 5.59676 15.6573 4.30614 14.1312 4.30614ZM9.15759 7.18882C9.15759 4.32311 11.3844 2 14.1312 2C16.8781 2 19.1049 4.32311 19.1049 7.18882C19.1049 10.0545 16.8781 12.3776 14.1312 12.3776C11.3844 12.3776 9.15759 10.0545 9.15759 7.18882Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.46534 17.1468C9.32384 15.5537 11.691 14.6837 14.1319 14.6836C16.5729 14.6837 18.9403 15.5539 20.7988 17.1471C22.6587 18.7415 23.8869 20.9567 24.241 23.3932L24.2431 23.4072C24.2874 23.7393 24.2591 24.0765 24.1612 24.3951C24.0635 24.7135 23.8994 25.003 23.6839 25.2456C23.4686 25.4879 23.207 25.6781 22.9186 25.8068C22.6308 25.9352 22.3209 26.0004 22.0092 25.9998L22.0106 24.8467V25.9998H22.0092H6.70117M14.1319 16.9897C12.1904 16.9898 10.3221 17.6827 8.86863 18.9286C7.42974 20.162 7.16859 20.5847 7.16859 20.5847L6.9008 23.6871L9.18201 23.6937L22.0106 23.6937L22.013 23.6937C22.0267 23.6937 22.0386 23.6909 22.0478 23.6871C21.7684 21.8521 20.8341 20.1622 19.3954 18.9289C17.9419 17.6828 16.0735 16.9899 14.1319 16.9897Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7981 17.1468C18.9396 15.5537 16.5724 14.6837 14.1315 14.6836C11.6905 14.6837 9.32313 15.5539 7.4646 17.1471C5.60474 18.7415 4.37649 20.9567 4.02239 23.3932L4.02036 23.4072C3.97607 23.7393 4.00436 24.0765 4.10219 24.3951C4.19996 24.7135 4.36403 25.003 4.57957 25.2456C4.79486 25.4879 5.05647 25.6781 5.34479 25.8068C5.63268 25.9352 5.94255 26.0004 6.25421 25.9998L6.25284 24.8467V25.9998H6.25421H21.5623M14.1316 16.9897C16.073 16.9898 17.9413 17.6827 19.3948 18.9286C20.8337 20.162 21.0948 20.5847 21.0948 20.5847L21.3626 23.6871L19.0814 23.6937L6.25284 23.6937L6.25041 23.6937C6.23677 23.6937 6.22479 23.6909 6.21563 23.6871C6.49504 21.8521 7.4293 20.1622 8.86799 18.9289C10.3215 17.6828 12.19 16.9899 14.1316 16.9897Z"
        fill={props.color}
      />
    </IconBase>
  );
};
