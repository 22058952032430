import { IconBase, IconComponent } from "@Core/Icons";

export const FarmdIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        d="M14.4287 14.5078H8.91666C8.66593 14.5089 8.42582 14.6092 8.2489 14.7869C8.07198 14.9646 7.97265 15.2051 7.97266 15.4558V21.1998H9.57266V16.1078H14.4287V21.1998H16.0287V15.4558C16.0287 15.2051 15.9293 14.9646 15.7524 14.7869C15.5755 14.6092 15.3354 14.5089 15.0847 14.5078H14.4287Z"
        fill={props.color}
      />
      <path
        d="M22.8848 10.5929L18.3088 5.53291L17.6848 4.84491L12.5648 1.95291C12.4083 1.86346 12.2311 1.81641 12.0508 1.81641C11.8705 1.81641 11.6933 1.86346 11.5368 1.95291L6.4008 4.96891L5.8288 5.60091C5.80826 5.61611 5.78947 5.63355 5.7728 5.65291L1.1208 10.5849C0.986949 10.7297 0.911275 10.9188 0.908295 11.1159C0.905314 11.313 0.975238 11.5043 1.10465 11.6531C1.23406 11.8018 1.41384 11.8976 1.6095 11.9219C1.80515 11.9462 2.0029 11.8974 2.1648 11.7849V20.1529C2.16586 20.6911 2.38041 21.207 2.76137 21.5872C3.14233 21.9674 3.65857 22.1809 4.1968 22.1809H19.9048C20.443 22.1809 20.9593 21.9674 21.3402 21.5872C21.7212 21.207 21.9357 20.6911 21.9368 20.1529V11.8449C22.0799 11.9158 22.2409 11.9423 22.3992 11.9209C22.5574 11.8995 22.7057 11.8313 22.8248 11.7249C22.9032 11.6546 22.967 11.5696 23.0125 11.4746C23.058 11.3796 23.0843 11.2765 23.0899 11.1714C23.0955 11.0662 23.0802 10.961 23.045 10.8617C23.0098 10.7624 22.9554 10.6711 22.8848 10.5929ZM20.3368 20.1529C20.3357 20.2668 20.2898 20.3756 20.2089 20.4558C20.128 20.5359 20.0187 20.5809 19.9048 20.5809H4.1968C4.08291 20.5809 3.97363 20.5359 3.89273 20.4558C3.81183 20.3756 3.76585 20.2668 3.7648 20.1529V10.1529L7.4088 6.22491L11.9528 3.55691C11.9825 3.5393 12.0163 3.53001 12.0508 3.53001C12.0853 3.53001 12.1191 3.5393 12.1488 3.55691L16.6728 6.10891L17.1208 6.60491L18.8208 8.48491L20.3368 10.1569V20.1529Z"
        fill={props.color}
      />
      <path
        d="M11.9997 12.7475C11.4087 12.7475 10.8311 12.5722 10.3397 12.2439C9.8483 11.9156 9.46532 11.4489 9.23917 10.9029C9.01301 10.357 8.95384 9.75617 9.06913 9.17656C9.18443 8.59694 9.46901 8.06453 9.88689 7.64665C10.3048 7.22877 10.8372 6.94419 11.4168 6.8289C11.9964 6.71361 12.5972 6.77278 13.1432 6.99893C13.6892 7.22509 14.1558 7.60807 14.4842 8.09944C14.8125 8.59082 14.9877 9.16852 14.9877 9.75949C14.9867 10.5516 14.6715 11.311 14.1114 11.8712C13.5513 12.4313 12.7919 12.7464 11.9997 12.7475ZM11.9997 8.37549C11.725 8.37549 11.4565 8.45699 11.2282 8.60969C10.9998 8.76238 10.8219 8.97939 10.717 9.23325C10.6121 9.48711 10.5848 9.76639 10.6387 10.0357C10.6926 10.3051 10.8252 10.5524 11.0197 10.7464C11.2142 10.9403 11.4619 11.0722 11.7314 11.1253C12.0009 11.1784 12.2801 11.1503 12.5337 11.0447C12.7872 10.939 13.0037 10.7605 13.1557 10.5317C13.3078 10.3029 13.3885 10.0342 13.3877 9.75949C13.3867 9.39206 13.24 9.04004 12.9798 8.78061C12.7196 8.52117 12.3671 8.37548 11.9997 8.37549Z"
        fill={props.color}
      />
    </IconBase>
  );
};
