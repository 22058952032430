import { REQUEST_URL } from "@Constants";
import { PaginationQueryModel } from "@Models";
import { RequestUtil } from "@Utils";

export const NewService = {
  getAllNews: (
    query: PaginationQueryModel & {
      from: string;
      to: string;
      searchString: string;
      isDraft?: boolean;
      isActive?: boolean;
    }
  ) => {
    const url = REQUEST_URL.NEWS_GET_ALL;
    return RequestUtil.get({ url, query: query });
  },

  getNewsById: (id: string) => {
    const url = REQUEST_URL.NEWS_DETAIL_DELETE_UPDATE.replace("{newsId}", id);
    return RequestUtil.get({ url });
  },

  createNews: (formData: FormData) => {
    const url = REQUEST_URL.NEWS_POST;
    return RequestUtil.post({ url, data: formData });
  },
};
