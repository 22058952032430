export * from "./shared";
export * from "./app.model";
export * from "./other.model";
export * from "./customer.model";
export * from "./location.model";
export * from "./notification.model";
export * from "./address.model";
export * from "./debt.model";
export * from "./product.model";
export * from "./order.model";
export * from "./discount.model";
export * from "./plan.model";
export * from "./salesorg.model";
export * from "./news.model";
export * from "./feedback.model";
export * from "./report.model";
