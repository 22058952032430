/* eslint-disable react-hooks/exhaustive-deps */
import Img from "@Assets/images/empty.png";
import Excel from "@Assets/images/icons/excel.png";
import Search from "@Assets/images/icons/img-search.png";
import EmptyList from "@Components/EmptyList";
import FloatingBtn from "@Components/FloatingBtn";
import Loading from "@Components/Loading";
import { APP_ROUTES, NavHeaderSubAccount, TEXT_HOLDER } from "@Constants";
import { Action, FetchAction, SubAccountModel, SubAccountPermission } from "@Models";
import { PaginateFetchReducer } from "@Models/shared";
import { FiberManualRecord as DotIcon, Search as SearchIcon } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import { CustomerService } from "@Services";
import _ from "lodash";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { useHistory } from "react-router-dom";
import "./index.scss";
import { PermissionUtil } from "@Utils";
import { useSnackbar } from "notistack";

type P_Props = {
  accountType: string;
};

function reducer(state: PaginateFetchReducer<SubAccountModel>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: state.data?.concat(action.payload.data) || [],
        loading: false,
      };
    case Action.RESET:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload.data,
        loading: false,
      };
    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload, loading: false };

    default:
      return { ...state };
  }
}

const SubAccount: React.FC<P_Props> = ({ accountType }) => {
  const { t } = useTranslation(["translation", "customer"]);
  // const checkExportRef = useRef<any>(null);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useRef(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [visibleSearch, setVisibleSearch] = useState<boolean>(false);
  const [selectTab, setSelectTab] = useState<any>("ALL");
  const [profile, setProfile] = useState<any>([]);

  const loadProfileData = _.debounce(async () => {
    const response = await CustomerService.getAuthProfile();
    setProfile(response.data);
  }, 100);

  useEffect(() => {
    loadProfileData();
    //eslint-disable-next-line
  }, []);

  const [state, dispatch] = useReducer(reducer, {
    page: 0,
    totalPages: 0,
    limit: 0,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
    loading: true,
  });

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const fetchData = async (query: any, isReset: boolean = false) => {
    if (state.fetching) return;
    dispatch({ type: Action.FETCH, payload: {} });
    try {
      const result = await CustomerService.getAllSubAccount(query);
      if (isReset)
        dispatch({
          type: Action.RESET,
          payload: result.data,
        });
      else if (mounted.current)
        dispatch({
          type: Action.SUCCESS,
          payload: result.data,
        });
    } catch (err) {
      if (mounted)
        dispatch({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
    }
  };

  const [isExport, setIsExport] = useState<any>();
  const [success, setSuccess] = useState<any>();

  useEffect(() => {
    fetchData(
      {
        page: 1,
        limit: 50,
        strSearch: searchValue,
        status: selectTab,
      },
      true
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, selectTab, success?.isExport === !isExport]);

  const hasMore = state.data.length < state.totalRecords && state.page < state.totalPages;

  const onSearch = _.debounce((value) => {
    setSearchValue(value);

    value ? setVisibleSearch(true) : setVisibleSearch(false);
  }, 500);

  useEffect(() => {
    setIsExport(state?.isExport);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectTab, state.loading, success?.isExport === !isExport]);

  //permission
  const checkPermissionExportExcel = PermissionUtil.check(
    profile?.role?.permissions,
    [SubAccountPermission.MANAGE_SUBACCOUNT_EXPORT],
    true
  );

  const checkPermissionSubAccountCreate = PermissionUtil.check(
    profile?.role?.permissions,
    SubAccountPermission.MANAGE_SUBACCOUNT_ADD,
    true
  );

  const checkPermissionSubAccountViewDetail = PermissionUtil.check(
    profile?.role?.permissions,
    SubAccountPermission.MANAGE_SUBACCOUNT_VIEWDETAILS,
    true
  );

  return (
    <div className="sub-account">
      <div className="account-content">
        <div className="action">
          <div className="account__nav-header">
            {NavHeaderSubAccount.map((o, idx: number) => {
              return (
                <div
                  key={o.id + idx}
                  className={
                    // cancel
                    //   ? o.value === "cancelled"
                    //     ? "tab tab-active"
                    //     : "tab tab-hover"
                    //   :
                    o.value === selectTab ? "tab tab-active" : "tab tab-hover"
                  }
                  onClick={() => {
                    setSelectTab(o.value);
                    // if (o.title === "cancelled") {
                    //   setCancel(true);
                    // } else {
                    //   setCancel(false);
                    // }
                  }}>
                  {t(`SUB_${o.label}`)} {o.title && <span>{state?.count?.[o.title]}</span>}
                </div>
              );
            })}
          </div>
          <div style={{ display: "flex" }}>
            <TextField
              placeholder={t("ENTER_SEARCH")}
              variant="outlined"
              className={"search-style"}
              inputProps={{ className: "input" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="large" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                onSearch(e.target.value);
              }}
            />
            {!isExport && checkPermissionExportExcel && (
              <button
                className="btn-excel"
                onClick={() => {
                  setSuccess(undefined);
                }}>
                <img src={Excel} alt="icon-excel" />
                {t("EXPORT_DATA")}
              </button>
            )}
          </div>
        </div>
        <div className="list">
          <div className="list-header">
            <div className="name">{t("SUB_ACCOUNT_NAME")}</div>
            <div className="code">{t("LOGIN_CODE")}</div>
            <div className="phone">{t("PHONE_NUMBER")}</div>
            <div className="email">{t("EMAIL")}</div>
            <div className="name">{t("MAIN_ACCOUNT")}</div>
            <div className="role">{t("ROLE")}</div>
            <div className="status">{t("STATUS")}</div>
          </div>
          {state.loading || (state.fetching && searchValue) ? (
            <Loading style={{ margin: "2rem 0", height: "60vh" }} />
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {state.data ? (
                state.data.length > 0 ? (
                  <InfiniteScroll
                    useWindow={false}
                    pageStart={0}
                    initialLoad={false}
                    hasMore={hasMore}
                    loadMore={() =>
                      fetchData({
                        limit: 50,
                        page: state.page + 1,
                        strSearch: searchValue,
                        status: selectTab,
                      })
                    }>
                    {state.data.map((act: SubAccountModel) => {
                      return (
                        <div
                          className="item"
                          key={act.code}
                          onClick={() => {
                            checkPermissionSubAccountViewDetail
                              ? history.push(
                                  APP_ROUTES.SUB_ACCOUNT_DETAIL.replace(
                                    ":accountId",
                                    act?.id.toString()
                                  )
                                )
                              : enqueueSnackbar(t("ACCESS_DENIED"), {
                                  variant: "error",
                                  preventDuplicate: true,
                                });
                          }}>
                          <div className="name">{act.nickname}</div>
                          <div className="code">{act.code}</div>
                          <div className="phone">{act.phoneNumber || TEXT_HOLDER}</div>
                          <div className="email">{act.email || TEXT_HOLDER}</div>
                          <div className="name">{act.parentAccount?.nickname}</div>
                          <div className="role">{act.role?.position || TEXT_HOLDER}</div>
                          <div className={`status ${act.status.toLowerCase()}`}>
                            <DotIcon style={{ fontSize: "1rem" }} />{" "}
                            {t(`SUB_${act.status.toUpperCase()}`)}
                          </div>
                        </div>
                      );
                    })}
                  </InfiniteScroll>
                ) : visibleSearch === true ? (
                  <EmptyList img={Search} title={t("NO_RESULT_FOUND")} />
                ) : (
                  <EmptyList img={Img} title={t("EMPTY_LIST")} />
                )
              ) : (
                <EmptyList img={Img} title={t("EMPTY_LIST")} />
              )}
            </>
          )}
        </div>
      </div>
      {checkPermissionSubAccountCreate && (
        <FloatingBtn
          visible={false}
          onClickPrimary={() => {
            history.push("create-" + accountType);
            // setOpenAccountModal(true);
          }}
          btns={[]}
        />
      )}

      {/* <NotiExportExcel
        notiExport={notiExport}
        title={t("SUB_ACCOUNT")}
        closeForm={() => {
          setNotiExport(false);
          clearInterval(checkExportRef.current);
        }}
        success={success?.isExport === false ? success?.fileName : undefined}
      /> */}
    </div>
  );
};

export default SubAccount;
