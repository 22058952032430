export const APP_ROUTES = {
  HOME: "/",
  SIGNIN: "/sign-in",
  SIGNUP: "/sign-up",
  VERIFY_OTP: "/verify-otp",
  IS_BLOCKED: "/is-blocked",
  FORGETPASSWORD: "/forget-password",
  RESETPASSWORD: "/reset-password",

  //order
  ORDER: "/order",
  ORDER_FEED: "/order-feed",
  ORDER_FARM: "/order-farm",
  ORDER_VET: "/order-vet",
  ORDER_LAB: "/order-lab",
  ORDER_CCU: "/order-ccu",

  ORDER_DETAIL: "/order/:orderId",
  ORDER_DETAIL_FARM: "/order-farm/:orderId",
  ORDER_DETAIL_VET: "/order-vet/:orderId",
  ORDER_DETAIL_LAB: "/order-lab/:orderId",

  UPDATE_ORDER_DRAFT: "/update-order-draft/:orderId",
  UPDATE_ORDER_DRAFT_FARM: "/update-order-draft-farm/:orderId",
  UPDATE_ORDER_DRAFT_VET: "/update-order-draft-vet/:orderId",
  UPDATE_ORDER_FEED: "/update-order-feed/:orderId",
  UPDATE_ORDER_VET: "/update-order-vet/:orderId",
  UPDATE_ORDER_LAB: "/update-order-lab/:orderId",
  UPDATE_ORDER_LAB_SAMPLE: "/update-order-lab-sample/:orderId",

  CREATE_FEED: "/create-feed",
  CREATE_FEED_ID: "/create-feed/:orderId",
  CREATE_FARM: "/create-farm",
  CREATE_CCU: "/create-ccu",
  CREATE_VET: "/create-vet",
  CREATE_LAB: "/create-lab",

  //plan (kế hoạch đặt hàng)
  PLAN: "/plan",
  PLAN_DETAIL: "/plan-detail/:planId",
  CREATE_PLAN: "/create-plan",
  UPDATE_PLAN: "/update-plan/:planId",
  DRAFT_PLAN: "/draft-plan/:planId",
  RECREATE_PLAN: "/recreate-plan/:planId",

  //customer
  CUSTOMER: "/customer",
  IDENTITY: "/settings/customer-identity",
  SIGNATURE: "/settings/customer-signature",
  RESET_PASSWORD: "/settings/customer-reset-password",
  PRIVACY_POLICY: "/settings/customer-privacy-policy",

  //sub account
  SUB_ACCOUNT: "/sub-account",
  CREATE_SUB_ACCOUNT: "/create-sub-account",
  UPDATE_SUB_ACCOUNT: "/update-sub-account/:accountId",
  SUB_ACCOUNT_DETAIL: "/sub-account/:accountId",

  //other
  COMMINGSOON: "/comming-soon",
  ACCOMMINGSOON: "/ac-comming-soon",
  SETTING: "/settings",

  //debt
  DEBT_DETAIL: "/debt/:debtId",
  REPORT_SALES: "/dashboard/report-sales",
  REPORT: "/dashboard/report",

  //dashboard
  DASHBOARD: "/dashboard",
  DASHBOARD_OVERVIEW: "/dashboard/overview",
  DEBT: "/dashboard/debt",

  //product
  PRODUCT: "/product",
  PRODUCT_FEED: "/product-feed",
  PRODUCT_FARM: "/product-farm",
  PRODUCT_VET: "/product-vet",
  PRODUCT_LAB: "/product-lab",

  //news
  NEWS: "/news",
  NEWS_DETAIL: "/detail-news/:newsId",

  //bill
  BILL: "/bill",
  BILL_FEED: "/bill-feed",
  BILL_FARM: "/bill-farm",

  //discount
  DISCOUNT_LETTER: "/dashboard/discount-letter",
  REPORT_DISCOUNT: "/dashboard/discount",
  REPORT_REVENUE: "/dashboard/report-revenue",

  //access-denied
  ACCESS_DENIED: "/access-denied",
} as const;
