/* eslint-disable */
import IconClose from "@Assets/images/icons/ic_close.png";
import DO from "@Components/DO";
import { DoIcon } from "@Components/Icons/DoIcon";
import Loading from "@Components/Loading";
import Notification from "@Components/Notification";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import { APP_ROUTES, ReasonStatus, TEXT_ASTERISK, TEXT_HOLDER, UpdateHistory } from "@Constants";
import { ItemPermission, OrderPermission, OrderStatus, Reason } from "@Models";
import { Nullable } from "@Models/shared";
import { CustomerService, OrderService } from "@Services";
import {
  PermissionUtil,
  StatusUIUtil,
  checkLinkImage,
  currencyFormat,
  renderErrorImageItem,
  renderImageProNull,
} from "@Utils";
import {
  ArrowDropDown as ArrowDropDownIcon,
  Clear as ClearIcon,
  ExpandMore as ExpandMoreIcon,
  PriorityHighRounded as PriorityHighRoundedIcon,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import _ from "lodash";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "./OrderDetailFarm.scss";

const OrderDetailFarm: React.FC = () => {
  const { t } = useTranslation(["translation"]);
  const submitRef = useRef<any | null>(null);
  const { orderId }: { orderId: string } = useParams();
  const history = useHistory();
  const location = useLocation<any>();
  const mounted = useRef(false);
  const [order, setOrder] = useState<Nullable<any>>(null);
  const [openListDO, setOpenListDO] = useState<number>(0);
  const [open, setOpen] = useState<string | false>(false);
  const handleChangeOpen = (open: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setOpen(isExpanded ? open : false);
  };
  const [showCancel, setShowCancel] = useState<boolean>(false);
  const [cancelValue, setCancelValue] = useState<string>(Reason.NOT_RESPONSE);
  const [reason, setReason] = useState<string>("");
  const [border, setBorder] = useState<boolean>(true);
  const [submitCancel, setSubmitCancel] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const [profile, setProfile] = useState<any>([]);

  const loadProfileData = _.debounce(async () => {
    const response = await CustomerService.getAuthProfile();
    setProfile(response.data);
  }, 100);

  useEffect(() => {
    loadProfileData();
    //eslint-disable-next-line
  }, []);

  const fetchData: () => Promise<void> = async () => {
    try {
      const result = await OrderService.getOrderById(orderId);
      if (mounted.current) {
        setOrder(result.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const childOrderCheck =
    order &&
    order?.orders
      .find((orders: any) => orders?.id === location?.state?.orderId)
      ?.childOrders.find((child: any) => child?.id === location?.state?.childOrderId);

  const scrollToMyRef = () =>
    (
      document.getElementById(location?.state?.childOrderId?.toString()) as HTMLElement
    ).scrollIntoView({
      behavior: "smooth",
    });

  useEffect(() => {
    if (childOrderCheck?.id === location?.state?.childOrderId) {
      setBorder(true);
    }
    if (document.getElementById(location?.state?.childOrderId?.toString())) {
      scrollToMyRef();
    }
    setTimeout(() => setBorder(false), 3000);
  }, [order]);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, [order]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location?.state?.orderId && location?.state?.childOrderId) {
      setOpen(location?.state?.orderId.toString());
    } else {
      order?.orders.length === 1 &&
        order?.orders[0].childOrders.length > 0 &&
        setOpen(order.orders[0].id.toString());
    }
  }, [order, location?.state?.childOrderId, order?.orders[0].childOrders.length > 0]);

  const gridSize: any =
    12 /
    (open
      ? order?.orders
          .filter((f: any) => f.id.toString() === open)?.[0]
          ?.childOrders.filter((child: any) => child.status !== OrderStatus.CANCELLED)?.length + 2
      : order?.orders?.length > 1
      ? order?.orders?.length + 2
      : order?.orders?.length + 1);

  const status = Array.from(
    new Set(
      order?.orders.map((o: any) => {
        return o.status;
      })
    )
  );

  const cancelOrderFarm = async () => {
    if (submitRef.current) {
      submitRef.current.disabled = true;
    }
    try {
      await OrderService.cancelOrderFarm({
        reasonStatus: cancelValue,
        orderGroupId: +orderId,
        reason: reason,
        source: "farm",
      });
      enqueueSnackbar(t("ORDER_CANCELLED_SUCCESSFULLY"), {
        variant: "success",
      });
      history.push(APP_ROUTES.ORDER_FARM);
    } catch (error) {
      enqueueSnackbar(t("CANCEL_ORDER_FAILED"), {
        variant: "error",
      });
      setSubmitCancel(false);
      submitRef.current.disabled = false;
    }
  };

  //permission
  const checkPermissionViewPrice = PermissionUtil.check(
    profile?.role?.permissions,
    ItemPermission.MANAGE_ITEM_VIEW_PRICE,
    true
  );
  const checkPermissionCancelOrder = PermissionUtil.check(
    profile?.role?.permissions,
    OrderPermission.MANAGE_ORDER_CANCEL,
    true
  );

  return (
    <Dialog fullScreen open={true}>
      <div className="order-farm-detail">
        <SimpleModalHeader
          title={t("ORDER_DETAIL")}
          onClose={() => {
            history.push({
              pathname: APP_ROUTES.ORDER_FARM,
              state: {
                startTime: location.state?.startTime,
                endTime: location.state?.endTime,
                status: location.state?.status,
              },
            });
          }}
        />
        <div className="order-farm-detail-container">
          {!!order ? (
            <>
              <div className="content">
                <Grid container className="order-farm-detail-info">
                  <Grid item xs={status.includes(OrderStatus.CREATED) ? 3 : 4}>
                    <div className="label">{t("ORDER_ID")}</div>#{order.code}
                  </Grid>
                  <Grid item xs={3}>
                    <div className="label">{t("ORDER_TIME")}</div>
                    {moment(order.createdAt).format("YYYY-MM-DD - HH:mm")}
                  </Grid>
                  <Grid item xs={status.includes(OrderStatus.CREATED) ? 2 : 3}>
                    <div className="label">{t("ORDER_PLACER")}</div>
                    {order.createdBy?.account?.code && order.createdBy?.account?.code + " - "}
                    {order.createdBy?.fullName}
                  </Grid>
                  <Grid item xs={2} className="order-farm-detail-info-status">
                    <div className="label">{t("STATUS")}</div>
                    {StatusUIUtil.renderBgStatus(order.status, "farm")}
                  </Grid>
                  {status.includes(OrderStatus.CREATED) && (
                    <Grid item xs={2} justifyContent="flex-end" display="flex" alignItems="center">
                      <button
                        className="btn-confirm-style"
                        onClick={() => {
                          checkPermissionCancelOrder
                            ? setShowCancel(true)
                            : enqueueSnackbar(t("ACCESS_DENIED"), {
                                preventDuplicate: true,
                                variant: "error",
                              });
                        }}>
                        <img src={IconClose} alt="icon-close" />
                        {t("CANCEL_ORDER")}
                      </button>
                    </Grid>
                  )}
                </Grid>
                {order.status !== OrderStatus.COMPLETED && (
                  <div className="col-text">{t("PIG_PRICE_TIME_OF_DELIVERY")}</div>
                )}
                <div className={"list list-farm"}>
                  {order.orders.map((g: any) => {
                    return (
                      <Accordion
                        expanded={open === g.id.toString()}
                        className="list-child"
                        onClick={() => handleChangeOpen(open as any)}
                        style={{
                          fontFamily: "SVN-Gotham",
                        }}>
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon
                              style={{
                                color: "#0A6836",
                                fontSize: "2.2rem",
                              }}
                            />
                          }
                          className="title"
                          style={{
                            fontFamily: "SVN-Gotham",
                          }}>
                          <div className="order-farm-detail-header">
                            <div
                              className="detail"
                              onClick={() => {
                                open === g.id.toString()
                                  ? setOpen(false)
                                  : setOpen(g.id.toString());
                              }}>
                              {open === g.id.toString() ? "Thu gọn" : t("DETAIL")}
                            </div>
                            <div className="name">
                              {t("BREED")} ({g.orderlines.length})
                            </div>
                            <div className="quan">{t("QUANTITY")}</div>
                            <div className="weight">{t("TOTAL_WEIGHT")}</div>
                            <div className="price">{t("UNIT_PRICE")}</div>
                            <div className="unit">{t("STOCK_FEE_EXCESS_PORTION")}</div>
                            <div className="total">{t("TOTAL_PRICE")}</div>
                            <div className="chip">
                              {StatusUIUtil.renderBgStatus(g.status, "farm")}
                            </div>
                          </div>
                          {g.orderlines.map((o: any, idx: number) => {
                            return (
                              <>
                                <div style={{ background: "#f7f7f7" }} className="item" key={idx}>
                                  <div className="detail">
                                    <img
                                      src={
                                        checkLinkImage(o.item.images?.picture) ||
                                        renderImageProNull(order?.orderType)
                                      }
                                      alt={o.item.shortName}
                                      onError={renderErrorImageItem(order?.orderType)}
                                    />
                                  </div>
                                  <div className="name">
                                    <div>
                                      {o.item.name}
                                      {/* &ensp;&#8226;&ensp;
                                      {Array.isArray(o?.item.dimension)
                                        ? o.item.dimension
                                            .filter((f: any) => {
                                              return +f[0] === +o.dimensionToShow;
                                            })
                                            .map((d: any) => {
                                              return (
                                                <span>{d[1] === null ? "Trên " + d[0] : d[1]}</span>
                                              );
                                            })
                                        : o.item.dimension}
                                      &ensp;{o.baseUoM} */}
                                      <div className="code">{o.item.code}</div>
                                    </div>
                                  </div>
                                  <div className="quan">
                                    {o.orderQuantity ? parseFloat(o.orderQuantity) : TEXT_HOLDER}
                                  </div>
                                  <div className="weight">
                                    {currencyFormat(+o.dimension)}&ensp;kg
                                    {/* {o.baseUoM} */}
                                  </div>
                                  <div className="price">
                                    {checkPermissionViewPrice
                                      ? currencyFormat(o.price)
                                      : TEXT_ASTERISK}
                                    &ensp;đ/
                                    <span style={{ color: "#41ad49" }}>{o.item.baseUoM}</span>
                                  </div>
                                  <div className="unit">
                                    {(() => {
                                      if (+o.attachUnit) {
                                        if (o.prices?.priceType === "increment") {
                                          return (
                                            <span>
                                              {(checkPermissionViewPrice
                                                ? currencyFormat(o.attachUnit)
                                                : TEXT_ASTERISK) + " đ/"}
                                              <span style={{ color: "#41ad49" }}>{o.baseUoM}</span>
                                            </span>
                                          );
                                        } else {
                                          return (
                                            <span>
                                              {(checkPermissionViewPrice
                                                ? currencyFormat(o.attachUnit)
                                                : TEXT_ASTERISK) + " đ/"}
                                              <span style={{ color: "#41ad49" }}>{o.saleUnit}</span>
                                            </span>
                                          );
                                        }
                                      } else return TEXT_HOLDER;
                                    })()}
                                  </div>
                                  <div className="total">
                                    {checkPermissionViewPrice
                                      ? currencyFormat(o.subTotal)
                                      : TEXT_ASTERISK}
                                    &ensp;đ
                                  </div>
                                  <div className="chip"></div>
                                </div>
                                {open !== g.id.toString() && (
                                  <div className="ship" style={{ background: "#f7f7f7" }}>
                                    <div className="detail"></div>
                                    <div className="name">
                                      <div className="label">{t("DELIVERY_DATE")}</div>
                                      {g.requiredDate}
                                    </div>
                                    <div className="price">
                                      <div className="label">{t("NOTES")}</div>
                                      <span
                                        style={{
                                          color:
                                            o.note !== "" && o.note !== null && o.note !== undefined
                                              ? "#B8292F"
                                              : "#5A5959",
                                        }}>
                                        {o.note || TEXT_HOLDER}
                                      </span>
                                    </div>
                                    <div className="unit">
                                      <div className="label">Nơi nhận hàng</div>
                                      {g.address || TEXT_HOLDER}
                                    </div>
                                  </div>
                                )}
                              </>
                            );
                          })}
                        </AccordionSummary>
                        <AccordionDetails
                          style={{
                            fontFamily: "SVN-Gotham",
                          }}>
                          {g.childOrders.map((o: any, idx: number) => {
                            return (
                              <div
                                key={idx}
                                id={o?.id.toString()}
                                style={{
                                  background: "rgba(247, 247, 247, 0.4)",
                                  border: border
                                    ? location?.state?.childOrderId === o?.id
                                      ? "1px solid rgb(65, 173, 73)"
                                      : undefined
                                    : undefined,
                                  borderBottom: border
                                    ? location?.state?.childOrderId === o?.id
                                      ? "1px solid rgb(65, 173, 73)"
                                      : undefined
                                    : "1px solid #D6D5D6",
                                }}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: o.orderGFId ? "space-between" : "flex-end",
                                    padding: "2.3rem 2rem 0 16rem",
                                  }}>
                                  {(o?.status === OrderStatus.HALF_COMPLETED ||
                                    o?.status === OrderStatus.COMPLETED) && (
                                    <button
                                      className="btn-camp"
                                      onClick={() => {
                                        o.orderGFId && setOpenListDO(o.id);
                                      }}>
                                      <DoIcon
                                        color={"#5A5959"}
                                        size={[22, 20]}
                                        viewBox={[18, 18]}
                                      />
                                      Phiếu giao hàng
                                    </button>
                                  )}
                                  <div className="chip">
                                    {StatusUIUtil.renderBgStatus(o.status, "farm")}
                                  </div>
                                </div>
                                {o.orderlines.map((orderlines: any) => {
                                  return (
                                    <div className="item">
                                      <div className="detail"></div>
                                      <div className="site">
                                        <div>
                                          {orderlines.itemName}
                                          {/* &ensp;&#8226;&ensp;
                                          {Array.isArray(orderlines?.item.dimension)
                                            ? orderlines.item.dimension
                                                .filter((f: any) => {
                                                  return +f[0] === +orderlines.dimensionToShow;
                                                })
                                                .map((d: any) => {
                                                  return (
                                                    <span>
                                                      {d[1] === null ? "Trên " + d[0] : d[1]}
                                                    </span>
                                                  );
                                                })
                                            : orderlines.item.dimension}
                                          &ensp;{orderlines.baseUoM} */}
                                        </div>
                                        <div className="alter-code">
                                          {"Mã ERP: " +
                                            (orderlines.alterItem?.code
                                              ? orderlines.alterItem?.code
                                              : TEXT_HOLDER)}
                                        </div>
                                      </div>
                                      <div className="quan">
                                        {orderlines.orderQuantity
                                          ? parseFloat(orderlines.orderQuantity)
                                          : TEXT_HOLDER}
                                      </div>
                                      <div className="weight">
                                        {currencyFormat(+orderlines.dimension)}&ensp;kg
                                        {/* {orderlines.baseUoM} */}
                                      </div>
                                      <div className="price">
                                        {checkPermissionViewPrice
                                          ? currencyFormat(orderlines.price)
                                          : TEXT_ASTERISK}
                                        &ensp;đ/
                                        <span style={{ color: "#41ad49" }}>
                                          {orderlines.baseUoM || TEXT_HOLDER}
                                        </span>
                                      </div>
                                      <div className="unit">
                                        {(() => {
                                          if (+orderlines?.attachUnit) {
                                            if (orderlines.prices?.priceType === "increment") {
                                              return (
                                                <span>
                                                  {(checkPermissionViewPrice
                                                    ? currencyFormat(orderlines?.attachUnit)
                                                    : TEXT_ASTERISK) + " đ/"}
                                                  <span style={{ color: "#41ad49" }}>
                                                    {orderlines.baseUoM}
                                                  </span>
                                                </span>
                                              );
                                            } else {
                                              return (
                                                <span>
                                                  {(checkPermissionViewPrice
                                                    ? currencyFormat(orderlines?.attachUnit)
                                                    : TEXT_ASTERISK) + " đ/"}
                                                  <span style={{ color: "#41ad49" }}>
                                                    {orderlines.saleUnit}
                                                  </span>
                                                </span>
                                              );
                                            }
                                          } else return TEXT_HOLDER;
                                        })()}
                                      </div>
                                      <div className="total">
                                        {checkPermissionViewPrice
                                          ? currencyFormat(orderlines.subTotal)
                                          : TEXT_ASTERISK}
                                        &ensp;đ
                                      </div>
                                    </div>
                                  );
                                })}

                                <div className="ship ship-child">
                                  <div className="detail"></div>
                                  <div className="name">
                                    <div className="label"> {t("REFERENCE")}</div>
                                    <div
                                      // onClick={() => {
                                      //   o.orderGFId && setOpenListDO(o.id);
                                      // }}
                                      style={{ color: "#41ad49" }}>
                                      {o.orderGFId || TEXT_HOLDER}
                                    </div>
                                  </div>
                                  <div className="price">
                                    <div className="label">{t("DELIVERY_DATE")}</div>
                                    {o.requiredDate}
                                  </div>
                                  <div className="weight">
                                    <div className="label">Người điều phối</div>
                                    {o.allocator?.nickname
                                      ? o.allocator?.nickname
                                      : o.allocator?.code || TEXT_HOLDER}
                                  </div>
                                  <div className="total">
                                    <div className="label">Trang trại</div>
                                    {o.site?.name || TEXT_HOLDER}
                                  </div>
                                </div>
                                <div className="ship ship-child ship-child-2">
                                  <div className="detail"></div>
                                  <div className="name">
                                    <div className="label">{t("NOTES")}</div>
                                    <span
                                      style={{
                                        color:
                                          o.note !== "" && o.note !== null && o.note !== undefined
                                            ? "#B8292F"
                                            : "#5A5959",
                                      }}>
                                      {o.note || TEXT_HOLDER}
                                    </span>
                                  </div>
                                  <div className="weight">
                                    <div className="label">Nơi nhận hàng</div>
                                    {o.address || TEXT_HOLDER}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </div>
              </div>
              <div className="footer">
                <div
                  className="total-gr"
                  style={{
                    width: (
                      open
                        ? order?.orders.filter((f: any) => f.id.toString() === open)?.[0]
                            ?.childOrders?.length > 1
                        : order?.orders?.length > 1
                    )
                      ? "60vw"
                      : "35vw",
                  }}>
                  <Grid container>
                    <Grid item xs={gridSize}>
                      {open
                        ? order?.orders.filter((f: any) => f.id.toString() === open)?.[0]
                            ?.childOrders?.length > 0 && (
                            <div className="right-label lb" style={{ color: "transparent" }}>
                              {TEXT_HOLDER}
                            </div>
                          )
                        : order?.orders?.length > 1 && (
                            <div className="right-label lb" style={{ color: "transparent" }}>
                              {TEXT_HOLDER}
                            </div>
                          )}
                    </Grid>
                    {!open &&
                      order?.orders?.length > 1 &&
                      order?.orders.map((o: any) => {
                        return (
                          <Grid item xs={gridSize} className="right">
                            <div className="right-label lb">
                              {o.division.description === "DEFAULT FARM DIVISION" && t("BREED")}
                            </div>
                          </Grid>
                        );
                      })}
                    {!open && (
                      <Grid item xs={gridSize} className="right">
                        {order?.orders?.length > 1 && (
                          <div className="right-label lb">{t("TOTAL_ORDER")}</div>
                        )}
                      </Grid>
                    )}
                    {order?.orders
                      .filter((f: any) => f.id.toString() === open)
                      .map((o: any) => {
                        return o.childOrders
                          .filter((child: any) => child.status !== OrderStatus.CANCELLED)
                          .map((child: any) => {
                            return (
                              <Grid item xs={gridSize} className="right">
                                <div className="right-label lb" style={{ color: "#41AD49" }}>
                                  {child.orderGFId || TEXT_HOLDER}
                                </div>
                              </Grid>
                            );
                          });
                      })}
                    {order?.orders.filter((f: any) => f.id.toString() === open)?.[0]?.childOrders
                      ?.length > 0 && (
                      <Grid item xs={gridSize} className="right">
                        <div className="right-label lb">{t("TOTAL_DISTRIBUTION")}</div>
                      </Grid>
                    )}
                    <div className="total-accordion">
                      <Accordion
                        style={{
                          fontFamily: "SVN-Gotham",
                        }}>
                        <AccordionSummary
                          expandIcon={
                            <ArrowDropDownIcon style={{ color: "#272B2F", fontSize: "2.5rem" }} />
                          }
                          style={{
                            fontFamily: "SVN-Gotham",
                          }}>
                          {!open && (
                            <Grid container>
                              <Grid item xs={gridSize}>
                                <div className="text-bold">{t("TOTAL")}</div>
                              </Grid>
                              {order?.orders?.length > 1 &&
                                order?.orders.map((o: any) => {
                                  return (
                                    <Grid item xs={gridSize} className="right">
                                      <div className="text-bold" style={{ color: "#0A6836" }}>
                                        {checkPermissionViewPrice
                                          ? currencyFormat(+o.totalPay)
                                          : TEXT_ASTERISK}
                                        &ensp;đ
                                      </div>
                                    </Grid>
                                  );
                                })}
                              <Grid item xs={gridSize} className="right">
                                <div className="text-bold" style={{ color: "#0A6836" }}>
                                  {checkPermissionViewPrice
                                    ? currencyFormat(+order.totalAmount)
                                    : TEXT_ASTERISK}
                                  &ensp;đ
                                </div>
                              </Grid>
                            </Grid>
                          )}
                          {order?.orders
                            .filter((f: any) => f.id.toString() === open)
                            .map((o: any) => {
                              return (
                                <Grid container>
                                  <Grid item xs={gridSize}>
                                    <div className="text-bold">{t("TOTAL")}</div>
                                  </Grid>
                                  {o.childOrders
                                    .filter((child: any) => child.status !== OrderStatus.CANCELLED)
                                    .map((child: any) => {
                                      return (
                                        <Grid item xs={gridSize} className="right">
                                          <div className="text-bold" style={{ color: "#0A6836" }}>
                                            {checkPermissionViewPrice
                                              ? currencyFormat(+child.totalPay)
                                              : TEXT_ASTERISK}
                                            &ensp;đ
                                          </div>
                                        </Grid>
                                      );
                                    })}
                                  <Grid item xs={gridSize} className="right">
                                    <div className="text-bold" style={{ color: "#0A6836" }}>
                                      {checkPermissionViewPrice
                                        ? o.childOrders.length === 0
                                          ? currencyFormat(+o.totalPay)
                                          : currencyFormat(
                                              o.childOrders
                                                .filter(
                                                  (child: any) =>
                                                    child.status !== OrderStatus.CANCELLED
                                                )
                                                .reduce((sum: any, val: any) => {
                                                  return (sum += +val.totalPay);
                                                }, 0)
                                            )
                                        : TEXT_ASTERISK}
                                      &ensp;đ
                                    </div>
                                  </Grid>
                                </Grid>
                              );
                            })}
                        </AccordionSummary>
                        <AccordionDetails
                          style={{
                            fontFamily: "SVN-Gotham",
                            padding: 0,
                          }}>
                          <Grid container>
                            <Grid item xs={gridSize}>
                              <div className="lb">{t("DISCOUNT")} </div>
                              <div className="lb">{t("PROMOTION")}</div>
                              <div className="lb">{t("WITHHOLDING_PIP")}</div>
                              <div>{t("TAX")} </div>
                            </Grid>
                            {!open &&
                              order?.orders?.length > 1 &&
                              order?.orders.map((o: any) => {
                                return (
                                  <Grid item xs={gridSize} className="right">
                                    <div className="lb">
                                      {checkPermissionViewPrice
                                        ? currencyFormat(parseInt(o.preDiscount))
                                        : TEXT_ASTERISK}
                                      &ensp;đ
                                    </div>
                                    <div className="lb">
                                      {checkPermissionViewPrice
                                        ? currencyFormat(parseInt(o.commerceDiscount))
                                        : TEXT_ASTERISK}
                                      &ensp;đ
                                    </div>
                                    <div className="lb">
                                      {checkPermissionViewPrice
                                        ? currencyFormat(parseInt(o.personalVat))
                                        : TEXT_ASTERISK}
                                      &ensp;đ
                                    </div>
                                    <div>
                                      {checkPermissionViewPrice
                                        ? currencyFormat(parseInt(o.vat))
                                        : TEXT_ASTERISK}
                                      &ensp;đ
                                    </div>
                                  </Grid>
                                );
                              })}
                            {order?.orders
                              .filter((f: any) => f.id.toString() === open)
                              .map((o: any) => {
                                return (
                                  <>
                                    {o.childOrders
                                      .filter(
                                        (child: any) => child.status !== OrderStatus.CANCELLED
                                      )
                                      .map((child: any) => {
                                        return (
                                          <Grid item xs={gridSize} className="right">
                                            <div className="lb">
                                              {checkPermissionViewPrice
                                                ? currencyFormat(+child.preDiscount)
                                                : TEXT_ASTERISK}
                                              &ensp;đ
                                            </div>
                                            <div className="lb">
                                              {checkPermissionViewPrice
                                                ? currencyFormat(+child.commerceDiscount)
                                                : TEXT_ASTERISK}
                                              &ensp;đ
                                            </div>
                                            <div className="lb">
                                              {checkPermissionViewPrice
                                                ? currencyFormat(+child.personalVat)
                                                : TEXT_ASTERISK}
                                              &ensp;đ
                                            </div>
                                            <div>
                                              {checkPermissionViewPrice
                                                ? currencyFormat(+child.vat)
                                                : TEXT_ASTERISK}
                                              &ensp;đ
                                            </div>
                                          </Grid>
                                        );
                                      })}
                                    <Grid item xs={gridSize} className="right">
                                      <div className="lb">
                                        {checkPermissionViewPrice
                                          ? currencyFormat(+o.preDiscount)
                                          : TEXT_ASTERISK}
                                        &ensp;đ
                                      </div>
                                      <div className="lb">
                                        {checkPermissionViewPrice
                                          ? currencyFormat(+o.commerceDiscount)
                                          : TEXT_ASTERISK}
                                        &ensp;đ
                                      </div>
                                      <div className="lb">
                                        {checkPermissionViewPrice
                                          ? currencyFormat(+o.personalVat)
                                          : TEXT_ASTERISK}
                                        &ensp;đ
                                      </div>
                                      <div>
                                        {checkPermissionViewPrice
                                          ? currencyFormat(+o.vat)
                                          : TEXT_ASTERISK}
                                        &ensp;đ
                                      </div>
                                    </Grid>
                                  </>
                                );
                              })}
                            {!open && (
                              <Grid item xs={gridSize} className="right">
                                <div className="lb">
                                  {checkPermissionViewPrice
                                    ? currencyFormat(parseInt(order.preDiscount))
                                    : TEXT_ASTERISK}
                                  &ensp;đ
                                </div>
                                <div className="lb">
                                  {checkPermissionViewPrice
                                    ? currencyFormat(parseInt(order.commerceDiscount))
                                    : TEXT_ASTERISK}
                                  &ensp;đ
                                </div>
                                <div className="lb">
                                  {checkPermissionViewPrice
                                    ? currencyFormat(parseInt(order.personalVat))
                                    : TEXT_ASTERISK}
                                  &ensp;đ
                                </div>
                                <div>
                                  {checkPermissionViewPrice
                                    ? currencyFormat(parseInt(order.totalVat))
                                    : TEXT_ASTERISK}
                                  &ensp;đ
                                </div>
                              </Grid>
                            )}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </Grid>
                </div>

                <Accordion className="update-history" disabled>
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon style={{ color: "#272B2F" }} />}
                    className="title">
                    {t("UPDATE_HISTORY")}
                  </AccordionSummary>
                  <Divider style={{ marginBottom: "1.2rem" }} />
                  <AccordionDetails className="label">
                    <Grid container>
                      <Grid item xs={3}>
                        {t("TIME")}
                      </Grid>
                      <Grid item xs={3}>
                        {t("ACTION")}
                      </Grid>
                      <Grid item xs={2}>
                        {t("IMPLEMENT")}
                      </Grid>
                      <Grid item xs={4}>
                        {t("DETAIL")}
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                  {UpdateHistory.map((u, index: number) => {
                    return (
                      <AccordionDetails key={index + u.id} className="item">
                        <Grid container>
                          <Grid item xs={3}>
                            {u.time}
                          </Grid>
                          <Grid item xs={3}>
                            {u.update}
                          </Grid>
                          <Grid item xs={2}>
                            {u.name}
                          </Grid>
                          <Grid item xs={4}>
                            {u.update}
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    );
                  })}
                </Accordion>
              </div>
            </>
          ) : (
            <Loading style={{ margin: "2rem 0", height: "80vh" }} />
          )}
        </div>
      </div>

      {openListDO !== 0 && <DO closeForm={() => setOpenListDO(0)} id={openListDO} />}

      <Dialog maxWidth="md" open={showCancel}>
        <div className="dialog-cancel">
          <div className="title">
            <div>{t("CANCEL_ORDER")}</div>
            <ClearIcon
              style={{ fontSize: "3rem", cursor: "pointer" }}
              onClick={() => {
                setShowCancel(false);
              }}
            />
          </div>
          <RadioGroup
            name="controlled-radio-buttons-group"
            value={cancelValue}
            onChange={(e) => {
              setCancelValue(e.target.value);
            }}>
            {ReasonStatus.map((r) => {
              return (
                <FormControlLabel
                  key={r.id}
                  value={r.value}
                  control={<Radio color="success" />}
                  label={t(r.label)}
                  className="text"
                />
              );
            })}
            <div className="label">{t("REASON")}</div>
            <TextField
              fullWidth
              placeholder={t("INPUT_BY_NAME", { NAME: t("REASON") })}
              className="form-textarea-field"
              multiline
              rows={3}
              variant="outlined"
              color="success"
              inputProps={{ className: "input" }}
              value={reason}
              onChange={(e) => {
                setReason(e.target.value);
              }}
            />
          </RadioGroup>
          <div className="btn-group">
            <button
              className="btn-cancel btn__text-cancel"
              onClick={() => {
                setShowCancel(false);
              }}>
              {t("NO")}
            </button>
            <button
              className="btn-confirm-style"
              style={{ marginLeft: "2.2rem" }}
              onClick={() => {
                setShowCancel(false);
                setSubmitCancel(true);
              }}>
              {t("CANCEL_ORDER")}
            </button>
          </div>
        </div>
      </Dialog>

      <Notification
        visible={submitCancel}
        title={t("CANCEL_ORDER")}
        subTitle={
          <span>
            {t("CANNOT_RECOVER_CANCELED_ORDER")}
            <br />
            {t("ARE_YOU_SURE_YOU_WANT_CANCEL_ORDER_BY_NAME", {
              NAME: `#${order?.code}`,
            })}
          </span>
        }
        icon={
          <div className={`notification-icon-box red`}>
            <PriorityHighRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
          </div>
        }
        actions={
          <>
            <button
              className="btn-confirm-style"
              style={{
                background: "#fff",
                color: "#0A6836",
                border: "0.1rem solid #0A6836",
                width: "20.6rem",
                marginRight: "2rem",
              }}
              onClick={() => {
                setSubmitCancel(false);
              }}>
              {t("NO")}
            </button>
            <button
              ref={submitRef}
              className="btn-confirm-style"
              style={{
                width: "20.6rem",
                marginLeft: "2rem",
              }}
              onClick={() => {
                cancelOrderFarm();
              }}>
              {t("CONFIRM")}
            </button>
          </>
        }
        onClose={() => {
          setSubmitCancel(false);
        }}
      />
    </Dialog>
  );
};

export default OrderDetailFarm;
