/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-useless-fragment */
import Search from "@Assets/images/icons/img-search.png";
import Img from "@Assets/images/listnull.png";
import EmptyList from "@Components/EmptyList";
import Loading from "@Components/Loading";
import Notification from "@Components/Notification";
import { APP_ROUTES, TEXT_ASTERISK, TEXT_HOLDER, sumAmt } from "@Constants";
import { Action, FetchAction, ItemPermission, PaginateFetchReducer } from "@Models";
import { CustomerService } from "@Services";
import { currencyFormat, PermissionUtil } from "@Utils";
import { ClearRounded as ClearRoundedIcon, Search as SearchIcon } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useReducer, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useHistory } from "react-router-dom";
import "./index.scss";
import { useTranslation } from "react-i18next";

type P_Props = {
  sourceType: any;
};

export const dept_headers = [
  { label: "Số hoá đơn" },
  { label: "Ngày  hoá đơn " },
  { label: "Tổng công nợ" },
  { label: "Công nợ chưa đến hạn" },
  { label: "Công nợ đến hạn" },
  { label: "Công nợ quá hạn" },
  { label: "1 - 7 ngày" },
  { label: "8 - 14 ngày" },
  { label: "15 - 21 ngày" },
  { label: "22 - 28 ngày" },
  { label: "29 - 60 ngày" },
  { label: "61 - 90 ngày" },
  { label: "91 - 120 ngày" },
  { label: "121 - 180 ngày" },
  { label: "181 - 365 ngày" },
  { label: "366 - 730 ngày" },
  { label: "731 - 1095 ngày" },
  { label: "Trên 1095 ngày" },
];

export const amtRange = [
  { label: "amtRange1" },
  { label: "amtRange2" },
  { label: "amtRange3" },
  { label: "amtRange4" },
  { label: "amtRange5" },
  { label: "amtRange6" },
  { label: "amtRange7" },
  { label: "amtRange8" },
  { label: "amtRange9" },
  { label: "amtRange10" },
  { label: "amtRange11" },
  { label: "amtRange12" },
];

function reducer(state: PaginateFetchReducer<any>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: [...state.data, ...action.payload.data],
        loading: false,
      };
    case Action.RESET:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload.data,
        loading: false,
      };
    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload };

    default:
      return { ...state };
  }
}

const Debt: React.FC<P_Props> = ({ sourceType }) => {
  const { t } = useTranslation(["translation"]);
  const mounted = useRef(false);
  const [searchValue, setSearchValue] = useState<string>("");
  // const [range, setRange] = useDateRange();
  const [visibleSearch, setVisibleSearch] = useState<boolean>(false);
  const [notiActivated, setNotiActivated] = useState<{
    message: string;
    status: boolean;
  }>({
    message: "",
    status: false,
  });
  const history = useHistory();
  const [profile, setProfile] = useState<any>([]);

  const [state, dispatch] = useReducer(reducer, {
    page: 0,
    totalPages: 0,
    limit: 0,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
    loading: true,
  });

  const loadProfileData = _.debounce(async () => {
    const response = await CustomerService.getAuthProfile();
    setProfile(response.data);
  }, 100);

  useEffect(() => {
    loadProfileData();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const fetchData = async (query: any, isReset: boolean = false) => {
    if (state.fetching) return;
    dispatch({ type: Action.FETCH, payload: {} });
    try {
      const result = await CustomerService.getAllDebt(query);
      if (isReset)
        dispatch({
          type: Action.RESET,
          payload: result.data,
        });
      else if (mounted.current)
        dispatch({
          type: Action.SUCCESS,
          payload: result.data,
        });
    } catch (err: any) {
      if (err.response?.status === 401) {
        if (err.response?.data.message === "SESSION_EXPIRED") {
          setNotiActivated({ message: "SESSION_EXPIRED", status: true });
        } else {
          setNotiActivated({ message: "UNAUTHORIZED", status: true });
        }
      }
      if (mounted)
        dispatch({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
    }
  };

  useEffect(() => {
    fetchData(
      {
        page: 1,
        limit: 10,
        // from: range.startTime.toISOString(),
        // to: range.endTime.toISOString(),
        searchValue: searchValue,
        source: sourceType,
      },
      true
    );
  }, [
    searchValue,
    // , range.startTime.toISOString(), range.endTime.toISOString()
  ]);

  const hasMore = state.data.length < state.totalRecords && state.page < state.totalPages;

  const onSearch = _.debounce((value) => {
    setSearchValue(value);

    value ? setVisibleSearch(true) : setVisibleSearch(false);
  }, 300);

  //permission
  const checkPermissionViewPrice = PermissionUtil.check(
    profile?.role?.permissions,
    ItemPermission.MANAGE_ITEM_VIEW_PRICE,
    true
  );

  return (
    <div className="debt-listing">
      <div className="header">
        <div style={{ display: "flex" }}>
          <TextField
            placeholder="Tìm mã hợp đồng"
            variant="outlined"
            className={"search-style"}
            inputProps={{ className: "input" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="large" />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              onSearch(e.target.value);
            }}
          />
          {/* <RangePicker
                startDate={range.startTime}
                endDate={range.endTime}
                onChangeDate={(val) => {
                  if (val && val.length) {
                    setRange(
                      moment(val[0]).set({ hours: 0, minutes: 0, seconds: 0 }),
                      moment(val[1]).set({ hours: 23, minutes: 59, seconds: 59 })
                    );
                  }
                }}
              /> */}
        </div>
        <div>
          <div className="group">
            <div>
              <div className="text" style={{ color: "#0A6836" }}>
                {checkPermissionViewPrice
                  ? state.customer
                    ? currencyFormat(+state.customer.totalDebt)
                    : TEXT_HOLDER
                  : TEXT_ASTERISK}{" "}
                đ
              </div>
              Công Nợ
            </div>
            <div style={{ marginLeft: "6rem" }}>
              <div className="text" style={{ color: "#E53935" }}>
                {checkPermissionViewPrice
                  ? state.customer
                    ? currencyFormat(+state.customer.totalOverdueDebt)
                    : TEXT_HOLDER
                  : TEXT_ASTERISK}{" "}
                đ
              </div>
              Nợ quá hạn
            </div>
          </div>
        </div>
      </div>
      <div className="text-update">
        Cập nhật lần cuối lúc {moment(state.data?.[0]?.updatedAt).format("hh:mm - DD/MM/YYYY")}
      </div>
      {state.loading === true ? (
        <Loading style={{ marginTop: "20rem" }} />
      ) : (
        <>
          {state.data.length > 0 ? (
            <div className="list">
              <div className="list-group">
                <div className="list-header">
                  {dept_headers.map((item: any) => {
                    return <div className="label">{item.label}</div>;
                  })}
                </div>
                <InfiniteScroll
                  useWindow={false}
                  pageStart={0}
                  initialLoad={false}
                  hasMore={hasMore}
                  loadMore={() =>
                    fetchData({
                      limit: 10,
                      page: state.page + 1,
                      searchValue,
                      source: sourceType,
                    })
                  }>
                  {state.fetching && searchValue && <Loading />}
                  {state.data.map((debt: any) => {
                    return (
                      <div className="item" key={debt.id}>
                        <div className="label">{debt.refDocNo}</div>
                        <div className="label">{moment(debt.pstngDate).format("DD/MM/YYYY")}</div>
                        <div className="label">
                          {checkPermissionViewPrice
                            ? currencyFormat(debt.amtDoccur)
                            : TEXT_ASTERISK}{" "}
                          đ
                        </div>
                        <div className="label">
                          {checkPermissionViewPrice
                            ? currencyFormat(debt.amtNotDue)
                            : TEXT_ASTERISK}{" "}
                          đ
                        </div>
                        <div className="label">
                          {checkPermissionViewPrice ? currencyFormat(debt.amtDue) : TEXT_ASTERISK} đ
                        </div>
                        <div className="label">
                          {checkPermissionViewPrice ? currencyFormat(debt.amtCurr) : TEXT_ASTERISK}{" "}
                          đ
                        </div>
                        {amtRange.map((amt) => {
                          return (
                            <div className="label">
                              {checkPermissionViewPrice
                                ? currencyFormat(debt[amt.label])
                                : TEXT_ASTERISK}{" "}
                              đ
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </InfiniteScroll>
                <div className="item item-total">
                  <div className="label">Tổng hóa đơn</div>
                  <div className="label"></div>
                  <div className="label">
                    {checkPermissionViewPrice
                      ? currencyFormat(state.sum.sumAmtDoccur)
                      : TEXT_ASTERISK}{" "}
                    đ
                  </div>
                  <div className="label">
                    {checkPermissionViewPrice
                      ? currencyFormat(state.sum.sumAmtNotDue)
                      : TEXT_ASTERISK}{" "}
                    đ
                  </div>
                  <div className="label">
                    {checkPermissionViewPrice ? currencyFormat(state.sum.sumAmtDue) : TEXT_ASTERISK}{" "}
                    đ
                  </div>
                  <div className="label">
                    {checkPermissionViewPrice
                      ? currencyFormat(state.sum.sumAmtCurr)
                      : TEXT_ASTERISK}{" "}
                    đ
                  </div>
                  {sumAmt.map((amt: any) => {
                    return (
                      <div className="label">
                        {checkPermissionViewPrice
                          ? currencyFormat(state.sum[amt.label])
                          : TEXT_ASTERISK}
                        đ
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : (
            <div className="list-null">
              {visibleSearch === true ? (
                <>
                  <img src={Search} alt="search" />
                  <br />
                  {t("NO_RESULT_FOUND")}
                </>
              ) : (
                <EmptyList img={Img} title="Chưa có công nợ" />
              )}
            </div>
          )}
        </>
      )}

      <Notification
        visible={notiActivated.status}
        title={
          notiActivated.message === "SESSION_EXPIRED"
            ? t("YOUR_LOGIN_SESSION_EXPIRED")
            : t("YOUR_ACCOUNT_DISABLED")
        }
        subTitle={
          notiActivated.message === "SESSION_EXPIRED" ? (
            <span>{t("LOGIN_AGAIN")}</span>
          ) : (
            <span
              dangerouslySetInnerHTML={{
                __html: t("CONTACT_STAFF_GF_ACTIVE_ACCOUNT"),
              }}
            />
          )
        }
        icon={
          <div className={`notification-icon-box red`}>
            <ClearRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
          </div>
        }
        actions={
          <button
            className="btn-confirm-style"
            onClick={() => {
              localStorage.removeItem("accessToken");
              window.location.reload();
              history.push(APP_ROUTES.SIGNIN);
            }}>
            {notiActivated.message === "SESSION_EXPIRED" ? t("CLOSE") : t("GO_BACK")}
          </button>
        }
        onClose={() => {
          localStorage.removeItem("accessToken");
          window.location.reload();
          history.push(APP_ROUTES.SIGNIN);
        }}
      />
    </div>
  );
};

export default Debt;
