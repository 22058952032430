import { Clear as ClearIcon } from "@mui/icons-material";
import React from "react";
import { Cell, Pie, PieChart } from "recharts";
import { useBreakpoints } from "@Hooks";
import { currencyFormat } from "@Utils";
import { COLORS } from "@Containers/Dashboard";
import "./index.scss";

type P_Props = {
  closeForm: () => void;
  data: any;
  report: any;
  activeShape: any;
  onMouseEnter: any;
  activeIndex: any;
  salesLength: any;
};

const DashboardPie: React.FC<P_Props> = ({
  data,
  report,
  closeForm,
  activeShape,
  onMouseEnter,
  activeIndex,
  salesLength,
}) => {
  const breakpoints = useBreakpoints();

  return (
    <div className="dialog-pie">
      <div className="title">
        {data?.[0].sum_production !== undefined
          ? salesLength
            ? "Tỷ trọng Sản lượng giữa các đơn vị kinh doanh"
            : "Tỷ trọng Sản lượng giữa các Giám đốc kinh doanh"
          : salesLength
          ? "Tỷ trọng Doanh số giữa các đơn vị kinh doanh"
          : "Tỷ trọng Doanh số giữa các Giám đốc kinh doanh"}
        <ClearIcon style={{ fontSize: "2.2rem", cursor: "pointer" }} onClick={closeForm} />
      </div>
      {data && (
        <div className="pie">
          <div style={{ display: "flex" }}>
            <div>
              {data.slice(0, Math.floor(data.length / 2)).map((item: any, index: number) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingBottom: "1.6rem",
                      marginRight: "3rem",
                    }}>
                    <div
                      className="cir"
                      style={{
                        background: COLORS[index % COLORS.length],
                      }}></div>
                    <span className="item-pie">
                      <div className="name">{item.salesName}</div>
                      {item.sum_production !== undefined ? (
                        <div className="value">
                          ({((item.sum_production / +report?.total_production) * 100).toFixed(2)}
                          &ensp;%&ensp;-&ensp;{currencyFormat(item.sum_production)}&ensp;kg)
                        </div>
                      ) : (
                        <div className="value">
                          ({((item.sum_value / +report?.total_value) * 100).toFixed(2)}
                          &ensp;%&ensp;-&ensp;{currencyFormat(item.sum_value)}&ensp;đ)
                        </div>
                      )}
                    </span>
                  </div>
                );
              })}
            </div>
            <div>
              {data
                .slice(Math.floor(data.length / 2), data.length)
                .map((item: any, index: number) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingBottom: "1.6rem",
                      }}>
                      <div
                        className="cir"
                        style={{
                          background: COLORS[index + (Math.floor(data.length / 2) % COLORS.length)],
                        }}></div>
                      <span className="item-pie">
                        <div className="name">{item.salesName}</div>
                        {item.sum_production !== undefined ? (
                          <div className="value">
                            ({((item.sum_production / +report?.total_production) * 100).toFixed(2)}
                            &ensp;%&ensp;-&ensp;{currencyFormat(item.sum_production)}&ensp;kg)
                          </div>
                        ) : (
                          <div className="value">
                            ({((item.sum_value / +report?.total_value) * 100).toFixed(2)}
                            &ensp;%&ensp;-&ensp;{currencyFormat(item.sum_value)}&ensp;đ)
                          </div>
                        )}
                      </span>
                    </div>
                  );
                })}
            </div>
          </div>
          <PieChart width={!breakpoints.lg ? 240 : 333} height={!breakpoints.lg ? 240 : 333}>
            <Pie
              data={data}
              cx="55%"
              cy={!breakpoints.lg ? "100" : "145"}
              innerRadius={!breakpoints.lg ? 55 : 80}
              outerRadius={!breakpoints.lg ? 90 : 140}
              startAngle={90}
              endAngle={-270}
              activeShape={activeShape}
              onMouseEnter={onMouseEnter}
              activeIndex={activeIndex}
              dataKey={data?.[0].sum_production !== undefined ? "sum_production" : "sum_value"}>
              {data?.map((entry: any, index: any) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
          </PieChart>
        </div>
      )}
    </div>
  );
};

export default DashboardPie;
