/* eslint-disable react-hooks/exhaustive-deps */
import { getMessaging } from "@firebase/messaging/sw";
import { DoneAll as DoneAllIcon, Search as SearchIcon } from "@mui/icons-material";
import { Badge, Divider, InputAdornment, Popover, Tab, Tabs, TextField } from "@mui/material";
//import firebase, { message } from "./firebase";
import { initializeApp } from "firebase/app";
import { getToken, onMessage } from "firebase/messaging";
import moment from "moment";
//import { useSnackbar } from "notistack";
import Avt from "@Assets/images/noti.png";
import { APP_ROUTES } from "@Constants";
import { Action, FetchAction, NotificationModel, PaginateFetchReducer, SourceType } from "@Models";
import { AuthService, NotificationService } from "@Services";
import _ from "lodash";
import React, { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { useHistory } from "react-router-dom";
import { NotificationIcon } from "../Icons/NotificationIcon";
import "./index.scss";

function reducer(state: PaginateFetchReducer<NotificationModel>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      const data: any = state.data;
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: {
          ...data,
          ...action.payload.data,
          notification: data.notification.concat(action.payload.data.notification),
        },
      };
    case Action.RESET:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload.data,
      };

    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload };

    default:
      return { ...state };
  }
}

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIERBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const menuNotiTab = [
  {
    label: "Công nợ-Duyệt nợ",
    value: 0,
    valueLabel: "debt",
  },
  {
    label: "Đơn hàng",
    value: 1,
    valueLabel: "order",
  },
  {
    label: "Đơn hàng xét nghiệm",
    value: 2,
    valueLabel: "lab",
  },
  {
    label: "Phản hồi",
    value: 3,
    valueLabel: "feedback",
  },
  {
    label: "Tin tức",
    value: 4,
    valueLabel: "news",
  },
  {
    label: "Khác",
    value: 5,
    valueLabel: "notify",
  },
];

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const PushNotification: React.FC = () => {
  const { t } = useTranslation(["translation", "order"]);
  //const { enqueueSnackbar } = useSnackbar();
  const [metaNotification, setMetaNotification] = useState<any>();
  const [source, setSource] = useState<string>("");
  const mounted = useRef(false);

  const [isTokenFound, setTokenFound] = useState(false);
  const [notiId, setNotiId] = useState<number>(0);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  /* Firebase web 8 */

  // useEffect(() => {
  //   const messaging = firebase.messaging();
  //   // getToken(messaging).then((currentToken) => {
  //   //   if (currentToken) {
  //   //     setTokenFound(true);
  //   //     // Send the token to your server and update the UI if necessary
  //   //     // ...
  //   //     console.log('currentToken', currentToken)
  //   //   } else {
  //   //     // Show permission request UI
  //   //     console.log('No registration token available. Request permission to generate one.');
  //   //     // ...
  //   //   }
  //   // }).catch((err) => {
  //   //   console.log('An error occurred while retrieving token. ', err);
  //   //   // ...
  //   // });
  //   Promise.resolve(message.getToken()).then(function (firebaseToken) {
  //     localStorage.setItem("firebaseToken", firebaseToken);
  //   });
  //   messaging
  //     .requestPermission()
  //     .then(() => {
  //       return messaging.getToken();
  //     })
  //     .then((token) => {
  //       //push client id to server
  //       // return PushNotification.addDeviceToken(token, getAccessToken())
  //     })
  //     .then((response) => {
  //       // console.log(response)
  //     })
  //     .catch(function (err) {
  //       console.log("Unable to get permission to notify.", err);
  //     });
  // }, []);

  /* Firebase web 9 */

  const messaging = getMessaging(app);

  useEffect(() => {
    async function fetchData() {
      //registration
      await navigator.serviceWorker.ready;
    }
    fetchData();
  }, []);

  useEffect(() => {
    onMessage(messaging, (payload) => {
      console.log("Message received. ", payload);
      // ...
    });
    getToken(messaging)
      .then((currentToken) => {
        if (currentToken) {
          setTokenFound(true);
          // Send the token to your server and update the UI if necessary
          localStorage.setItem("firebaseToken", currentToken);
        } else {
          // Show permission request UI
          console.log("No registration token available. Request permission to generate one.");
        }
      })
      .catch((err) => {
        // enqueueSnackbar("Vui lòng bật thông báo!", {
        //   variant: "error",
        //   preventDuplicate: true,
        // });

        console.log("An error occurred while retrieving token. ", err);
      });
  }, [messaging]);

  const saveToken = async () => {
    await AuthService.saveTokenDevice({
      type_device: "browser",
      token: localStorage.getItem("firebaseToken"),
      meta_data: "",
    });
  };

  useEffect(() => {
    !isTokenFound &&
      setTimeout(() => {
        saveToken();
      }, 200);
  }, [messaging]);

  const [state, dispatch] = useReducer(reducer, {
    page: 0,
    totalPages: 0,
    limit: 0,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
  });

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const [searchString, setSearchString] = useState<string>("");
  const onSearch = _.debounce((value) => {
    setSearchString(value);
  }, 800);

  const fetchData = async (query: any, isReset: boolean = false) => {
    if (state.fetching) return;
    dispatch({ type: Action.FETCH, payload: {} });
    try {
      const result = await NotificationService.getNotificationByCustomer(query);
      if (isReset)
        dispatch({
          type: Action.RESET,
          payload: result.data,
        });
      else if (mounted.current)
        dispatch({
          type: Action.SUCCESS,
          payload: result.data,
        });
    } catch (err) {
      if (mounted)
        dispatch({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
    }
  };

  useEffect(() => {
    fetchData(
      {
        page: 1,
        limit: 10,
        type: menuNotiTab?.find((f) => f.value === value)?.valueLabel,
        strSearch: searchString !== "" ? searchString : undefined,
      },
      true
    );
  }, [metaNotification, value, searchString]);

  useEffect(() => {
    notiId && markAsRead();
  }, [notiId, metaNotification]);

  const markAsRead = useCallback(async () => {
    try {
      await NotificationService.UpdateNotificationById(notiId);
    } catch (error) {
      console.log(error);
    }
  }, [notiId, metaNotification]);

  const markAllAsRead = async () => {
    try {
      await NotificationService.UpdateAllNotificationById();
    } catch (error) {
      console.log(error);
    }
  };

  const history = useHistory();

  useEffect(() => {
    metaNotification &&
      (source === SourceType.FEED
        ? history.push(
            APP_ROUTES.ORDER_DETAIL.replace(":orderId", metaNotification.orderGroupId.toString())
          )
        : source === SourceType.FARM
        ? history.push(
            APP_ROUTES.ORDER_DETAIL_FARM.replace(
              ":orderId",
              metaNotification.orderGroupId.toString()
            )
          )
        : source === SourceType.VET
        ? history.push(
            APP_ROUTES.ORDER_DETAIL_VET.replace(
              ":orderId",
              metaNotification.orderGroupId.toString()
            )
          )
        : history.push(
            APP_ROUTES.ORDER_DETAIL_LAB.replace(
              ":orderId",
              metaNotification.orderGroupId.toString()
            )
          ));
  }, [metaNotification]);

  return (
    <div className="badge-noti">
      <div
        className="push-noti-icon"
        onClick={(e: any) => {
          handleClick(e);
        }}>
        <Badge
          badgeContent={state.data.totalUnRead > 99 ? `99+` : state.data.totalUnRead}
          overlap="circular">
          <NotificationIcon color="#ffffff" size={[24, 24]} viewBox={[24, 24]} />
        </Badge>
      </div>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}>
        <div className="notification-list">
          <div className="header">
            <div className="header-title">
              <div className="total">{t("NOTIFICATION")}</div>
              {state.data.totalUnRead > 0 && (
                <span
                  className="mark-as-read"
                  onClick={() => {
                    markAllAsRead();
                    setTimeout(() => {
                      window.location.reload();
                    }, 200);
                  }}>
                  <DoneAllIcon style={{ fontSize: "2rem", marginRight: 10 }} />
                  {t("MARK_AS_SEEN")}
                </span>
              )}
            </div>
            {/* <div className="subtab">
              <Tabs
                className="tab"
                value={list}
                onChange={(e: any) => {
                  handleChangeTab(list);
                }}>
                <Tab
                  label={`${t("UNSEEN")}(${state.data.totalUnRead})`}
                  value={false}
                  style={{ fontFamily: "SVN-Gotham" }}
                />
                <Tab
                  label={`${t("SEEN")}(${state.data.totalRead})`}
                  value={true}
                  style={{ fontFamily: "SVN-Gotham" }}
                />
              </Tabs>
            </div> */}
            <div className="noti-tab-list">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#0A6836",
                  },
                }}>
                {menuNotiTab?.map((tab) => {
                  return (
                    <Tab
                      className="noti-tab-list__item"
                      label={
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {tab.label}
                          {value === tab.value && state.data.totalUnRead > 0 && (
                            <div className="noti-tab-list__badge">{state.data.totalUnRead}</div>
                          )}
                        </div>
                      }
                      value={tab.value}
                      style={{
                        fontFamily: "SVN-Gotham",
                        color: value === tab.value ? "#0A6836" : "#5A5959",
                        fontWeight: value === tab.value ? 700 : 450,
                      }}
                    />
                  );
                })}
              </Tabs>
            </div>
            <div style={{ background: "#f9f9f9", padding: "12px 16px" }}>
              <TextField
                placeholder={t("ENTER_SEARCH")}
                variant="outlined"
                className="search-style"
                inputProps={{ className: "input" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="large" className="icon" />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  onSearch(e.target.value);
                }}
                autoComplete="off"
              />
            </div>
          </div>
          {!!state.data?.notification?.length && (
            <InfiniteScroll
              useWindow={false}
              pageStart={0}
              initialLoad={false}
              hasMore={
                state.data.notification?.length < state.totalRecords &&
                state.page < state.totalPages
              }
              loadMore={() =>
                fetchData({
                  limit: 10,
                  page: state.page + 1,
                  type: menuNotiTab?.find((f) => f.value === value)?.valueLabel,
                  strSearch: searchString !== "" ? searchString : undefined,
                })
              }>
              {state.data?.notification
                // ?.filter((s: any) => s.seenFlg === list)
                ?.map((t: NotificationModel) => {
                  return (
                    <div
                      className="item"
                      key={t.id}
                      onClick={() => {
                        !t.seenFlg && setNotiId(t.id);
                        if (t.type === "DEBT") {
                          history.push(APP_ROUTES.REPORT_SALES);
                          handleClose();
                        }
                        if (t.type === "NEWS") {
                          history.push({
                            pathname: APP_ROUTES.NEWS_DETAIL.replace(
                              ":newsId",
                              t.meta?.newsId?.toString()
                            ),
                          });
                          handleClose();
                        }
                        if (t.type === "ORDER") {
                          setMetaNotification(t.meta);
                        }
                        setSource(t?.source);
                      }}>
                      <div className="image">
                        <img src={Avt} alt="customer" />
                        <div className="notice">
                          <div className="title">{t.title}</div>
                          <div className="content">{t.body}</div>
                          <div className="createAt">{moment(t.createdAt).format("DD/MM/YYYY")}</div>
                        </div>
                      </div>
                      <Divider style={{ marginTop: "2.4rem" }} />
                    </div>
                  );
                })}
            </InfiniteScroll>
          )}
        </div>
      </Popover>
    </div>
  );
};

export default PushNotification;
