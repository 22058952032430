import { REQUEST_URL } from "@Constants";
import { RequestUtil } from "@Utils";

export const ObjectiveService = {
  getAllObjective: () => {
    const url = REQUEST_URL.OBJECTIVE_GET_OR_CREATE;
    return RequestUtil.get({ url });
  },

  getObjectiveById: (id: string) => {
    const url = REQUEST_URL.OBJECTIVE_BY_ID.replace("{id}", id);
    return RequestUtil.get({ url });
  },

  createObjective: (query: any) => {
    const url = REQUEST_URL.OBJECTIVE_GET_OR_CREATE;
    return RequestUtil.post({ url, data: query });
  },

  deleteObjective: (id: string) => {
    const url = REQUEST_URL.OBJECTIVE_BY_ID.replace("{id}", id);
    return RequestUtil.delete({ url });
  },
};
