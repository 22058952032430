import { IconBase, IconComponent } from "@Core/Icons";

export const SearchFile: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        d="M19 5.50002H12.72L12.4 4.50002C12.1926 3.91325 11.8077 3.40553 11.2989 3.04718C10.7901 2.68884 10.1824 2.49762 9.56 2.50002H5C4.20435 2.50002 3.44129 2.81609 2.87868 3.3787C2.31607 3.94131 2 4.70437 2 5.50002V18.5C2 19.2957 2.31607 20.0587 2.87868 20.6213C3.44129 21.184 4.20435 21.5 5 21.5H19C19.7956 21.5 20.5587 21.184 21.1213 20.6213C21.6839 20.0587 22 19.2957 22 18.5V8.50002C22 7.70437 21.6839 6.94131 21.1213 6.3787C20.5587 5.81609 19.7956 5.50002 19 5.50002ZM20 18.5C20 18.7652 19.8946 19.0196 19.7071 19.2071C19.5196 19.3947 19.2652 19.5 19 19.5H5C4.73478 19.5 4.48043 19.3947 4.29289 19.2071C4.10536 19.0196 4 18.7652 4 18.5V5.50002C4 5.23481 4.10536 4.98045 4.29289 4.79292C4.48043 4.60538 4.73478 4.50002 5 4.50002H9.56C9.76964 4.49948 9.97416 4.56484 10.1446 4.68686C10.3151 4.80889 10.4429 4.9814 10.51 5.18002L11.05 6.82002C11.1171 7.01864 11.2449 7.19116 11.4154 7.31318C11.5858 7.4352 11.7904 7.50056 12 7.50002H19C19.2652 7.50002 19.5196 7.60538 19.7071 7.79292C19.8946 7.98045 20 8.23481 20 8.50002V18.5Z"
        fill="#312F30"
      />
      <path
        d="M14.7783 15.7246L13.9015 14.8623C14.1818 14.3771 14.2939 13.8141 14.2209 13.2596C14.1478 12.7051 13.8934 12.1897 13.4969 11.7922C13.1319 11.4173 12.6616 11.1602 12.1472 11.0542C11.6328 10.9482 11.0982 10.9984 10.6129 11.1981C10.1277 11.3979 9.71431 11.738 9.4267 12.1741C9.13909 12.6102 8.99056 13.1221 9.00046 13.6432C8.99789 14.0916 9.11352 14.5328 9.33591 14.9232C9.5583 15.3136 9.8797 15.6395 10.2683 15.8686C10.6568 16.0978 11.099 16.2222 11.551 16.2295C12.0029 16.2368 12.449 16.1268 12.8449 15.9104L13.7142 16.7801C13.7838 16.8498 13.8667 16.9051 13.9581 16.9428C14.0494 16.9806 14.1473 17 14.2463 17C14.3452 17 14.4431 16.9806 14.5345 16.9428C14.6258 16.9051 14.7087 16.8498 14.7783 16.7801C14.8486 16.711 14.9043 16.6288 14.9424 16.5382C14.9804 16.4476 15 16.3505 15 16.2523C15 16.1542 14.9804 16.057 14.9424 15.9665C14.9043 15.8759 14.8486 15.7937 14.7783 15.7246ZM12.4027 14.4237C12.1887 14.6234 11.9059 14.7345 11.6121 14.7345C11.3183 14.7345 11.0355 14.6234 10.8215 14.4237C10.6152 14.2154 10.4995 13.9352 10.4993 13.6432C10.4977 13.4966 10.5261 13.3511 10.5828 13.2157C10.6395 13.0803 10.7232 12.9577 10.829 12.8552C11.0288 12.6581 11.2967 12.5438 11.5784 12.5356C11.7299 12.5264 11.8817 12.5483 12.0243 12.6C12.1668 12.6517 12.2971 12.7321 12.4069 12.8361C12.5166 12.9401 12.6035 13.0654 12.6622 13.2043C12.7208 13.3432 12.7498 13.4926 12.7475 13.6432C12.7413 13.9384 12.6173 14.2191 12.4027 14.4237Z"
        fill="#312F30"
      />
    </IconBase>
  );
};
