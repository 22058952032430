export const FormUIUtil = {
  renderFormItem,
};

function renderFormItem(label: any, value: any, withoutIcon: boolean = false) {
  return (
    <div className="form-item">
      <div className="form-label">{label}</div>
      {value}
    </div>
  );
}
