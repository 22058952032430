import accountImage from "@Assets/images/avt.png";
import Img from "@Assets/images/history.png";
import EmptyList from "@Components/EmptyList";
import Loading from "@Components/Loading";
import Notification from "@Components/Notification";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import DefaultSwitch from "@Components/SwitchV2/DefaultSwitch";
import { APP_ROUTES, TEXT_HOLDER } from "@Constants";
import {
  AccountCustomerPermission,
  SourceType,
  SubAccountDetailModel,
  SubAccountPermission,
  SubAccountStatus,
} from "@Models";
import { CustomerService } from "@Services";
import { checkLinkImage, PermissionUtil } from "@Utils";
import {
  CheckRounded as CheckRoundedIcon,
  Clear as ClearIcon,
  FiberManualRecord as DotIcon,
  PriorityHigh as PriorityHighIcon,
} from "@mui/icons-material";
import { Dialog, Divider, Grid, TextField } from "@mui/material";
import _debounce from "lodash/debounce";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import "./SubAccountDetail.scss";

const SubAccountDetail: React.FC = () => {
  const { t } = useTranslation(["translation"]);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const mounted = useRef(false);
  const [authAccount, setAuthAccount] = useState<SubAccountDetailModel | null>(null);
  const { accountId }: { accountId: string } = useParams();
  const [closeForm, setCloseForm] = useState<boolean>(true);
  const [noti, setNoti] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [reason, setReason] = useState<string>("");
  const [showCancel, setShowCancel] = useState<boolean>(false);
  const [showApprove, setShowApprove] = useState<boolean>(false);
  const [profile, setProfile] = useState<any>([]);
  const [openPending, setOpenPending] = useState<boolean>(false);

  const [notiStatus, setNotiStatus] = useState<{
    visible: boolean;
    status: boolean;
  }>({
    visible: false,
    status: false,
  });

  const loadProfileData = _debounce(async () => {
    const response = await CustomerService.getAuthProfile();
    setProfile(response.data);
  }, 100);

  useEffect(() => {
    loadProfileData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [reCallAPI, setRecallAPI] = useState<boolean>(false);

  const getAccountDetail: () => Promise<void> = async () => {
    try {
      const response = await CustomerService.getSubAccountById(accountId);
      if (mounted.current) {
        setAuthAccount(() => response.data);
        setRecallAPI(false);
      }
    } catch (err: any) {
      if (err.message.includes("404")) {
      }
    }
  };

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    getAccountDetail();
    // eslint-disable-next-line
  }, [reCallAPI === true]);

  const accountImageOnErrorHandler = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = accountImage;
    event.currentTarget.className = "error";
  };

  const changeStatus = () => {
    CustomerService.changeSubAccountStatus({
      accountId: Number(accountId),
      isActive: isActive,
    })
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          if (notiStatus.status) {
            enqueueSnackbar(t("ACTIVATE_ACCOUNT_SUCCESS"), {
              variant: "success",
            });
            setRecallAPI(true);
          } else {
            enqueueSnackbar(t("DEACTIVATE_ACCOUNT_SUCCESS"), {
              variant: "success",
            });
            setRecallAPI(true);
          }
        } else {
          // do something
        }
      })
      .catch((err) => {
        setIsActive(!isActive);
        if (isActive) {
          enqueueSnackbar(t("ACTIVATE_ACCOUNT_FAILED"), {
            variant: "error",
          });
        } else {
          enqueueSnackbar(t("DEACTIVATE_ACCOUNT_FAILED"), {
            variant: "error",
          });
        }
      });
  };

  useEffect(() => {
    if (authAccount && authAccount.isActive === false) {
      setIsActive(false);
    } else {
      setIsActive(true);
    }
    // eslint-disable-next-line
  }, [authAccount]);

  const onApproveOrRejectAccount = async (status: "APPROVE" | "REJECT") => {
    const data = {
      review: status,
      reason: reason,
    };
    try {
      const res = await CustomerService.reviewSubAccount(authAccount?.id.toString()!, data);
      if (res.status === 200 || res.status === 201) {
        if (status === "APPROVE") {
          enqueueSnackbar(t("APPROVE_ACCOUNT_SUCCESSFUL"), {
            variant: "success",
            preventDuplicate: true,
          });
        } else {
          enqueueSnackbar(t("REJECT_ACCOUNT_SUCCESSFUL"), {
            variant: "success",
            preventDuplicate: true,
          });
        }

        setTimeout(() => getAccountDetail(), 200);
      }
    } catch (error) {
      if (status === "APPROVE") {
        enqueueSnackbar(t("APPROVE_ACCOUNT_FAILED"), {
          variant: "error",
          preventDuplicate: true,
        });
      } else {
        enqueueSnackbar(t("REJECT_ACCOUNT_FAILED"), {
          variant: "error",
          preventDuplicate: true,
        });
      }
    }
    setShowApprove(false);
    setShowCancel(false);
    setReason("");
  };

  const onProvisionAccount = async () => {
    const data = {
      status: SubAccountStatus.PENDING.toUpperCase(),
    };
    try {
      const res = await CustomerService.changeSubAccountProvision(accountId, data);
      if (res.status === 200 || res.status === 201) {
        enqueueSnackbar(t("ACCOUNT_PROVISION_SUCCESS"), {
          variant: "success",
          preventDuplicate: true,
        });

        setTimeout(() => getAccountDetail(), 200);
      }
    } catch (error) {
      enqueueSnackbar(t("ACCOUNT_PROVISION_FAILED"), {
        variant: "error",
        preventDuplicate: true,
      });
    }
    setOpenPending(false);
  };

  const checkPermissionCusAccountApprove = PermissionUtil.check(
    profile?.role?.permissions,
    AccountCustomerPermission.MANAGE_CUSTOMER_ACCOUNT_APPROVE,
    true
  );

  const checkPermissionSubAccountChangeStatus = PermissionUtil.check(
    profile?.role?.permissions,
    SubAccountPermission.MANAGE_SUBACCOUNT_UPDATE_STATUS,
    true
  );

  const checkPermissionSubAccountResetPassword = PermissionUtil.check(
    profile?.role?.permissions,
    SubAccountPermission.MANAGE_SUBACCOUNT_RESET_PASSWORD,
    true
  );

  const checkPermissionSubAccountUpdate = PermissionUtil.check(
    profile?.role?.permissions,
    SubAccountPermission.MANAGE_SUBACCOUNT_UPDATE,
    true
  );

  return (
    <>
      <Dialog fullScreen open={closeForm}>
        <div className="sub-account-detail">
          <SimpleModalHeader
            title={t("CUSTOMER_ACCOUNT_DETAILS")}
            onClose={() => {
              setCloseForm(false);
              history.push(APP_ROUTES.SUB_ACCOUNT);
            }}
          />
          <div className="content">
            {!!authAccount ? (
              <Grid container>
                <Grid item xs={3} className="left">
                  <div className="image">
                    <img
                      src={checkLinkImage(authAccount?.avatar) || accountImage}
                      alt="img"
                      onError={accountImageOnErrorHandler}
                    />
                  </div>
                  <div className="title">{authAccount.nickname || TEXT_HOLDER}</div>
                  {authAccount && (
                    <div className={`status ${authAccount.status.toLowerCase()}`}>
                      <DotIcon style={{ fontSize: "1rem" }} />{" "}
                      {t(`SUB_${authAccount.status.toUpperCase()}`)}
                    </div>
                  )}

                  {authAccount.status.toLowerCase() === SubAccountStatus.ACTIVATED && (
                    <div
                      className="btn-setpw"
                      style={{ backgroundColor: "#0a6836" }}
                      onClick={() =>
                        checkPermissionSubAccountResetPassword
                          ? setNoti(true)
                          : enqueueSnackbar(t("ACCESS_DENIED"), {
                              variant: "error",
                              preventDuplicate: true,
                            })
                      }>
                      <div className="text" style={{ color: "#fff" }}>
                        {t("RESET_PASSWORD")}
                      </div>
                    </div>
                  )}
                  {(authAccount.status.toLowerCase() === SubAccountStatus.OPENING ||
                    authAccount.status.toLowerCase() === SubAccountStatus.PENDING) && (
                    <div
                      className="btn-setpw"
                      onClick={() => {
                        checkPermissionSubAccountUpdate
                          ? history.push(
                              APP_ROUTES.UPDATE_SUB_ACCOUNT.replace(
                                ":accountId",
                                authAccount.id.toString()
                              )
                            )
                          : enqueueSnackbar(t("ACCESS_DENIED"), {
                              variant: "error",
                              preventDuplicate: true,
                            });
                      }}>
                      <div className="text">{t("EDIT")}</div>
                    </div>
                  )}
                </Grid>
                <Grid item xs={8} className="center">
                  <div className="box">
                    <div className="box-header">
                      <div className="title">{t("ACCOUNT_INFO")}</div>
                      <div>
                        <DefaultSwitch
                          checked={authAccount.cancelled ? false : isActive}
                          disabled={authAccount.cancelled}
                          onChange={() => {
                            if (!checkPermissionSubAccountChangeStatus) {
                              enqueueSnackbar(t("ACCESS_DENIED"), {
                                variant: "error",
                                preventDuplicate: true,
                              });
                              return;
                            }
                            setIsActive(!isActive);
                            setNotiStatus({
                              visible: true,
                              status: isActive === false ? true : false,
                            });
                          }}
                        />
                        {isActive === false ? (
                          <span style={{ color: "#838283" }}>{t("DISABLE")}</span>
                        ) : (
                          <span style={{ color: "#41AD49" }}>{t("ACTIVATE")}</span>
                        )}
                      </div>
                    </div>

                    <Grid container>
                      <Grid item xs={4}>
                        <div>
                          <div className="label">{t("LOGIN_CODE")}</div>
                          {authAccount.code || TEXT_HOLDER}
                        </div>
                        <div>
                          <div className="label">{t("ROLE")}</div>
                          {authAccount.role?.position || TEXT_HOLDER}
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div>
                          <div className="label">{t("LOGIN_PHONE")}</div>
                          {authAccount.phoneNumber || TEXT_HOLDER}
                        </div>
                        <div>
                          <div className="label">{t("OTP_VERIFICATION")}</div>
                          {(authAccount.requireOTP === true ? t("YES") : t("NO")) || TEXT_HOLDER}
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div>
                          <div className="label">{t("LOGIN_EMAIL")}</div>
                          {authAccount.email || TEXT_HOLDER}
                        </div>
                        <div>
                          <div className="label">{t("MAIN_ACCOUNT")}</div>
                          {authAccount.parentAccount?.nickname || TEXT_HOLDER}
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container>
                      <div>
                        <div className="label">{t("DELIVERY_ADDRESS")}</div>
                        {authAccount.saleOffices?.map((i) => i.description).join(", ") ||
                          TEXT_HOLDER}
                      </div>
                    </Grid>

                    <Grid container>
                      <div>
                        <div className="label">{t("NOTES")}</div>
                        {authAccount.permissionNote || TEXT_HOLDER}
                      </div>
                    </Grid>

                    <div>
                      <div className="header">
                        <div className="source">{t("ACCESS_SCOPE")}</div>
                        <div className="cus">{t("CUSTOMER")}</div>
                        <div className="access">{t("ACCESS_GRANT")}</div>
                      </div>
                      <div className="table">
                        {!!authAccount.customers?.length &&
                          authAccount.customers?.map((cus: any, idx: number) => {
                            return (
                              <div className="table-content" key={idx}>
                                <div className="col source">
                                  <div>
                                    {(() => {
                                      if (cus.source === SourceType.FEED) {
                                        return t("FEED");
                                      } else if (cus.source === SourceType.FARM) {
                                        return t("FARM");
                                      } else if (cus.source === SourceType.VET) {
                                        return t("VET");
                                      } else if (cus.source === SourceType.LAB) {
                                        return t("LAB");
                                      } else {
                                        return t("CCU");
                                      }
                                    })()}
                                  </div>
                                </div>
                                <div className="col cus">
                                  <div className="cus-info">
                                    {/* <div className="cus-info__img">
                                      <img
                                        src={BASE_LINK_IMAGE + cus.avatar || accountImage}
                                        alt="avatar"
                                        onError={accountImageOnErrorHandler}
                                      />
                                    </div> */}
                                    <div>
                                      <div className="cus-info__name">
                                        {cus.fullName || TEXT_HOLDER} - {cus.code || TEXT_HOLDER}
                                      </div>
                                      <div className="cus-info__phone">
                                        {cus.phoneNumber || TEXT_HOLDER} -{" "}
                                        {cus.email || TEXT_HOLDER}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col access">
                                  <div
                                    className="ic-green"
                                    style={{ backgroundColor: cus.isActive ? "" : "#efefef" }}>
                                    <CheckRoundedIcon
                                      style={{
                                        fontSize: "1.7rem",
                                        color: "#fff",
                                        background: cus.isActive ? "" : "#efefef",
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: "4rem" }}>
                    <div className="box">
                      <div className="title"> {t("ACCESS_HISTORY")}</div>
                      <Divider style={{ margin: "1.6rem 0" }} />

                      {!!authAccount?.accessLogs?.length ? (
                        <>
                          <div className="label">
                            <Grid container>
                              <Grid item xs={3}>
                                {t("TIME")}
                              </Grid>
                              <Grid item xs={3}>
                                IP
                              </Grid>
                              <Grid item xs={3}>
                                {t("STATUS")}
                              </Grid>
                              <Grid item xs={3}>
                                {t("DEVICE")}
                              </Grid>
                            </Grid>
                          </div>
                          {authAccount.accessLogs?.map((u: any, idx: number) => {
                            return (
                              <div style={{ maxHeight: "54rem", overflow: "auto" }} key={idx}>
                                <div className="item">
                                  <Grid container>
                                    <Grid item xs={3}>
                                      {moment(new Date(u.createdAt)).format("DD/MM/YYYY")} lúc{" "}
                                      {(new Date(u.createdAt).toString() as any).substr(16, 5)}
                                    </Grid>
                                    <Grid item xs={3}>
                                      {u.ip || TEXT_HOLDER}
                                    </Grid>
                                    <Grid item xs={3}>
                                      {(u.status ? t("SUCCESS") : t("FAILED")) ?? TEXT_HOLDER}
                                    </Grid>
                                    <Grid item xs={3}>
                                      {u.deviceType || TEXT_HOLDER}
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <EmptyList img={Img} title={t("NO_ACCESS_HISTORY")} />
                      )}
                    </div>
                  </div>
                </Grid>
              </Grid>
            ) : (
              <Loading style={{ margin: "2rem 0", height: "70vh" }} />
            )}
          </div>
        </div>

        {false &&
          !!authAccount &&
          (authAccount?.status.toLowerCase() === SubAccountStatus.PENDING ||
            authAccount?.status.toLowerCase() === SubAccountStatus.OPENING) && (
            <div className="sub-account-footer">
              <div className="show">
                <button
                  onClick={() => {
                    // if (authAccount.status.toLowerCase() === SubAccountStatus.OPENING) {
                    //   setCloseForm(false);
                    //   history.push(APP_ROUTES.SUB_ACCOUNT);
                    // } else {
                    //   setShowCancel(true);
                    // }
                    setShowCancel(true);
                  }}
                  type="submit"
                  className="btn-confirm-style btn-border btn--transparent"
                  style={{ marginLeft: "4rem" }}>
                  {/* {authAccount.status.toLowerCase() === SubAccountStatus.OPENING
                    ? t("CANCEL")
                    : t("REJECT")} */}
                  {t("CANCEL")}
                </button>
                {false && (
                  <button
                    onClick={() => {
                      if (!checkPermissionCusAccountApprove) {
                        enqueueSnackbar(t("ACCESS_DENIED"), {
                          variant: "error",
                          preventDuplicate: true,
                        });
                        return;
                      } else {
                        if (authAccount?.status.toLowerCase() === SubAccountStatus.OPENING) {
                          setOpenPending(true);
                        } else {
                          setShowApprove(true);
                        }
                      }
                    }}
                    type="submit"
                    className="btn-confirm-style"
                    style={{ marginLeft: "4rem" }}>
                    {authAccount?.status.toLowerCase() === SubAccountStatus.OPENING
                      ? t("ACCOUNT_ISSUE")
                      : t("APPROVE")}
                  </button>
                )}
              </div>
            </div>
          )}
      </Dialog>

      <Notification
        visible={openPending}
        title={t("ACCOUNT_ISSUE")}
        subTitle={t("ACCOUNT_ISSUE_CONTENT")}
        icon={
          <div className={"notification-icon-box yellow"}>
            <PriorityHighIcon style={{ fontSize: "7rem", color: "#fff" }} />
          </div>
        }
        actions={
          <>
            <button
              className="btn-confirm-style"
              style={{
                background: "#fff",
                color: "#0A6836",
                border: "0.1rem solid #0A6836",
                width: "20.6rem",
                marginRight: "2rem",
              }}
              onClick={() => setOpenPending(false)}>
              {t("NO")}
            </button>
            <button
              className="btn-confirm-style"
              style={{
                width: "20.6rem",
                marginRight: "2rem",
              }}
              onClick={() => onProvisionAccount()}>
              {t("CONFIRM")}
            </button>
          </>
        }
        onClose={() => {
          setOpenPending(false);
        }}
      />

      <Notification
        visible={noti}
        title={t("RESET_PASSWORD")}
        subTitle={t("CONFIRM_RESET_PASSWORD")}
        icon={
          <div className={"notification-icon-box yellow"}>
            <PriorityHighIcon style={{ fontSize: "7rem", color: "#fff" }} />
          </div>
        }
        actions={
          <>
            <button
              className="btn-confirm-style"
              style={{
                background: "#fff",
                color: "#0A6836",
                border: "0.1rem solid #0A6836",
                width: "20.6rem",
                marginRight: "2rem",
              }}
              onClick={() => setNoti(false)}>
              {t("NO")}
            </button>
            <button
              className="btn-confirm-style"
              style={{
                width: "20.6rem",
                marginRight: "2rem",
              }}
              onClick={() => {}}>
              {t("CONFIRM")}
            </button>
          </>
        }
        onClose={() => {
          setNoti(false);
        }}
      />

      {/**
       * chỉ hiện inactive popup khi active của dòng đó là true
       */}
      {notiStatus && (
        <Notification
          visible={notiStatus.visible}
          title={notiStatus.status ? t("ACTIVATE_ACCOUNT") : t("DEACTIVATE_ACCOUNT")}
          subTitle={
            notiStatus.status ? (
              <span dangerouslySetInnerHTML={{ __html: t("CONFIRM_ACTIVATE_ACCOUNT") }} />
            ) : (
              <span dangerouslySetInnerHTML={{ __html: t("CONFIRM_DEACTIVATE_ACCOUNT") }} />
            )
          }
          icon={
            <div className={`notification-icon-box ${notiStatus.status ? "yellow" : "red"}`}>
              <PriorityHighIcon style={{ fontSize: "7rem", color: "#fff" }} />
            </div>
          }
          actions={
            <>
              <button
                className="btn-confirm-style"
                style={{
                  background: "#fff",
                  color: "#0A6836",
                  border: "0.1rem solid #0A6836",
                  width: "20.6rem",
                  marginRight: "2rem",
                }}
                onClick={() => {
                  notiStatus.status
                    ? setNotiStatus({ visible: false, status: true })
                    : setNotiStatus({ visible: false, status: false });
                  notiStatus.status ? setIsActive(false) : setIsActive(true);
                }}>
                {t("NO")}
              </button>
              <button
                className="btn-confirm-style"
                style={{
                  width: "20.6rem",
                  marginRight: "2rem",
                }}
                onClick={() => {
                  changeStatus();
                  notiStatus.status
                    ? setNotiStatus({ visible: false, status: true })
                    : setNotiStatus({ visible: false, status: false });
                }}>
                {t("CONFIRM")}
              </button>
            </>
          }
          onClose={() => {
            notiStatus.status
              ? setNotiStatus({ visible: false, status: true })
              : setNotiStatus({ visible: false, status: false });
            notiStatus.status ? setIsActive(false) : setIsActive(true);
          }}
        />
      )}

      <Dialog maxWidth="md" open={showCancel}>
        <div className="dialog-cancel">
          <div className="title">
            <div>{t("REJECT_REASON")}</div>
            <ClearIcon
              style={{ fontSize: "3rem", cursor: "pointer" }}
              onClick={() => {
                setShowCancel(false);
                setReason("");
              }}
            />
          </div>
          <div className="label asterisk">{t("REASON")}</div>
          <TextField
            fullWidth
            placeholder={t("INPUT_BY_NAME", { NAME: t("REASON") })}
            className="form-textarea-field"
            multiline
            rows={3}
            variant="outlined"
            color="success"
            inputProps={{ className: "input" }}
            value={reason}
            onChange={(e) => {
              setReason(e.target.value);
            }}
            error={!reason}
          />
          <div className="btn-group" style={{ justifyContent: "space-between" }}>
            <button
              className="btn-cancel"
              onClick={() => {
                setShowCancel(false);
                setReason("");
              }}>
              {t("CANCEL")}
            </button>
            <button
              className="btn-confirm-style"
              style={{ marginLeft: "2.2rem" }}
              onClick={() => {
                onApproveOrRejectAccount("REJECT");
              }}>
              {t("CONFIRM")}
            </button>
          </div>
        </div>
      </Dialog>

      <Notification
        visible={showApprove}
        title={t("APPROVE_ACCOUNT")}
        subTitle={t("APPROVE_ACCOUNT_SUB")}
        icon={
          <div className={"notification-icon-box yellow"}>
            <PriorityHighIcon style={{ fontSize: "7rem", color: "#fff" }} />
          </div>
        }
        actions={
          <>
            <button
              className="btn-confirm-style"
              style={{
                background: "#fff",
                color: "#0A6836",
                border: "0.1rem solid #0A6836",
                width: "20.6rem",
                marginRight: "2rem",
              }}
              onClick={() => setShowApprove(false)}>
              {t("NO")}
            </button>
            <button
              className="btn-confirm-style"
              style={{
                width: "20.6rem",
                marginRight: "2rem",
              }}
              onClick={() => onApproveOrRejectAccount("APPROVE")}>
              {t("CONFIRM")}
            </button>
          </>
        }
        onClose={() => {
          setShowApprove(false);
        }}
      />
    </>
  );
};

export default SubAccountDetail;
