/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react-hooks/exhaustive-deps */
import { EditIcon } from "@Components/Icons/EditIcon";
import { TEXT_HOLDER } from "@Constants";
import {
  DetailItemPlanOutputModel,
  ItemsPlanOutputModel,
  PlanDetailStatus,
  PlanStatus,
} from "@Models";
import { Add as AddIcon, ArrowDropDown as ArrowDropDownIcon } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  Tab,
  Tabs,
} from "@mui/material";
import { dateFormat, getFirstNumberOfStr, StatusUIUtil } from "@Utils";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import OnlyOrder from "../OnlyOrder";
import OrderPlan from "../OrderPlan";
import "./index.scss";

type P_Props = {
  weekList: any[];
  checkWeekUpdate: boolean;
  customerId?: number;
  onChange?: (details: any) => void;
  dataDetails?: DetailItemPlanOutputModel[];
  onVisible?: () => void;
  statusAll?: string;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export type OrderItem = {
  quantity: number;
  itemId: number;
  divisionId?: number;
  unitPrice?: string;
  saleUnit?: string;
  baseUoM?: string;
  dimension?: string;
  subTotal?: number;
  name?: string;
};

const TabCustom: React.FC<P_Props> = (props) => {
  const { weekList, checkWeekUpdate, customerId, onChange, dataDetails, onVisible, statusAll } =
    props;
  const [value, setValue] = React.useState(0);
  const [list, setList] = useState<any>([]);
  const [visibleChange, setVisibleChange] = useState<boolean>(false);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [orderUpdate, setOrderUpdate] = useState<any>(null);

  const CustomTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}>
        {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
      </div>
    );
  };

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const onAddOrder = (key: number, addOrder?: any, addNew: boolean = false) => {
    const idx = list.findIndex((item: any) => item.key === key);

    let data = [...list];

    if (idx !== -1) {
      if (addNew) {
        return;
      }
      //update

      data[idx] = {
        ...data[idx],
        details: [...data[idx].details, addOrder],
      };
    } else {
      // thêm mới
      if (!addOrder) {
        return;
      }
      data.push({ key, details: addOrder });
    }

    setList(data);
  };

  const onUpdateOrder = (key: number, index: number, event: any) => {
    const idx = list.findIndex((item: any) => item.key === key);

    const values = [...list[idx]?.details];

    values[index].items = event;
  };

  const handleRemoveOrder = (key: number, index: number) => {
    const idx = list.findIndex((item: any) => item.key === key);

    let data = [...list];

    let values = [...list[idx]?.details];

    values.splice(index, 1);

    data[idx] = {
      ...data[idx],
      details: values,
    };

    setList(data);
  };

  let listPros =
    list
      ?.map((d: any) => d?.details)
      ?.flat()
      ?.map((d: any) => d?.items)
      ?.flat() || [];

  let total = listPros?.reduce((sum: any, item: ItemsPlanOutputModel) => {
    return sum + +item.quantity;
  }, 0);

  useEffect(() => {
    if (onChange) onChange(list);
  }, [list, visibleChange]);

  useEffect(() => {
    if (customerId && dataDetails) {
      setList(dataDetails);
    }
  }, [customerId]);

  return (
    <Box sx={{ width: "100%" }} className="tab-custom">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{
            style: {
              backgroundColor: "#0A6836",
            },
          }}
          variant="fullWidth">
          {weekList?.map((item, key: number) => {
            return (
              <Tab
                key={key}
                value={key}
                {...a11yProps(key)}
                label={
                  <span
                    className={clsx("plan-create__tab", {
                      "plan-create__tab__active": value === key,
                    })}>
                    {dateFormat(item)}
                  </span>
                }
              />
            );
          })}
        </Tabs>
      </Box>
      {weekList?.map((week, key: number) => {
        const listUpdate = list?.find((f: any) => f.key === value)?.details;

        const dataList = dataDetails?.filter(
          (f) => f.date === dateFormat(week, false, "YYYY-MM-DD")
        );

        return (
          <CustomTabPanel key={key} value={value} index={key}>
            {customerId ? (
              <>
                {listUpdate
                  ?.sort((a: any, b: any) => a.id - b.id)
                  ?.map((item: any, index: number) => {
                    return (
                      <OrderPlan
                        orderLabel={index + 1}
                        data={item}
                        onChange={(data: any) => {
                          onUpdateOrder(value, index, data);
                          setVisibleChange(!visibleChange);
                        }}
                        onRemoveOrder={() => {
                          handleRemoveOrder(value, index);
                          setVisibleChange(!visibleChange);
                        }}
                        checkWeekUpdate={checkWeekUpdate}
                      />
                    );
                  })}

                {checkWeekUpdate && (
                  <div
                    onClick={() => {
                      const keyValue = list?.find((f: any) => f.key === value)?.details?.length;
                      if (list?.length > 0 && list?.map((m: any) => m.key)?.includes(key)) {
                        onAddOrder(value, {
                          date: dateFormat(week),
                          id: keyValue + 1,
                          items: [{ itemId: 0, quantity: 1 } as OrderItem],
                        });
                      } else {
                        onAddOrder(
                          value,
                          [
                            {
                              date: dateFormat(week),
                              id: 1,
                              items: [{ itemId: 0, quantity: 1 } as OrderItem],
                            },
                          ],
                          true
                        );
                      }
                    }}
                    className="btn-border-confirm-style"
                    style={{ width: "fit-content" }}>
                    <AddIcon style={{ fontSize: "1.8rem", marginRight: "1rem" }} /> Thêm kế hoạch
                    đơn hàng
                  </div>
                )}

                {list?.length > 0 && (
                  <>
                    <Divider style={{ margin: "2.4rem 0" }} />
                    <div className="plan-create__group">
                      <div>Tổng sản lượng đặt theo tuần</div>
                      <div className="plan-create__group__total">
                        {total}
                        &#160;kg
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              dataList
                ?.sort((a, b) => getFirstNumberOfStr(a.label) - getFirstNumberOfStr(b.label))
                ?.map((item) => {
                  return (
                    <Accordion key={item?.id} className="tab-custom__accordion" defaultExpanded>
                      <AccordionSummary aria-controls="panel1-content" id="panel1-header">
                        <span>
                          {item.label}
                          {!!item.orderGroup
                            ? ` - Mã đơn hàng tham chiếu: ${item.orderGroup?.code}`
                            : ""}
                          <ArrowDropDownIcon className="custom-arrow" />
                        </span>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}>
                          {statusAll === PlanStatus.CHANGING &&
                            item.status === PlanDetailStatus.CHANGING &&
                            (new Date(item.date).getTime() < new Date().getTime() ? (
                              <div className="col-text">
                                Bạn không được chỉnh sửa kế hoạch đặt hàng trong quá khứ
                              </div>
                            ) : (
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setOrderUpdate(item);
                                }}>
                                <EditIcon color={"#061938"} size={[22, 22]} viewBox={[16, 16]} />
                              </div>
                            ))}
                          {statusAll !== PlanStatus.CHANGING && (
                            <div style={{ position: "relative", display: "flex" }}>
                              <div style={{ marginRight: 2 }}>
                                {StatusUIUtil.renderBgStatusPlanDetail(item.status, true)}
                              </div>
                            </div>
                          )}
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={3} className="tab-custom__accordion__label">
                          <Grid item xs={6} style={{ marginBottom: 12 }}>
                            Sản phẩm
                          </Grid>
                          <Grid item xs={6} style={{ marginBottom: 12 }}>
                            Sản lượng
                          </Grid>
                        </Grid>
                        {item.items?.map((product: ItemsPlanOutputModel, index: number) => {
                          return (
                            <Grid
                              container
                              key={index}
                              spacing={3}
                              className="tab-custom__accordion__line">
                              <Grid item xs={6} style={{ marginBottom: 12 }}>
                                {`${product?.item?.code} - ${product?.item?.name}` || TEXT_HOLDER}
                              </Grid>
                              <Grid item xs={6} style={{ marginBottom: 12 }}>
                                {+product?.quantity || TEXT_HOLDER}
                              </Grid>
                            </Grid>
                          );
                        })}
                        <Divider />
                        <div className="tab-custom__accordion__totalRow">
                          <div>Tổng sản lượng theo ngày</div>
                          <div className="total">
                            {item?.items?.reduce((sum: any, item: any) => {
                              return sum + +item.quantity;
                            }, 0)}
                            &#160;kg
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  );
                })
            )}
          </CustomTabPanel>
        );
      })}

      {orderUpdate !== null && onVisible && (
        <OnlyOrder
          detail={orderUpdate}
          onClose={() => setOrderUpdate(null)}
          onVisible={onVisible}
        />
      )}
    </Box>
  );
};

export default TabCustom;
