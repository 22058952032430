import { CheckRounded as CheckRoundedIcon } from "@mui/icons-material";
import exporting from "@Assets/exporting.gif";
import Notification from "@Components/Notification";
import { BASE_LINK_IMAGE } from "@Constants";
import { useTranslation } from "react-i18next";

export type P_Props = {
  notiExport: any;
  title: string;
  closeForm: () => void;
  success?: any;
};

const NotiExportExcel: React.FC<P_Props> = ({ notiExport, title, closeForm, success }) => {
  const { t } = useTranslation(["translation"]);
  return (
    <Notification
      visible={notiExport}
      title={success ? t("DATA_EXPORT_SUCCESSFUL") : t("EXPORT_DATA")}
      subTitle={
        success ? (
          <span>
            {t("DATA_SUCCESSFULLY_EXPORTED_BY_NAME", { NAME: title })}
            <br />
            {t("CLICK_DOWNLOAD_FILE")}
          </span>
        ) : (
          <span>
            {t("EXPORT_REQUEST_PROGRESS_BY_NAME", { NAME: title })}
            <br />
            {t("PLEASE_WAIT_MOMENT")}
          </span>
        )
      }
      icon={
        <div className={`notification-icon-box green`}>
          {success ? (
            <CheckRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
          ) : (
            <img src={exporting} alt="exporting" style={{ width: "12rem", height: "12rem" }} />
          )}
        </div>
      }
      actions={
        <>
          <button
            className="btn-confirm-style"
            style={{
              background: "#fff",
              color: "#0A6836",
              border: "0.1rem solid #0A6836",
              width: "20.6rem",
              marginRight: "2rem",
              borderRadius: "4rem",
            }}
            onClick={() => {
              closeForm();
            }}>
            {t("CLOSE")}
          </button>
          {success ? (
            <a
              className="btn-confirm-style"
              style={{
                width: "20.6rem",
                borderRadius: "4rem",
              }}
              rel="noreferrer"
              href={`${BASE_LINK_IMAGE + success}`}
              onClick={() => {
                setTimeout(() => {
                  closeForm();
                }, 3000);
              }}>
              {t("DOWNLOAD")}
            </a>
          ) : (
            <button
              disabled
              className="btn-confirm-style"
              style={{
                width: "20.6rem",
                borderRadius: "4rem",
                opacity: "0.4",
                cursor: "not-allowed",
              }}>
              {t("DOWNLOAD")}
            </button>
          )}
        </>
      }
      onClose={() => {
        closeForm();
      }}
    />
  );
};

export default NotiExportExcel;
