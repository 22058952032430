import ComingSoonAccess from "@Components/CommingSoon/ComingSoonAccess";
import React from "react";
import { RouteProps } from "react-router-dom";
// import { CcuIcon } from "@Components/Icons/CcuIcon";
// import { BillIcon } from "@Components/Icons/BillIcon";
import { DashboardIcon } from "@Components/Icons/DashboardIcon";
import { FarmdIcon } from "@Components/Icons/FarmIcon";
import { FeedIcon } from "@Components/Icons/FeedIcon";
import { HomeIcon } from "@Components/Icons/HomeIcon";
import { OrderIcon } from "@Components/Icons/OrderIcon";
import { SettingsIcon } from "@Components/Icons/SettingsIcon";
// import { UserIcon } from "@Components/Icons/UserIcon";
import { APP_ROUTES } from "@Constants";
import ChangePassword from "@Containers/Customer/ChangePassword/ChangePassword";
import Identity from "@Containers/Customer/Identity/Identity";
import Profile from "@Containers/Customer/Profile/Profile";
import Signature from "@Containers/Customer/Signature/Signature";
// import Bill from "@Containers/Bill";
import { AccountSubIcon } from "@Components/Icons/AccountSub";
import { LabIcon } from "@Components/Icons/LabIcon";
import { PlanIcon } from "@Components/Icons/PlanIcon";
import { VetIcon } from "@Components/Icons/VetIcon";
import PolicyPrivacy from "@Containers/Customer/Policy/Policy";
import Dashboard from "@Containers/Dashboard";
// import Debt from "@Containers/Debt";
// import Discount from "@Containers/Debt/Discount";
// import DiscountLetter from "@Containers/Debt/DiscountLetter";
import AccessDenied from "@Components/AccessDenied";
import Debt from "@Containers/Dashboard/Debt";
import Discount from "@Containers/Dashboard/Debt/Discount";
import DiscountLetter from "@Containers/Dashboard/Debt/DiscountLetter";
import Report from "@Containers/Dashboard/Debt/Report";
import Home from "@Containers/Home";
import NewsPreview from "@Containers/News/Detail/NewsPreview";
import OrderListing from "@Containers/Order";
import OrderDraftFarm from "@Containers/Order/Farm/OrderDraft/OrderDraftFarm";
import OrderModalFarm from "@Containers/Order/Farm/OrderModalFarm";
import OrderDraft from "@Containers/Order/Feed/OrderDraft/OrderDraft";
import OrderModal from "@Containers/Order/Feed/OrderModal";
import UpdateOrderFeed from "@Containers/Order/Feed/UpdateOrder/UpdateOrderFeed";
import OrderModalLab from "@Containers/Order/Lab/OrderModalLab";
import UpdateOrderLab from "@Containers/Order/Lab/UpdateOrder/UpdateOrderLab";
import UpdateOrderLabSample from "@Containers/Order/Lab/UpdateOrder/UpdateOrderLabSample";
import OrderDetail from "@Containers/Order/OrderDetail/OrderDetail";
import OrderDetailFarm from "@Containers/Order/OrderDetail/OrderDetailFarm";
import OrderDetailLab from "@Containers/Order/OrderDetail/OrderDetailLab";
import OrderDetailVet from "@Containers/Order/OrderDetail/OrderDetailVet";
import OrderModalVet from "@Containers/Order/Vet/OrderModalVet";
import UpdateOrderVet from "@Containers/Order/Vet/UpdateOrder/UpdateOrderVet";
import Plan from "@Containers/Plan";
import CreatePlan from "@Containers/Plan/CreatePlan";
import PlanDetail from "@Containers/Plan/Detail";
import DraftPlan from "@Containers/Plan/DraftPlan";
import UpdatePlan from "@Containers/Plan/UpdatePlan";
import Product from "@Containers/Product";
import SubAccount from "@Containers/SubAccount";
import SubAccountDetail from "@Containers/SubAccount/Detail/SubAccountDetail";
import SubAccountModal from "@Containers/SubAccount/Modal/SubAccountModal";
import UpdateSubAccountModal from "@Containers/SubAccount/Modal/UpdateSubAccount";
import {
  AccountType,
  ItemPermission,
  OrderPermission,
  OrderPlanPermission,
  ReportPermission,
  SourceType,
  SubAccountPermission,
} from "@Models";
import OrderDraftVet from "@Containers/Order/Vet/OrderDraft/OrderDraft";

export const DEFAULT_COLOR = "#6C778D";
export const ACTIVE_COLOR = "#FFF";
export const DEFAULT_SIZE: [number, number] = [28, 28];

export type ModuleItem = {
  title: string;
  label: string;
  defaultIcon: React.ReactElement;
  activeIcon: React.ReactElement;
  path: string;
  subRoutes?: Array<ModuleItem>;
  permission?: any;
  enabled: boolean;
  source?: string;
};

const moduleList: ModuleItem[] = [
  {
    title: "HOME",
    label: "HOME",
    defaultIcon: <HomeIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    activeIcon: <HomeIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    path: APP_ROUTES.HOME,
    enabled: true,
  },
  {
    title: "ORDER",
    label: "ORDER",
    defaultIcon: <OrderIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    activeIcon: <OrderIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    path: APP_ROUTES.ORDER,
    permission: OrderPermission.MANAGE_ORDER_VIEWLIST,
    enabled: true,
    subRoutes: [
      {
        title: "FEED",
        label: "Đơn hàng",
        defaultIcon: <FeedIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[22, 24]} />,
        activeIcon: <FeedIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[22, 24]} />,
        path: APP_ROUTES.ORDER,
        enabled: true,
        source: "feed",
      },
      {
        title: "FARM",
        label: "Đơn hàng",
        defaultIcon: <FarmdIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
        activeIcon: <FarmdIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
        path: APP_ROUTES.ORDER,
        enabled: true,
        source: "farm",
      },
      {
        title: "VET",
        label: "Đơn hàng",
        defaultIcon: <VetIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[20, 20]} />,
        activeIcon: <VetIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[20, 20]} />,
        path: APP_ROUTES.ORDER,
        enabled: true,
        source: "vet",
      },
      {
        title: "LAB",
        label: "Đơn hàng",
        defaultIcon: <LabIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
        activeIcon: <LabIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
        path: APP_ROUTES.ORDER,
        enabled: true,
        source: "lab",
      },
      // {
      //   title: "Chuỗi cung ứng",
      //   label: "Đơn hàng",
      //   defaultIcon: <CcuIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
      //   activeIcon: <CcuIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
      //   path: APP_ROUTES.ORDER,
      //   enabled: true,
      //   source: "ccu",
      // },
    ],
  },
  // {
  //   title: "Hoá đơn (Tính năng đang được cập nhật)",
  //   label: "Hoá đơn",
  //   defaultIcon: <BillIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
  //   activeIcon: <BillIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
  //   path: APP_ROUTES.BILL,
  //   enabled: true,
  //   subRoutes: [
  //     {
  //       title: "Thức ăn chăn nuôi",
  //       label: "Hoá đơn",
  //       defaultIcon: <FeedIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[22, 24]} />,
  //       activeIcon: <FeedIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[22, 24]} />,
  //       path: APP_ROUTES.BILL,
  //       enabled: true,
  //       source: "feed",
  //     },
  //     {
  //       title: "Trang trại & con giống",
  //       label: "Hoá đơn",
  //       defaultIcon: <FarmdIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
  //       activeIcon: <FarmdIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
  //       path: APP_ROUTES.BILL,
  //       enabled: true,
  //       source: "farm",
  //     },
  //   ],
  // },
  {
    title: "Kế hoạch đặt hàng",
    label: "Kế hoạch đặt hàng",
    defaultIcon: <PlanIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    activeIcon: <PlanIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    path: APP_ROUTES.PLAN,
    permission: OrderPlanPermission.MANAGE_ORDERPLAN_VIEWLIST,
    enabled: true,
  },
  {
    title: "PRODUCT",
    label: "PRODUCT",
    defaultIcon: <FeedIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[22, 24]} />,
    activeIcon: <FeedIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[22, 24]} />,
    path: APP_ROUTES.PRODUCT,
    permission: ItemPermission.MANAGE_ITEM_VIEWLIST,
    enabled: true,
    subRoutes: [
      {
        title: "FEED",
        label: "Sản phẩm",
        defaultIcon: <FeedIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[22, 24]} />,
        activeIcon: <FeedIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[22, 24]} />,
        path: APP_ROUTES.PRODUCT,
        enabled: true,
        source: "feed",
      },
      {
        title: "FARM",
        label: "Sản phẩm",
        defaultIcon: <FarmdIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
        activeIcon: <FarmdIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
        path: APP_ROUTES.PRODUCT,
        enabled: true,
        source: "farm",
      },
      {
        title: "VET",
        label: "Sản phẩm",
        defaultIcon: <VetIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[20, 20]} />,
        activeIcon: <VetIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[20, 20]} />,
        path: APP_ROUTES.PRODUCT,
        enabled: true,
        source: "vet",
      },
      {
        title: "LAB",
        label: "Sản phẩm",
        defaultIcon: <LabIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
        activeIcon: <LabIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
        path: APP_ROUTES.PRODUCT,
        enabled: true,
        source: "lab",
      },
    ],
  },
  {
    title: "REPORT",
    label: "REPORT",
    defaultIcon: <DashboardIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    activeIcon: <DashboardIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    path: APP_ROUTES.DASHBOARD,
    permission: Object.values(ReportPermission),
    enabled: true,
    subRoutes: [
      {
        title: "FEED",
        label: "Báo cáo",
        defaultIcon: <FeedIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[22, 24]} />,
        activeIcon: <FeedIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[22, 24]} />,
        path: APP_ROUTES.DASHBOARD,
        enabled: true,
        source: "feed",
      },
      {
        title: "FARM",
        label: "Báo cáo",
        defaultIcon: <FarmdIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
        activeIcon: <FarmdIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
        path: APP_ROUTES.DASHBOARD,
        enabled: true,
        source: "farm",
      },
      {
        title: "VET",
        label: "Báo cáo",
        defaultIcon: <VetIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[20, 20]} />,
        activeIcon: <VetIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[20, 20]} />,
        path: APP_ROUTES.DASHBOARD,
        enabled: true,
        source: "vet",
      },
      {
        title: "LAB",
        label: "Báo cáo",
        defaultIcon: <LabIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
        activeIcon: <LabIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
        path: APP_ROUTES.DASHBOARD,
        enabled: true,
        source: "lab",
      },
    ],
  },
  // {
  //   title: "Thư CN/CK",
  //   label: "Thư CN/CK",
  //   defaultIcon: <LetterIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
  //   activeIcon: <LetterIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
  //   path: APP_ROUTES.DISCOUNT_LETTER,
  //   enabled: true,
  //   subRoutes: [
  //     {
  //       title: "Thức ăn chăn nuôi",
  //       label: "Thư CN/CK",
  //       defaultIcon: <FeedIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[22, 24]} />,
  //       activeIcon: <FeedIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[22, 24]} />,
  //       path: APP_ROUTES.DISCOUNT_LETTER,
  //       enabled: true,
  //       source: "feed",
  //     },
  //     {
  //       title: "Trang trại & con giống",
  //       label: "Thư CN/CK",
  //       defaultIcon: <FarmdIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
  //       activeIcon: <FarmdIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
  //       path: APP_ROUTES.DISCOUNT_LETTER,
  //       enabled: true,
  //       source: "farm",
  //     },
  //   ],
  // },
  // {
  //   title: "Thông tin cá nhân",
  //   label: "Hồ sơ",
  //   defaultIcon: <UserIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
  //   activeIcon: <UserIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
  //   path: APP_ROUTES.CUSTOMER,
  //   enabled: true,
  // },

  {
    title: "SUB_ACCOUNT",
    label: "SUB_ACCOUNT",
    defaultIcon: (
      <AccountSubIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />
    ),
    activeIcon: <AccountSubIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    path: APP_ROUTES.SUB_ACCOUNT,
    permission: Object.values(SubAccountPermission),
    enabled: true,
  },
  {
    title: "PERSONAL_INFORMATION",
    label: "PROFILE",
    defaultIcon: <SettingsIcon color={"#0A6836"} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    activeIcon: <SettingsIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    path: APP_ROUTES.SETTING,
    enabled: true,
  },
];

export const appRoutes: Array<RouteProps> = [
  {
    exact: true,
    path: APP_ROUTES.HOME,
    component: Home,
  },

  /** ORDER */
  {
    exact: true,
    path: APP_ROUTES.ORDER_FEED,
    component: () => {
      return <OrderListing sourceType={SourceType.FEED} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.ORDER_FARM,
    component: () => {
      return <OrderListing sourceType={SourceType.FARM} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.ORDER_CCU,
    component: () => {
      return <OrderListing sourceType="ccu" />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.ORDER_VET,
    component: () => {
      return <OrderListing sourceType={SourceType.VET} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.ORDER_LAB,
    component: () => {
      return <OrderListing sourceType={SourceType.LAB} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.ORDER_DETAIL,
    component: OrderDetail,
  },
  {
    exact: true,
    path: APP_ROUTES.ORDER_DETAIL_FARM,
    component: OrderDetailFarm,
  },
  {
    exact: true,
    path: APP_ROUTES.ORDER_DETAIL_VET,
    component: OrderDetailVet,
  },
  {
    exact: true,
    path: APP_ROUTES.ORDER_DETAIL_LAB,
    component: OrderDetailLab,
  },
  {
    exact: true,
    path: APP_ROUTES.UPDATE_ORDER_DRAFT,
    component: OrderDraft,
  },

  {
    exact: true,
    path: APP_ROUTES.UPDATE_ORDER_DRAFT_FARM,
    component: OrderDraftFarm,
  },
  {
    exact: true,
    path: APP_ROUTES.UPDATE_ORDER_DRAFT_VET,
    component: OrderDraftVet,
  },
  {
    exact: true,
    path: APP_ROUTES.UPDATE_ORDER_FEED,
    component: UpdateOrderFeed,
  },
  {
    exact: true,
    path: APP_ROUTES.UPDATE_ORDER_VET,
    component: UpdateOrderVet,
  },
  {
    exact: true,
    path: APP_ROUTES.UPDATE_ORDER_LAB,
    component: UpdateOrderLab,
  },
  {
    exact: true,
    path: APP_ROUTES.UPDATE_ORDER_LAB_SAMPLE,
    component: UpdateOrderLabSample,
  },
  {
    exact: true,
    path: APP_ROUTES.CREATE_FEED,
    component: OrderModal,
  },
  {
    exact: true,
    path: APP_ROUTES.CREATE_FARM,
    component: OrderModalFarm,
  },
  {
    exact: true,
    path: APP_ROUTES.CREATE_VET,
    component: OrderModalVet,
  },
  {
    exact: true,
    path: APP_ROUTES.CREATE_LAB,
    component: OrderModalLab,
  },
  {
    exact: true,
    path: APP_ROUTES.CREATE_FEED_ID,
    component: OrderModal,
  },

  /** PLAN */
  {
    exact: true,
    path: APP_ROUTES.PLAN,
    component: Plan,
  },
  {
    exact: false,
    path: APP_ROUTES.PLAN_DETAIL,
    component: PlanDetail,
  },
  {
    exact: true,
    path: APP_ROUTES.CREATE_PLAN,
    component: CreatePlan,
  },
  {
    exact: true,
    path: APP_ROUTES.DRAFT_PLAN,
    component: DraftPlan,
  },
  {
    exact: true,
    path: APP_ROUTES.UPDATE_PLAN,
    component: UpdatePlan,
  },

  /** PRODUCT */
  {
    exact: true,
    path: APP_ROUTES.PRODUCT_FEED,
    component: () => {
      return <Product sourceType={SourceType.FEED} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.PRODUCT_FARM,
    component: () => {
      return <Product sourceType={SourceType.FARM} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.PRODUCT_VET,
    component: () => {
      return <Product sourceType={SourceType.VET} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.PRODUCT_LAB,
    component: () => {
      return <Product sourceType={SourceType.LAB} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.RECREATE_PLAN,
    component: DraftPlan,
  },

  /** REPORT */
  {
    exact: true,
    path: APP_ROUTES.DASHBOARD_OVERVIEW + "-feed",
    component: Dashboard,
  },
  {
    exact: true,
    path: APP_ROUTES.DEBT + "-feed",
    component: () => {
      return <Debt sourceType={SourceType.FEED} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.DEBT + "-farm",
    component: () => {
      return <Debt sourceType={SourceType.FARM} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.DEBT + "-vet",
    component: () => {
      return <Debt sourceType={SourceType.VET} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.DEBT + "-lab",
    component: () => {
      return <Debt sourceType={SourceType.LAB} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.REPORT + "-feed",
    component: Report,
  },
  {
    exact: true,
    path: APP_ROUTES.REPORT_SALES,
    component: () => {
      return <DiscountLetter sourceType={SourceType.FEED} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.DISCOUNT_LETTER + "-feed",
    component: () => {
      return <DiscountLetter sourceType={SourceType.FEED} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.DISCOUNT_LETTER + "-farm",
    component: ComingSoonAccess,
  },
  {
    exact: true,
    path: APP_ROUTES.REPORT_DISCOUNT + "-feed",
    component: () => {
      return <Discount sourceType={SourceType.FEED} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.REPORT_DISCOUNT + "-farm",
    component: ComingSoonAccess,
  },
  {
    exact: true,
    path: APP_ROUTES.ACCESS_DENIED,
    component: AccessDenied,
  },

  /** ACCOUNT */
  {
    exact: true,
    path: APP_ROUTES.SUB_ACCOUNT,
    component: () => {
      return <SubAccount accountType={AccountType.SUB_ACCOUNT} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.CREATE_SUB_ACCOUNT,
    component: SubAccountModal,
  },
  {
    exact: true,
    path: APP_ROUTES.UPDATE_SUB_ACCOUNT,
    component: UpdateSubAccountModal,
  },
  {
    exact: false,
    path: APP_ROUTES.SUB_ACCOUNT_DETAIL,
    component: SubAccountDetail,
  },

  /** NEWS */
  {
    exact: false,
    path: APP_ROUTES.NEWS_DETAIL,
    component: NewsPreview,
  },

  /** SETTING */
  {
    exact: true,
    path: APP_ROUTES.IDENTITY,
    component: Identity,
  },
  {
    exact: true,
    path: APP_ROUTES.RESET_PASSWORD,
    component: ChangePassword,
  },
  {
    exact: true,
    path: APP_ROUTES.SIGNATURE,
    component: Signature,
  },
  {
    exact: true,
    path: APP_ROUTES.PRIVACY_POLICY,
    component: PolicyPrivacy,
  },
  {
    exact: true,
    path: APP_ROUTES.SETTING,
    component: Profile,
  },

  /** OTHER */
  {
    exact: true,
    path: APP_ROUTES.ACCOMMINGSOON,
    component: ComingSoonAccess,
  },
];

export default moduleList;
