import { PlanStatus, Reason, ReportPermission } from "@Models";
import { APP_ROUTES } from "./module.constant";
import IconVN from "@Assets/images/mock/ic-vietnam.png";
import IconEN from "@Assets/images/mock/ic-english.png";
import IconCD from "@Assets/images/mock/ic-cambodia.png";
import IconMA from "@Assets/images/mock/ic-myanmar.png";
// import IconCN from "@Assets/images/mock/ic-china.png";
import { LANGUAGES } from "./other.constant";

export const NavHeaderOrder = [
  {
    id: "1",
    label: "ALL",
    value: "all",
  },
  {
    id: "2",
    label: "CART",
    value: "draft",
  },
  {
    id: "3",
    label: "CREATED",
    value: "created",
  },
  {
    id: "4",
    label: "PENDING",
    value: "verified",
  },
  {
    id: "5",
    label: "RECORDED",
    value: "confirmed",
  },
  {
    id: "6",
    label: "DELIVERING",
    value: "processing",
  },
  {
    id: "7",
    label: "DELIVERED",
    value: "completed",
  },
  {
    id: "8",
    label: "CANCELLED",
    value: "cancelled",
  },
];

export const NavHeaderOrderFarm = [
  {
    id: "1",
    label: "ALL",
    value: "all",
  },
  {
    id: "2",
    label: "CART",
    value: "draft",
  },
  {
    id: "3",
    label: "CREATED",
    value: "created",
  },
  {
    id: "4",
    label: "DISPATCHED",
    value: "confirmed",
  },
  {
    id: "5",
    label: "PROCESSING",
    value: "verified",
  },
  {
    id: "6",
    label: "PARTIALLY_DELIVERED",
    value: "halfCompleted",
  },
  {
    id: "7",
    label: "DELIVERED",
    value: "completed",
  },
  {
    id: "8",
    label: "CANCELLED",
    value: "cancelled",
  },
];

export const NavHeaderOrderLab = [
  {
    id: "1",
    label: "ALL",
    value: "all",
  },
  {
    id: "2",
    label: "DRAFT",
    value: "draft",
  },
  {
    id: "3",
    label: "SEND_REQUEST",
    value: "created",
  },
  {
    id: "4",
    label: "CONFIRMED",
    value: "confirmed",
  },
  {
    id: "5",
    label: "TRANSFER_SAMPLE",
    value: "processing",
  },
  {
    id: "6",
    label: "SAMPLE_SCHEDULED",
    value: "scheduled",
  },
  {
    id: "7",
    label: "SAMPLE_RECEIVED",
    value: "received",
  },
  {
    id: "8",
    label: "COMPLETED",
    value: "completed",
  },
  {
    id: "9",
    label: "CANCELLED",
    value: "cancelled",
  },
  {
    id: "10",
    label: "REJECTED",
    value: "rejected",
  },
];

export const NavHeaderNews = [
  {
    id: "0",
    label: "ALL",
    value: "ALL",
  },
  {
    id: "1",
    label: "PUBLISHED",
    value: "post",
  },
  {
    id: "2",
    label: "DRAFTS",
    value: "drag",
  },
  {
    id: "3",
    label: "HIDDEN",
    value: "hidden",
  },
];

export const UpdateHistory = [
  {
    id: "1",
    time: "25/10/2020 lúc 21:00",
    name: "Brooklyn",
    update: "Thay đổi Tên khách hàng",
  },
  {
    id: "2",
    time: "25/10/2020 lúc 21:00",
    name: "Brooklyn",
    update: "Thay đổi Tên khách hàng ",
  },
  {
    id: "3",
    time: "25/10/2020 lúc 21:00",
    name: "Brooklyn",
    update: "Thay đổi Tên khách hàng",
  },
  {
    id: "4",
    time: "25/10/2020 lúc 21:00",
    name: "Brooklyn",
    update: "Thay đổi Tên khách hàng",
  },
];

export const Newstab = [
  {
    id: 1,
    title: "Tin mới nhất",
  },
  {
    id: 2,
    title: "Tin nổi bật",
  },
  {
    id: 3,
    title: "Xem tất cả",
  },
];

export const DebtList = [
  {
    date: "1 - 7 ngày",
    id: "amtRange1",
  },
  {
    date: "8 - 14 ngày",
    id: "amtRange2",
  },
  {
    date: "15 - 21 ngày",
    id: "amtRange3",
  },
  {
    date: "22 - 28 ngày",
    id: "amtRange4",
  },
  {
    date: "29 - 60 ngày",
    id: "amtRange5",
  },
  {
    date: "61 - 90 ngày",
    id: "amtRange6",
  },
  {
    date: "91 - 120 ngày",
    id: "amtRange7",
  },

  {
    date: "121 - 180 ngày",
    id: "amtRange8",
  },
  {
    date: "181 - 365 ngày",
    id: "amtRange9",
  },
  {
    date: "366 - 730 ngày",
    id: "amtRange10",
  },
  {
    date: "731 - 1095 ngày",
    id: "amtRange11",
  },
  {
    date: "Trên 1095 ngày",
    id: "amtRange12",
  },
];

export const MockBillListing = [
  { id: "GR/16P-00242970", date: "04/07/2021" },
  { id: "GR/16P-00242970", date: "04/07/2021" },
  { id: "GR/16P-00242970", date: "04/07/2021" },
  { id: "GR/16P-00242970", date: "04/07/2021" },
  { id: "GR/16P-00242970", date: "04/07/2021" },
  { id: "GR/16P-00242970", date: "04/07/2021" },
  { id: "GR/16P-00242970", date: "04/07/2021" },
  { id: "GR/16P-00242970", date: "04/07/2021" },
  { id: "GR/16P-00242970", date: "04/07/2021" },
];

export const ReasonStatus = [
  {
    id: "1",
    label: "NOT_RESPONSE",
    value: Reason.NOT_RESPONSE,
  },
  {
    id: "2",
    label: "CHANGE_OF_MIND",
    value: Reason.CHANGE_OF_MIND,
  },
  {
    id: "3",
    label: "DUPLICATE_ORDER",
    value: Reason.DUPLICATED,
  },
  {
    id: "4",
    label: "OTHER",
    value: Reason.OTHER,
  },
];

export const sumAmt = [
  { label: "sumAmt1" },
  { label: "sumAmt2" },
  { label: "sumAmt3" },
  { label: "sumAmt4" },
  { label: "sumAmt5" },
  { label: "sumAmt6" },
  { label: "sumAmt7" },
  { label: "sumAmt8" },
  { label: "sumAmt9" },
  { label: "sumAmt10" },
  { label: "sumAmt11" },
  { label: "sumAmt12" },
];

export const Year = [
  {
    year: "1990",
  },
  {
    year: "1991",
  },
  {
    year: "1992",
  },
  {
    year: "1993",
  },
  {
    year: "1994",
  },
  {
    year: "1995",
  },
  {
    year: "1996",
  },
  {
    year: "1997",
  },
  {
    year: "1998",
  },
  {
    year: "1999",
  },
  {
    year: "2000",
  },
  {
    year: "2001",
  },
  {
    year: "2002",
  },
  {
    year: "2003",
  },
  {
    year: "2004",
  },
  {
    year: "2005",
  },
  {
    year: "2006",
  },
  {
    year: "2007",
  },
  {
    year: "2008",
  },
  {
    year: "2009",
  },
  {
    year: "2010",
  },
  {
    year: "2011",
  },
  {
    year: "2012",
  },
  {
    year: "2013",
  },
  {
    year: "2014",
  },
  {
    year: "2015",
  },
  {
    year: "2016",
  },
  {
    year: "2017",
  },
  {
    year: "2018",
  },
  {
    year: "2019",
  },
  {
    year: "2020",
  },
  {
    year: "2021",
  },
  {
    year: "2022",
  },
];

export const Month = [
  {
    month: "01",
  },
  {
    month: "02",
  },
  {
    month: "03",
  },
  {
    month: "04",
  },
  {
    month: "05",
  },
  {
    month: "06",
  },
  {
    month: "07",
  },
  {
    month: "08",
  },
  {
    month: "09",
  },
  {
    month: "10",
  },
  {
    month: "11",
  },
  {
    month: "12",
  },
];

export const SubTab = [
  {
    label: "Tổng quan",
    path: APP_ROUTES.DASHBOARD_OVERVIEW,
    source: "feed",
    permission: ReportPermission.MANAGE_REPORT_OVERVIEW,
  },
  {
    label: "Sản lượng",
    path: APP_ROUTES.REPORT,
    source: "feed",
    permission: ReportPermission.MANAGE_REPORT_SALES_VOLUMES,
  },
  {
    label: "Công nợ",
    path: APP_ROUTES.DEBT,
    source: "feed",
    permission: ReportPermission.MANAGE_REPORT_DEBT,
  },
  {
    label: "Chiết khấu",
    path: APP_ROUTES.REPORT_DISCOUNT,
    source: "feed",
    permission: ReportPermission.MANAGE_REPORT_DISCOUNT,
  },
  /*farm */
  {
    label: "Doanh thu",
    path: APP_ROUTES.REPORT_REVENUE,
    source: "farm",
    permission: ReportPermission.MANAGE_REPORT_REVENUE,
  },
  {
    label: "Công nợ",
    path: APP_ROUTES.DEBT,
    source: "farm",
    permission: ReportPermission.MANAGE_REPORT_DEBT,
  },
  /*vet */
  {
    label: "Công nợ",
    path: APP_ROUTES.DEBT,
    source: "vet",
    permission: ReportPermission.MANAGE_REPORT_DEBT,
  },
];

export const ConditionQuantity = [
  { value: ">", title: "Lớn hơn" },
  { value: ">=", title: "Lớn hơn hoặc bằng" },
  { value: "<", title: "Nhỏ hơn" },
  { value: "<=", title: "Nhỏ hơn hoặc bằng" },
  // { value: "==", title: "Bằng" },
  // { value: "!=", title: "Không bằng" },
  // { value: "<&&<", title: "Ở giữa" },
  // { value: ">||>", title: "Không nằm trong khoảng" },
];

export const DataLanguageHeader = [
  { id: 0, label: "Tiếng Việt", icon: IconVN, code: LANGUAGES.vi },
  { id: 1, label: "English", icon: IconEN, code: LANGUAGES.en },
  { id: 2, label: "Cambodia", icon: IconCD, code: LANGUAGES.km },
  { id: 3, label: "Myanmar", icon: IconMA, code: LANGUAGES.my },
  // { id: 4, label: "Trung Quốc", icon: IconCN, code: LANGUAGES.vi },
];

export enum OrderStatusLab {
  DRAFT = "draft",
  CREATED = "created",
  CONFIRMED = "confirmed",
  PROCESSING = "processing",
  SCHEDULED = "scheduled",
  RECEIVED = "received",
  COMPLETED = "completed",
  CANCELLED = "cancelled",
  REJECTED = "rejected",
}

export const StatusPlan = [
  {
    id: "1",
    label: "ALL",
    value: "ALL",
  },
  {
    id: "2",
    label: "Đang soạn",
    value: PlanStatus.OPENING,
  },
  {
    id: "3",
    label: "Đã tạo",
    value: PlanStatus.CREATED,
  },
  {
    id: "4",
    label: "Đáp ứng",
    value: PlanStatus.ADAPTED,
  },
  {
    id: "5",
    label: "Cần điều chỉnh",
    value: PlanStatus.CHANGING,
  },
  {
    id: "6",
    label: "Đã xác nhận",
    value: PlanStatus.CONFIRMED,
  },
  {
    id: "7",
    label: "Đang xử lý",
    value: PlanStatus.PROCESSING,
  },
  {
    id: "8",
    label: "Từ chối",
    value: PlanStatus.REJECTED,
  },
  {
    id: "9",
    label: "Đã đóng",
    value: PlanStatus.CLOSED,
  },
  {
    id: "10",
    label: "Không đáp ứng",
    value: PlanStatus.NOT_ADAPTED,
  },
];

export const ReasonPlan = [
  {
    id: "1",
    label: "Không nhận được phản hồi từ nhân viên",
    value: Reason.NOT_RESPONSE,
  },
  {
    id: "2",
    label: "Thay đổi yêu cầu",
    value: "2",
  },
  {
    id: "3",
    label: "Yêu cầu đã tồn tại",
    value: "3",
  },
  {
    id: "4",
    label: "Khác",
    value: Reason.OTHER,
  },
];

export const NavHeaderSubAccount = [
  {
    id: "0",
    label: "ALL",
    value: "ALL",
    title: undefined,
  },
  {
    id: "1",
    label: "OPENING",
    value: "OPENING",
    title: "OPENING",
  },
  {
    id: "2",
    label: "PENDING",
    value: "PENDING",
    title: "PENDING",
  },
  {
    id: "3",
    label: "ACTIVATED",
    value: "ACTIVATED",
    title: "ACTIVATED",
  },
  {
    id: "4",
    label: "LOCKED",
    value: "LOCKED",
    title: "LOCKED",
  },
  {
    id: "5",
    label: "CANCELLED",
    value: "CANCELLED",
    title: "CANCELLED",
  },
];

export const ActorForNews = [
  {
    value: "customer",
    label: "CUSTOMER",
  },
  {
    value: "employee",
    label: "EMPLOYEE",
  },
  {
    value: "role",
    label: "ROLE",
  },
  {
    value: "admin",
    label: "Admin",
  },
];
