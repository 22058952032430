/* eslint-disable react/jsx-no-useless-fragment */
import Logo from "@Assets/images/gf-logo.png";
import LeftImage from "@Assets/images/signin/left-login.png";
import RightImage from "@Assets/images/signin/right-login.png";
import Notification from "@Components/Notification";
import { APP_ROUTES, BASE_ADMIN_URL, REGEX } from "@Constants";
import { AuthService } from "@Services";
import { FormUtil, RequestUtil, loadCallback } from "@Utils";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import {
  CheckRounded as CheckRoundedIcon,
  ClearRounded as ClearRoundedIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useHistory, useLocation } from "react-router-dom";
import "./index.scss";
import { useTranslation } from "react-i18next";

const ResetPassword: React.FC = () => {
  const { t } = useTranslation(["translation"]);
  const history = useHistory();
  const [showPw, setShowPw] = useState<boolean>(false);
  const [showConfirmPw, setShowConfirmPw] = useState<boolean>(false);
  const [noti, setNoti] = useState<boolean>(false);
  const [notiSignUp, setNotiSignUp] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const [checkPassword, setCheckPassword] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation<any>();
  const [password, setPassword] = useState<string>("");
  const [acsToken, setAcsToken] = useState<string>("");

  let submit: any;
  const submitForm = (values: any) => {
    AuthService.updatePassword({
      password: values.password,
      requestToken: localStorage.getItem("requestToken"),
      isEmployee: false,
    })
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          setNotiSignUp(true);
          setNoti(true);
          localStorage.setItem("requestToken", res.data.destroyToken);
          if (res.data.isEmployee) {
            setAcsToken(res.data.token);
          } else {
            localStorage.removeItem("phoneNumber");
            setPassword(values.password);
          }
        }
      })
      .catch((error: AxiosError) => {
        if (error.response?.data.message === "OLD_PASSWORD_CONFLICT") {
          setCheckPassword(true);
        } else {
          setNotiSignUp(true);
        }
        setNoti(false);
      });
  };

  useEffect(() => {
    setPassword(password);
  }, [password]);

  const submitLogin = async () => {
    const fpPromise = FingerprintJS.load();

    // Get the visitor identifier when you need it.
    const fp = await fpPromise;
    const result = await fp.get();

    // This is the visitor identifier (deviceId):
    const visitorId = result.visitorId;
    localStorage.setItem("deviceID", visitorId);
    if (acsToken !== "") {
      window.location.href = `${BASE_ADMIN_URL + `/redirect/${acsToken}`}`;
    } else {
      await AuthService.signIn({
        user: location.state?.user,
        password: password,
        deviceId: visitorId,
        platform: "webCustomer",
      })
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            enqueueSnackbar("Đăng nhập thành công", {
              variant: "success",
            });
            localStorage.setItem("accessToken", res.data.token);
            setTimeout(() => {
              RequestUtil.updateHeader();
              history.push(APP_ROUTES.HOME);
              window.location.reload();
            }, 200);
          } else {
            // do something
          }
        })
        .catch((err: AxiosError) => {
          if (err.response?.status === 500) {
            enqueueSnackbar("Lỗi hệ thống", {
              variant: "error",
            });
          } else {
            enqueueSnackbar("Đăng nhập không thành công.", {
              variant: "error",
            });
          }
        });
    }
  };

  //Logout when recover or change password
  const destroyToken = async () => {
    await AuthService.destroyToken({
      requestToken: localStorage.getItem("requestToken"),
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          enqueueSnackbar("Thành công", {
            variant: "success",
          });
          submitLogin();
        } else {
          // do something
        }
      })
      .catch(() => {
        enqueueSnackbar("Thất bại", {
          variant: "error",
        });
      });
  };

  return (
    <div className="resetpw-form">
      <div className="resetpw-form__content">
        <div className="resetpw-form__content__logo">
          <img src={Logo} alt="greenfeed-logo" />
        </div>
        <div className="resetpw-form__content__title">Đặt lại mật khẩu</div>
        <div className="resetpw-form__content__des">
          Bạn vui lòng nhập mật khẩu mới để tiếp tục đăng nhập vào hệ thống
        </div>
        <Form
          initialValuesEqual={() => true}
          validate={(values) => {
            const errors: Record<string, unknown> = {};
            if (values.confirmPassword !== values.password) {
              errors.confirmPassword = (
                <span style={{ fontSize: "1.2rem" }}>Mật khẩu không trùng khớp</span>
              );
            } else if (checkPassword === true) {
              errors.password = "Mật khẩu không trùng mật khẩu cũ";
            } else {
            }
            return errors;
          }}
          onSubmit={submitForm}
          render={({ handleSubmit }) => {
            submit = handleSubmit;
            return (
              <>
                <form onSubmit={handleSubmit} className="form">
                  <div className="label-form">Mật khẩu mới</div>
                  <Field
                    name="password"
                    validate={FormUtil.composeValidators([
                      FormUtil.Rule.required("Nhập mật khẩu mới"),
                      FormUtil.Rule.pattern(REGEX.PASSWORD, {
                        errorMessage: "Mật khẩu không hợp lệ",
                      }),
                    ])}>
                    {({ input, meta, ...rest }) => {
                      return (
                        <TextField
                          {...input}
                          {...rest}
                          fullWidth
                          type="password"
                          placeholder={"Mật khẩu mới"}
                          className={"text-border-style"}
                          inputProps={{ className: "input" }}
                          InputProps={{
                            type: showPw ? "text" : "password",
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={() => setShowPw((show) => !show)}>
                                  {showPw ? (
                                    <VisibilityIcon
                                      style={{ fontSize: "2rem", color: "#000000" }}
                                    />
                                  ) : (
                                    <VisibilityOffIcon
                                      style={{ fontSize: "2rem", color: "#000000" }}
                                    />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          helperText={submitError === true ? meta.error || meta.submitError : ""}
                          error={submitError === true ? meta.error || meta.submitError : ""}
                          onChange={(e) => {
                            input.onChange(e.target.value);
                            setValue(e.target.value);
                            setSubmitError(true);
                            setCheckPassword(false);
                          }}
                          onBlur={(e) => {
                            input.onChange(e.target.value.replace(/\s/g, ""));
                            setSubmitError(true);
                          }}
                        />
                      );
                    }}
                  </Field>
                  <div className="label-form">Nhập lại mật khẩu mới</div>
                  <Field name="confirmPassword">
                    {({ input, meta, ...rest }) => {
                      return (
                        <TextField
                          {...input}
                          {...rest}
                          //type="password"
                          fullWidth
                          placeholder={"Mật khẩu mới"}
                          className={"text-border-style"}
                          inputProps={{ className: "input" }}
                          InputProps={{
                            type: showConfirmPw ? "text" : "password",
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={() => setShowConfirmPw((show) => !show)}>
                                  {showConfirmPw ? (
                                    <VisibilityIcon
                                      style={{ fontSize: "2rem", color: "#000000" }}
                                    />
                                  ) : (
                                    <VisibilityOffIcon
                                      style={{ fontSize: "2rem", color: "#000000" }}
                                    />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          helperText={meta.touched ? meta.error : ""}
                          error={meta.error && meta.touched}
                        />
                      );
                    }}
                  </Field>
                  <div className="resetpw-form__validate">
                    <div className="validate-title">Yêu cầu mật khẩu có :</div>
                    <div className="validate-content">
                      {value !== "" ? (
                        <div className="item">
                          {REGEX.CHARACTER_LENGTH.test(value) === true ? (
                            <div className="ic-green">
                              <CheckRoundedIcon style={{ fontSize: "0.8rem", color: "#fff" }} />
                            </div>
                          ) : (
                            <div className="ic-red">
                              <ClearRoundedIcon style={{ fontSize: "0.8rem", color: "#fff" }} />
                            </div>
                          )}
                          <div>&ensp;Tối thiểu 8 ký tự</div>
                        </div>
                      ) : (
                        <div className="item-inactive">
                          <div className="ic-green">
                            <CheckRoundedIcon style={{ fontSize: "0.8rem", color: "#fff" }} />
                          </div>
                          <div>&ensp;Tối thiểu 8 ký tự</div>
                        </div>
                      )}

                      {value !== "" ? (
                        <div className="item">
                          {REGEX.UPPERCASE.test(value) === true ? (
                            <div className="ic-green">
                              <CheckRoundedIcon style={{ fontSize: "0.8rem", color: "#fff" }} />
                            </div>
                          ) : (
                            <div className="ic-red">
                              <ClearRoundedIcon style={{ fontSize: "0.8rem", color: "#fff" }} />
                            </div>
                          )}
                          <div>&ensp;Ít nhất 1 ký tự viết in hoa</div>
                        </div>
                      ) : (
                        <div className="item-inactive">
                          <div className="ic-green">
                            <CheckRoundedIcon style={{ fontSize: "0.8rem", color: "#fff" }} />
                          </div>
                          <div>&ensp;Ít nhất 1 ký tự viết in hoa</div>
                        </div>
                      )}

                      {value !== "" ? (
                        <div className="item">
                          {REGEX.DIGITS.test(value) === true ? (
                            <div className="ic-green">
                              <CheckRoundedIcon style={{ fontSize: "0.8rem", color: "#fff" }} />
                            </div>
                          ) : (
                            <div className="ic-red">
                              <ClearRoundedIcon style={{ fontSize: "0.8rem", color: "#fff" }} />
                            </div>
                          )}
                          <div>&ensp;Ít nhất 1 chữ số</div>
                        </div>
                      ) : (
                        <div className="item-inactive">
                          <div className="ic-green">
                            <CheckRoundedIcon style={{ fontSize: "0.8rem", color: "#fff" }} />
                          </div>
                          <div>&ensp;Ít nhất 1 chữ số</div>
                        </div>
                      )}

                      {value !== "" ? (
                        <div className="item">
                          {REGEX.SPECIAL_CHARACTER.test(value) === true ? (
                            <div className="ic-green">
                              <CheckRoundedIcon style={{ fontSize: "0.8rem", color: "#fff" }} />
                            </div>
                          ) : (
                            <div className="ic-red">
                              <ClearRoundedIcon style={{ fontSize: "0.8rem", color: "#fff" }} />
                            </div>
                          )}
                          <div>&ensp;Ít nhất 1 ký tự đặc biệt như @#$%^& ...</div>
                        </div>
                      ) : (
                        <div className="item-inactive">
                          <div className="ic-green">
                            <CheckRoundedIcon style={{ fontSize: "0.8rem", color: "#fff" }} />
                          </div>
                          <div>&ensp;Ít nhất 1 ký tự đặc biệt như @#$%^& ...</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <button
                    className="btn-confirm-style"
                    onClick={(e) => {
                      e.preventDefault();
                      loadCallback(submit, e);
                    }}>
                    Đặt lại mật khẩu
                  </button>
                </form>
              </>
            );
          }}
        />

        {/* <div className="resetpw-form__textcolor">
          Đã có tài khoản,{" "}
          <span
            style={{ color: "#0a6836", cursor: "pointer" }}
            onClick={() => history.push(APP_ROUTES.SIGNIN)}>
            Đăng nhập
          </span>
        </div> */}
        <div className="resetpw-form__text">
          Điều khoản sử dụng và bảo mật
          <br />
          Phiên bản 1.2.21 • Bản quyền thuộc về
          <a
            style={{ color: "#0A6836", fontWeight: 700 }}
            rel="noreferrer"
            href={"https://www.greenfeed.com.vn/"}
            target="_blank">
            {" "}
            GREENFEED VIETNAM
          </a>
        </div>
      </div>
      <img className="img-left" src={LeftImage} alt="left" />

      <img className="img-right" src={RightImage} alt="right" />

      <Notification
        visible={notiSignUp}
        icon={
          <div className={`notification-icon-box ${noti ? "green" : "red"}`}>
            {noti ? (
              <CheckRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
            ) : (
              <ClearRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
            )}
          </div>
        }
        title={noti ? "Đổi mật khẩu thành công!" : "Đổi mật khẩu thất bại!"}
        subTitle={
          noti ? (
            <span>
              <br />
              Mật khẩu mới cập nhật thành công
              <br />
              Quý khách có muốn đăng xuất khỏi các thiết bị khác
            </span>
          ) : (
            <span>Vui lòng đặt lại mật khẩu</span>
          )
        }
        actions={
          <>
            <button
              className="btn-confirm-style"
              style={{
                background: "#fff",
                color: "#0A6836",
                border: "0.1rem solid #0A6836",
                width: "12.6rem",
                marginRight: "2rem",
              }}
              onClick={() => {
                submitLogin();
              }}>
              {t("NO")}
            </button>
            <button
              className="btn-confirm-style"
              onClick={() => {
                noti ? destroyToken() : setNotiSignUp(false);
              }}>
              {noti ? t("AGREE") : "Thử lại"}
            </button>
          </>
        }
        onClose={() => history.push(APP_ROUTES.SIGNIN)}
      />
    </div>
  );
};

export default ResetPassword;
