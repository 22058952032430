// backend don't have staging yet
const BACKEND_API_ADDRESS = process.env.REACT_APP_BACKEND_API;
const BACKEND_API_ADDRESS_LOGIN = process.env.REACT_APP_BACKEND_API_LOGIN;

const LINK_IMG: any = process.env.REACT_APP_LINK_IMAGE;

const ADMIN_URL: any = process.env.REACT_APP_URL;
const LINK_IMG_V2: any = process.env.REACT_APP_LINK_IMAGE_V2;

// we don't have staging for cms page yet
const CMS_ADDRESS = "production" ? "" : "";

export const BASE_API_DOMAIN = BACKEND_API_ADDRESS;
export const BASE_API_DOMAIN_LOGIN = BACKEND_API_ADDRESS_LOGIN;
export const BASE_IMG_DOMAIN = BACKEND_API_ADDRESS;
export const BASE_ADMIN_URL = ADMIN_URL;
export const BASE_LINK_IMAGE = LINK_IMG;
export const BASE_LINK_IMAGE_V2 = LINK_IMG_V2;

export const BASE_CLIENT_CONFIG = {
  DOMAIN: CMS_ADDRESS,
};
