/* eslint-disable array-callback-return */
import { APP_ROUTES, SubTab } from "@Constants";
import { AuthCustomerProfileOutputModel, ReportPermission, SourceType } from "@Models";
import { CustomerService } from "@Services";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation } from "react-router-dom";
import "./SubNavbar.scss";
import { ModuleItem } from "./routes";

export type Props = {
  routes?: ModuleItem[];
  source?: string;
};

const SubNavbar: React.FC<Props> = (props) => {
  const { t } = useTranslation(["translation"]);
  const location = useLocation();
  const history = useHistory();
  const [profile, setProfile] = useState<AuthCustomerProfileOutputModel | null>(null);
  const loadProfileData = _.debounce(async () => {
    const response = await CustomerService.getAuthProfile();
    setProfile(response.data);
  }, 100);

  useEffect(() => {
    loadProfileData();
    //eslint-disable-next-line
  }, []);

  const checkURL = SubTab.filter(
    (s) =>
      profile?.role?.dataAccessPermissions?.some((d: any) => d.source === s.source) ||
      profile?.customer?.some((d: any) => d.source === s.source)
  ).filter((s) => profile?.role?.permissions.includes(s.permission));

  const checkPermissionDashBoard = SubTab.some((s) =>
    profile?.role?.permissions.includes(s.permission)
  );

  useEffect(() => {
    if (checkPermissionDashBoard && checkURL) {
      history.push(checkURL[0]?.path + "-" + checkURL[0]?.source);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname === APP_ROUTES.DASHBOARD + "-" + checkURL?.[0]?.source]);

  if (props.routes?.length === 0) return <div></div>;

  const getStringAfterHyphen = (str: string) => {
    const parts = str.lastIndexOf("-");
    if (parts !== -1) {
      return str.substring(parts + 1);
    } else {
      return null;
    }
  };

  return (
    <div className="wrapper">
      <div className={"sub-route-menu"}>
        <div style={{ display: "flex" }}>
          {location.pathname.substring(0, 10) !== APP_ROUTES.DASHBOARD &&
            props.routes?.map(({ title, defaultIcon, activeIcon, path, source }) => {
              // const isActive = isActiveRoute(path);
              const isActive = path + "-" + source === location.pathname;
              // const classes = clsx(
              //   "nav-item",
              //   path + "-" + source === location.pathname && "tab-green-style btn--shiny"
              // );
              const checkSourceType =
                !!source && Object.keys(SourceType).includes(source.toUpperCase());

              if (checkSourceType) {
                if (profile?.role?.dataAccessPermissions !== undefined) {
                  if (profile?.role?.dataAccessPermissions?.some((d: any) => d.source === source)) {
                    return (
                      <Link key={title} to={path + "-" + source}>
                        <div
                          className={
                            isActive ? "tab-green-style btn--shiny" : "nav-item nav-item-hover"
                          }>
                          <div className="icon">{isActive ? activeIcon : defaultIcon}</div>
                          <span className="title">{t(title)}</span>
                        </div>
                      </Link>
                    );
                  }
                } else {
                  if (profile?.customer?.some((d: any) => d.source === source)) {
                    return (
                      <Link key={title} to={path + "-" + source}>
                        <div
                          className={
                            isActive ? "tab-green-style btn--shiny" : "nav-item nav-item-hover"
                          }>
                          <div className="icon">{isActive ? activeIcon : defaultIcon}</div>
                          <span className="title">{t(title)}</span>
                        </div>
                      </Link>
                    );
                  }
                }
              } else {
                return (
                  <Link key={title} to={path + "-" + source}>
                    <div
                      className={
                        isActive ? "tab-green-style btn--shiny" : "nav-item nav-item-hover"
                      }>
                      <div className="icon">{isActive ? activeIcon : defaultIcon}</div>
                      <span className="title">{t(title)}</span>
                    </div>
                  </Link>
                );
              }
            })}
          {location.pathname.substring(0, 10) === APP_ROUTES.DASHBOARD &&
            props.routes?.map(({ title, defaultIcon, activeIcon, path, source }) => {
              const isActive = source === getStringAfterHyphen(location.pathname);

              const linkMock = (paths: any, sources: any) => {
                const fullPath = `${paths}-${sources}`;

                switch (fullPath) {
                  case "/dashboard-farm":
                    if (profile?.role?.permissions.includes(ReportPermission.MANAGE_REPORT_DEBT)) {
                      return "/dashboard/debt-farm";
                    }
                    return APP_ROUTES.ACCESS_DENIED;

                  case "/dashboard-vet":
                    if (profile?.role?.permissions.includes(ReportPermission.MANAGE_REPORT_DEBT)) {
                      return "/dashboard/debt-vet";
                    }
                    return fullPath;
                  case "/dashboard-lab":
                    if (profile?.role?.permissions.includes(ReportPermission.MANAGE_REPORT_DEBT)) {
                      return "/dashboard/debt-lab";
                    }
                    return APP_ROUTES.ACCESS_DENIED;

                  default:
                    return fullPath;
                }
              };

              return (
                <Link key={title} to={linkMock(path, source)}>
                  <div
                    className={isActive ? "tab-green-style btn--shiny" : "nav-item nav-item-hover"}>
                    <div className="icon">{isActive ? activeIcon : defaultIcon}</div>
                    <span className="title">{t(title)}</span>
                  </div>
                </Link>
              );
            })}
        </div>

        {location.pathname.substring(0, 10) === APP_ROUTES.DASHBOARD && (
          <div className="sub-tab-route-menu">
            {(() => {
              // let checkSubtab = SubTab.filter((f) => f.source === location.pathname.slice(-4)).map(
              //   ({ label, path, source }) => {
              //     const classes = clsx(
              //       "nav-item",
              //       path + "-" + source === location.pathname && "tab-style"
              //     );
              let checkSubtab = checkURL
                .filter((f) => {
                  if (f.source !== SourceType.FEED && f.source !== SourceType.FARM) {
                    return f.source === location.pathname.slice(-3);
                  }
                  return f.source === location.pathname.slice(-4);
                })
                .map(({ label, path, source, permission }) => {
                  const classes = clsx(
                    "nav-item",
                    path + "-" + source === location.pathname && "tab-style"
                  );

                  return (
                    <Link key={label} to={path + "-" + source}>
                      <div className={classes} style={{ flexDirection: "column" }}>
                        <span className="title">{t(label)}</span>
                        {path + "-" + source === location.pathname && (
                          <hr
                            style={{
                              width: "10rem",
                              height: "0.3rem",
                              background: "#41AD49",
                              border: 0,
                              position: "absolute",
                              bottom: 0,
                            }}
                          />
                        )}
                      </div>
                    </Link>
                  );
                });
              checkSubtab = _.compact(checkSubtab);

              return checkSubtab?.length > 0 && checkSubtab;
            })()}
          </div>
        )}
      </div>
    </div>
  );
};

export default SubNavbar;
