import Loading from "@Components/Loading";
import Notification from "@Components/Notification";
import { PolicyOutputModel } from "@Models";
import {
  PriorityHighRounded as PriorityHighRoundedIcon,
  FiberManualRecord as DotIcon,
} from "@mui/icons-material";
import { Box, Tab, Tabs } from "@mui/material";
import { CustomerService } from "@Services";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LeftNavigation from "../LeftNavigation";
import "./Policy.scss";

const PolicyPrivacy: React.FC = () => {
  const { t } = useTranslation(["translation"]);
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = React.useState(0);
  const [dataPolicy, setDataPolicy] = useState<PolicyOutputModel[]>([]);
  const [notiAccept, setNotiAccept] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getPolicyPrivacy();
  }, []);

  const getPolicyPrivacy = async () => {
    setLoading(true);
    try {
      const res = await CustomerService.getCustomerPolicy();
      if (res && (res.status === 200 || res.status === 201)) {
        const resData: PolicyOutputModel = res.data;
        setDataPolicy([res.data]);
        setValue(resData.id);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const onSubmitPolicy = async () => {
    setLoading(true);
    try {
      const res = await CustomerService.acceptCustomerPolicy();
      if (res && (res.status === 200 || res.status === 201)) {
        enqueueSnackbar(t("YOU_AGREED_PREIVATE_POLICY"), {
          variant: "success",
        });
        setLoading(false);
        setNotiAccept(false);
      }
    } catch (error) {
      enqueueSnackbar(t("ERROR_OCCURRED"), {
        variant: "error",
      });
      setLoading(false);
      setNotiAccept(false);
    }
  };

  return (
    <>
      <div className="cus-policy">
        <div className="left">
          <LeftNavigation />
        </div>
        <div className="right">
          {loading ? (
            <Loading style={{ margin: "2rem 0", height: "80vh" }} />
          ) : (
            <>
              <div className="policy">
                <div className="title">{t("PRIVATE_POLICY")}</div>
                <div className="tab">
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      variant="scrollable"
                      scrollButtons="auto"
                      TabIndicatorProps={{
                        style: {
                          backgroundColor: "#0A6836",
                        },
                      }}>
                      {dataPolicy?.map((tab) => {
                        return (
                          <Tab
                            key={tab.id}
                            className="policy-tab-list__item"
                            label={
                              <div className="policy-tab-label">
                                {tab.title}{" "}
                                {!tab.policyAccepted && (
                                  <DotIcon style={{ fontSize: "2rem", color: "#ED0404" }} />
                                )}
                              </div>
                            }
                            value={tab.id}
                            style={{
                              fontFamily: "SVN-Gotham",
                              color: value === tab.id ? "#0A6836" : "#5A5959",
                              fontWeight: value === tab.id ? 700 : 450,
                            }}
                          />
                        );
                      })}
                    </Tabs>
                  </Box>
                  <div
                    role="tabpanel"
                    id={`simple-tabpanel-${value}`}
                    aria-labelledby={`simple-tab-${value}`}>
                    <Box sx={{ pt: 3 }}>{dataPolicy.find((i) => i.id === value)?.body}</Box>
                  </div>
                </div>
              </div>

              {!dataPolicy.find((i) => i.id === value)?.policyAccepted && (
                <div className="policy_footer">
                  <div className="footer-box">
                    <button
                      type="submit"
                      className="btn-confirm-style"
                      onClick={() => setNotiAccept(true)}>
                      {t("CONFIRM")}
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <Notification
        visible={notiAccept}
        title={t("NOTIFICATION")}
        subTitle={
          <span
            dangerouslySetInnerHTML={{
              __html: t("CONFIRM_PRIVATE_POLICY"),
            }}
          />
        }
        icon={
          <div className={`notification-icon-box yellow`}>
            <PriorityHighRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
          </div>
        }
        actions={
          <>
            <button
              className="btn-confirm-style"
              style={{
                background: "#fff",
                color: "#0A6836",
                border: "0.1rem solid #0A6836",
                width: "20.6rem",
                marginRight: "2rem",
              }}
              onClick={() => {
                setNotiAccept(false);
              }}>
              {t("NO")}
            </button>
            <button
              className="btn-confirm-style"
              style={{
                width: "20.6rem",
                marginLeft: "2rem",
              }}
              disabled={loading}
              onClick={() => !loading && onSubmitPolicy()}>
              {t("CONFIRM")}
            </button>
          </>
        }
        onClose={() => setNotiAccept(false)}
      />
    </>
  );
};
export default PolicyPrivacy;
