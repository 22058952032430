import BannerCancel from "@Assets/images/block-acc/banner-block.png";
import BannerSuccess from "@Assets/images/mock/image-forget.png";
import { APP_ROUTES } from "@Constants";
import {
  CheckRounded as CheckRoundedIcon,
  Clear as ClearIcon,
  ClearRounded as ClearRoundedIcon,
} from "@mui/icons-material";
import { Dialog } from "@mui/material";
import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
import "./index.scss";
import { SourceType } from "@Models";
import { useTranslation } from "react-i18next";

type P_Props = {
  visible: any;
  newOrder: any;
  dataOrd: any;
  setNotiOrder: (visible: boolean, newOrder: boolean) => void;
  source: string;
};

const ResultOrder: React.FC<P_Props> = (props) => {
  const { t } = useTranslation(["translation"]);
  const history = useHistory();

  return (
    <Dialog fullScreen className="result-order" open={props.visible}>
      <img src={props.newOrder ? BannerSuccess : BannerCancel} alt="banner" />
      <ClearIcon
        style={{
          fontSize: "3rem",
          cursor: "pointer",
          margin: "2rem 2rem",
          position: "absolute",
          top: "1.6rem",
          right: "1.6rem",
          color: "#ffffff",
        }}
        onClick={() =>
          props.newOrder
            ? history.push(APP_ROUTES.ORDER + "-" + props.dataOrd?.orderType)
            : props.setNotiOrder(false, false)
        }
      />
      <div className="result-order__content">
        <div className="result-order__content__icon">
          <div className={`notification-icon-box ${props.newOrder ? "green" : "red"}`}>
            {props.newOrder ? (
              <CheckRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
            ) : (
              <ClearRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
            )}
          </div>
        </div>
        <div className={`result-order__content__title ${props.newOrder ? "title-white" : ""}`}>
          {props.newOrder
            ? t("YOUR_ORDER_PLACED_SUCCESSFULLY")
            : t("YOUR_ORDER_PLACED_NOT_SUCCESSFULLY")}
        </div>
        <div className={`result-order__content__sub-title ${props.newOrder ? "title-white" : ""}`}>
          {props.newOrder ? (
            <span>
              {t("ORDER")}
              <span style={{ color: "#41ad49" }}> #{props.dataOrd?.code}</span>{" "}
              {t("YOUR_EXPECTED_DELIVERED", {
                DAY: moment(props.dataOrd?.orders?.[0]?.requiredDate).format("DD/MM/YYYY"),
              })}
              <br />
              <br />
              {props?.dataOrd?.orders?.[0]?.customer?.email !== undefined ? (
                <>
                  {t("ORDER_HAS_SENT_EMAIL")}
                  <span style={{ color: "#41ad49" }}>
                    {" "}
                    {
                      props?.dataOrd?.orders?.[0]?.customer?.emailprops?.dataOrd?.orders?.[0]
                        ?.customer?.email
                    }
                  </span>
                  {t("CHECK_SPAM_EMAIL")}
                </>
              ) : (
                <div dangerouslySetInnerHTML={{ __html: t("PLEASE_CONTACT_HOTLINE") }} />
              )}
              <br />
              <br />
              {/* Nếu có bất kỳ thắc mắc, vui lòng liên hệ hotline
              <span style={{ color: "#41ad49" }}> 1900 636035</span> để được hỗ trợ. */}
            </span>
          ) : (
            <span>
              {t("YOUR_ORDER_COULD_NOT_PROCESSED")}
              <br />
              <br />
              {t("PLEASE_CONTACT_HOTLINE")}
              <span style={{ color: "#41ad49" }}> 1900 636035</span> {t("ASSISTANCE_PLACING_ORDER")}
            </span>
          )}
        </div>
        <div className="result-order__content__actions">
          {
            <>
              <button
                className="btn-confirm-style"
                style={{
                  width: "21.6rem",
                  marginRight: "2rem",
                  background: props.newOrder ? "#41AD49" : "",
                  borderRadius: "3rem",
                }}
                onClick={() => {
                  props.newOrder
                    ? history.push(
                        (props.source === SourceType.FARM
                          ? APP_ROUTES.ORDER_DETAIL_FARM
                          : props.source === SourceType.VET
                          ? APP_ROUTES.ORDER_DETAIL_VET
                          : props.source === SourceType.LAB
                          ? APP_ROUTES.ORDER_DETAIL_LAB
                          : APP_ROUTES.ORDER_DETAIL
                        ).replace(":orderId", props.dataOrd?.id)
                      )
                    : props.setNotiOrder(false, false);
                }}>
                {props.newOrder ? t("ORDER_DETAIL") : t("RESET_ORDER")}
              </button>
              {!props.newOrder && (
                <button
                  className="btn-confirm-style"
                  style={{
                    background: "#fff",
                    color: "#0A6836",
                    border: "0.1rem solid #0A6836",
                    width: "20.6rem",
                    marginLeft: "2rem",
                    borderRadius: "3rem",
                  }}
                  onClick={() => {
                    history.push(APP_ROUTES.ORDER + "-" + props.dataOrd?.orderType);
                  }}>
                  {t("CANCEL")}
                </button>
              )}
            </>
          }
        </div>
      </div>
    </Dialog>
  );
};

export default ResultOrder;
