import { IconBase, IconComponent } from "@Core/Icons";

export const VetIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        d="M15 1L19 5M17 3L12.5 7.5M9.5 4.5L15.5 10.5M14.5 9.5L8 16H4M4 16V12L10.5 5.5M4 16L1 19M5.5 10.5L7 12M8.5 7.5L10 9"
        stroke={props.color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconBase>
  );
};
