import { FeedBackModel } from "@Models";
import { MoreVert as MoreVertIcon } from "@mui/icons-material";
import { IconButton, Menu, MenuItem, Rating } from "@mui/material";
import { FeedBackService } from "@Services";
import { checkLinkImage, dateFormat, renderErrorImageItem, renderImageProNull } from "@Utils";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import FeedBackModal from "../FeedBackModal";
import "./index.scss";

type P_Props = {
  data: FeedBackModel;
  onSuccess: () => void;
};

const FeedBack: React.FC<P_Props> = ({ data, onSuccess }) => {
  const { orderId }: { orderId: string } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [feedBackUpdate, setFeedBackUpdate] = useState<any>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onDeleteFeedback = async () => {
    let val = {
      rating: data.rating,
      nextOrderDate: data.nextOrderDate,
      isDelete: "true",
    };

    FeedBackService.updateFeedBack(orderId, data.id.toString(), val as any)
      .then(async (res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          enqueueSnackbar("Xoá phản hồi thành công!", {
            variant: "success",
          });
          onSuccess();
        }
      })
      .catch(() => {
        enqueueSnackbar("Xoá phản hồi thất bại", {
          variant: "error",
        });
      });
  };

  return (
    <div className="feed-back">
      <div className="feed-back__title">Phản hồi và trả lời phản hồi</div>
      <div className="feed-back__content">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}>
            <div className="feed-back__content__img">
              <img
                src={checkLinkImage(data.customer.account.avatar as any) || renderImageProNull()}
                alt={data.customer.account.nickname}
                onError={renderErrorImageItem()}
              />
            </div>
            <div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="feed-back__content__nickname">{data.customer.account.nickname}</div>
                <div className="feed-back__content__date">{dateFormat(data.updatedAt, true)}</div>
              </div>
              <Rating value={data.rating} readOnly style={{ fontSize: 18, margin: "2px 0" }} />
              <div>{data.description}</div>
            </div>
          </div>
          <IconButton
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}>
            <MoreVertIcon style={{ fontSize: 20 }} />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}>
            <MenuItem
              onClick={() => {
                setFeedBackUpdate(data);
                handleClose();
              }}
              style={{ padding: "0.5rem 1rem" }}>
              Chỉnh sửa
            </MenuItem>
            <MenuItem
              onClick={() => {
                onDeleteFeedback();
                handleClose();
              }}
              style={{ padding: "0.5rem 1rem" }}>
              Xoá
            </MenuItem>
          </Menu>
        </div>

        {data?.replies?.length > 0 &&
          data.replies?.map((item: any) => {
            return (
              <div
                key={item?.id}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  margin: "3rem 0",
                  width: "100%",
                }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}>
                  <div className="feed-back__content__img">
                    <img
                      src={checkLinkImage(item?.userReply?.avatar as any) || renderImageProNull()}
                      alt={item?.userReply?.nickname}
                      onError={renderErrorImageItem()}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div className="feed-back__content__nickname">
                        {item?.userReply?.nickname}
                      </div>
                      <div className="feed-back__content__date">
                        {dateFormat(item?.updatedAt, true)}
                      </div>
                    </div>
                    <div>{item?.description}</div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>

      {feedBackUpdate !== null && (
        <FeedBackModal
          closeForm={() => {
            setFeedBackUpdate(null);
            onSuccess();
          }}
          data={feedBackUpdate}
        />
      )}
    </div>
  );
};

export default FeedBack;
