import ImgEmpty from "@Assets/images/img_empty.png";
import Loading from "@Components/Loading";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import { NewsDetailOutputModel } from "@Models";
import { Dialog, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./NewsDetail.scss";
import { APP_ROUTES, BASE_LINK_IMAGE, TEXT_HOLDER } from "@Constants";
import { useHistory, useParams } from "react-router-dom";
import { NewService } from "@Services";

const NewsPreview: React.FC = () => {
  const { t } = useTranslation(["translation", "order", "customer"]);
  const history = useHistory();
  const mounted = useRef(false);
  const { newsId }: { newsId: string } = useParams();
  const [closeForm, setCloseForm] = useState<boolean>(true);
  const [dataDetailNews, setDataDetailNews] = useState<NewsDetailOutputModel | null>(null);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    getNewsDetail();
    // eslint-disable-next-line
  }, []);

  const getNewsDetail: () => Promise<void> = async () => {
    try {
      const response = await NewService.getNewsById(newsId);
      if (mounted.current) {
        setDataDetailNews(() => response.data.data);
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  return (
    <Dialog fullScreen open={closeForm}>
      <div className="news-detail">
        <SimpleModalHeader
          title={t("DETAIL_NEWS")}
          onClose={() => {
            setCloseForm(false);
            history.push(APP_ROUTES.HOME);
          }}
        />
        {!!dataDetailNews ? (
          <div className="content">
            <Grid container>
              <Grid item xs={8} className="content-info">
                <div className="body">
                  <div className="content-title">{dataDetailNews.title}</div>
                  <div className="content-body" style={{ paddingBottom: "2rem" }}>
                    <p>{dataDetailNews.body}</p>
                    <a
                      style={{
                        color: "#0A6836",
                        textDecoration: "underline",
                      }}
                      href={dataDetailNews.url || TEXT_HOLDER}
                      target="_blank"
                      rel="noopener noreferrer">
                      {dataDetailNews.url || TEXT_HOLDER}
                    </a>
                  </div>
                  <div className="content-image">
                    {!!dataDetailNews.image ? (
                      <img src={BASE_LINK_IMAGE + dataDetailNews.image} alt="news" />
                    ) : (
                      <img src={ImgEmpty} alt="news" />
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        ) : (
          <Loading style={{ margin: "2rem 0", height: "80vh" }} />
        )}
      </div>
    </Dialog>
  );
};

export default NewsPreview;
