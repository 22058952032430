import { getStartOfWeek, getWeek } from "@Utils";
import moment, { Moment } from "moment";
import { useMemo, useState } from "react";

const useDateRange = (getWeekOfYear?: boolean) => {
  const [value, setValue] = useState<{
    startTime: Moment;
    endTime: Moment;
  }>({
    startTime: moment().subtract(7, "days").set({ hours: 0, minutes: 0, seconds: 0 }),
    endTime: moment().set({ hours: 23, minutes: 59, seconds: 59 }),
  });

  const startDate = getStartOfWeek(new Date().getFullYear(), getWeek(new Date()));

  useMemo(() => {
    if (getWeekOfYear) {
      setValue(() => ({
        startTime: moment(startDate).set({ hours: 0, minutes: 0, seconds: 0 }),
        endTime: moment(startDate).add(6, "days").set({ hours: 23, minutes: 59, seconds: 59 }),
      }));
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const setTimerange = (start: Moment, end: Moment) => {
    if (start > end) throw new Error("start time must be earlier than end time");
    setValue(() => ({
      startTime: start,
      endTime: end,
    }));
  };

  return [value, setTimerange, startDate] as const;
};

export default useDateRange;
