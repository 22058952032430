import { RouteProps } from "react-router-dom";
// import SignInForm from ".";
import { APP_ROUTES } from "@Constants";
import ForgetPassword from "../ForgetPassword";
import ResetPassword from "../ForgetPassword/ResetPassword";
import SignUpForm from "../SignUpForm";
import BlockAccount from "./BlockAccount/BlockAccount";

export const UnAuthRoute: Array<RouteProps> = [
  {
    exact: true,
    path: APP_ROUTES.FORGETPASSWORD,
    component: ForgetPassword,
  },
  {
    exact: true,
    path: APP_ROUTES.RESETPASSWORD,
    component: ResetPassword,
  },
  {
    exact: true,
    path: APP_ROUTES.SIGNUP,
    component: SignUpForm,
  },
  {
    exact: true,
    path: APP_ROUTES.IS_BLOCKED,
    component: BlockAccount,
  },
];
