import ImageUpload from "@Components/ImageUpload";
import Loading from "@Components/Loading";
import { TEXT_ASTERISK, TEXT_HOLDER } from "@Constants";
import {
  Action,
  AuthProfileCustomerOutputModel,
  CustomerOutputModel,
  FetchAction,
  FetchReducer,
  FormUploadImageModel,
  ItemPermission,
  SourceType,
  UploadedImageModel,
} from "@Models";
import { CustomerService } from "@Services";
import { checkLinkImage, currencyFormat, PermissionUtil } from "@Utils";
import { Wallpaper as WallpaperIcon } from "@mui/icons-material";
import { Divider, Grid } from "@mui/material";
import moment from "moment";
import React, { useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import LeftNavigation from "../LeftNavigation";
import "./Profile.scss";
import _ from "lodash";

function reducer(state: FetchReducer<CustomerOutputModel>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        fetching: false,
        data: action.payload,
      };
    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload };
    default:
      return { ...state };
  }
}

const Profile: React.FC = () => {
  const { t } = useTranslation(["translation"]);
  const [selectTab, setSelectTab] = useState<number>(0);
  const [profile, setProfile] = useState<any>([]);

  const loadProfileData = _.debounce(async () => {
    const response = await CustomerService.getAuthProfile();
    setProfile(response.data);
  }, 100);

  useEffect(() => {
    loadProfileData();
    //eslint-disable-next-line
  }, []);

  //permission
  const checkPermissionViewPrice = PermissionUtil.check(
    profile?.role?.permissions,
    ItemPermission.MANAGE_ITEM_VIEW_PRICE,
    true
  );

  const [state, dispatch] = useReducer(reducer, {
    data: [],
    fetching: false,
    failMessage: "",
  });

  useEffect(() => {
    let cancel = false;
    const fetchData = async () => {
      dispatch({ type: Action.FETCH, payload: {} });
      try {
        const result = await CustomerService.getAuthProfile();
        if (!cancel)
          dispatch({
            type: Action.SUCCESS,
            payload: result.data,
          });
      } catch (err) {
        if (!cancel)
          dispatch({
            type: Action.FAILED,
            payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
          });
      }
    };

    fetchData();

    return () => {
      cancel = true;
    };
  }, []);

  const [avatars, setAvatars] = useState<Array<FormUploadImageModel & { file: any }>>([]);
  useEffect(() => {
    setAvatars(
      state.data.avatar
        ? [
            {
              id: "",
              src: `${checkLinkImage(state.data?.avatar)}`,
              alt: "",
              file: null,
            },
          ]
        : []
    );
  }, [state.data]);

  const uploadAvatar = async () => {
    try {
      const formData = new FormData();

      formData.append("image", avatars[0].file);

      await CustomerService.uploadImage("avatar", formData);
      window.location.reload();
    } catch (error) {}
  };

  return (
    <div className="profile">
      <div className="left">
        <LeftNavigation />
      </div>
      {state.data.customer ? (
        <div className="right">
          <div className="subtab ">
            {state.data.customer?.map((cus: CustomerOutputModel, idx: number) => {
              return (
                <div
                  key={cus.id}
                  className={idx === selectTab ? "tab-green-style btn--shiny" : "tab"}
                  onClick={() => {
                    setSelectTab(idx);
                  }}>
                  {(() => {
                    switch (cus.source) {
                      case SourceType.FEED:
                        return t("FEED");
                      case SourceType.FARM:
                        return t("FARM");
                      case SourceType.VET:
                        return t("VET");
                      case SourceType.LAB:
                        return t("LAB");
                      default:
                        return t("CCU");
                    }
                  })()}
                </div>
              );
            })}
          </div>
          <div className="info">
            {state.data.customer?.map((cus: AuthProfileCustomerOutputModel, idx: number) => {
              return (
                <div className={idx === selectTab ? "selected" : "unselected"} key={cus.id}>
                  <div>
                    <div className="title-info">Hồ sơ cá nhân</div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <div>
                        <div className="avt">
                          <ImageUpload
                            title="Ảnh đại diện"
                            max={1}
                            fileList={avatars}
                            onChange={(data: UploadedImageModel) => {
                              setAvatars([
                                {
                                  id: uuidv4(),
                                  src: data.fileStr,
                                  alt: data.alt || "",
                                  file: data.file,
                                },
                              ]);
                              uploadAvatar();
                            }}
                            onDelete={() => {
                              setAvatars([]);
                            }}
                            config={{ uploadModal: true }}
                          />
                          <div className="ic-up-avt">
                            <WallpaperIcon style={{ color: "#ffffff", fontSize: "2.2rem" }} />
                          </div>
                        </div>
                        <div className="name">{cus.fullName}</div>
                      </div>
                      <div>
                        <div className="salary">
                          <div className="column">
                            <div className="text-color green">
                              {checkPermissionViewPrice
                                ? cus?.debt?.totalDebt
                                  ? currencyFormat(+cus?.debt?.totalDebt)
                                  : TEXT_HOLDER
                                : TEXT_ASTERISK}{" "}
                              đ
                            </div>
                            <div className="label">Công Nợ</div>
                          </div>
                          <div className="column">
                            <div className="text-color red">
                              {checkPermissionViewPrice
                                ? cus?.debt?.totalOverdueDebt
                                  ? currencyFormat(+cus?.debt?.totalOverdueDebt)
                                  : TEXT_HOLDER
                                : TEXT_ASTERISK}{" "}
                              đ
                            </div>
                            <div className="label">Công nợ quá hạn</div>
                          </div>
                        </div>
                        <div className="upd-time">
                          Cập nhật lần cuối lúc&#160;
                          {moment(cus?.debt?.updatedAt).format("hh:mm - DD/MM/YYYY")}
                        </div>
                      </div>
                    </div>
                  </div>
                  <Divider
                    style={{ background: "#ADACAC", marginTop: "3.9rem", marginBottom: "3rem" }}
                  />
                  <div className="title">Thông tin chung</div>
                  <Grid container spacing={7}>
                    <Grid item xs={4}>
                      <div className="label"> Mã khách hàng</div>
                      {cus.code}
                      <br /> <br />
                      <div className="label"> Người liên lạc</div>
                      {cus.fullName}
                      <br /> <br />
                      <div className="label"> Địa chỉ</div>
                      {cus.address}
                    </Grid>
                    <Grid item xs={4}>
                      <div className="label"> Giới tính</div>
                      {TEXT_HOLDER}
                      <br /> <br />
                      <div className="label"> Email</div>
                      {cus.email || TEXT_HOLDER}
                      <br /> <br />
                      <div className="label"> Nhóm khách hàng</div>
                      {TEXT_HOLDER}
                    </Grid>
                    <Grid item xs={4}>
                      <div className="label"> Ngày sinh</div>
                      {TEXT_HOLDER}
                      <br /> <br />
                      <div className="label"> Mã số thuế</div>
                      {TEXT_HOLDER}
                      <br /> <br />
                      <div className="label"> Công ty</div>
                      {cus.company || TEXT_HOLDER}
                    </Grid>
                  </Grid>

                  <div className="title" style={{ marginTop: "5rem" }}>
                    Thông tin cá nhân
                  </div>
                  <Grid container spacing={5}>
                    <Grid item xs={4}>
                      <div className="label"> CMND / CCCD</div>
                      {cus.idCard || TEXT_HOLDER}
                    </Grid>
                    <Grid item xs={4}>
                      <div className="label"> Ngày cấp</div>
                      {cus.idIssueDate ? moment(cus.idIssueDate).format("DD/MM/yyyy") : TEXT_HOLDER}
                    </Grid>
                    <Grid item xs={4}>
                      <div className="label"> Nơi cấp</div>
                      {TEXT_HOLDER}
                    </Grid>
                  </Grid>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Profile;
