import { IconBase, IconComponent } from "@Core/Icons";

export const ProfileIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        d="M16.2895 9.65941C16.2895 11.5316 14.7718 13.0493 12.8996 13.0493C11.0275 13.0493 9.50977 11.5316 9.50977 9.65941C9.50977 7.78723 11.0275 6.26953 12.8996 6.26953C14.7718 6.26953 16.2895 7.78723 16.2895 9.65941Z"
        stroke={props.color}
        strokeWidth="2.125"
      />
      <path
        d="M18.4649 20.7903C18.4649 18.5641 15.9732 16.3379 12.8995 16.3379C9.82573 16.3379 7.33398 18.5641 7.33398 20.7903"
        stroke={props.color}
        strokeWidth="2.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.0007 12.9993C24.0007 19.2586 18.9265 24.3327 12.6673 24.3327C6.40809 24.3327 1.33398 19.2586 1.33398 12.9993C1.33398 6.74012 6.40809 1.66602 12.6673 1.66602C18.9265 1.66602 24.0007 6.74012 24.0007 12.9993Z"
        stroke={props.color}
        strokeWidth="2.125"
      />
    </IconBase>
  );
};
