import { REQUEST_URL } from "@Constants";
import { RequestUtil } from "@Utils";

export const ExportService = {
  getRefreshStatus(data: { type: string; source?: string }) {
    const url = REQUEST_URL.REFRESH_STATUS;
    return RequestUtil.get({ url, query: data });
  },

  exportPlanById(planId: string) {
    const url = REQUEST_URL.EXPORT_ORDER_PLAN?.replace("{planId}", planId);
    return RequestUtil.get({ url });
  },

  getExportReport(data: { period: string }) {
    const url = REQUEST_URL.EXPORT_REPORT;

    return RequestUtil.get({ url, query: data });
  },

  exportOrder: (query: { from: string; to: string; source: string; strSearch: string }) => {
    const url = REQUEST_URL.EXPORT_ORDER;

    return RequestUtil.get({ url, query: query });
  },

  exportOrderFarm: (query: { from: string; to: string }) => {
    const url = REQUEST_URL.EXPORT_ORDER_FARM;

    return RequestUtil.get({ url, query: query });
  },
};
