import { REQUEST_URL } from "@Constants";
import { RequestUtil } from "@Utils";

export const TargetService = {
  getAllTarget: () => {
    const url = REQUEST_URL.TARGET_GET_OR_CREATE;
    return RequestUtil.get({ url });
  },

  getTargetById: (id: string) => {
    const url = REQUEST_URL.TARGET_BY_ID.replace("{id}", id);
    return RequestUtil.get({ url });
  },

  createTarget: (query: any) => {
    const url = REQUEST_URL.TARGET_GET_OR_CREATE;
    return RequestUtil.post({ url, data: query });
  },

  deleteTarget: (id: string) => {
    const url = REQUEST_URL.TARGET_BY_ID.replace("{id}", id);
    return RequestUtil.delete({ url });
  },
};
