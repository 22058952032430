import { IconBase, IconComponent } from "@Core/Icons";

export const InterestedIcon4: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        d="M14.1961 5.30027L14.2106 5.30234L14.2252 5.30357C14.2745 5.30771 14.3205 5.32575 14.358 5.35437C14.39 5.3789 14.4143 5.41009 14.4297 5.4445C14.4362 5.47988 14.4339 5.51629 14.4229 5.55089C14.4099 5.59145 14.3851 5.62887 14.3499 5.6581L14.3372 5.66872L14.3251 5.68016L11.2456 8.6084L11.0505 8.79395L11.098 9.059L11.8436 13.2171L11.8436 13.2171L11.8449 13.2241C11.853 13.2658 11.8489 13.309 11.8326 13.349C11.8162 13.3891 11.788 13.4251 11.7499 13.4519L11.7478 13.4534C11.7056 13.4834 11.6536 13.5002 11.5993 13.5L11.596 13.5C11.5607 13.5001 11.5265 13.4912 11.4968 13.4748L11.4904 13.4712L11.4838 13.4678L7.68101 11.5059L7.45264 11.3881L7.22391 11.5052L3.40617 13.4598L3.40616 13.4598L3.40317 13.4613C3.36216 13.4827 3.31522 13.4927 3.2678 13.4896C3.22039 13.4865 3.17558 13.4705 3.13834 13.4443L2.8511 13.8536L3.13873 13.4446C3.10061 13.4178 3.07238 13.3818 3.05605 13.3417C3.03976 13.3017 3.03559 13.2585 3.04369 13.2167L3.04374 13.2167L3.04499 13.2098L3.79064 9.05168L3.83817 8.78663L3.64303 8.60108L0.566941 5.67613C0.537018 5.64614 0.516629 5.60976 0.506801 5.57117C0.497168 5.53334 0.497772 5.49382 0.508589 5.45624C0.522201 5.41802 0.546468 5.38291 0.580016 5.35526C0.614959 5.32646 0.658391 5.30711 0.705613 5.30052L0.705614 5.30053L0.708229 5.30015L4.95099 4.68522L5.20877 4.64786L5.32588 4.4152L7.22729 0.637769L7.22731 0.637777L7.22909 0.634168C7.24809 0.595653 7.27842 0.561839 7.3178 0.537607C7.35726 0.513325 7.40372 0.5 7.45177 0.5C7.49982 0.5 7.54627 0.513325 7.58573 0.537607C7.62511 0.561839 7.65545 0.595652 7.67445 0.634169L7.67444 0.634175L7.67607 0.637422L9.57748 4.42217L9.69476 4.65563L9.95338 4.69266L14.1961 5.30027Z"
        stroke="#41AD49"
      />
    </IconBase>
  );
};
