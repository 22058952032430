/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-useless-fragment */
import IconDB2a from "@Assets/images/icons/ic-db-2a.png";
import IconDB2b from "@Assets/images/icons/ic-db-2b.png";
import DB1 from "@Assets/images/mock/db1.png";
import DB2 from "@Assets/images/mock/db2.png";
import DB3 from "@Assets/images/mock/db3.png";
import DB4 from "@Assets/images/mock/db4.png";
import DBReportSales from "@Components/DBReportSales";
import DashboardPie from "@Components/DashboardPie";
import { Calendar1Icon } from "@Components/Icons/Calendar1";
import { Month, TEXT_ASTERISK, TEXT_HOLDER } from "@Constants";
import { useBreakpoints } from "@Hooks";
import { CustomerService, ReportService } from "@Services";
import { PermissionUtil, YearList, currencyFormat } from "@Utils";
import {
  ArrowDropDown as ArrowDropDownIcon,
  // NavigateNext as NavigateNextIcon,
  TuneRounded as TuneRoundedIcon,
} from "@mui/icons-material";
import {
  Dialog,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Popover,
  Select,
} from "@mui/material";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  LabelList,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Sector,
  XAxis,
  YAxis,
} from "recharts";
// import { APP_ROUTES } from "@Constants";
import _debounce from "lodash/debounce";
import { useTranslation } from "react-i18next";
import "./index.scss";
import { useLocation } from "react-router-dom";
import { ItemPermission } from "@Models";

export const COLORS = [
  "#3C53F6",
  "#7786E6",
  "#22CB70",
  "#9CF387",
  "#EDD780",
  "#FAAD82",
  "#EE5627",
  "#EA2727",
  "#A71FC9",
  "#4300D2",
];

const Dashboard: React.FC = () => {
  const { t } = useTranslation(["translation", "order"]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const location = useLocation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const history = useHistory();
  const open = Boolean(anchorEl);
  const [month, setMonth] = useState<string>(("0" + (new Date().getMonth() + 1)).slice(-2));
  const [year, setYear] = useState<string>(new Date().getFullYear().toString());
  const [time, setTime] = useState<string>(
    new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2)
  );
  const [salesOrg, setSalesOrg] = useState<any>([]);
  const [salesOrgId, setSalesOrgId] = useState<number | undefined>();
  const [reportCustomer, setReportCustomer] = useState<any>(null);
  const [reportItem, setReportItem] = useState<any>(null);
  const [reportSales, setReportSales] = useState<any>([]);
  const [reportDensityValue, setReportDensityValue] = useState<any>(null);
  const [reportDensityPro, setReportDensityPro] = useState<any>(null);
  const [openDialogPie, setOpenDialogPie] = useState<any>();
  const [profile, setProfile] = useState<any>([]);

  const loadProfileData = _debounce(async () => {
    const response = await CustomerService.getAuthProfile();
    setProfile(response.data);
  }, 100);

  useEffect(() => {
    const fetchData = async () => {
      ReportService.getReportBu({
        source: location.pathname.slice(location.pathname.indexOf("-") + 1),
      })
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            setSalesOrg(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    fetchData();
  }, [location.pathname.slice(location.pathname.indexOf("-") + 1)]);

  useEffect(() => {
    loadProfileData();
    setSalesOrg([]);
    setReportCustomer(null);
    setReportItem(null);
    setReportSales([]);
    setReportDensityValue(null);
    setReportDensityPro(null);
  }, []);

  const salesOrgLength =
    salesOrg?.length >= 2
      ? [{ id: undefined, description: "Tất cả đơn vị" }, ...salesOrg]
      : salesOrg;

  useEffect(() => {
    setSalesOrgId(salesOrgLength?.[0]?.id);
  }, [salesOrg]);

  // Density
  useEffect(() => {
    const fetchData = async () => {
      ReportService.getReportDensity({
        period: time,
        source: "feed",
        salesOrgId: salesOrgId,
        sortType: "DESC",
        sortField: "sum_production",
      })
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            setReportDensityPro(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    fetchData();
  }, [time, salesOrgId]);

  useEffect(() => {
    const fetchData = async () => {
      ReportService.getReportDensity({
        period: time,
        source: "feed",
        salesOrgId: salesOrgId,
        sortType: "DESC",
        sortField: "sum_value",
      })
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            setReportDensityValue(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    fetchData();
  }, [time, salesOrgId]);

  const dataProduction = reportDensityPro?.data.map((rep: any) => {
    return {
      salesCode: rep.salesCode,
      salesId: rep.salesId,
      salesName: rep.salesName,
      sum_production: +rep.sum_production,
    };
  });

  const totalDataProduction = dataProduction
    ?.slice(6, dataProduction.length)
    .map((m: any) => {
      return m.sum_production;
    })
    .reduce((sum: any, val: any) => sum + val, 0);

  const dataProductionLength =
    dataProduction?.length <= 6
      ? dataProduction
      : dataProduction && [
          ...dataProduction.slice(0, 5),
          {
            salesCode: "0",
            salesId: 0,
            salesName: "Khác",
            sum_production: totalDataProduction,
          },
        ];

  const dataValue = reportDensityValue?.data.map((rep: any) => {
    return {
      salesCode: rep.salesCode,
      salesId: rep.salesId,
      salesName: rep.salesName,
      sum_value: +rep.sum_value,
    };
  });

  const totalDataValue = dataValue
    ?.slice(6, dataValue.length)
    .map((m: any) => {
      return m.sum_value;
    })
    .reduce((sum: any, val: any) => sum + val, 0);

  const dataValueLength =
    dataValue?.length <= 6
      ? dataValue
      : dataValue && [
          ...dataValue.slice(0, 5),
          {
            salesCode: "0",
            salesId: 0,
            salesName: "Khác",
            sum_value: totalDataValue,
          },
        ];

  const renderActiveShape = (props: any) => {
    const { cx, cy, payload, fill, innerRadius, outerRadius, startAngle, endAngle } = props;
    return (
      <g>
        <text
          x={cx}
          y={cy}
          dy={-20}
          textAnchor="middle"
          fill={fill}
          style={{ fontSize: "1.4rem", fontWeight: 500 }}>
          {((payload.sum_production / +reportDensityPro?.total_production) * 100).toFixed(2)} %
        </text>
        <text
          x={cx}
          y={cy}
          dy={5}
          textAnchor="middle"
          style={{ fontSize: "1.4rem", fontWeight: "bold" }}>
          {payload.salesName}
        </text>
        <text
          x={cx}
          y={cy}
          dy={30}
          textAnchor="middle"
          style={{ fontSize: "1.4rem", fontWeight: 500 }}>
          {currencyFormat(payload.sum_production)} kg
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius - 1}
          outerRadius={outerRadius + 8}
          fill={fill}
        />
      </g>
    );
  };

  const renderActiveShape2 = (props: any) => {
    const { cx, cy, payload, fill, innerRadius, outerRadius, startAngle, endAngle } = props;

    return (
      <g>
        <text
          x={cx}
          y={cy}
          dy={-20}
          textAnchor="middle"
          fill={fill}
          style={{ fontSize: "1.4rem", fontWeight: 500 }}>
          {((payload.sum_value / +reportDensityValue?.total_value) * 100).toFixed(2)} %
        </text>
        <text
          x={cx}
          y={cy}
          dy={5}
          textAnchor="middle"
          style={{ fontSize: "1.4rem", fontWeight: "bold" }}>
          {payload.salesName}
        </text>
        <text
          x={cx}
          y={cy}
          dy={30}
          textAnchor="middle"
          style={{ fontSize: "1.4rem", fontWeight: 500 }}>
          {checkPermissionViewPrice ? currencyFormat(payload.sum_value) : TEXT_ASTERISK} đ
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius - 1}
          outerRadius={outerRadius + 8}
          fill={fill}
        />
      </g>
    );
  };

  /*----------------------------*/

  useEffect(() => {
    const fetchData = async () => {
      ReportService.getReportTopCustomer({
        period: time,
        source: "feed",
        //Nếu chọn tất cả thì k truyền salesOrgId
        salesOrgId: salesOrgId,
      })
        .then((res) => {
          if (res.status === 200 || res.status === 204) {
            setReportCustomer(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    fetchData();
  }, [time, salesOrgId]);

  useEffect(() => {
    const fetchData = async () => {
      ReportService.getReportItem({
        period: time,
        source: "feed",
        salesOrgId: salesOrgId,
      })
        .then((res) => {
          if (res.status === 200 || res.status === 204) {
            setReportItem(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    fetchData();
  }, [time, salesOrgId]);

  useEffect(() => {
    const fetchData = async () => {
      ReportService.getReportSales({
        period: time,
        source: "feed",
        salesOrgId: salesOrgId,
      })
        .then((res) => {
          if (res.status === 200 || res.status === 204) {
            setReportSales(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    fetchData();
  }, [time, salesOrgId]);

  /*custom UI */

  const [activeIndex, setActiveIndex] = useState(0);

  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const [activeIndex2, setActiveIndex2] = useState(0);

  const onPieEnter2 = useCallback(
    (_, index) => {
      setActiveIndex2(index);
    },
    [setActiveIndex2]
  );

  const reportSalesData = reportSales
    ?.filter((f: any) => moment(f.date).format("MM") === time.slice(-2))
    .map((r: any) => {
      return {
        date: r.date,
        sum_production: +r.sum_production,
        sum_production2: +r.sum_production * 0.01,
        sum_value: +r.sum_value,
      };
    });

  // No data + tinh ngay
  const [y, m] = time.split("-");

  const maxDate = new Date(+y, +m, 0).getDate();

  for (let date = 1; date <= maxDate; date++) {
    const fullDate = new Date(+y, +m - 1, date).toISOString();
    if (
      Array.isArray(reportSalesData) &&
      !reportSalesData.some((report: any) => report.date === fullDate)
    ) {
      reportSalesData.push({
        date: fullDate,
        sum_production: 0,
        sum_production2: 0,
        sum_value: 0,
      });
    }
  }
  // reportSalesData?.sort((a: any, b: any) => {
  //   if (a.date > b.date) {
  //     return 1;
  //   }
  //   return -1;
  // });

  // const totalValueDensity = dataValue?.reduce((sum: number, item: any) => {
  //   sum += +item.sum_value;
  //   return sum;
  // }, 0);

  // const totalProDensity = dataProduction?.reduce((sum: number, item: any) => {
  //   sum += +item.sum_production;
  //   return sum;
  // }, 0);

  const reportItemFormat = reportItem?.data.map((d: any) => {
    return {
      itemGroupName: d.itemGroupName,
      sum_production: +d.sum_production,
      sum_value: +d.sum_value,
      sum_value_label: +d.sum_value,
    };
  });

  const reportCustomerValueFormat = reportCustomer?.data?.topCustomerValue.map((d: any) => {
    return {
      customerName: d.customerName,
      sum_value: +d.sum_value,
      sum_value_label: +d.sum_value,
    };
  });

  const reportCustomerProFormat = reportCustomer?.data?.topCustomerProduction.map((d: any) => {
    return {
      customerName: d.customerName,
      sum_production: +d.sum_production,
    };
  });

  reportItemFormat?.sort((a: any, b: any) => {
    if (b.sum_production > a.sum_production) {
      return 1;
    }
    return -1;
  });

  const breakpoints = useBreakpoints();
  const checkUnitReportItem =
    +Math.max.apply(
      Math,
      reportItemFormat?.map((sumVal: any) => {
        return sumVal.sum_value_label;
      })
    ) > 1000000000;

  const checkUnit = +Math.max.apply(
    Math,
    reportCustomerValueFormat?.map((sumVal: any) => {
      return sumVal.sum_value_label;
    })
  );

  const checkPermissionViewPrice = PermissionUtil.check(
    profile?.role?.permissions,
    ItemPermission.MANAGE_ITEM_VIEW_PRICE,
    true
  );

  return (
    <div className="dash-board">
      <div className="content">
        <div className="header">
          <div style={{ display: "flex" }}>
            <button onClick={handleClick} className="btn-white">
              <div style={{ display: "flex", alignItems: "center" }}>
                <Calendar1Icon color="#272b2f" size={[20, 20]} viewBox={[20, 20]} />
                &emsp;Tháng {month}/{year}
              </div>
              <ArrowDropDownIcon />
            </button>
            {salesOrgId ? (
              <>
                {salesOrgId && (
                  <Select
                    className="btn-white"
                    variant="standard"
                    disableUnderline
                    value={salesOrgId}
                    displayEmpty
                    startAdornment={
                      <InputAdornment position="start">
                        <TuneRoundedIcon style={{ fontSize: "2.2rem" }} />
                      </InputAdornment>
                    }
                    MenuProps={{
                      className: "select-menu-list select-menu-sale-org",
                      anchorOrigin: {
                        vertical: 40,
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                    }}>
                    {salesOrgLength?.map((s: any) => {
                      return (
                        <MenuItem key={s.id} value={s.id} onClick={() => setSalesOrgId(s.id)}>
                          {s.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              </>
            ) : (
              <Select
                className="btn-white"
                variant="standard"
                disableUnderline
                value={salesOrgId}
                displayEmpty
                startAdornment={
                  <InputAdornment position="start">
                    <TuneRoundedIcon style={{ fontSize: "2.2rem" }} />
                  </InputAdornment>
                }
                MenuProps={{
                  className: "select-menu-list select-menu-sale-org",
                  anchorOrigin: {
                    vertical: 40,
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}>
                {salesOrgLength?.map((s: any) => {
                  return (
                    <MenuItem key={s.id} value={s.id} onClick={() => setSalesOrgId(s.id)}>
                      {s.description}
                    </MenuItem>
                  );
                })}
              </Select>
            )}
          </div>
          <div className="total">
            <div>
              Tổng doanh số
              <span>
                {checkPermissionViewPrice
                  ? +reportDensityValue?.total_value
                    ? currencyFormat(+reportDensityValue?.total_value)
                    : TEXT_HOLDER
                  : TEXT_ASTERISK}{" "}
                đ
              </span>
            </div>
            <div style={{ paddingLeft: "2rem" }}>
              Tổng sản lượng
              <span>
                {checkPermissionViewPrice
                  ? +reportDensityValue?.total_production
                    ? currencyFormat(+reportDensityValue?.total_production)
                    : TEXT_HOLDER
                  : TEXT_ASTERISK}{" "}
                kg
              </span>
            </div>
          </div>
        </div>
        <div>
          <Grid container spacing={2}>
            {profile?.employee?.length < 1 && (
              <>
                <Grid item xs={6}>
                  <div className="box">
                    <div className="title">
                      {salesOrg?.length >= 2
                        ? "Tỷ trọng Sản lượng giữa các đơn vị kinh doanh"
                        : "Tỷ trọng Sản lượng giữa các Giám đốc kinh doanh"}
                      {dataValue?.length > 0 && (
                        <div
                          className="see-details"
                          onClick={() => {
                            setOpenDialogPie(dataProduction);
                          }}>
                          Xem chi tiết &gt;
                        </div>
                      )}
                    </div>
                    <div className="pie">
                      {dataProduction?.length > 0 ? (
                        <PieChart
                          width={341}
                          height={!breakpoints.xl ? 240 : 333}
                          style={{ display: "flex", position: "initial" }}>
                          <Pie
                            data={dataProductionLength}
                            cx={!breakpoints.xl ? "70%" : "80%"}
                            cy={!breakpoints.xl ? "120" : "160"}
                            innerRadius={!breakpoints.xl ? 55 : 80}
                            outerRadius={!breakpoints.xl ? 90 : 140}
                            startAngle={90}
                            endAngle={-270}
                            activeShape={renderActiveShape}
                            onMouseEnter={onPieEnter}
                            activeIndex={activeIndex}
                            dataKey={"sum_production"}>
                            {dataProductionLength.map((entry: any, index: any) => (
                              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                          </Pie>
                          <Legend
                            iconSize={17}
                            iconType="circle"
                            layout="vertical"
                            wrapperStyle={{
                              top: "20%",
                              left: "4%",
                            }}
                            payload={dataProductionLength.map((item: any, index: any) => ({
                              id: item.sum_production,
                              color: COLORS[index % COLORS.length],
                              type: "circle",
                              value: (
                                <span className="item-pie">
                                  <div className="name">{item.salesName}</div>
                                  <div className="value">
                                    (
                                    {(
                                      (item.sum_production / +reportDensityPro?.total_production) *
                                      100
                                    ).toFixed(2)}
                                    &ensp;%&ensp;-&ensp;{currencyFormat(item.sum_production)}
                                    &ensp;kg)
                                  </div>
                                </span>
                              ),
                            }))}
                          />
                        </PieChart>
                      ) : (
                        <img src={DB1} alt="db1" style={{ marginTop: "5rem" }} />
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="box">
                    <div className="title">
                      {salesOrg?.length >= 2
                        ? "Tỷ trọng Doanh số giữa các đơn vị kinh doanh"
                        : "Tỷ trọng Doanh số giữa các Giám đốc kinh doanh"}
                      {dataValue?.length > 0 && (
                        <div
                          className="see-details"
                          onClick={() => {
                            setOpenDialogPie(dataValue);
                          }}>
                          Xem chi tiết &gt;
                        </div>
                      )}
                    </div>
                    <div className="pie">
                      {dataValue?.length > 0 ? (
                        <PieChart
                          width={341}
                          height={!breakpoints.xl ? 240 : 333}
                          style={{ display: "flex", position: "initial" }}>
                          <Pie
                            data={dataValueLength}
                            cx={!breakpoints.xl ? "70%" : "80%"}
                            cy={!breakpoints.xl ? "120" : "160"}
                            innerRadius={!breakpoints.xl ? 55 : 80}
                            outerRadius={!breakpoints.xl ? 90 : 140}
                            startAngle={90}
                            endAngle={-270}
                            activeShape={renderActiveShape2}
                            onMouseEnter={onPieEnter2}
                            activeIndex={activeIndex2}
                            dataKey="sum_value">
                            {dataValueLength?.map((entry: any, index: any) => (
                              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                          </Pie>
                          <Legend
                            iconSize={17}
                            iconType="circle"
                            layout="vertical"
                            wrapperStyle={{
                              top: "20%",
                              left: "4%",
                            }}
                            payload={dataValueLength?.map((item: any, index: any) => ({
                              id: item.sum_value,
                              color: COLORS[index % COLORS.length],
                              type: "circle",
                              value: (
                                <span className="item-pie">
                                  <div className="name">{item.salesName}</div>
                                  <div className="value">
                                    (
                                    {(
                                      (item.sum_value / +reportDensityValue?.total_value) *
                                      100
                                    ).toFixed(2)}
                                    &ensp;%&ensp;-&ensp;
                                    {checkPermissionViewPrice
                                      ? currencyFormat(item.sum_value)
                                      : TEXT_ASTERISK}
                                    &ensp;đ)
                                  </div>
                                </span>
                              ),
                            }))}
                          />
                        </PieChart>
                      ) : (
                        <img src={DB1} alt="db1" style={{ marginTop: "5rem" }} />
                      )}
                    </div>
                  </div>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <div className="box">
                <div className="title" style={{ paddingBottom: "3.6rem" }}>
                  Doanh số và sản lượng theo ngày
                  {reportSales?.length > 0 && (
                    <div
                      style={{
                        display: "flex",
                        fontSize: "1.7rem",
                        textTransform: "none",
                        fontWeight: "normal",
                      }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginLeft: "2rem",
                        }}>
                        <img src={IconDB2b} alt="iconb" style={{ width: "3.2rem" }} />
                        &emsp;Doanh số (đồng)
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginLeft: "2rem",
                        }}>
                        <img src={IconDB2a} alt="icona" style={{ width: "3.2rem" }} />
                        &emsp;Sản lượng (kg)
                      </div>
                    </div>
                  )}
                </div>
                {reportSales?.length > 0 ? (
                  <DBReportSales data={reportSalesData} />
                ) : (
                  <img src={DB2} alt="db2" style={{ marginTop: "5rem" }} />
                )}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="box db-item">
                <div className="title">Doanh số các nhóm sản phẩm (đồng)</div>
                {reportItem?.data?.length > 0 ? (
                  <ResponsiveContainer width={"100%"} height={600}>
                    <ComposedChart
                      layout="vertical"
                      data={reportItemFormat}
                      margin={{
                        top: 20,
                        right: 40,
                        bottom: 20,
                        left: 10,
                      }}>
                      <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="0" stopColor="#8A58F3" stopOpacity={1} />
                          <stop offset=".8" stopColor="#30B1C2" stopOpacity={0.8} />
                        </linearGradient>
                      </defs>
                      <CartesianGrid stroke="#BDC6D7" strokeDasharray="2 2" horizontal={false} />
                      <XAxis
                        unit={checkUnitReportItem ? " tỷ" : " triệu"}
                        type="number"
                        domain={[
                          0,
                          +Math.max
                            .apply(
                              Math,
                              reportItemFormat?.map((sumVal: any) => {
                                return sumVal.sum_value_label * 1.2;
                              })
                            )
                            .toFixed(),
                        ]}
                        // tickCount={10}
                        // interval={500000}
                        dataKey="sum_value_label"
                        style={{ fontSize: "1.2rem", fontWeight: 400 }}
                        tickFormatter={(value: number) =>
                          currencyFormat(checkUnitReportItem ? value / 1000000000 : value / 1000000)
                        }
                      />
                      <YAxis
                        dataKey="itemGroupName"
                        type="category"
                        tick={{ fill: "#272B2F" }}
                        width={!breakpoints.xl ? 170 : 240}
                        tickMargin={!breakpoints.xl ? 40 : 55}
                      />
                      <Bar
                        dataKey="sum_value_label"
                        barSize={9}
                        fill="url(#colorUv)"
                        isAnimationActive={false}
                        radius={[0, 10, 10, 0]}>
                        <LabelList
                          dataKey="sum_value"
                          position="right"
                          style={{ fontSize: "1.2rem", fontWeight: 400 }}
                          formatter={(x1a: number) => currencyFormat(x1a)}
                          width={200}
                        />
                        <LabelList
                          dataKey="sum_value"
                          position="left"
                          formatter={(x1b: number) =>
                            `${((x1b / reportItem?.total_value) * 100).toFixed(2)} %`
                          }
                          fill="#6C93DE"
                          style={{ fontSize: "1.2rem", fontWeight: 500 }}
                          dx={-5}
                          width={200}
                        />
                      </Bar>
                    </ComposedChart>
                  </ResponsiveContainer>
                ) : (
                  <img src={DB3} alt="db3" style={{ marginTop: "5rem" }} />
                )}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="box db-item">
                <div className="title">sản lượng các nhóm Sản phẩm (kg)</div>
                {reportItem?.data?.length > 0 ? (
                  <ResponsiveContainer width={"100%"} height={600}>
                    <ComposedChart
                      layout="vertical"
                      data={reportItemFormat}
                      margin={{
                        top: 20,
                        right: 40,
                        bottom: 20,
                        left: 10,
                      }}>
                      <defs>
                        <linearGradient id="colorUv2" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="0" stopColor="#67BD6D" stopOpacity={1} />
                          <stop offset=".8" stopColor="#49D453" stopOpacity={0.8} />
                        </linearGradient>
                      </defs>
                      <CartesianGrid stroke="#BDC6D7" strokeDasharray="2 2" horizontal={false} />
                      <XAxis
                        type="number"
                        dataKey="sum_production"
                        domain={[
                          0,
                          Math.max.apply(
                            Math,
                            reportItemFormat?.map((sumPro: any) => {
                              return sumPro.sum_production * 1.2;
                            })
                          ),
                        ]}
                        // tickCount={10}
                        style={{ fontSize: "1.2rem", fontWeight: 400 }}
                        tickFormatter={(value: number) => currencyFormat(value)}
                      />
                      <YAxis
                        dataKey="itemGroupName"
                        type="category"
                        scale="band"
                        tick={{ fill: "#272B2F" }}
                        width={!breakpoints.xl ? 170 : 240}
                        tickMargin={!breakpoints.xl ? 40 : 55}
                      />
                      <Bar
                        dataKey="sum_production"
                        barSize={9}
                        fill="url(#colorUv2)"
                        isAnimationActive={false}
                        radius={[0, 10, 10, 0]}>
                        <LabelList
                          dataKey="sum_production"
                          position="right"
                          style={{ fontSize: "1.2rem", fontWeight: 400 }}
                          formatter={(x2a: number) => `${currencyFormat(x2a)}`}
                        />
                        <LabelList
                          dataKey="sum_production"
                          position="left"
                          formatter={(x2b: number) =>
                            `${((x2b / reportItem?.total_production) * 100).toFixed(2)} %`
                          }
                          fill="#68C96F"
                          width={200}
                          style={{ fontSize: "1.2rem", fontWeight: 500 }}
                          dx={-5}
                        />
                      </Bar>
                    </ComposedChart>
                  </ResponsiveContainer>
                ) : (
                  <img src={DB3} alt="db3" style={{ marginTop: "5rem" }} />
                )}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="box">
                <div className="title">
                  TOP {reportCustomer?.data?.topCustomerProduction.length} Khách hàng CÓ sản lượng
                  CAO NHẤT (kg)
                  {/* <div
                    className="see-details"
                    onClick={() => {
                      history.push(APP_ROUTES.REPORT + "-feed");
                    }}>
                    Xem chi tiết <NavigateNextIcon />
                  </div> */}
                </div>

                {reportCustomer?.data?.topCustomerProduction?.length > 0 ? (
                  <ResponsiveContainer width={"100%"} height={600}>
                    <ComposedChart
                      layout="vertical"
                      data={reportCustomerProFormat}
                      margin={{
                        top: 20,
                        right: 30,
                        bottom: 20,
                        left: 70,
                      }}>
                      <CartesianGrid stroke="#BDC6D7" strokeDasharray="2 2" horizontal={false} />
                      <XAxis
                        type="number"
                        dataKey="sum_production"
                        domain={[
                          0,
                          Math.max.apply(
                            Math,
                            reportCustomerProFormat?.map((sumPro: any) => {
                              return sumPro.sum_production * 1.2;
                            })
                          ),
                        ]}
                        // tickCount={10}
                        style={{ fontSize: "1.2rem" }}
                        tickFormatter={(value: number) => currencyFormat(value)}
                      />
                      <YAxis
                        dataKey="customerName"
                        type="category"
                        style={{
                          fontFamily: "SVN-Gotham",
                          fontSize: "1.6rem",
                          fontWeight: 500,
                          textTransform: "capitalize",
                        }}
                        tick={{ fill: "#272B2F" }}
                        width={!breakpoints.xl ? 280 : 350}
                        dx={!breakpoints.xl ? -50 : -70}
                      />
                      <defs>
                        <linearGradient
                          id="colorUv5"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="100%"
                          gradientUnits="userSpaceOnUse">
                          <stop offset="0" stopColor="#10C866" />
                          <stop offset="0.8" stopColor="#C7F5C9" />
                        </linearGradient>
                      </defs>
                      <Bar
                        dataKey="sum_production"
                        barSize={20}
                        fill="url(#colorUv5)"
                        isAnimationActive={false}
                        radius={[0, 4, 4, 0]}>
                        <LabelList
                          dataKey="sum_production"
                          position="right"
                          style={{ fontSize: "1.6rem", fontWeight: 500 }}
                          formatter={(x3a: number) => `${currencyFormat(x3a)}`}
                          width={200}
                        />
                        <LabelList
                          dataKey="sum_production"
                          position="left"
                          formatter={(x3b: number) =>
                            `${((x3b / reportCustomer?.total_production) * 100).toFixed(2)} %`
                          }
                          fill="#10C866"
                          style={{ fontSize: "1.6rem", fontWeight: 500 }}
                          width={200}
                          dx={-5}
                        />
                      </Bar>
                    </ComposedChart>
                  </ResponsiveContainer>
                ) : (
                  <img src={DB4} alt="db4" style={{ marginTop: "5rem" }} />
                )}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="box">
                <div className="title">
                  TOP {reportCustomer?.data?.topCustomerValue.length} Khách hàng CÓ Doanh số CAO
                  NHẤT (đồng)
                  {/* <div
                    className="see-details"
                    onClick={() => {
                      history.push(APP_ROUTES.REPORT + "-feed");
                    }}>
                    Xem chi tiết <NavigateNextIcon />
                  </div> */}
                </div>
                {reportCustomer?.data?.topCustomerValue?.length > 0 ? (
                  <ResponsiveContainer width={"100%"} height={600}>
                    <ComposedChart
                      layout="vertical"
                      data={reportCustomerValueFormat}
                      margin={{
                        top: 20,
                        right: 20,
                        bottom: 20,
                        left: 70,
                      }}>
                      <CartesianGrid stroke="#BDC6D7" strokeDasharray="2 2" horizontal={false} />
                      <XAxis
                        unit={checkUnit > 1000000000 ? " Tỷ" : " Triệu"}
                        type="number"
                        dataKey="sum_value_label"
                        domain={[0, checkUnit * 1.2]}
                        style={{ fontSize: "1.2rem" }}
                        // tickCount={10}
                        tickFormatter={(value: number) =>
                          currencyFormat(
                            checkUnit > 1000000000 ? value / 1000000000 : value / 1000000
                          )
                        }
                      />
                      <YAxis
                        dataKey="customerName"
                        type="category"
                        style={{
                          fontFamily: "SVN-Gotham",
                          fontSize: "1.6rem",
                          fontWeight: 500,
                          textTransform: "capitalize",
                        }}
                        tick={{ fill: "#272B2F" }}
                        width={!breakpoints.xl ? 280 : 350}
                        dx={!breakpoints.xl ? -50 : -70}
                      />
                      <defs>
                        <linearGradient
                          id="colorUv5"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="100%"
                          gradientUnits="userSpaceOnUse">
                          <stop offset="0" stopColor="#10C866" />
                          <stop offset="0.8" stopColor="#C7F5C9" />
                        </linearGradient>
                      </defs>
                      <Bar
                        dataKey="sum_value_label"
                        barSize={20}
                        fill="url(#colorUv5)"
                        isAnimationActive={false}
                        radius={[0, 4, 4, 0]}>
                        <LabelList
                          dataKey="sum_value"
                          position="right"
                          style={{ fontSize: "1.6rem", fontWeight: 500 }}
                          formatter={(x4a: number) => currencyFormat(x4a)}
                          width={200}
                        />
                        <LabelList
                          dataKey="sum_value"
                          position="left"
                          formatter={(x4b: number) =>
                            `${((x4b / reportCustomer?.total_value) * 100).toFixed(2)} %`
                          }
                          fill="#10C866"
                          style={{ fontSize: "1.6rem", fontWeight: 500 }}
                          width={200}
                          dx={-5}
                        />
                      </Bar>
                    </ComposedChart>
                  </ResponsiveContainer>
                ) : (
                  <img src={DB4} alt="db4" style={{ marginTop: "5rem" }} />
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}>
        <div className="popover-date">
          <Grid container spacing={1} style={{ paddingBottom: 17 }} className="popover-date-select">
            <Grid item xs={6}>
              <Select
                value={month}
                onChange={(e) => {
                  setMonth(e.target.value);
                }}
                input={<OutlinedInput />}
                fullWidth
                color="success"
                MenuProps={{
                  className: "select-menu-list popover-date-menu-list",
                  anchorOrigin: {
                    vertical: 40,
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}>
                {Month.map((m: any, idx: number) => {
                  return (
                    <MenuItem
                      key={idx}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        fontFamily: "SVN-Gotham",
                      }}
                      value={m.month}>
                      {m.month}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <Select
                value={year}
                onChange={(e) => {
                  setYear(e.target.value);
                }}
                input={<OutlinedInput />}
                fullWidth
                color="success"
                MenuProps={{
                  className: "select-menu-list popover-date-menu-list",
                  anchorOrigin: {
                    vertical: 40,
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}>
                {YearList().map((y: any, idx: number) => {
                  return (
                    <MenuItem
                      key={idx}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                      value={y}>
                      {y}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          </Grid>
          <div style={{ display: "flex" }}>
            <button className="btn-cancel-style" onClick={handleClose}>
              {t("CANCEL")}
            </button>
            <button
              className="btn-confirm-style"
              onClick={() => {
                setTime(year + "-" + month);
                handleClose();
              }}>
              {t("CONFIRM")}
            </button>
          </div>
        </div>
      </Popover>

      <Dialog maxWidth={!breakpoints.lg ? "sm" : "md"} fullWidth open={openDialogPie}>
        <DashboardPie
          data={openDialogPie}
          report={
            openDialogPie?.[0].sum_production === undefined ? reportDensityValue : reportDensityPro
          }
          closeForm={() => {
            setOpenDialogPie(undefined);
          }}
          activeShape={
            openDialogPie?.[0].sum_production === undefined ? renderActiveShape2 : renderActiveShape
          }
          onMouseEnter={openDialogPie?.[0].sum_production === undefined ? onPieEnter2 : onPieEnter}
          activeIndex={openDialogPie?.[0].sum_production === undefined ? activeIndex2 : activeIndex}
          salesLength={salesOrg?.length >= 2}
        />
      </Dialog>
    </div>
  );
};

export default Dashboard;
