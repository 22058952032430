import NotFoundImg from "@Assets/images/404.png";
import { APP_ROUTES } from "@Constants";
import React from "react";
import { useHistory } from "react-router-dom";
import "./NotFound.scss";

const NotFound: React.FC = () => {
  const history = useHistory();
  return (
    <div className="notfound">
      <div className="title">Không tìm thấy trang</div>
      <div className="label">Trang mà bạn tìm kiếm không còn tồn tại nữa</div>
      <img src={NotFoundImg} alt="404" />
      <button
        type="submit"
        className="btn-confirm-style"
        style={{ borderRadius: "4rem", marginBottom: "10rem" }}
        onClick={() => {
          history.push(APP_ROUTES.HOME);
        }}>
        Quay lại trang chủ
      </button>
    </div>
  );
};

export default NotFound;
