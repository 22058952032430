import HomePage3 from "@Assets/images/mock/banner_8_12_23.png";
import HomePage2 from "@Assets/images/mock/home-page-2.jpg";
import HomePage from "@Assets/images/mock/home-page.jpg";
import Loading from "@Components/Loading";
import { APP_ROUTES, BASE_LINK_IMAGE } from "@Constants";
import {
  Action,
  FetchAction,
  NewsOutputModel,
  PaginateFetchReducer,
  PolicyOutputModel,
} from "@Models";
import {
  Clear as ClearIcon,
  FiberManualRecord as DotIcon,
  NavigateNext as NavigateNextIcon,
} from "@mui/icons-material";
import { Box, Dialog, Grid, Tab, Tabs } from "@mui/material";
import { CustomerService, NewService } from "@Services";
import { useSnackbar } from "notistack";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "./index.scss";

// install Swiper modules
SwiperCore.use([Autoplay, Pagination]);

type P_Props = {};

function reducer(state: PaginateFetchReducer<NewsOutputModel>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: state.data?.concat(action.payload.data) || [],
        loading: false,
      };
    case Action.RESET:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload.data,
        loading: false,
      };
    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload, loading: false };

    default:
      return { ...state };
  }
}
const Home: React.FC<P_Props> = () => {
  const { t } = useTranslation(["translation"]);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const mounted = useRef(false);
  const [dataPolicy, setDataPolicy] = useState<PolicyOutputModel[]>([]);
  const [value, setValue] = React.useState(0);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [state, dispatch] = useReducer(reducer, {
    page: 0,
    totalPages: 0,
    limit: 0,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
    loading: true,
  });

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const fetchData = async (query: any, isReset: boolean = false) => {
    if (state.fetching) return;
    dispatch({ type: Action.FETCH, payload: {} });
    try {
      const result = await NewService.getAllNews(query);
      if (isReset)
        dispatch({
          type: Action.RESET,
          payload: result.data,
        });
      else if (mounted.current)
        dispatch({
          type: Action.SUCCESS,
          payload: result.data,
        });
    } catch (err) {
      if (mounted)
        dispatch({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
    }
  };

  const getPolicyPrivacy = async () => {
    try {
      const res = await CustomerService.getCustomerPolicy();
      if (res && (res.status === 200 || res.status === 201)) {
        const resData: PolicyOutputModel = res.data;
        setDataPolicy([res.data]);
        setValue(resData.id);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchData(
      {
        page: 1,
        limit: 50,
        strSearch: "",
      },
      true
    );
    getPolicyPrivacy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dataNews: NewsOutputModel[] = state.data;

  const renderImage = (linkImg: string | null, type: "banner" | "thumb") => {
    if (!!linkImg) {
      return BASE_LINK_IMAGE + linkImg;
    } else {
      if (type === "banner") {
        return HomePage;
      } else {
        return HomePage2;
      }
    }
  };

  useEffect(() => {
    const savedData = localStorage.getItem("policyKey");
    const timeout = setTimeout(() => {
      if (!!dataPolicy.length && !dataPolicy[0]?.policyAccepted) {
        if (!savedData) {
          setShowPopup(true);
        } else {
          if (savedData === "show") {
            setShowPopup(true);
          } else if (savedData === "hidden") {
            setShowPopup(false);
          }
        }
      } else {
        setShowPopup(false);
      }
    }, 1500);

    return () => clearTimeout(timeout);
    //eslint-disable-next-line
  }, [dataPolicy]);

  const handlePolicy = () => {
    localStorage.setItem("policyKey", "hidden");
    setShowPopup(false);
  };

  const onSubmitPolicy = async () => {
    try {
      const res = await CustomerService.acceptCustomerPolicy();
      if (res && (res.status === 200 || res.status === 201)) {
        enqueueSnackbar(t("YOU_AGREED_PREIVATE_POLICY"), {
          variant: "success",
        });
        handlePolicy();
      }
    } catch (error) {
      enqueueSnackbar(t("ERROR_OCCURRED"), {
        variant: "error",
      });
      setShowPopup(false);
    }
  };

  return (
    <>
      {!!dataNews.length ? (
        <Grid className="homepage_container" container>
          {!state.loading ? (
            <Grid className="home" item md={12}>
              <Grid className="content" container>
                <Grid className="content-left" xs={9}>
                  <Swiper
                    modules={[Pagination]}
                    spaceBetween={300}
                    slidesPerView={1}
                    pagination={{ clickable: true }}
                    loop
                    autoplay={{
                      delay: 4000,
                      disableOnInteraction: false,
                    }}>
                    {dataNews?.map((item: NewsOutputModel, index: number) => {
                      return (
                        <SwiperSlide key={index}>
                          <div className="content-left__item">
                            <div
                              className="content-left__item__image"
                              onClick={() => {
                                history.push(
                                  APP_ROUTES.NEWS_DETAIL.replace(":newsId", item.id.toString())
                                );
                              }}>
                              <img
                                src={item.image ? renderImage(item.image, "banner") : HomePage}
                                alt="#"
                                width={"100%"}
                              />
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </Grid>
                <Grid className="content-right" xs={3}>
                  <div
                    className="content-right__item__image"
                    onClick={() => {
                      history.push(
                        APP_ROUTES.NEWS_DETAIL.replace(":newsId", dataNews[0].id.toString())
                      );
                    }}>
                    <img
                      src={
                        dataNews?.length > 2 ? renderImage(dataNews[0].image, "thumb") : HomePage2
                      }
                      alt="#"
                      width={"100%"}
                    />
                  </div>
                  <div
                    className="content-right__item__image"
                    onClick={() => {
                      history.push(
                        APP_ROUTES.NEWS_DETAIL.replace(":newsId", dataNews[0].id.toString())
                      );
                    }}>
                    <img
                      src={
                        dataNews?.length > 2 ? renderImage(dataNews[1].image, "thumb") : HomePage2
                      }
                      alt="#"
                      width={"100%"}
                    />
                  </div>
                </Grid>
              </Grid>

              <div className="news">
                <div className="news_header">
                  <div className="news_header_title">{t("NEWS")}</div>
                </div>
                <div className="news_slide">
                  <div className="swiper-wrapper">
                    <Swiper
                      modules={[Pagination]}
                      spaceBetween={100}
                      pagination={{ clickable: true }}
                      slidesPerView={4}
                      slidesPerGroup={1}
                      breakpoints={{
                        991: {
                          slidesPerView: 4,
                          spaceBetween: 140,
                        },
                      }}
                      loop={true}>
                      {dataNews?.map((item: NewsOutputModel, index: number) => {
                        let text = item.title;
                        let result = text.toUpperCase();
                        return (
                          <SwiperSlide key={index}>
                            <div className="news_slide_item">
                              <div
                                className="news_slide_item_image"
                                onClick={() => {
                                  history.push(
                                    APP_ROUTES.NEWS_DETAIL.replace(":newsId", item.id.toString())
                                  );
                                }}>
                                <img src={renderImage(item.image, "thumb")} alt="#" />
                              </div>
                              <div className="news_slide_item_content">
                                <div className="slide_text">
                                  <a
                                    style={{
                                      textDecoration: "underline",
                                    }}
                                    href={item?.url || ""}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    {result}
                                  </a>
                                </div>
                                <div className="slide_btn">
                                  <div className="slide_btn_text">Xem thêm</div>
                                  <div className="slide_btn_icon">
                                    <NavigateNextIcon />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                </div>
              </div>
            </Grid>
          ) : (
            <Loading style={{ margin: "2rem 0", height: "80vh" }} />
          )}
        </Grid>
      ) : (
        <div className="homepage">
          <Swiper
            modules={[Pagination]}
            slidesPerView={1}
            pagination={{ clickable: true }}
            loop
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}>
            <SwiperSlide>
              <div className="homepage_container default">
                <img src={HomePage3} alt="hb" />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      )}

      <Dialog maxWidth="md" open={showPopup}>
        <div className="dialog-policy">
          <div className="header">
            <div></div>
            <div className="title">{t("PRIVATE_POLICY")}</div>
            <ClearIcon
              style={{ fontSize: "3rem", cursor: "pointer" }}
              onClick={() => handlePolicy()}
            />
          </div>

          <div className="policy">
            <div className="tab">
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={(e, value) => setValue(value)}
                  variant="scrollable"
                  scrollButtons="auto"
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#0A6836",
                    },
                  }}>
                  {dataPolicy?.map((tab) => {
                    return (
                      <Tab
                        key={tab.id}
                        className="policy-tab-list__item"
                        label={
                          <div className="policy-tab-label">
                            {tab.title}{" "}
                            {!tab.policyAccepted && (
                              <DotIcon style={{ fontSize: "2rem", color: "#ED0404" }} />
                            )}
                          </div>
                        }
                        value={tab.id}
                        style={{
                          fontFamily: "SVN-Gotham",
                          color: value === tab.id ? "#0A6836" : "#5A5959",
                          fontWeight: value === tab.id ? 700 : 450,
                        }}
                      />
                    );
                  })}
                </Tabs>
              </Box>
              <div
                role="tabpanel"
                id={`simple-tabpanel-${value}`}
                aria-labelledby={`simple-tab-${value}`}>
                <Box sx={{ pt: 3 }}>{dataPolicy.find((i) => i.id === value)?.body}</Box>
              </div>
            </div>
          </div>

          <div className="dialog-policy_footer">
            <button
              className="btn-confirm-style"
              style={{ marginLeft: "2.2rem" }}
              onClick={() => onSubmitPolicy()}>
              {t("CONFIRM")}
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default Home;
