import { TEXT_ASTERISK } from "@Constants";
import { ItemPermission } from "@Models";
import { CustomerService } from "@Services";
import {
  AxesDirective,
  AxisDirective,
  ChartComponent,
  DataLabel,
  DateTime,
  IAxisLabelRenderEventArgs,
  Inject,
  Legend,
  LineSeries,
  SeriesCollectionDirective,
  SeriesDirective,
  SplineAreaSeries,
  SplineSeries,
  StackingColumnSeries,
  Tooltip,
  Zoom,
  ZoomMode,
} from "@syncfusion/ej2-react-charts";
import { currencyFormat, PermissionUtil } from "@Utils";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import "./index.scss";

type P_Props = {
  data: any;
};

const DBReportSales: React.FC<P_Props> = ({ data }) => {
  const [profile, setProfile] = useState<any>([]);

  const loadProfileData = _.debounce(async () => {
    const response = await CustomerService.getAuthProfile();
    setProfile(response.data);
  }, 100);

  useEffect(() => {
    loadProfileData();
    //eslint-disable-next-line
  }, []);

  //permission
  const checkPermissionViewPrice = PermissionUtil.check(
    profile?.role?.permissions,
    ItemPermission.MANAGE_ITEM_VIEW_PRICE,
    true
  );

  const primaryxAxis = {
    labelFormat: "dd-MM",
    interval: 1,
    valueType: "DateTime",
    zoomFactor: 0.5,
    zoomPosition: 0,
    majorGridLines: {
      width: 0,
    },

    labelStyle: {
      fontFamily: "SVN-Gotham",
    },
  };

  data?.sort((a: any, b: any) => {
    if (a.date > b.date) {
      return 1;
    }
    return -1;
  });

  const primaryyAxis = {
    minimum: 0,
    maximum: Math.max.apply(
      Math,
      data?.map((sumPro: any) => {
        return sumPro.sum_production * 1.2;
      })
    ),
    interval: Math.round(
      Math.max.apply(
        Math,
        data?.map((sumPro: any) => {
          return sumPro.sum_production * 1.2;
        })
      ) / 6
    ),
    lineStyle: { width: 0 },
    majorGridLines: {
      width: 1,
      dashArray: "2",
    },
    labelStyle: {
      fontFamily: "SVN-Gotham",
    },
  };
  const marker = { visible: true, width: 8, height: 8, border: { color: "#41AD49" } };

  const maxximumVal = +Math.max.apply(
    Math,
    data?.map((sumVal: any) => {
      return sumVal.sum_value * 1.2;
    })
  );

  const zoomsettings = {
    enable: true,
    doubleclick: false,
    enableSelectionZooming: false,
    enablePinchZoom: false,
    mode: "X" as ZoomMode,
    enablePan: true,
  };

  const animation = { enable: true };

  const chartTemplate = (args: any) => {
    return (
      <div id="templateWrap">
        <div className="tooltip-custom">
          <div>{currencyFormat(data[args.index].sum_production)} kg</div>
          <div>
            {checkPermissionViewPrice ? currencyFormat(data[args.index].sum_value) : TEXT_ASTERISK}{" "}
            đ
          </div>
        </div>
      </div>
    );
  };

  const template = chartTemplate;
  const tooltip = {
    enable: true,
    template: template,
  };

  const axisLabelRender = (args: IAxisLabelRenderEventArgs) => {
    if (args.axis.orientation === "Vertical") {
      args.text = args.text.replaceAll(",", ".");
      if (args.text.slice(args.text.length - 2, args.text.length) === "tỷ") {
        const val = args.text.replace(" tỷ", "");
        args.text = parseFloat(val).toFixed() + (maxximumVal > 1000000000 ? " Tỷ" : " Triệu");
      }
    }
  };

  return (
    <div>
      <ChartComponent
        id="charts"
        primaryXAxis={primaryxAxis as any}
        primaryYAxis={primaryyAxis as any}
        palettes={["rgba(99, 255, 111, 0.4)", "#41AD49", "#41AD49", "#41AD49"]}
        zoomSettings={zoomsettings}
        tooltip={tooltip as any}
        useGroupingSeparator={true}
        axisLabelRender={axisLabelRender}
        // zoomSettings={
        //   {
        //     enable: true,
        //     doubleclick: true,
        //     enableSelectionZooming: true,
        //     enablePan: true,
        //   } as any
        // }
      >
        <Inject
          services={[
            StackingColumnSeries,
            LineSeries,
            Legend,
            Tooltip,
            DataLabel,
            DateTime,
            Zoom,
            SplineAreaSeries,
            SplineSeries,
          ]}
        />
        <AxesDirective>
          <AxisDirective
            rowIndex={0}
            name="yAxis1"
            opposedPosition={true}
            majorGridLines={{ width: 0 }}
            lineStyle={{ width: 0 }}
            labelFormat={"{value} tỷ"}
            maximum={maxximumVal}
            minimum={0}
            interval={maxximumVal / 6}
            labelStyle={{ fontFamily: "SVN-Gotham", fontWeight: "Regular" }}></AxisDirective>
        </AxesDirective>
        <SeriesCollectionDirective>
          <SeriesDirective
            dataSource={data}
            xName="date"
            yName="sum_production"
            type="StackingColumn"
            animation={animation}
            columnWidth={0.5}></SeriesDirective>
          <SeriesDirective
            dataSource={data}
            xName="date"
            yName="sum_production2"
            type="StackingColumn"
            animation={animation}
            columnWidth={0.5}></SeriesDirective>
          <SeriesDirective
            dataSource={data}
            xName="date"
            yName="sum_value"
            type="SplineArea"
            splineType="Natural"
            fill="url(#gradient-chart)"
            yAxisName="yAxis1"
            animation={animation}></SeriesDirective>
          <SeriesDirective
            dataSource={data}
            xName="date"
            yName="sum_value"
            width={2}
            type="Spline"
            yAxisName="yAxis1"
            marker={marker}
            animation={animation}></SeriesDirective>
        </SeriesCollectionDirective>
      </ChartComponent>

      <svg style={{ height: "0" }}>
        <defs>
          <linearGradient id="gradient-chart" x1="0" x2="0" y1="0" y2="1">
            <stop offset="15%" stopColor="#63FF6F" stopOpacity={0.3} />
            <stop offset="90%" stopColor="#FFFFFF" stopOpacity={0} />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default DBReportSales;
