import { IconBase, IconComponent } from "@Core/Icons";

export const MapIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7 2.02966C4.0903 2.02966 1.75 4.35194 1.75 7.19397C1.75 8.2054 2.20308 9.10142 2.8841 10.0027C3.22437 10.4531 3.60498 10.8838 3.99635 11.3254L4.0194 11.3514C4.39555 11.7757 4.78612 12.2164 5.1167 12.6603C5.76591 13.5322 6.39621 14.6023 6.86302 15.4499C6.88447 15.4888 6.93367 15.5287 7.02123 15.5296C7.10852 15.5306 7.15677 15.4923 7.17816 15.4552C7.66668 14.6063 8.32041 13.5325 8.96989 12.6603C9.28834 12.2327 9.65852 11.8089 10.0152 11.4006C10.0345 11.3785 10.0538 11.3564 10.0731 11.3344C10.4552 10.8968 10.8247 10.47 11.1536 10.0233C11.8097 9.13223 12.25 8.23253 12.25 7.19397C12.25 4.24802 9.91921 2.02966 7 2.02966ZM0.25 7.19397C0.25 3.50324 3.28229 0.529663 7 0.529663C10.7082 0.529663 13.75 3.3807 13.75 7.19397C13.75 8.69011 13.1052 9.90266 12.3615 10.9126C11.9906 11.4165 11.5816 11.8873 11.2029 12.321L11.1595 12.3708C10.7901 12.7936 10.4554 13.1769 10.173 13.5562C9.57649 14.3572 8.95738 15.3708 8.47821 16.2034C7.83423 17.3223 6.16816 17.2975 5.5491 16.1735C5.09332 15.3459 4.5017 14.3459 3.91362 13.5562C3.6218 13.1643 3.26764 12.7647 2.87378 12.3203C2.4881 11.8851 2.06856 11.4116 1.68733 10.907C0.925351 9.89857 0.25 8.68233 0.25 7.19397ZM7 6.25417C6.45723 6.25417 6.03571 6.68506 6.03571 7.19397C6.03571 7.70287 6.45723 8.13377 7 8.13377C7.54277 8.13377 7.96429 7.70287 7.96429 7.19397C7.96429 6.68506 7.54277 6.25417 7 6.25417ZM4.53571 7.19397C4.53571 5.83637 5.64922 4.75417 7 4.75417C8.35078 4.75417 9.46429 5.83637 9.46429 7.19397C9.46429 8.55157 8.35078 9.63377 7 9.63377C5.64922 9.63377 4.53571 8.55157 4.53571 7.19397Z"
        fill={props.color}
      />
    </IconBase>
  );
};
