import { Divider } from "@mui/material";
import { checkLinkImage, PermissionUtil, renderErrorImageItem, renderImageProNull } from "@Utils";
import { useTranslation } from "react-i18next";
import { InterestedIcon1 } from "../Icons/InterestedIcon1";
import { InterestedIcon2 } from "../Icons/InterestedIcon2";
import { InterestedIcon3 } from "../Icons/InterestedIcon3";
import { InterestedIcon4 } from "../Icons/InterestedIcon4";
import "./index.scss";
import { TEXT_ASTERISK } from "@Constants";
import { CustomerService } from "@Services";
import { useEffect, useState } from "react";
import { ItemPermission } from "@Models";
import _ from "lodash";

export type P_Props = {
  class: any;
  key: any;
  id: number;
  onClick: () => void;
  image: any;
  shortName: any;
  name: any;
  value: any;
  baseUoM: any;
  source: string;
  additionalValue?: string;
  priceType?: string;
  farmBaseUoM?: string;
  farmSaleUnit?: string;
  interestedItem: any;
  updateProductInterested: (interested: boolean) => void;
  disableImg?: boolean;
  color?: string | null;
};

const ProductItem: React.FC<P_Props> = (props) => {
  const { t } = useTranslation(["translation"]);
  const [profile, setProfile] = useState<any>([]);

  const loadProfileData = _.debounce(async () => {
    const response = await CustomerService.getAuthProfile();
    setProfile(response.data);
  }, 100);

  useEffect(() => {
    loadProfileData();
    //eslint-disable-next-line
  }, []);

  //permission
  const checkPermissionViewPrice = PermissionUtil.check(
    profile?.role?.permissions,
    ItemPermission.MANAGE_ITEM_VIEW_PRICE,
    true
  );

  return (
    <div className="product-item" key={props.key}>
      {!props.disableImg && (
        <div className="icon-interested">
          {(() => {
            if (props.interestedItem) {
              if (props.interestedItem.stared === false && props.interestedItem.bought > 0) {
                return (
                  <div onClick={() => props.updateProductInterested(true)}>
                    <InterestedIcon1 color="#41AD49" size={[24, 24]} viewBox={[36, 36]} />
                  </div>
                );
              } else if (props.interestedItem.stared === true && props.interestedItem.bought > 0) {
                return (
                  <div onClick={() => props.updateProductInterested(false)}>
                    <InterestedIcon2 color="#41AD49" size={[24, 24]} viewBox={[36, 36]} />
                  </div>
                );
              } else if (
                props.interestedItem.stared === false &&
                props.interestedItem.bought === 0
              ) {
                return (
                  <div className="icon-star" onClick={() => props.updateProductInterested(true)}>
                    <InterestedIcon4 color="#41AD49" size={[13, 13]} viewBox={[15, 14]} />
                  </div>
                );
              } else if (
                props.interestedItem.stared === true &&
                props.interestedItem.bought === 0
              ) {
                return (
                  <div className="icon-star" onClick={() => props.updateProductInterested(false)}>
                    <InterestedIcon3 color="#41AD49" size={[13, 13]} viewBox={[15, 14]} />
                  </div>
                );
              }
            } else
              return (
                <div className="icon-star" onClick={() => props.updateProductInterested(true)}>
                  <InterestedIcon4 color="#41AD49" size={[13, 13]} viewBox={[15, 14]} />
                </div>
              );
          })()}
        </div>
      )}
      <div
        className={props.class ? "item active" : "item"}
        style={{
          borderLeft: `3px solid ${props.color ? props.color : "transparent"}`,
        }}
        onClick={() => {
          props.onClick();
        }}>
        {!props.disableImg && (
          <img
            src={checkLinkImage(props.image) || renderImageProNull(props.source)}
            alt={props.shortName}
            onError={renderErrorImageItem(props.source)}
          />
        )}
        <div>
          <div className="name" style={{ color: props?.color ?? "#312f30" }}>
            {props.shortName}
          </div>
          <div className="text">{props.name}</div>
          <div className="price">
            {checkPermissionViewPrice ? props.value : TEXT_ASTERISK} đ/<span>{props.baseUoM}</span>
          </div>
          {props.source === "farm" && (
            <div className="additionalValue">
              {t("STOCK_FEE_EXCESS_PORTION")} +{" "}
              <span>{checkPermissionViewPrice ? props.additionalValue : TEXT_ASTERISK}</span> đ/
              {(() => {
                if (props.priceType === "increment") {
                  return (
                    <span
                      style={{
                        color: "#41AD49",
                        textTransform: "lowercase",
                      }}>
                      {props.farmBaseUoM}
                    </span>
                  );
                }
                return (
                  <span
                    style={{
                      color: "#41AD49",
                      textTransform: "lowercase",
                    }}>
                    {props.farmSaleUnit}
                  </span>
                );
              })()}
            </div>
          )}
        </div>
      </div>
      <Divider style={{ marginRight: "3rem", marginLeft: "3rem" }} />
    </div>
  );
};

export default ProductItem;
