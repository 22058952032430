import Banner from "@Assets/images/block-acc/banner-block.png";
import BlockIcon from "@Assets/images/block-acc/block-icon.png";
import { APP_ROUTES } from "@Constants";
import React from "react";
import { useHistory } from "react-router-dom";
import "./BlockAccount.scss";

const BlockAccount: React.FC = () => {
  const history = useHistory();
  return (
    <div className="block-form">
      <img src={Banner} alt="banner" />
      <div className="block-form__content">
        <div className="block-form__content__logo">
          <img src={BlockIcon} alt="block-icon" />
        </div>
        <div className="title">Tài khoản của bạn bị tạm khóa</div>
        <div className="description">
          Bạn đã yêu cầu gửi lại mã xác thực OTP quá số lần quy định. Xin vui lòng liên hệ
          <br /> Hotline
          <span style={{ color: "#8DCE92", margin: "0 0.2rem" }}>1900 1099</span> để được hỗ trợ
        </div>
        <button
          type="submit"
          className="btn-confirm-style"
          onClick={() => {
            localStorage.removeItem("requestToken");
            history.push(APP_ROUTES.SIGNIN);
            window.location.reload();
          }}>
          Quay lại trang chủ
        </button>
        <div className="block-form__text">
          Điều khoản sử dụng và bảo mật
          <br />
          Phiên bản 1.2.21 • Bản quyền thuộc về
          <a
            style={{ color: "#0A6836", fontWeight: 700 }}
            rel="noreferrer"
            href={"https://www.greenfeed.com.vn/"}
            target="_blank">
            {" "}
            GREENFEED VIETNAM
          </a>
        </div>
      </div>
    </div>
  );
};

export default BlockAccount;
