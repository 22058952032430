import { REQUEST_URL } from "@Constants";
import { FeedBackInputModel } from "@Models";
import { RequestUtil } from "@Utils";

export const FeedBackService = {
  createFeedBack: (orderId: string, data: FeedBackInputModel) => {
    const url = REQUEST_URL.FEEDBACK_CREATE.replace("{orderId}", orderId);
    return RequestUtil.post({ url, data });
  },

  updateFeedBack: (orderId: string, feedbackId: string, data: FeedBackInputModel) => {
    const url = REQUEST_URL.FEEDBACK_UPDATE.replace("{orderId}", orderId).replace(
      "{feedbackId}",
      feedbackId
    );
    return RequestUtil.put({ url, data });
  },
};
