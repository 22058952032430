import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export function useBreakpoints() {
  const theme = useTheme();
  const breakpointXs = useMediaQuery(theme.breakpoints.up("xs"));
  const breakpointSm = useMediaQuery(theme.breakpoints.up("sm"));
  const breakpointMd = useMediaQuery(theme.breakpoints.up("md"));
  const breakpointLg = useMediaQuery(theme.breakpoints.up("lg"));
  const breakpointXl = useMediaQuery(theme.breakpoints.up("xl"));
  const breakpointsmall = { min: 0, max: 900 };
  const breakpointbig = { min: 901, max: 2000 };

  return {
    xs: breakpointXs,
    small: breakpointsmall,
    big: breakpointbig,
    sm: breakpointSm,
    md: breakpointMd,
    lg: breakpointLg,
    xl: breakpointXl,
  };
}
