import { REQUEST_URL } from "@Constants";
import { PaginationQueryModel } from "@Models";
import { RequestUtil } from "@Utils";

export const DiscountService = {
  getDiscount: (
    query: PaginationQueryModel & {
      period: string;
      searchString?: string;
    }
  ) => {
    const url = REQUEST_URL.BULK_DISCOUNT;

    return RequestUtil.get({ url, query });
  },

  importDiscount(formData: FormData) {
    const url = REQUEST_URL.BULK_DISCOUNT;

    return RequestUtil.post({ url, data: formData });
  },

  getDiscountReport: (
    query: PaginationQueryModel & {
      period?: string;
      searchString?: string;
    }
  ) => {
    const url = REQUEST_URL.REPORT_DISCOUNT;

    return RequestUtil.get({ url, query });
  },
};
