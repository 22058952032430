import React from "react";
export type IconModel = {
  color: string;
  size: [number, number]; // mang kich thuoc x,y (width, height)
  viewBox?: [number, number]; // mang kich thuoc x,y (width, height)
  className?: string;
  style?: React.CSSProperties;
  options?: {
    color: string;
    backgroundColor: string;
  };
};
export const SvgCommon: React.FC<IconModel> = (props) => {
  return (
    <svg
      width={props.size[0]}
      height={props.size[1]}
      viewBox={`0 0 ${props.viewBox ? props.viewBox[0] : props.size[0]} ${
        props.viewBox ? props.viewBox[1] : props.size[1]
      }`}
      className={props.className}
      style={props.style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      {props.children}
    </svg>
  );
};
