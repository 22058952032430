import { IconBase, IconComponent } from "@Core/Icons";

export const EventIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        d="M6 2H3C1.89543 2 1 2.89543 1 4V16C1 17.1046 1.89543 18 3 18H17C18.1046 18 19 17.1046 19 16V4C19 2.89543 18.1046 2 17 2H6ZM6 2V0M6 2V4M14 0V2V4"
        stroke="#41AD49"
        strokeWidth="2"
      />
      <rect x="3.69922" y="5.69922" width="2.6" height="2.6" rx="1.3" fill="#D9EFDB" />
      <rect x="3.69922" y="9.19922" width="2.6" height="2.6" rx="1.3" fill="#41AD49" />
      <rect x="3.69922" y="12.6992" width="2.6" height="2.6" rx="1.3" fill="#41AD49" />
      <rect x="13.6992" y="5.69922" width="2.6" height="2.6" rx="1.3" fill="#41AD49" />
      <rect x="13.6992" y="9.19922" width="2.6" height="2.6" rx="1.3" fill="#41AD49" />
      <rect x="13.6992" y="12.6992" width="2.6" height="2.6" rx="1.3" fill="#D9EFDB" />
      <rect x="8.69922" y="5.69922" width="2.6" height="2.6" rx="1.3" fill="#41AD49" />
      <rect x="8.69922" y="9.19922" width="2.6" height="2.6" rx="1.3" fill="#41AD49" />
      <rect x="8.69922" y="12.6992" width="2.6" height="2.6" rx="1.3" fill="#D9EFDB" />
    </IconBase>
  );
};
