export const REQUEST_URL = {
  // auth
  LOGIN: "/auth/login",
  VERIFY_OTP: "/auth/verify-otp",
  RETRY_OTP: "/auth/retry-otp",
  SIGN_UP: "/user/sign-up",
  FORGET_PASSWORD: "/user/forgot-password",
  VERIFY_FORGET_PASSWORD: "/auth/verify-forgot-password-otp",
  UPDATE_PASSWORD: "/user/update-password",
  SAVE_TOKEN_DEVICE: "/auth/save-token-device",
  DEACTIVE_TOKEN_DEVICE: "/auth/deactive-token-device",
  DESTROY_TOKEN: "/auth/destroy-token",

  //customer
  CHANGE_PW: "/auth/change-password",
  CUSTOMER_GROUPS: "/customer/groups",
  CUSTOMER_PROFILE: "/customer/profiles",
  AUTH_PROFILE: "/auth/get-profile",
  CUSTOMER_IDENTITY: "/customer/upload",
  CUSTOMER_UPLOADIMAGE: "/customer/upload/{type}",
  CUSTOMER_ORDER: "/order/by-customer",
  NOTIFICATION: "/notification",
  UPDATE_NOTIFICATION: "/notification",
  GET_ADDRESS: "/shipping",
  CREATE_ADDRESS: "/shipping",
  DIVISION: "/division",
  POLICY_GET: "/policy",
  POLICY_ACCEPT: "/policy/accept",

  //product
  PRODUCT: "/item",
  PRODUCT_ITEM: "/item-group",
  ITEM_BY_ID: "/item/by-id",
  PRODUCT_LIST: "/item/list",
  PRODUCT_INTERESTED: "/item/interested",

  //news
  NEWS_GET_ALL: "/news/actor",
  NEWS_DETAIL_DELETE_UPDATE: "/news/{newsId}",
  NEWS_POST: "/news",

  //order
  ORDER: "/order",
  ORDER_DETAIL: "/order/{orderId}",
  CREATE_ORDER_DRAFT: "/order/create-by-draft-order",
  DELETE_ORDER_DRAFT: "/order/delete-draft-order",
  UPDATE_ORDER_FEED: "/order/update-feed-order",
  CANCEL_ORDER_FEED: "/order/cancel-order",
  DELIVERY_ORDER: "/delivery-order",
  CANCEL_ORDER_FARM: "/order/cancel-farm-order",
  UPDATE_ORDER_VET: "/order/update-vet-order",
  CREATE_ORDER_LAB: "/order/create-order-lab",
  UPDATE_ORDER_LAB: "/order/lab/{id}",
  CANCEL_ORDER_LAB: "/order/cancel-order-lab",
  UPDATE_ORDER_LAB_STATUS: "/order/update-lab-status/{id}",
  UPDATE_ORDER_LAB_SAMPLE: "/order/update-sample-lab-order",
  EXPORT_ORDER_LAB_SAMPLE: "/order/lab/{id}/sample-template/export",
  IMPORT_ORDER_LAB_SAMPLE: "/order/lab/{id}/sample-template/import",

  //debtId
  DEBT: "/customer/debts",
  DEBT_DETAIL: "/customer/debt",

  // report
  GET_ALL_REPORT: "/report/get-all",
  REPORT_BU: "/report/get-bu",
  REPORT_DENSITY: "/report/density",
  REPORT_SALES: "/report/sales-and-quantity",
  REPORT_TOP_CUSTOMER: "/report/top-customer",
  REPORT_ITEM_GROUP: "/report/item-group",

  //shipping
  SHIPPING: "/shipping",

  //sales-office
  SALES_OFFICE: "sales-office",

  //sales-org
  GET_SALES_ORG: "/sales-org",
  GET_SALES_ORG_FOR_RECALL: "/sales-org/for-recall",

  //discount
  BULK_DISCOUNT: "/bulk-discount",
  REPORT_DISCOUNT: "/discount",

  //target
  TARGET_GET_OR_CREATE: "/target",
  TARGET_BY_ID: "/target/{id}",

  //objective
  OBJECTIVE_GET_OR_CREATE: "/objective",
  OBJECTIVE_BY_ID: "/objective/{id}",

  //requestType
  REQUEST_TYPE_GET_OR_CREATE: "/request-type",

  //plan
  ORDER_PLAN: "/order-plan/list",
  DETAIL_ORDER_PLAN: "/order-plan/{planId}",
  CREATE_ORDER_PLAN: "/order-plan",
  UPDATE_ORDER_PLAN: "/order-plan/{planId}",
  UPDATE_STATUS_ORDER_PLAN: "/order-plan/{planId}/status",
  UPDATE_DETAIL_ORDER_PLAN: "/order-plan/detail/{detailId}",

  //sub account
  SUB_ACCOUNT_GET_ALL: "/sub-account/list",
  SUB_ACCOUNT_GET_DETAIL: "/sub-account/{id}",
  SUB_ACCOUNT_CREATE: "/sub-account",
  SUB_ACCOUNT_UPDATE: "/sub-account/{id}",
  SUB_ACCOUNT_REVIEW: "/sub-account/{id}/review",
  SUB_ACCOUNT_CHANGE_STATUS: "/sub-account/status",
  SUB_ACCOUNT_PROVISION: "/sub-account/{id}",

  //export-Excel
  REFRESH_STATUS: "/export/refresh-status",
  EXPORT_ORDER_PLAN: "/export/order-plan/{planId}",
  EXPORT_REPORT: "/export/report-sales-volumes",
  EXPORT_ORDER: "/export/order",
  EXPORT_ORDER_FARM: "/export/order-farm",

  //feedback
  FEEDBACK_CREATE: "order/{orderId}/feedback",
  FEEDBACK_UPDATE: "order/{orderId}/feedback/{feedbackId}",
} as const;
