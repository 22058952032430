import { APP_ROUTES } from "@Constants";

export const OrderUtil = {
  buildOrderDetailPath,
  checkQuantityOrderFarm,
};

function buildOrderDetailPath(orderId: string): string {
  return orderId ? APP_ROUTES.ORDER_DETAIL.replace(":orderId", orderId) : APP_ROUTES.ORDER;
}

function checkQuantityOrderFarm(quantity: number, value: any) {
  if (value.includes(">||>")) {
    if (quantity < +value?.match(/[0-9]+/g)?.[0] || +value?.match(/[0-9]+/g)?.[1] < quantity) {
      return true;
    }
  } else if (value.includes("<&&<")) {
    if (quantity > +value?.match(/[0-9]+/g)?.[0] && +value?.match(/[0-9]+/g)?.[1] > quantity) {
      return true;
    }
    // eslint-disable-next-line
  } else if (eval(quantity + value)) {
    return true;
  }

  return false;
}
