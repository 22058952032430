import { REQUEST_URL } from "@Constants";
import { RequestUtil, RequestUtilV2 } from "@Utils";

export const AuthService = {
  signIn,
  verifyOtp,
  retryOtp,
  signUp,
  forgetPassword,
  verifyForgetPassword,
  updatePassword,
  saveTokenDevice,
  deActiveTokenDevice,
  destroyToken,
};

function signIn(data: { user: string; password: string; deviceId: string; platform: string }) {
  const url = REQUEST_URL.LOGIN;
  return RequestUtilV2.post({ url, data });
}

function verifyOtp(data: { otp: string; requestToken: string | null; type: string }) {
  const url = REQUEST_URL.VERIFY_OTP;

  return RequestUtil.post({ url, data });
}

function retryOtp(data: { requestToken: string | null; type: string }) {
  const url = REQUEST_URL.RETRY_OTP;

  return RequestUtil.post({ url, data });
}

function signUp(data: {
  user: string;
  phoneNumber: string;
  fullName: string;
  address: string;
  sources: string[];
  company: string;
  taxId: string;
  province: string;
  district: string;
  ward: string;
  note: string;
}) {
  const url = REQUEST_URL.SIGN_UP;

  return RequestUtil.post({ url, data });
}

function forgetPassword(data: { user: string; platform: string }) {
  const url = REQUEST_URL.FORGET_PASSWORD;
  return RequestUtil.post({ url, data });
}

function verifyForgetPassword(data: {
  otp: string;
  requestToken: string | null;
  deviceId: string;
}) {
  const url = REQUEST_URL.VERIFY_FORGET_PASSWORD;
  return RequestUtil.post({ url, data });
}

function updatePassword(data: {
  password: string;
  requestToken: string | null;
  isEmployee: boolean;
}) {
  const url = REQUEST_URL.UPDATE_PASSWORD;
  return RequestUtil.post({ url, data });
}

function saveTokenDevice(data: {
  type_device: "browser";
  token: string | null;
  meta_data: string;
}) {
  const url = REQUEST_URL.SAVE_TOKEN_DEVICE;
  return RequestUtil.post({ url, data });
}

function deActiveTokenDevice(data: { token: string | null }) {
  const url = REQUEST_URL.DEACTIVE_TOKEN_DEVICE;
  return RequestUtil.post({ url, data });
}

function destroyToken(data: { requestToken: string | null }) {
  return RequestUtil.put({
    url: REQUEST_URL.DESTROY_TOKEN,
    data,
  });
}
