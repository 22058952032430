import { AuthProfileCustomerOutputModel, AuthProfileOutputModel } from "@Models";
import { CustomerService } from "@Services";
import { useEffect, useState } from "react";

const useProfile = (source?: string) => {
  const [profile, setProfile] = useState<AuthProfileOutputModel | null>();

  useEffect(() => {
    const fetchProfile = async () => {
      CustomerService.getAuthProfile()
        .then((res) => {
          if (res.status === 200 || res.status === 204) {
            setProfile(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    fetchProfile();
  }, []);

  const customerBySource = profile?.customer.find(
    (f: AuthProfileCustomerOutputModel) => f.source === source
  );

  return {
    profile,
    customerBySource,
  };
};

export default useProfile;
