const HOUR_MINUTE = "HH:mm";
const HOUR_MINUTE_SECOND = "HH:mm:ss";
const DAY_MONTH_YEAR = "DD/MM/YYYY";
const DAY_MONTH_YEAR_HOUR_MINUTE = "DD/MM/YYYY, HH:mm";
const DAY_MONTH_YEAR_HOUR_MINUTE_SECOND = "DD/MM/YYYY HH:mm:ss";

export const TIME = {
  HOUR_MINUTE,
  HOUR_MINUTE_SECOND,
  DAY_MONTH_YEAR,
  DAY_MONTH_YEAR_HOUR_MINUTE,
  DAY_MONTH_YEAR_HOUR_MINUTE_SECOND,
};
