/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react-hooks/exhaustive-deps */
import Img from "@Assets/images/empty.png";
import ExcelWhite from "@Assets/images/icons/excel_w.png";
import Search from "@Assets/images/icons/img-search.png";
import LetterNull from "@Assets/images/letter-null.png";
import EmptyList from "@Components/EmptyList";
import { Calendar1Icon } from "@Components/Icons/Calendar1";
import { UploadNullIcon } from "@Components/Icons/UploadNullIcon";
import { UploadSuccessIcon } from "@Components/Icons/UploadSuccessIcon";
import Loading from "@Components/Loading";
import { BASE_LINK_IMAGE, Month, TEXT_ASTERISK, TEXT_HOLDER, Year } from "@Constants";
import { Action, DiscountOutputModel, FetchAction, ItemPermission } from "@Models";
import { PaginateFetchReducer } from "@Models/shared";
import { CustomerService, DiscountService } from "@Services";
import { currencyFormat, PermissionUtil } from "@Utils";
import {
  ArrowDropDown as ArrowDropDownIcon,
  Clear as ClearIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import {
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Popover,
  Select,
  TextField,
} from "@mui/material";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useReducer, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import "./index.scss";
import { useTranslation } from "react-i18next";

type P_Props = {
  sourceType: string;
};

function reducer(state: PaginateFetchReducer<DiscountOutputModel>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: [...state.data, ...action.payload.data],
        loading: false,
      };
    case Action.RESET:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload.data,
        loading: false,
      };
    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload };
    default:
      return { ...state };
  }
}

const DiscountLetter: React.FC<P_Props> = ({ sourceType }) => {
  const { t } = useTranslation(["translation"]);
  const mounted = useRef(false);
  const [month, setMonth] = useState<string>(("0" + (new Date().getMonth() + 1)).slice(-2));
  const [year, setYear] = useState<string>(new Date().getFullYear().toString());
  const [time, setTime] = useState<string>(
    ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + new Date().getFullYear()
  );
  const [searchValue, setSearchValue] = useState<string>("");
  const [visibleSearch, setVisibleSearch] = useState<boolean>(false);
  const [detailLetter, setDetailLetter] = useState<any>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const [profile, setProfile] = useState<any>([]);

  const loadProfileData = _.debounce(async () => {
    const response = await CustomerService.getAuthProfile();
    setProfile(response.data);
  }, 100);

  useEffect(() => {
    loadProfileData();
    //eslint-disable-next-line
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSearch = _.debounce((value) => {
    setSearchValue(value);

    value ? setVisibleSearch(true) : setVisibleSearch(false);
  }, 500);

  const [state, dispatch] = useReducer(reducer, {
    page: 0,
    totalPages: 0,
    limit: 20,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
    loading: true,
  });

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const fetchData = async (query: any, isReset: boolean = false) => {
    if (state.fetching) return;
    dispatch({ type: Action.FETCH, payload: {} });
    try {
      const result = await DiscountService.getDiscount(query);
      if (isReset)
        dispatch({
          type: Action.RESET,
          payload: result.data,
        });
      else if (mounted.current)
        dispatch({
          type: Action.SUCCESS,
          payload: result.data,
        });
    } catch (err) {
      if (mounted)
        dispatch({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
    }
  };

  useEffect(() => {
    fetchData(
      {
        page: 1,
        limit: 10,
        searchString: searchValue,
        period: time,
      },
      true
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, time]);

  const hasMore = state.data.length < state.totalRecords && state.page < state.totalPages;

  //permission
  const checkPermissionViewPrice = PermissionUtil.check(
    profile?.role?.permissions,
    ItemPermission.MANAGE_ITEM_VIEW_PRICE,
    true
  );

  return (
    <div className="discount-letter">
      <div className="discount-letter-content">
        <div className="action">
          <div style={{ display: "flex" }}>
            <TextField
              placeholder="Nhập mã, tên khách hàng..."
              variant="outlined"
              className={"search-style"}
              inputProps={{ className: "input" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="large" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                onSearch(e.target.value);
              }}
            />
            <button onClick={handleClick} className="btn-white">
              <div style={{ display: "flex", alignItems: "center" }}>
                <Calendar1Icon color="#272b2f" size={[20, 20]} viewBox={[20, 20]} />
                &emsp;Tháng {month}/{year}
              </div>
              <ArrowDropDownIcon />
            </button>
          </div>
        </div>

        <div className="list">
          <div className="list-left" style={{ width: detailLetter !== null ? "30%" : "100%" }}>
            <div className="list-header">
              <div className="id" style={{ width: detailLetter && "50%" }}>
                No.
              </div>
              {!detailLetter && (
                <>
                  <div className="division">Ngành hàng</div>
                  <div className="time">{t("TIME_UPDATE")}</div>
                </>
              )}
              <div
                className="dis"
                style={{
                  width: detailLetter && "50%",
                  marginRight: detailLetter ? "5rem" : "0",
                }}>
                {t("DISCOUNT")}
              </div>
              {!detailLetter && <div className="pic">Hình ảnh</div>}
            </div>
            {state.loading ? (
              <Loading style={{ margin: "2rem 0", height: "60vh" }} />
            ) : (
              <>
                {state.data ? (
                  state.data.length > 0 ? (
                    <InfiniteScroll
                      useWindow={false}
                      pageStart={0}
                      initialLoad={false}
                      hasMore={hasMore}
                      loadMore={() =>
                        fetchData({
                          limit: 50,
                          page: state.page + 1,
                          searchString: searchValue,
                          period: time,
                        })
                      }
                      loader={state.fetching ? <Loading /> : undefined}>
                      {state.fetching && searchValue && <Loading />}
                      {state.data.map((item: any, index: number) => {
                        return (
                          <div
                            key={index}
                            className="item"
                            onClick={() => {
                              setDetailLetter(item);
                            }}>
                            <div className="id" style={{ width: detailLetter && "45%" }}>
                              {item.code}
                            </div>
                            {!detailLetter && (
                              <>
                                <div className="division">
                                  {item.divisions?.map((s: any) => {
                                    return <span>{s?.description || TEXT_HOLDER},&ensp;</span>;
                                  })}
                                </div>
                                <div className="time">
                                  {moment(item?.updatedAt).format("DD/MM/YYYY - HH:mm")}
                                </div>
                              </>
                            )}
                            <div
                              className="dis"
                              style={{
                                width: detailLetter && "55%",
                                marginRight: detailLetter ? "5rem" : "0",
                              }}>
                              {checkPermissionViewPrice
                                ? currencyFormat(item.amount)
                                : TEXT_ASTERISK}{" "}
                              đ
                            </div>
                            {!detailLetter && (
                              <div className="pic">
                                {item.file ? (
                                  <UploadSuccessIcon
                                    color="#41AD49"
                                    size={[20, 20]}
                                    viewBox={[20, 20]}
                                  />
                                ) : (
                                  <UploadNullIcon
                                    color="#ADACAC"
                                    size={[20, 20]}
                                    viewBox={[20, 20]}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </InfiniteScroll>
                  ) : visibleSearch === true ? (
                    <EmptyList img={Search} title={t("NO_RESULT_FOUND")} />
                  ) : (
                    <EmptyList img={Img} title="Danh sách trống" />
                  )
                ) : (
                  <EmptyList img={Img} title="Danh sách trống" />
                )}
              </>
            )}
          </div>
          {detailLetter && (
            <div className="view">
              <div className="view-header">
                <div className="title">{detailLetter.code || TEXT_HOLDER}</div>
                <div className="view-header-button">
                  {!detailLetter.file ? (
                    <button
                      className="btn-confirm-style"
                      onClick={() => {}}
                      disabled={!detailLetter.file}
                      style={{ opacity: !detailLetter.file ? 0.6 : 1 }}>
                      <img src={ExcelWhite} alt="icon-excel_w" />
                      Xuất file
                    </button>
                  ) : (
                    <a
                      href={BASE_LINK_IMAGE + detailLetter.file}
                      download
                      target="_blank"
                      rel="noreferrer"
                      className="btn-confirm-style">
                      <img src={ExcelWhite} alt="icon-excel_w" />
                      Xuất file
                    </a>
                  )}
                  <button
                    className="btn-white"
                    onClick={(e) => {
                      setDetailLetter(null);
                    }}>
                    <ClearIcon fontSize="large" />
                  </button>
                </div>
              </div>
              <div className="view-image">
                {detailLetter.file ? (
                  <>
                    <iframe
                      style={{ width: "96%", height: "100%" }}
                      src={BASE_LINK_IMAGE + detailLetter.file}
                      title="pdf"
                    />
                  </>
                ) : (
                  <img src={LetterNull} alt="letter" />
                )}
                <br />
                {!detailLetter.file && "Chưa tìm thấy thư CN/CK phù hợp"}
              </div>
            </div>
          )}
        </div>
      </div>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}>
        <div className="popover-date">
          <Grid container spacing={1} style={{ paddingBottom: 17 }} className="popover-date-select">
            <Grid item xs={6}>
              <Select
                value={month}
                onChange={(e) => {
                  setMonth(e.target.value);
                }}
                input={<OutlinedInput />}
                fullWidth
                color="success"
                MenuProps={{
                  className: "select-menu-list popover-date-menu-list",
                  anchorOrigin: {
                    vertical: 40,
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}>
                {Month.map((d: any) => {
                  return (
                    <MenuItem
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                      value={d.month}>
                      {d.month}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <Select
                value={year}
                onChange={(e) => {
                  setYear(e.target.value);
                }}
                input={<OutlinedInput />}
                fullWidth
                color="success"
                MenuProps={{
                  className: "select-menu-list popover-date-menu-list",
                  anchorOrigin: {
                    vertical: 40,
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}>
                {Year.map((y: any) => {
                  return (
                    <MenuItem
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                      value={y.year}>
                      {y.year}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          </Grid>
          <div style={{ display: "flex" }}>
            <button className="btn-cancel-style" onClick={handleClose}>
              {t("CANCEL")}
            </button>
            <button
              className="btn-confirm-style"
              onClick={() => {
                setTime(month + "-" + year);
                handleClose();
              }}>
              {t("CONFIRM")}
            </button>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default DiscountLetter;
