import LeftBottomImage from "@Assets/images/signup/left-bottom.png";
import LeftTopImage from "@Assets/images/signup/left-top.png";
import RightImage from "@Assets/images/signup/right.png";
import Notification from "@Components/Notification";
import { APP_ROUTES, REGEX } from "@Constants";
import { LocationModel } from "@Models";
import { AuthService } from "@Services";
import { FormUtil } from "@Utils";
import {
  CheckRounded as CheckRoundedIcon,
  ClearRounded as ClearRoundedIcon,
} from "@mui/icons-material";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useHistory } from "react-router-dom";
import "./index.scss";
import { useTranslation } from "react-i18next";

const SignUpForm: React.FC = () => {
  const { t } = useTranslation(["translation"]);
  const history = useHistory();
  const [loca, setLoca] = useState<LocationModel[]>([]);
  const [province, setProvince] = useState<string>("");
  const [district, setDistrict] = useState<string>("");
  const [ward, setWard] = useState<string>("");
  const [submitError, setSubmitError] = useState<boolean>(false);
  const [notiSignUp, setNotiSignUp] = useState<{
    visible: boolean;
    newAccount: boolean;
  }>({
    visible: false,
    newAccount: false,
  });
  const [checkedSource, setCheckedSource] = useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof checkedSource>) => {
    const {
      target: { value },
    } = event;
    setCheckedSource(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split("+") : value
    );
  };

  const Source = ["Thức ăn chăn nuôi", "Trang trại & con giống", "Chuỗi cung ứng"];
  const districts = loca.find((city) => city.name === province)?.districts || [];
  const wards = districts.find((d) => d.name === district)?.wards || [];

  useEffect(() => {
    axios
      .get("https://provinces.open-api.vn/api/?depth=3")
      .then((res) => {
        setLoca(res.data);
      })
      .catch((err) => {});
  }, []);

  const switchSource = (source: string) => {
    switch (source) {
      case "Thức ăn chăn nuôi":
        return "feed";
      case "Trang trại & con giống":
        return "farm";
      case "Chuỗi cung ứng":
        return "ccu";
      default:
        return "";
    }
  };
  const submitForm = async (values: any) => {
    try {
      await AuthService.signUp({
        ...values,
        sources: values.sources.map((val: string) => switchSource(val)),
      }).then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          setNotiSignUp({ visible: true, newAccount: true });
        } else {
          // setNotiOrder({ visible: true, newOrder: false });
        }
      });
    } catch (error: any) {
      setNotiSignUp({ visible: true, newAccount: false });
      const errors: Record<string, unknown> = {};
      if (error.message)
        errors.email = errors.phoneNumber =
          "E-mail hoặc Số điện thoại này đã được dùng để đăng ký tài khoản khác";
      return errors;
    }
  };

  return (
    <div className="signup-form">
      <img className="img-left left-top" src={LeftTopImage} alt="leftTop" />
      <img className="img-left left-bottom" src={LeftBottomImage} alt="leftBot" />
      <img className="img-right" src={RightImage} alt="right" />
      <div className="signup-form__content">
        <div className="title">Đăng ký thông tin</div>
        <div className="text-signin">
          Bạn đã có tài khoản?&nbsp;
          <a style={{ color: "#0A6836", cursor: "pointer" }} href={APP_ROUTES.SIGNIN}>
            Đăng nhập
          </a>
        </div>
        <Form initialValuesEqual={() => true} onSubmit={async (values) => submitForm(values)}>
          {({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit} className="signup-form__content-form" id="signup">
                <Grid container spacing={5}>
                  <Grid item xs={6}>
                    <div className="form-item">
                      <div className="form-label">Họ tên</div>
                      <Field
                        name="fullName"
                        validate={FormUtil.Rule.required("Bạn chưa nhập Họ tên")}>
                        {({ input, meta, ...rest }) => {
                          return (
                            <TextField
                              {...input}
                              {...rest}
                              color="success"
                              placeholder={"Nhập Họ tên..."}
                              className={"form-text-field"}
                              inputProps={{ className: "input" }}
                              helperText={meta.touched ? meta.error : ""}
                              error={meta.error && meta.touched}
                            />
                          );
                        }}
                      </Field>
                    </div>
                    <div className="form-item">
                      <div className="form-label">E-mail</div>
                      <Field
                        name="email"
                        validate={FormUtil.composeValidators([
                          FormUtil.Rule.required("Bạn chưa nhập E-mail"),
                          FormUtil.Rule.pattern(REGEX.EMAIL, {
                            errorMessage: "E-mail không đúng định dạng",
                          }),
                        ])}>
                        {({ input, meta, ...rest }) => {
                          return (
                            <TextField
                              {...input}
                              {...rest}
                              color="success"
                              placeholder={"Nhập E-mail..."}
                              className={"form-text-field"}
                              inputProps={{ className: "input" }}
                              helperText={
                                submitError === true ? meta.error || meta.submitError : ""
                              }
                              error={submitError === true ? meta.error || meta.submitError : ""}
                              onChange={(e) => {
                                input.onChange(e.target.value);
                                setSubmitError(true);
                              }}
                              onBlur={(e) => {
                                input.onChange(e.target.value.replace(/\s/g, ""));
                                setSubmitError(true);
                              }}
                            />
                          );
                        }}
                      </Field>
                    </div>
                    <div className="form-item">
                      <div className="form-label">Công ty</div>
                      <Field
                        name="company"
                        validate={FormUtil.Rule.required("Bạn chưa nhập tên Công ty")}>
                        {({ input, meta, ...rest }) => {
                          return (
                            <TextField
                              {...input}
                              {...rest}
                              color="success"
                              placeholder={"Nhập tên Công ty..."}
                              className={"form-text-field"}
                              inputProps={{ className: "input" }}
                              helperText={meta.touched ? meta.error : ""}
                              error={meta.error && meta.touched}
                            />
                          );
                        }}
                      </Field>
                    </div>
                    <div className="form-item">
                      <div className="form-label">Tỉnh/ Thành phố</div>
                      <Field
                        name="province"
                        validate={FormUtil.Rule.required("Bạn chưa chọn Tỉnh/ Thành phố")}>
                        {({ input, meta, ...rest }) => {
                          return (
                            <FormControl>
                              <Select
                                {...input}
                                {...rest}
                                value={province}
                                onChange={(e) => {
                                  input.onChange(e.target.value);
                                  setProvince(e.target.value as string);
                                }}
                                error={meta.error && meta.touched}
                                fullWidth
                                input={
                                  <OutlinedInput className="form-text-field" color="success" />
                                }
                                variant="outlined"
                                renderValue={
                                  input.value !== ""
                                    ? undefined
                                    : () => (
                                        <span className="form__placeholder">
                                          Chọn Tỉnh/ Thành phố
                                        </span>
                                      )
                                }
                                displayEmpty
                                className="form-select-field"
                                MenuProps={{
                                  className: "select-menu-list",
                                }}>
                                {loca.map((p) => {
                                  return (
                                    <MenuItem key={p.code} value={p.name}>
                                      {p.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                              <FormHelperText>{meta.touched ? meta.error : ""}</FormHelperText>
                            </FormControl>
                          );
                        }}
                      </Field>
                    </div>
                    <div className="form-item">
                      <div className="form-label">Phường/ Xã</div>
                      <Field
                        name="ward"
                        validate={FormUtil.Rule.required("Bạn chưa chọn Phường/ Xã")}>
                        {({ input, meta, ...rest }) => {
                          return (
                            <FormControl>
                              <Select
                                {...input}
                                {...rest}
                                value={ward}
                                onChange={(e) => {
                                  input.onChange(e.target.value);
                                  setWard(e.target.value as string);
                                }}
                                error={meta.error && meta.touched}
                                fullWidth
                                input={
                                  <OutlinedInput className="form-text-field" color="success" />
                                }
                                variant="outlined"
                                className="form-select-field"
                                renderValue={
                                  input.value !== ""
                                    ? undefined
                                    : () => (
                                        <span className="form__placeholder">Chọn Phường/ Xã</span>
                                      )
                                }
                                displayEmpty
                                MenuProps={{
                                  className: "select-menu-list",
                                }}>
                                {wards.length &&
                                  wards.map((d) => (
                                    <MenuItem key={d.code} value={d.name}>
                                      {d.name}
                                    </MenuItem>
                                  ))}
                              </Select>
                              <FormHelperText>{meta.touched ? meta.error : ""}</FormHelperText>
                            </FormControl>
                          );
                        }}
                      </Field>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="form-item">
                      <div className="form-label">Số điện thoại</div>
                      <Field
                        name="phoneNumber"
                        validate={FormUtil.composeValidators([
                          FormUtil.Rule.required("Bạn vẫn chưa nhập Số điện thoại"),
                          FormUtil.Rule.pattern(REGEX.PHONE, {
                            errorMessage: "Số điện thoại không đúng định dạng",
                          }),
                        ])}>
                        {({ input, meta, ...rest }) => {
                          return (
                            <TextField
                              {...input}
                              {...rest}
                              color="success"
                              placeholder={"Nhập Số điện thoại..."}
                              className={"form-text-field"}
                              inputProps={{ className: "input" }}
                              helperText={
                                meta.touched
                                  ? meta.error || (submitError === false && meta.submitError)
                                  : ""
                              }
                              error={
                                (meta.error || (submitError === false && meta.submitError)) &&
                                meta.touched
                              }
                              onChange={(e) => {
                                input.onChange(e.target.value);
                                setSubmitError(true);
                              }}
                            />
                          );
                        }}
                      </Field>
                    </div>
                    <div className="form-item">
                      <div className="form-label">Mảng sản phẩm</div>
                      <Field
                        name="sources"
                        validate={FormUtil.Rule.required("Bạn chưa chọn Mảng sản phẩm")}>
                        {({ input, meta, ...rest }) => {
                          return (
                            <FormControl>
                              <Select
                                {...input}
                                {...rest}
                                multiple
                                value={checkedSource}
                                onChange={(e) => {
                                  input.onChange(e.target.value);
                                  handleChange(e);
                                }}
                                input={
                                  <OutlinedInput className="form-text-field" color="success" />
                                }
                                renderValue={(selected) =>
                                  checkedSource.length === 0 ? (
                                    <span className="form__placeholder">Chọn Mảng sản phẩm</span>
                                  ) : (
                                    selected.join(" + ")
                                  )
                                }
                                variant="outlined"
                                className="form-select-field"
                                error={meta.error && meta.touched}
                                displayEmpty
                                MenuProps={{
                                  className: "select-menu-list",
                                }}>
                                {Source.map((name) => (
                                  <MenuItem key={name} value={name} className="checked-item">
                                    <ListItemText primary={name} />
                                    <Checkbox
                                      checked={checkedSource.indexOf(name) > -1}
                                      color="success"
                                      style={{ transform: "scale(1.5)" }}
                                    />
                                  </MenuItem>
                                ))}
                              </Select>
                              <FormHelperText>{meta.touched ? meta.error : ""}</FormHelperText>
                            </FormControl>
                          );
                        }}
                      </Field>
                    </div>
                    <div className="form-item">
                      <div className="form-label">Mã số thuế</div>
                      <Field
                        name="taxId"
                        validate={FormUtil.Rule.required("Bạn vẫn chưa nhập Mã số thuế")}>
                        {({ input, meta, ...rest }) => {
                          return (
                            <TextField
                              {...input}
                              {...rest}
                              color="success"
                              placeholder={"Nhập Mã số thuế"}
                              className={"form-text-field"}
                              inputProps={{ className: "input" }}
                              helperText={meta.touched ? meta.error : ""}
                              error={meta.error && meta.touched}
                            />
                          );
                        }}
                      </Field>
                    </div>
                    <div className="form-item">
                      <div className="form-label">Quận/ Huyện</div>
                      <Field
                        name="district"
                        validate={FormUtil.Rule.required("Bạn chưa chọn Quận/ Huyện")}>
                        {({ input, meta, ...rest }) => {
                          return (
                            <FormControl>
                              <Select
                                {...input}
                                {...rest}
                                value={district}
                                onChange={(e) => {
                                  input.onChange(e.target.value);
                                  setDistrict(e.target.value as string);
                                }}
                                fullWidth
                                error={meta.error && meta.touched}
                                input={
                                  <OutlinedInput className="form-text-field" color="success" />
                                }
                                variant="outlined"
                                className="form-select-field"
                                renderValue={
                                  input.value !== ""
                                    ? undefined
                                    : () => (
                                        <span className="form__placeholder">Chọn Quận/ Huyện</span>
                                      )
                                }
                                displayEmpty
                                MenuProps={{
                                  className: "select-menu-list",
                                }}>
                                {districts.length &&
                                  districts.map((d) => (
                                    <MenuItem key={d.code} value={d.name}>
                                      {d.name}
                                    </MenuItem>
                                  ))}
                              </Select>
                              <FormHelperText>{meta.touched ? meta.error : ""}</FormHelperText>
                            </FormControl>
                          );
                        }}
                      </Field>
                    </div>
                  </Grid>
                </Grid>
                <div className="form-item">
                  <div className="form-label">Địa chỉ</div>
                  <Field name="address" validate={FormUtil.Rule.required("Bạn chưa nhập Địa chỉ")}>
                    {({ input, meta, ...rest }) => {
                      return (
                        <TextField
                          {...input}
                          {...rest}
                          color="success"
                          variant="standard"
                          placeholder={"Nhập địa chỉ..."}
                          className={"form-text-field"}
                          inputProps={{ className: "input" }}
                          helperText={meta.touched ? meta.error : ""}
                          error={meta.error && meta.touched}
                        />
                      );
                    }}
                  </Field>
                </div>
                <div className="form-item">
                  <div className="form-label">{t("NOTES")}</div>
                  <Field name="note">
                    {({ input, meta, ...rest }) => {
                      return (
                        <TextField
                          {...input}
                          {...rest}
                          color="success"
                          variant="standard"
                          placeholder={t("INPUT_BY_NAME", {
                            NAME: t("NOTES"),
                          })}
                          className={"form-text-field"}
                          inputProps={{ className: "input" }}
                          helperText={meta.touched ? meta.error : ""}
                          error={meta.error && meta.touched}
                        />
                      );
                    }}
                  </Field>
                </div>
              </form>
            );
          }}
        </Form>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <button className="btn-confirm-style" type="submit" form="signup">
            Đăng ký
          </button>
        </div>
        <div className="rules">
          Điều khoản sử dụng và bảo mật
          <br />
          Phiên bản 1.2.21 • Bản quyền thuộc về
          <a
            style={{ color: "#0A6836", fontWeight: 700 }}
            rel="noreferrer"
            href={"https://www.greenfeed.com.vn/"}
            target="_blank">
            {" "}
            GREENFEED VIETNAM
          </a>
        </div>
      </div>
      <Notification
        visible={notiSignUp.visible}
        title={
          notiSignUp.newAccount ? "Đăng ký tài khoản thành công!" : "Đăng ký tài khoản thất bại!"
        }
        subTitle={
          notiSignUp.newAccount ? (
            <span>
              Đội ngũ GreenFeed sẽ nhanh chóng liên hệ tư vấn chi tiết các thông tin về sản phẩm và
              tiến trình cung cấp tài khoản. Hotline
              <span style={{ color: "#41ad49" }}> 1900 1099</span>
            </span>
          ) : (
            <span>
              Vui lòng liên hệ hotline<span style={{ color: "#41ad49" }}> 1900 1099 </span>để được
              hỗ trợ trong việc đăng ký tài khoản
            </span>
          )
        }
        icon={
          <div className={`notification-icon-box ${notiSignUp.newAccount ? "green" : "red"}`}>
            {notiSignUp.newAccount ? (
              <CheckRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
            ) : (
              <ClearRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
            )}
          </div>
        }
        actions={
          <button
            className="btn-confirm-style"
            onClick={() => {
              notiSignUp.newAccount
                ? history.push(APP_ROUTES.SIGNIN)
                : setNotiSignUp({ visible: false, newAccount: false });
              setSubmitError(false);
            }}>
            {notiSignUp.newAccount ? " Tìm hiểu thêm về GREENFEED" : t("GO_BACK")}
          </button>
        }
        onClose={() => {
          notiSignUp.newAccount
            ? history.push(APP_ROUTES.SIGNIN)
            : setNotiSignUp({ visible: false, newAccount: false });
        }}
      />
    </div>
  );
};

export default SignUpForm;
