import { REQUEST_URL } from "@Constants";
import { PaginationQueryModel } from "@Models";
import { RequestUtil } from "@Utils";

export const ProductService = {
  filterProducts: (
    query: PaginationQueryModel & {
      divisionId?: string;
      searchValue?: string;
      source: string;
      itemGroupId?: string;
      listDivision?: string;
    }
  ) => {
    const url = REQUEST_URL.PRODUCT;

    return RequestUtil.get({ url, query: query });
  },

  getProductById: (id: string) => {
    return RequestUtil.get({
      url: `${REQUEST_URL.PRODUCT}/${id}`,
    });
  },

  getItemById: (query: { itemIds: any; source: string; customerId: number }) => {
    const url = REQUEST_URL.ITEM_BY_ID;
    return RequestUtil.get({
      url,
      query: query,
    });
  },

  getListProduct: (
    query: PaginationQueryModel & {
      source: string;
      searchValue?: string;
    }
  ) => {
    const url = REQUEST_URL.PRODUCT_LIST;
    return RequestUtil.get({ url, query });
  },

  updateProductInterested: (data: { itemId: number; interested: boolean; source: string }) => {
    const url = REQUEST_URL.PRODUCT_INTERESTED;
    return RequestUtil.post({ url, data });
  },

  getDivisionBySource: (query: { searchValue?: string; source?: string; sources?: string }) => {
    const url = REQUEST_URL.DIVISION;
    return RequestUtil.get({ url, query: query });
  },
};
