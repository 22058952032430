import { BaseEntity, FeedBackModel, SourceType } from ".";

export type OrderOutputModel = {
  id: number;
  code: string;
  address: string;
  source: string;
  note: string;
  status: string;
  outboundStatus: string;
  orderAmount: string;
  totalDiscount: string;
  vat: string;
  requiredDate: string;
  createdAt: string;
  updatedAt: string;
  customer: {
    id: number;
    email: string;
    code: string;
    phoneNumber: string;
    isActive: boolean;
    fullName: string;
    idCard: string | null;
    source: string;
    idIssueDate: string;
    idIssuePlace: string | null;
    businessRegistration: string | null;
    company: string | null;
    contactName: string | null;
    address: string;
    payTerm: string;
    createdAt: string;
    updatedAt: string;
  };
  orderlines: {
    id: number;
    price: string;
    subTotal: string;
    totalDiscount: string;
    vat: string;
    orderQuantity: string;
    expectQuantity: string;
    itemName: string;
    baseUoM: string;
    dimension: string;
    createdAt: string;
    updatedAt: string;
  };
  orders: {
    id: number;
    code: string;
    address: string | null;
    source: string;
    note: string | null;
    status: string;
    orderAmount: string | null;
    totalDiscount: string | null;
    vat: string | null;
    requiredDate: string;
    createdBy: number;
    orderGFId: string | null;
    childOrders: [];
    vehicleNumber: string | null;
    totalPay: string | null;
    weightTotal: string;
    createdAt: string | null;
    updatedAt: string | null;

    requiredType: string;
    isUpdated: string | null;
    subStatus: string;
    reasonStatus: string | null;
    reason: string | null;
    arNumber: string | null;
    totalQuantity: number;
    quantity: number;
    personalVat: string;
    deliveryFee: string;
    commerceDiscount: string;
    preDiscount: string;
    salesOrg: {
      code: string;
      createdAt: string | null;
      description: string;
      id: number;
      source: string;
    };
    itemGroup: {
      id: number;
      code: string;
      name: string;
      source: SourceType;
      image: string | null;
      createdAt: string;
      updatedAt: string;
    };
    sample: {
      id: number;
      code: string;
      name: string;
      source: SourceType;
      createdAt: string;
      updatedAt: string;
    };
    customer: {
      account: {
        avatar: string;
      } | null;
      id: number;
      email: string | null;
      code: string;
      phoneNumber: string | null;
      isActive: boolean;
      fullName: string;
      idCard: string | null;
      source: string;
      idIssueDate: string | null;
      idIssuePlace: string | null;
      businessRegistration: string | null;
      company: string | null;
      contactName: string | null;
      address: string | null;
      payTerm: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      customerDivisions: [
        {
          id: number;
          code: string | null;
          description: string | null;
          deliveryPlant: string | null;
          createdAt: string | null;
          updatedAt: string | null;
          customerDivisionEmployees: {
            id: number;
            position: string | null;
            createdAt: string | null;
            updatedAt: string | null;
          };
        }
      ];
    };
    lead: {
      id: number;
      email: string;
      code: string;
      phoneNumber: string;
      isActive: boolean;
      fullName: string;
      address: string;
      sources: string;
      taxId: string;
      idCard: string;
      idIssueDate: string | null;
      idIssuePlace: string;
      province: string;
      district: string;
      ward: string;
      note: string;
      company: string;
      status: string;
      createdAt: string;
      updatedAt: string;
    };
    orderlines: [
      {
        id: number;
        price: string | null;
        subTotal: string | null;
        totalDiscount: string | null;
        vat: string | null;
        orderQuantity: string | null;
        expectQuantity: string | null;
        itemName: string | null;
        dataSource: string | null;
        saleUnit: string | null;
        baseUoM: string | null;
        dimension: string | null;
        createdAt: string | null;
        updatedAt: string | null;

        actualQuantity: string | null;
        attachValue: string | null;
        attachUnit: string | null;
      }
    ];
    camp: {
      id: number;
      code: string;
      name: string;
      source: SourceType;
      createdAt: string;
      updatedAt: string;
    };
  }[];
  createdBy: {
    id: number;
    createdActor: string;
    name: string;
  };

  totalAmount: string;
  //orderlab
  isProcessing: boolean;
  totalSample: number;
  preDiscount: string;
  commerceDiscount: string;
  totalVat: string;
  personalVat: string;
  totalDeliveryFee: string;
  reasonStatus: string | null;
  reason: string | null;
  createdActor: string;
};

export type Orders = {
  id: number;
  code: string;
  totalAmount: string;
  createdAt: string;
  orders: OrderOutputModel[];
  status: string;
  createdBy: {
    id: number;
    createdActor: string;
    name: string;
  };
};

export enum OrderStatus {
  CREATED = "created",
  VERIFIED = "verified",
  CONFIRMED = "confirmed",
  PROCESSING = "processing",
  HALF_COMPLETED = "halfCompleted",
  CANCELLED = "cancelled",
  COMPLETED = "completed",
  DRAFT = "draft",
}

export type OrderDetailOutputModel = {
  customerId: number;
  creatorId: number;
  salesOrgId: number;
  distributionChannelId: number;
  address: string;
  currency: string;
  requiredDate: string;
  totalDiscount: number;
  vat: number;
  orderAmount: number;
  source: string;
  note: string;
  orderDetails: {
    itemId: number;
    divisionId: number;
    discount: number;
    quantity: number;
    unitPrice: number;
    saleUnit: string;
    baseUoM: string;
    dimension: string;
    subTotal: number;
  };
};

export type OrderDetailModel = BaseEntity & {
  id: number;
  orderType: string;
  status: string;
  code: string;
  totalAmount: string;
  totalVat: string;
  commerceDiscount: string;
  preDiscount: string;
  personalVat: string;
  createdBy: {
    id: number;
    email: null;
    code: string;
    phoneNumber: string;
    isActive: true;
    fullName: string;
    idCard: string;
    source: string;
    idIssueDate: string;
    idIssuePlace: string;
    businessRegistration: null;
    company: null;
    contactName: null;
    address: string;
    payTerm: null;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
  orders: [
    {
      id: number;
      code: string;
      address: string;
      source: string;
      note: null;
      status: string;
      orderAmount: string;
      totalDiscount: string;
      vat: string;
      requiredDate: string;
      createdBy: number;
      orderGFId: null;
      vehicleNumber: string;
      totalPay: string;
      weightTotal: string;
      createdAt: string;
      updatedAt: string;
      orderlines: [
        {
          id: number;
          price: string;
          subTotal: string;
          totalDiscount: string;
          vat: string;
          orderQuantity: string;
          expectQuantity: string;
          itemName: string;
          dataSource: string;
          saleUnit: string;
          baseUoM: string;
          dimension: string;
          createdAt: string;
          updatedAt: string;
          attachValue: string;
          item: {
            id: number;
            code: string;
            name: string;
            shortName: string;
            baseUoM: string;
            note: string;
            saleUnit: string;
            dimension: string;
            materialGroup: string;
            materialGroupName: string;
            source: string;
            createdAt: string;
            updatedAt: string;
            images: [
              {
                id: number;
                picture: string;
                alt: null;
              }
            ];
          };
        }
      ];
      division: {
        id: number;
        code: number;
        description: string;
        source: string;
        createdAt: string;
        updatedAt: string;
      };
    }
  ];
  feedback: FeedBackModel;
};

export type OrderCreateModel = {
  customerId: number;
  creatorId: number;
  salesOrgId: number;
  distributionChannelId: number;
  address: string;
  currency: string;
  requiredDate: string;
  totalDiscount: number;
  vat: number;
  orderAmount: number;
  source: string;
  note: string;
  isDraft: boolean;
  draftId: number | null;
  orderDetails: {
    itemId: number;
    divisionId: number;
    discount: number;
    quantity: number;
    unitPrice: number;
    saleUnit: string;
    baseUoM: string;
    dimension: string;
    subTotal: number;
  };
};

//lab model
export type OrderDetailLabOutputModel = {
  id: number;
  code: string;
  orderType: string;
  status: string;
  isProcessing: boolean;
  totalSample: number;
  shipInfo: string;
  shipPhone: string;
  receivedSampleDate: string;
  sentSampleDate: string;
  totalAmount: string;
  totalDiscount: string;
  preDiscount: string;
  commerceDiscount: string;
  totalVat: string;
  personalVat: string;
  totalDeliveryFee: string;
  reasonStatus: string | null;
  reason: string | null;
  createdBy: {
    role: string;
    fullName: string;
    id: number;
    account: {
      code: string;
      id: number;
      nickname: string;
      avatar: string;
    };
  };
  createdActor: string;
  createdAt: string;
  updatedAt: string;
  orders: OrderElementModel[];
  target: {
    id: number;
    code: string;
    name: string;
    source: SourceType;
    createdAt: string;
    updatedAt: string;
  };
  objective: {
    id: number;
    code: string;
    name: string;
    source: SourceType;
    createdAt: string;
    updatedAt: string;
  };
  history: any[];
  divisions: any[];
  orderLabOutcomeSample: OrderLabOutComeSample[];
  orderLabOutcome: OrderLabOutComeResult[];

  duplicateSample: any; //magic
};

export type OrderElementModel = {
  id: number;
  code: string;
  address: string | null;
  isUpdated: string | null;
  subStatus: string;
  reasonStatus: string | null;
  reason: string | null;
  source: SourceType;
  note: string;
  arNumber: string | null;
  totalQuantity: number;
  quantity: number;
  personalVat: string;
  status: string;
  orderAmount: string;
  totalDiscount: string;
  vat: string;
  deliveryFee: string;
  commerceDiscount: string;
  preDiscount: string;
  requiredDate: string;
  createdBy: number;
  orderGFId: string | null;
  vehicleNumber: string;
  totalPay: string;
  weightTotal: string;
  employees: string | null;
  createdAt: string;
  updatedAt: string;
  orderlines: OrderLine[];
  division: string | null;
  salesOrg: any; //chua biet
  salesOffice: string | null;
  distributionChannel: any | null;
  site: string | null;
  itemGroup: {
    id: number;
    code: string;
    name: string;
    source: SourceType;
    image: null;
    createdAt: string;
    updatedAt: string;
  };
  sample: {
    id: number;
    code: string;
    name: string;
    source: SourceType;
    createdAt: string;
    updatedAt: string;
  };
  camp: {
    id: number;
    code: string;
    name: string;
    source: SourceType;
    createdAt: string;
    updatedAt: string;
  };
  customer: Customer;
  invoice: string | null;
  lead: Customer | null;
  deliveryOrders: number;
  canCancelOrUpdate: boolean;
  orderLabDetails: OrderLabDetail[];
  requestType: {
    id: number;
    code: number;
    description: string;
    source: SourceType;
    createdAt: string;
    updatedAt: string;
  };

  //is update line sample
  updated?: boolean;
};

export type OrderLabOutComeSample = {
  id: number;
  source: string;
  name: string;
  responseDate: string;
  createdAt: string;
  updatedAt: string;
  orderLabOutcomeImages: OrderLabOutcomeImage[];
};
export type OrderLabOutComeResult = {
  id: number;
  source: string;
  description: string;
  responseDate: string;
  sendTime: number;
  createdAt: string;
  updatedAt: string;
  orderLabOutcomeImages: OrderLabOutcomeImage[];
};

export type OrderLabOutcomeImage = {
  id: number;
  code: string;
  data: string;
  createdAt: string;
  updatedAt: string;
};

export type Customer = {
  id: number;
  email: string | null;
  code: string;
  phoneNumber: string;
  isActive: boolean;
  fullName: string;
  idCard: string;
  source: SourceType;
  idIssueDate: string;
  idIssuePlace: string;
  businessRegistration: string;
  company: string | null;
  contactName: string;
  address: string;
  payTerm: string;
  createdAt: string;
  updatedAt: string;
  account: {
    code: string;
    id: number;
    nickname: string;
    avatar: string;
  };
};

export type OrderLine = {
  id: number;
  price: string;
  subTotal: string;
  totalDiscount: string;
  vat: string;
  orderQuantity: string;
  expectQuantity: string | null;
  actualQuantity: string | null;
  itemName: string;
  attachValue: string;
  attachUnit: string;
  dataSource: string;
  saleUnit: string;
  baseUoM: string;
  note: string | null;
  priceType: string | null;
  dimensionToShow: string | null;
  dimension: string;
  createdAt: string;
  updatedAt: string;
  item: Item;
  alterItem: string | null;
  prices: Prices;
};

export type OrderLabDetail = {
  id: number | string;
  source: string;
  age: string;
  sampleCode: string;
  createdAt: string;
  updatedAt: string;
  objectiveDetail: ObjectiveDetail | null;
  mergeSample: string;
};

export type ObjectiveDetail = {
  id: number;
  code: string;
  name: string;
  source: string;
  createdAt: Date;
  updatedAt: Date;
};

export type Item = {
  id: number;
  code: string;
  name: string;
  shortName: string;
  baseUoM: string;
  note: string;
  saleUnit: string;
  dimension: string;
  conditionQuantity: string | null;
  materialGroup: string;
  materialGroupName: string;
  source: SourceType;
  createdAt: string;
  updatedAt: string;
  itemGroup: null | {
    id: number;
    code: string;
    name: string;
    otherName: string;
    source: SourceType;
    image: string;
    codePoint: string;
    createdAt: string;
    updatedAt: string;
  };
  images: null | {
    id: number;
    picture: string;
    alt: string;
  };
  division: {
    id: number;
    code: number;
    description: string;
    source: SourceType;
    createdAt: string;
    updatedAt: string;
  };
};

export type Prices = {
  id: number;
  condition: string;
  dimension: string | null;
  material: string;
  validFrom: string;
  validTo: string;
  value: string;
  additionValue: string;
  baseUoM: string;
  note: string;
  source: SourceType;
  conditionPath: string;
  currency: string;
  priceType: string;
  createdAt: string;
  updatedAt: string;
};

export enum RequestType {
  PRE_ORDER = "PRE_ORDER",
  NOT_PRE_ORDER = "NOT_PRE_ORDER",
}

export type objectiveOutputModel = {
  id: number;
  code: string;
  name: string;
  source: string;
  createdAt: Date;
  updatedAt: Date;
  objectiveDetails?: objectiveOutputModel[];
};

export type OrderImportModel = {
  orderGroupId: number;
  targetId: number;
  objectiveId: number;
  shipPhone: string;
  sentSampleDate: Date;
  receivedSampleDate: Date;
  orderDetails: OrderDetail[];
};

//input
export type OrderInputModel = {
  customerId?: any;
  campId: number;
  salesOrgId: number;
  distributionChannelId: number;
  targetId: number;
  objectiveId: number;
  totalDiscount: number;
  requiredType?: string;
  requestTypeId: number;
  requiredDate: string;
  vat: number;
  orderAmount: number;
  totalSample: number;
  source: SourceType;
  note: string;
  isDraft: boolean;
  draftId: number;

  orderDetails: {
    orderlineDetails: {
      itemId: number;
      quantity: number;
      unitPrice: number;
      subTotal: number;
      divisionId: number;
    }[];
    itemGroupId: number;
    sampleId: number;
    quantity: number;
    totalQuantity: number;
    additionValue: number;
    subTotal: number;
  }[];
};

export type OrderSampleInputModel = {
  orderGroupId: number;
  targetId: number;
  objectiveId: number;
  shipPhone: string;
  shipInfo: string;
  sentSampleDate: string;
  receivedSampleDate: string;
  orderDetails: OrderDetail[];
  isDraft: boolean;
};

export type OrderDetail = {
  orderId: number;
  itemGroupId: number;
  orderSampleDetails: OrderSampleDetail[];
};

export type OrderSampleDetail = {
  objectiveDetail?: objectiveOutputModel;
  objectiveDetailId: number;
  age: string;
  sampleCode: string;
};
