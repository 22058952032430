import { IconBase, IconComponent } from "@Core/Icons";

export const IdIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        d="M2.78571 0C1.25821 0 0 1.2195 0 2.7V15.3C0 16.7805 1.25821 18 2.78571 18H23.2143C24.7418 18 26 16.7805 26 15.3V2.7C26 1.2195 24.7418 0 23.2143 0H2.78571ZM2.78571 1.8H23.2143C23.7399 1.8 24.1429 2.1906 24.1429 2.7V15.3C24.1429 15.8094 23.7399 16.2 23.2143 16.2H2.78571C2.26014 16.2 1.85714 15.8094 1.85714 15.3V2.7C1.85714 2.1906 2.26014 1.8 2.78571 1.8ZM8.35714 3.6C6.31429 3.6 4.64286 5.22 4.64286 7.2C4.64286 8.2017 5.08579 9.1053 5.77479 9.7596C5.14273 10.173 4.62456 10.7299 4.26542 11.3819C3.90629 12.0338 3.71708 12.761 3.71429 13.5H5.57143C5.57143 11.9988 6.80829 10.8 8.35714 10.8C9.906 10.8 11.1429 11.9988 11.1429 13.5H13C12.9972 12.761 12.808 12.0338 12.4489 11.3819C12.0897 10.7299 11.5716 10.173 10.9395 9.7596C11.6285 9.1053 12.0714 8.2026 12.0714 7.2C12.0714 5.22 10.4 3.6 8.35714 3.6ZM14.8571 4.5V6.3H22.2857V4.5H14.8571ZM8.35714 5.4C9.39436 5.4 10.2143 6.1947 10.2143 7.2C10.2143 8.2053 9.39436 9 8.35714 9C7.31993 9 6.5 8.2053 6.5 7.2C6.5 6.1947 7.31993 5.4 8.35714 5.4ZM14.8571 8.1V9.9H22.2857V8.1H14.8571ZM14.8571 11.7V13.5H19.5V11.7H14.8571Z"
        fill={props.color}
      />
    </IconBase>
  );
};
