import { FetchingPaginationDataModel, TLanguage } from "@Models";
import { DEFAULT_FETCHING } from "./default.constant";

export const INITIAL_FETCHING_PAGINATION_DATA: FetchingPaginationDataModel<any> = {
  page: 1,
  totalPages: 1,
  limit: DEFAULT_FETCHING.LIMIT,
  totalRecords: 1,
  data: [],
  loading: false,
};
export const CurrencyOptions: {
  id: string;
  locale: string;
}[] = [
  { id: "VND", locale: "vi-VN" },
  { id: "EUR", locale: "en-IE" },
  { id: "USD", locale: "en-US" },
];

export const Source = [
  { src: "feed", name: "Thức ăn chăn nuôi" },
  { src: "farm", name: "Trang trại & con giống" },
  { src: "ccu", name: "Chuỗi cung ứng" },
];

export const LANGUAGES: { [key in TLanguage]: TLanguage } = {
  en: "en",
  vi: "vi",
  km: "km",
  my: "my",
};
