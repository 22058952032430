import { IconBase, IconComponent } from "@Core/Icons";

export const WeightIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        d="M7 0C6.01393 0 5.2017 0.81223 5.2017 1.7983C5.2017 2.0093 5.24606 2.20831 5.3144 2.39773H2.31724L2.22313 2.88507L0.424832 11.8765L0.40625 11.9323V13.1875H13.5938V11.9323L13.5752 11.8765L11.7769 2.88507L11.6828 2.39773H8.6856C8.7576 2.20595 8.79573 2.00313 8.7983 1.7983C8.7983 0.81223 7.98607 0 7 0ZM7 1.19886C7.33748 1.19886 7.59943 1.46141 7.59943 1.7983C7.59943 2.13578 7.33688 2.39773 7 2.39773C6.66252 2.39773 6.40057 2.13518 6.40057 1.7983C6.40057 1.46082 6.66312 1.19886 7 1.19886ZM3.29072 3.59659H10.7093L12.3949 11.9886H1.60511L3.29072 3.59659Z"
        fill={props.color}
      />
    </IconBase>
  );
};
