import { useDragHorizontal } from "@Hooks/useDragHorizontal";
import { loadCallback, MouseUtil } from "@Utils";
import clsx from "clsx";
import React, { useRef } from "react";
import "./index.scss";

export type P_Props<
  T extends {
    id: string;
    [key: string]: any;
  }
> = {
  className?: string;
  list: T[];
  activeId?: string | any;
  onClick?: (item: T) => void;
  renderBtnLabel: (item: T) => any;
};

function BtnGroup<
  T extends {
    id: string;
    [key: string]: any;
  }
>(props: P_Props<T>) {
  const classes = clsx({ "btn-group": true, [props.className || ""]: Boolean(props.className) });
  const btnGroupRef: React.MutableRefObject<any | null> = useRef(null);
  useDragHorizontal(btnGroupRef);

  return (
    <div ref={btnGroupRef} className={classes}>
      {props.list.map((item, idx) => {
        const isActive = item?.id === props.activeId;
        const itemClasses = clsx({ "btn-group__item": true, active: isActive });

        return (
          <button
            key={item.id + idx}
            {...MouseUtil.validateClick({
              onClick: () => {
                loadCallback(props.onClick, item);
              },
            })}
            {...MouseUtil.validateTouch({
              onTouchEnd: () => {
                loadCallback(props.onClick, item);
              },
            })}
            className={itemClasses}>
            {props.renderBtnLabel(item)}
          </button>
        );
      })}
    </div>
  );
}
export default BtnGroup;
