import { Chip } from "@mui/material";
import React from "react";

type P_Props = {
  label: string;
  background: string;
  uppercase?: boolean;
  avatar?: any;
  width?: string;
};

const ChipCustom: React.FC<P_Props> = (props) => {
  const { label, background, uppercase, avatar, width } = props;
  return (
    <Chip
      label={label}
      sx={{
        height: 32,
        "& .MuiChip-label": {
          textTransform: uppercase ? "uppercase" : "none",
          fontSize: uppercase ? 11 : 14,
          color: "#fff",
          fontWeight: 700,
          fontFamily: "SVN-Gotham",
          paddingLeft: avatar ? 0 : "12px",
        },
      }}
      style={{ background: background, width: width ? width : "fit-content" }}
      avatar={avatar}
    />
  );
};

export default ChipCustom;
