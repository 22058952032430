/* eslint-disable react-hooks/exhaustive-deps */
//import { error } from "console";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
//import { divide } from "lodash";
import Logo from "@Assets/images/gf-logo.png";
import LeftImage from "@Assets/images/signin/left-login.png";
import RightImage from "@Assets/images/signin/right-login.png";
import { APP_ROUTES, BASE_ADMIN_URL } from "@Constants";
import { AuthService } from "@Services";
import { RequestUtil, loadCallback } from "@Utils";
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import OtpInput from "react-otp-input";
import { useHistory, useLocation } from "react-router-dom";
import "./OTPInput.scss";

const OTPInput: React.FC = () => {
  const phone = localStorage.getItem("phoneNumber");
  const [checkOtp, setCheckOtp] = useState<boolean>(false);
  const [notice, setNotice] = useState<boolean>(false);
  const [seconds, setSeconds] = useState<number>(60);
  const location = useLocation<any>();

  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();
  let submit: any;
  let retry: any;

  const submitForm = React.useCallback(async (values) => {
    setCheckOtp(false);
    try {
      await AuthService.verifyOtp({
        otp: values.otp,
        requestToken: localStorage.getItem("requestToken"),
        type: "login",
      })
        .then((res) => {
          if (res && (res.status === 200 || res.status === 201)) {
            RequestUtil.updateHeader();
            setCheckOtp(false);
            setNotice(false);
            if (res.data.isEmployee === true) {
              window.location.href = `${BASE_ADMIN_URL + `/redirect/${res.data.token}`}`;
            } else {
              localStorage.setItem("accessToken", res.data.token);
              history.push(APP_ROUTES.HOME);
              localStorage.removeItem("requestToken");
            }
          }
        })
        .catch((err: AxiosError) => {
          if (err.response?.status === 400) {
            setCheckOtp(true);
          } else if (err.response?.status === 401) {
            setCheckOtp(true);
          } else if (err.response?.status === 408) {
            history.push(APP_ROUTES.SIGNIN);
          } else if (err.response?.status === 410) {
            enqueueSnackbar("OTP hết hạn", {
              variant: "error",
            });
          } else {
            setCheckOtp(true);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const forgetPw = React.useCallback(async (values) => {
    const fpPromise = FingerprintJS.load();
    // Get the visitor identifier when you need it.
    const fp = await fpPromise;
    const result = await fp.get();

    // This is the visitor identifier (deviceId):
    const visitorId = result.visitorId;

    setCheckOtp(false);

    try {
      await AuthService.verifyForgetPassword({
        otp: values.otp,
        requestToken: localStorage.getItem("requestToken"),
        deviceId: visitorId,
      })
        .then((res) => {
          if (res && (res.status === 200 || res.status === 201)) {
            setCheckOtp(false);
            setNotice(false);
            history.push({
              pathname: APP_ROUTES.RESETPASSWORD,
              state: { user: location?.state.user },
            });
            localStorage.setItem("requestToken", res.data.token);
          } else {
            //do something
          }
        })
        .catch((err: AxiosError) => {
          if (err.response?.status === 400) {
            setCheckOtp(true);
          } else if (err.response?.status === 401) {
            setCheckOtp(true);
          } else if (err.response?.status === 408) {
            history.push(APP_ROUTES.SIGNIN);
          } else if (err.response?.status === 404) {
          } else {
            setCheckOtp(true);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const type = location?.state?.pathUrl === APP_ROUTES.RESETPASSWORD;

  const retryOtp = React.useCallback((values) => {
    AuthService.retryOtp({
      requestToken: localStorage.getItem("requestToken"),
      type: "forgot",
    })
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          setNotice(true);
        } else {
          //do something
        }
      })
      .catch((err: AxiosError) => {
        if (err.response?.data.message === "EXCEEDING_THE_LIMIT") {
          history.push(APP_ROUTES.IS_BLOCKED);
        } else if (err.response?.status === 401) {
          history.push(APP_ROUTES.SIGNIN);
        } else if (err.response?.status === 404) {
          history.push(APP_ROUTES.SIGNIN);
        }
      });
  }, []);

  const waitting = () => {};

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
    }
  });

  return (
    <div className="otp-form">
      <img className="img-left" src={LeftImage} alt="left" />
      <div className="otp-form__content">
        <div className="otp-form__logo">
          {notice && <div className="noti">Mã xác thực đã được gửi lại. Vui lòng kiểm tra</div>}
          <img style={{ width: "20rem" }} src={Logo} alt="greenfeed-logo" />
        </div>
        <div className="title">Nhập mã xác thực OTP</div>
        <div className="description">Vui lòng điền mã xác thực đã được gửi tới số {phone}</div>
        <Form
          initialValuesEqual={() => true}
          onSubmit={type ? forgetPw : submitForm}
          render={({ handleSubmit }) => {
            submit = handleSubmit;
            return (
              <>
                <Field name="otp">
                  {({ input, meta, ...rest }) => {
                    return (
                      <React.Fragment>
                        <OtpInput
                          {...input}
                          {...rest}
                          numInputs={6}
                          isInputNum={true}
                          containerStyle={{ justifyContent: "center" }}
                          focusStyle={{ border: "3px solid #0a6836" }}
                          inputStyle={{
                            width: "5rem",
                            height: "6rem",
                            margin: "2rem 1rem",
                            fontSize: "2rem",
                            borderRadius: 5,
                            border: checkOtp && "1px solid #E93C3C ",
                          }}
                        />
                        {checkOtp ? (
                          <div className="otp-form__wrong">Mã xác thực không đúng</div>
                        ) : (
                          <div>&nbsp;</div>
                        )}
                      </React.Fragment>
                    );
                  }}
                </Field>
                <Form
                  initialValuesEqual={() => true}
                  onSubmit={seconds === 0 ? retryOtp : waitting}
                  render={({ handleSubmit }) => {
                    retry = handleSubmit;
                    return (
                      <div
                        className={seconds > 0 ? "resend-inactive" : "otp-form__resend"}
                        onClick={(e) => {
                          e.preventDefault();
                          loadCallback(retry, e);
                          seconds === 0 && setSeconds(60);
                        }}>
                        <a href="/">Gửi lại mã xác thực {seconds !== 0 ? <>({seconds})</> : ""}</a>
                      </div>
                    );
                  }}></Form>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <button
                    type="submit"
                    className="otp-form__submit-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      loadCallback(submit, e);
                    }}>
                    Hoàn tất
                  </button>
                </div>
              </>
            );
          }}></Form>

        <div className="otp-form__text">
          Điều khoản sử dụng và bảo mật
          <br />
          Phiên bản 1.2.21 • Bản quyền thuộc về
          <a
            style={{ color: "#0A6836", fontWeight: 700 }}
            rel="noreferrer"
            href={"https://www.greenfeed.com.vn/"}
            target="_blank">
            {" "}
            GREENFEED VIETNAM
          </a>
        </div>
      </div>
      <img className="img-right" src={RightImage} alt="right" />
    </div>
  );
};

export default OTPInput;
