import { IconBase, IconComponent } from "@Core/Icons";

export const ChangePasswordIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        d="M13.5499 16.53H15.1185M20.6087 13.3928V11.8241C20.6087 11.4081 20.4434 11.0091 20.1492 10.715C19.8551 10.4208 19.4561 10.2555 19.0401 10.2555H3.35378C2.93776 10.2555 2.53877 10.4208 2.2446 10.715C1.95042 11.0091 1.78516 11.4081 1.78516 11.8241V21.2359C1.78516 21.6519 1.95042 22.0509 2.2446 22.3451C2.53877 22.6393 2.93776 22.8045 3.35378 22.8045H19.0401C19.4561 22.8045 19.8551 22.6393 20.1492 22.3451C20.4434 22.0509 20.6087 21.6519 20.6087 21.2359V19.6673V13.3928ZM20.6087 13.3928H14.3342C13.5021 13.3928 12.7041 13.7233 12.1158 14.3116C11.5275 14.9 11.1969 15.698 11.1969 16.53C11.1969 17.3621 11.5275 18.16 12.1158 18.7484C12.7041 19.3367 13.5021 19.6673 14.3342 19.6673H20.6087V13.3928ZM20.6087 13.3928C21.4407 13.3928 22.2387 13.7233 22.8271 14.3116C23.4154 14.9 23.7459 15.698 23.7459 16.53C23.7459 17.3621 23.4154 18.16 22.8271 18.7484C22.2387 19.3367 21.4407 19.6673 20.6087 19.6673V13.3928ZM6.49104 10.2555V5.54963C6.49104 4.30155 6.98683 3.10459 7.86936 2.22207C8.75188 1.33955 9.94884 0.84375 11.1969 0.84375C12.445 0.84375 13.642 1.33955 14.5245 2.22207C15.407 3.10459 15.9028 4.30155 15.9028 5.54963V10.2555H6.49104ZM19.8244 16.53H21.393H19.8244ZM16.6871 16.53H18.2557H16.6871Z"
        stroke={props.color}
        strokeWidth="1.66667"
      />
    </IconBase>
  );
};
