import { IconBase, IconComponent } from "@Core/Icons";

export const PlanIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        d="M9.15234 14.1387C9.05469 14.2363 8.85938 14.2363 8.76172 14.1387L6.95508 12.2832C6.85742 12.1855 6.85742 12.0391 6.95508 11.9414L7.58984 11.3066C7.6875 11.209 7.83398 11.209 7.98047 11.3066L8.95703 12.332L11.3008 10.0371C11.3984 9.93945 11.5449 9.93945 11.6426 10.0371L12.2773 10.6719C12.375 10.7695 12.375 10.916 12.2773 11.0137L9.15234 14.1387ZM19.4062 3.25C20.6758 3.25 21.75 4.32422 21.75 5.59375V22.7812C21.75 24.0996 20.6758 25.125 19.4062 25.125H5.34375C4.02539 25.125 3 24.0996 3 22.7812V5.59375C3 4.32422 4.02539 3.25 5.34375 3.25H9.25C9.25 1.54102 10.6172 0.125 12.375 0.125C14.084 0.125 15.5 1.54102 15.5 3.25H19.4062ZM12.375 2.46875C11.9355 2.46875 11.5938 2.85938 11.5938 3.25C11.5938 3.68945 11.9355 4.03125 12.375 4.03125C12.7656 4.03125 13.1562 3.68945 13.1562 3.25C13.1562 2.85938 12.7656 2.46875 12.375 2.46875ZM19.4062 22.3906V5.98438C19.4062 5.78906 19.2109 5.59375 19.0156 5.59375H17.0625V7.15625C17.0625 7.5957 16.6719 7.9375 16.2812 7.9375H8.46875C8.0293 7.9375 7.6875 7.5957 7.6875 7.15625V5.59375H5.73438C5.49023 5.59375 5.34375 5.78906 5.34375 5.98438V22.3906C5.34375 22.6348 5.49023 22.7812 5.73438 22.7812H19.0156C19.2109 22.7812 19.4062 22.6348 19.4062 22.3906ZM8.46875 16.1406C9.10352 16.1406 9.64062 16.6777 9.64062 17.3125C9.64062 17.9961 9.10352 18.4844 8.46875 18.4844C7.78516 18.4844 7.29688 17.9961 7.29688 17.3125C7.29688 16.6777 7.78516 16.1406 8.46875 16.1406ZM16.6719 11.8438C16.8672 11.8438 17.0625 12.0391 17.0625 12.2344V13.0156C17.0625 13.2598 16.8672 13.4062 16.6719 13.4062H12.082L13.4004 12.1367C13.4492 12.0391 13.498 11.9414 13.5469 11.8438H16.6719ZM16.6719 16.5312C16.8672 16.5312 17.0625 16.7266 17.0625 16.9219V17.7031C17.0625 17.9473 16.8672 18.0938 16.6719 18.0938H11.2031C10.959 18.0938 10.8125 17.9473 10.8125 17.7031V16.9219C10.8125 16.7266 10.959 16.5312 11.2031 16.5312H16.6719Z"
        fill={props.color}
      />
    </IconBase>
  );
};
