import React from "react";
import LeftBottomImage from "@Assets/images/signup/left-bottom.png";
import LeftTopImage from "@Assets/images/signup/left-top.png";
import RightImage from "@Assets/images/signup/right.png";
import CommingSoonImg from "@Assets/images/coming.png";
import ErrorImg from "@Assets/images/error.png";
import "./index.scss";

export type Props = {
  error?: boolean;
  closeForm: () => void;
};

const ComingSoon: React.FC<Props> = ({ error, closeForm }) => {
  return (
    <div className="coming-soon">
      <div className="coming-soon__left">
        <img className="img-left" src={LeftTopImage} alt="leftTop" />
        <img className="img-left" src={LeftBottomImage} alt="leftBot" />
      </div>
      <div className="coming-soon__content">
        <div className="title">{error ? "Lỗi hệ thống" : "Tính năng vẫn đang được cập nhật"}</div>
        <div className="des">
          {error
            ? "Xin lỗi quý khách, kết nối đến hệ thống tạm thời bị gián đoạn. Xin vui lòng thử lại sau."
            : "Chúng tôi đang nỗ  lực để ra mắt tính năng này"}
        </div>

        <img
          style={{ width: "48.8rem", height: "26rem" }}
          src={error ? ErrorImg : CommingSoonImg}
          alt=""
        />
        <button type="submit" className="btn-confirm-style" onClick={closeForm}>
          Quay lại trang chủ
        </button>
        <div className="rules">
          Điều khoản sử dụng và bảo mật
          <br />
          Phiên bản 1.2.21 • Bản quyền thuộc về
          <a
            style={{ color: "#0A6836", fontWeight: 700 }}
            rel="noreferrer"
            href={"https://www.greenfeed.com.vn/"}
            target="_blank">
            {" "}
            GREENFEED VIETNAM
          </a>
        </div>
      </div>
      <img className="img-right" src={RightImage} alt="right" />
    </div>
  );
};

export default ComingSoon;
