import { EventIcon } from "@Components/Icons/EventIcon";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { FeedBackInputModel, FeedBackModel } from "@Models";
import { Dialog, Rating, TextField } from "@mui/material";
import { FeedBackService } from "@Services";
import { FormUIUtil } from "@Utils";
import { vi } from "date-fns/locale";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import "./index.scss";

type P_Props = {
  closeForm: () => void;
  data?: FeedBackModel;
};

const FeedBackModal: React.FC<P_Props> = ({ closeForm, data }) => {
  const { t } = useTranslation(["translation"]);
  const [rating, setRating] = React.useState<number | null>(5);
  const { orderId }: { orderId: string } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (data) {
      setRating(data.rating);
    }
  }, [data]);

  const submitForm = async (values: any) => {
    let val = {
      ...values,
      rating: !rating ? 5 : rating,
      nextOrderDate: new Date(values.nextOrderDate)?.toISOString(),
    };

    if (!data) {
      FeedBackService.createFeedBack(orderId, val as FeedBackInputModel)
        .then(async (res) => {
          if (res && (res.status === 200 || res.status === 201)) {
            enqueueSnackbar("Phản hồi được gửi thành công!", {
              variant: "success",
            });
            closeForm();
          }
        })
        .catch(() => {
          enqueueSnackbar("Phản hồi gửi thất bại", {
            variant: "error",
          });
        });
    } else {
      FeedBackService.updateFeedBack(orderId, data.id.toString(), val as FeedBackInputModel)
        .then(async (res) => {
          if (res && (res.status === 200 || res.status === 201)) {
            enqueueSnackbar("Chỉnh sửa phản hồi thành công!", {
              variant: "success",
            });
            closeForm();
          }
        })
        .catch(() => {
          enqueueSnackbar("Chỉnh sửa phản hồi thất bại", {
            variant: "error",
          });
        });
    }
  };

  return (
    <Dialog open={true} maxWidth="md">
      <div className="feed-back-modal">
        <div className="feed-back-modal__title">Phản hồi đơn hàng</div>
        <div className="feed-back-modal__content">
          <div className="feed-back-modal__rating">
            <div>Mức độ hài lòng:</div>
            <Rating
              value={rating}
              onChange={(event, newValue) => {
                setRating(newValue);
              }}
              style={{ marginLeft: 40, fontSize: 32 }}
            />
          </div>
          <Form
            onSubmit={submitForm}
            initialValues={
              data && {
                ...data,
                nextOrderDate: moment(data.nextOrderDate).format("YYYY-MM-DD"),
              }
            }
            render={({ handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit} className="feed-back-modal__form">
                  <div className="feed-back-modal__box">
                    {FormUIUtil.renderFormItem(
                      "Ngày đặt hàng kế tiếp",
                      <Field
                        name="nextOrderDate"
                        // validate={FormUtil.Rule.required("Nhập ngày đặt hàng kế tiếp")}
                      >
                        {({ input, meta, ...rest }) => {
                          return (
                            <MuiPickersUtilsProvider locale={vi} utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                okLabel={t("SELECT")}
                                cancelLabel={t("CANCEL")}
                                fullWidth
                                disablePast
                                disableToolbar
                                InputProps={{ readOnly: true }}
                                inputVariant="outlined"
                                format="dd-MM-yyyy"
                                placeholder="Chọn ngày đặt hàng"
                                className="form-text-field date"
                                value={input.value}
                                onChange={(value) => {
                                  if (value) {
                                    input.onChange(value);
                                  }
                                }}
                                inputProps={{ className: "input" }}
                                keyboardIcon={
                                  <EventIcon color="#41AD49" size={[22, 22]} viewBox={[22, 22]} />
                                }
                                helperText={meta.touched ? meta.error : ""}
                                error={meta.error && meta.touched}
                              />
                            </MuiPickersUtilsProvider>
                          );
                        }}
                      </Field>
                    )}
                    <br />
                    <br />
                    {FormUIUtil.renderFormItem(
                      "Mô tả",
                      <Field name="description">
                        {({ input, meta, ...rest }) => {
                          return (
                            <TextField
                              {...input}
                              {...rest}
                              placeholder={"Nhập... (tối đa 500 ký tự)"}
                              color="success"
                              className="form-textarea-field"
                              inputProps={{ className: "input" }}
                              helperText={meta.touched ? meta.error : ""}
                              error={meta.error && meta.touched}
                              rows={7}
                              multiline
                              fullWidth
                            />
                          );
                        }}
                      </Field>
                    )}
                  </div>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div
                      className="btn-border-confirm-style"
                      onClick={closeForm}
                      style={{ width: 180 }}>
                      {t("CANCEL")}
                    </div>
                    <button
                      onClick={() => {}}
                      type="submit"
                      className="btn-confirm-style"
                      style={{ marginLeft: 16, width: 180 }}>
                      Gửi
                    </button>
                  </div>
                </form>
              );
            }}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default FeedBackModal;
