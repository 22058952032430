import ImageUpload from "@Components/ImageUpload";
import Loading from "@Components/Loading";
import {
  Action,
  AuthCustomerProfileOutputModel,
  FetchAction,
  FetchReducer,
  FormUploadImageModel,
  UploadedImageModel,
} from "@Models";
import { CustomerService } from "@Services";
import { checkLinkImage } from "@Utils";
import React, { useEffect, useReducer, useState } from "react";
import { Form } from "react-final-form";
import { v4 as uuidv4 } from "uuid";
import LeftNavigation from "../LeftNavigation";
import "./Signature.scss";

function reducer(state: FetchReducer<AuthCustomerProfileOutputModel>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        fetching: false,
        data: action.payload,
      };
    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload };
    default:
      return { ...state };
  }
}
const Signature: React.FC = () => {
  const [visibleBtn, setVisibleBtn] = useState<boolean>(false);
  const [state, dispatch] = useReducer(reducer, {
    data: [],
    fetching: false,
    failMessage: "",
  });

  useEffect(() => {
    let cancel = false;
    const fetchData = async () => {
      dispatch({ type: Action.FETCH, payload: {} });
      try {
        const result = await CustomerService.getAuthProfile();
        if (!cancel)
          dispatch({
            type: Action.SUCCESS,
            payload: result.data,
          });
      } catch (err) {
        if (!cancel)
          dispatch({
            type: Action.FAILED,
            payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
          });
      }
    };

    fetchData();

    return () => {
      cancel = true;
    };
  }, []);

  const [selectImage, setSelectImage] = useState<Array<FormUploadImageModel & { file: any }>>([]);
  useEffect(() => {
    setSelectImage(
      state.data.signature
        ? [
            {
              id: "",
              src: `${checkLinkImage(state.data?.signature)}`,
              alt: "",
              file: null,
            },
          ]
        : []
    );
  }, [state.data]);

  const uploadSignature = async () => {
    try {
      const formData = new FormData();

      formData.append("image", selectImage[0].file);

      await CustomerService.uploadImage("signature", formData);
      window.location.reload();
    } catch (error) {}
  };

  return (
    <div className="cus-signature">
      <div className="left">
        <LeftNavigation />
      </div>
      {state.data.customer ? (
        <div className="right">
          <Form onSubmit={async () => uploadSignature()}>
            {({ handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className="signature">
                    <div className="title">Chữ ký</div>
                    <ImageUpload
                      className="card"
                      title="Chữ ký"
                      max={1}
                      fileList={selectImage}
                      onChange={(data: UploadedImageModel) => {
                        setSelectImage([
                          {
                            id: uuidv4(),
                            src: data.fileStr,
                            alt: data.alt || "",
                            file: data.file,
                          },
                        ]);
                        setVisibleBtn(false);
                      }}
                      onDelete={() => {
                        setSelectImage([]);
                        setVisibleBtn(true);
                      }}
                      config={{ uploadModal: true }}
                    />
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                      <button
                        className={!visibleBtn ? "btn-confirm-style " : "hide"}
                        onClick={(e) => {
                          e.preventDefault();
                          uploadSignature();
                        }}>
                        Lưu
                      </button>
                    </div>
                  </div>
                </form>
              );
            }}
          </Form>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};
export default Signature;
