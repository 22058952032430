/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import clsx from "clsx";
import { Remove as RemoveIcon, Add as AddIcon } from "@mui/icons-material";
import "./index.scss";
import { isNumberConvertable, upToThreeDigits } from "@Utils/validator";
import { preventCharacter } from "@Utils";

type P_Props = {
  className?: string;
  disableMinusBtn?: boolean;
  disablePlusBtn?: boolean;
  quantity: number;
  onChangeQuantity: (value: number) => void;
  style?: React.CSSProperties;
};

const QuantityBtnGroup: React.FC<P_Props> = (props) => {
  const [value, setValue] = React.useState<string>(props.quantity.toString());
  const classes = clsx({
    "quantity-btn-group": true,
    [props?.className || ""]: Boolean(props?.className),
  });

  React.useEffect(() => {
    if (value) props.onChangeQuantity(parseFloat(value));
  }, [value]);

  React.useEffect(() => {
    setValue("" + props.quantity);
  }, [props.quantity]);

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isNumberConvertable(e.target.value) && upToThreeDigits(parseFloat(e.target.value))) {
      setValue((oldValue) => (oldValue !== e.target.value ? e.target.value : oldValue));
    } else if (e.target.value === "") {
      setValue("");
    }
  };

  return (
    <div className={classes} style={props.style}>
      <button
        disabled={props.disableMinusBtn}
        className={`quantity-btn-group__btn minus ${props.disableMinusBtn ? "disabled" : ""}`}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          const newValue = parseFloat(value) - 1;
          setValue(newValue.toString());
        }}>
        <RemoveIcon className="icon" style={{ fontSize: "1.8rem", color: "#fff" }} />
      </button>
      <input
        className="quantity-btn-group__value"
        value={value}
        maxLength={5}
        // onChange={(e) => {
        //   const reg = REGEX.NUMBER_ONLY;
        //   if (!isNaN(value as any) && reg.test(e.target.value)) {
        //     props.onChangeQuantity(Number(e.target.value));
        //   }
        // }}
        onFocus={(e) => {
          e.target.select();
        }}
        onBlur={(e) => {
          if (!e.target.value || value === "0") setValue("1");
          else if (/[^0-9]/g.test(e.target.value)) setValue(e.target.value.replace(/[^0-9]/g, ""));
        }}
        onChange={handleChangeValue}
        onKeyDown={preventCharacter}
      />
      <button
        disabled={props.disablePlusBtn}
        className={`quantity-btn-group__btn plus ${props.disablePlusBtn ? "disabled" : ""}`}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          const newValue = parseFloat(value) + 1;
          setValue(newValue.toString());
        }}>
        <AddIcon className="icon" style={{ fontSize: "1.8rem", color: "#fff" }} />
      </button>
    </div>
  );
};

export default QuantityBtnGroup;
