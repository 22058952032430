/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react-hooks/exhaustive-deps */
import Loading from "@Components/Loading";
import useProductSelect from "@Hooks/useProductSelect";
import { DetailItemPlanOutputModel, ItemsPlanOutputModel } from "@Models";
import {
  ArrowDropDown as ArrowDropDownIcon,
  Clear as ClearIcon,
  DeleteOutlineOutlined as DeleteOutlineOutlinedIcon,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Dialog,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import { PlanService } from "@Services/plan.service";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import "./index.scss";
import { preventCharacter } from "@Utils";

type P_Props = {
  detail: DetailItemPlanOutputModel;
  onClose: () => void;
  onVisible: () => void;
};

const OnlyOrder: React.FC<P_Props> = (props) => {
  const { detail, onClose, onVisible } = props;
  const dataProduct = useProductSelect();
  const { enqueueSnackbar } = useSnackbar();

  const detailItems = detail?.items?.map((item: ItemsPlanOutputModel) => {
    return {
      itemId: item.item.id,
      quantity: +item.quantity,
      name: item.item.name,
      code: item.item.code,
      divisionId: item.division.id,
      unitPrice: item.unitPrice,
      saleUnit: item.saleUnit,
      dimension: +item.dimension,
      baseUoM: item.baseUoM,
      subTotal: +item.subTotal,
    };
  });

  const [defaultData, setDefaultData] = useState<any>(detailItems);

  let productSelect = (defaultData && defaultData?.map((pro: any) => pro.itemId)) || [];

  const onUpdateProduct = (index: number, name?: string, value?: any) => {
    const values = [...defaultData];

    if (name) {
      values[index][name] = value;

      if (name === "itemId") {
        let product = dataProduct?.data?.find((f) => f.item.id === value);

        values[index].divisionId = product?.item.division.id;
        values[index].unitPrice = product?.value;
        values[index].saleUnit = product?.baseUoM;
        values[index].dimension = Number(product?.item.dimension) * values[index].quantity;
        values[index].name = product?.item.name;
        values[index].baseUoM = product?.item.baseUoM;

        if (product?.value) values[index].subTotal = product?.value * values[index].quantity;
      }
    } else {
      values.splice(index, 1);
    }

    setDefaultData(values);
  };

  const submitForm = async () => {
    const val = {
      date: detail.date,
      label: detail.label,
      items: defaultData,
    };

    PlanService.updatePlanDetailById(detail?.id?.toString(), val)
      .then(async (res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          enqueueSnackbar("Chỉnh sửa kế hoạch thành công!", {
            variant: "success",
          });
          onVisible();
          onClose();
        }
      })
      .catch(() => {
        enqueueSnackbar("Chỉnh sửa kế hoạch thất bại", {
          variant: "error",
        });
      });
  };

  return (
    <Dialog fullWidth maxWidth="md" open={true}>
      <div className="dialog-update-plan-detail">
        <div className="dialog-update-plan-detail__title">
          <div>Chỉnh sửa {detail.label}</div>
          <div onClick={onClose}>
            <ClearIcon style={{ fontSize: "3.6rem", cursor: "pointer" }} />
          </div>
        </div>
        <div className="dialog-update-plan-detail__item">
          <Accordion className="order-plan" defaultExpanded>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="panel1-content"
              id="panel1-header">
              <div>{detail.label}</div>
            </AccordionSummary>
            <AccordionDetails className="order-plan__grid">
              <Grid container spacing={3} className="order-plan__grid__label">
                <Grid item xs={6} style={{ marginBottom: 12 }}>
                  Sản phẩm
                </Grid>
                <Grid item xs={6} style={{ marginBottom: 12 }}>
                  Sản lượng
                </Grid>
              </Grid>
              {defaultData?.map((val: any, idx: number) => (
                <Grid container key={val.itemId} spacing={3}>
                  <Grid item xs={6} style={{ marginBottom: 12 }}>
                    <Autocomplete
                      value={val.name}
                      onChange={(event, newValue) => {
                        let itemId = dataProduct?.data?.find((f) => f.item.name === newValue)?.item
                          .id;
                        onUpdateProduct(idx, "itemId", itemId);
                      }}
                      disableClearable
                      clearIcon={false}
                      options={dataProduct?.data
                        ?.filter((f) => !productSelect?.includes(f.item.id))
                        ?.map((option) => option.item.name)}
                      noOptionsText={
                        dataProduct?.data?.filter((f) => !productSelect?.includes(f.item.id))
                          .length === 0 ? (
                          "Không có dữ liệu"
                        ) : (
                          <Loading />
                        )
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            className: "input",
                          }}
                          placeholder="Chọn sản phẩm"
                          variant="outlined"
                          className="form-text-field"
                        />
                      )}
                      ListboxProps={{
                        style: {
                          maxHeight: "200px",
                          overflow: "auto",
                          fontFamily: "SVN-Gotham",
                          fontSize: "14px",
                        },
                      }}
                      className="autocomplete-field"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    container
                    style={{ marginBottom: 12 }}
                    justifyContent={"space-between"}
                    alignItems={"center"}>
                    <TextField
                      placeholder="Nhập sản lượng"
                      color="success"
                      className="form-text-field"
                      inputProps={{ maxLength: 5, className: "input" }}
                      defaultValue={val.quantity}
                      onBlur={(e) => {
                        if (!e.target.value || val.quantity <= 0)
                          onUpdateProduct(idx, "quantity", 1);
                        else onUpdateProduct(idx, "quantity", +e.target.value);
                      }}
                      style={{ width: "94%" }}
                      onKeyDown={preventCharacter}
                    />
                    {defaultData?.length > 1 && (
                      <div onClick={() => onUpdateProduct(idx)}>
                        <DeleteOutlineOutlinedIcon
                          style={{ fontSize: 20, color: "#5A5959", cursor: "pointer" }}
                        />
                      </div>
                    )}
                  </Grid>
                </Grid>
              ))}

              <Divider />
              <div className="order-plan__totalRow">
                <div>Tổng sản lượng theo ngày</div>
                <div className="total">
                  {defaultData?.reduce((sum: any, item: any) => {
                    return sum + item.quantity;
                  }, 0)}
                  &#160;kg
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
        <div className="dialog-update-plan-detail__btn">
          <button className="btn-border-confirm-style" onClick={onClose}>
            Huỷ
          </button>
          <button className="btn-confirm-style" onClick={() => submitForm()}>
            Lưu
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default OnlyOrder;
