import React from "react";
import { SvgCommon } from "./SvgCommon";

export type IconModel = {
  color: string;
  size: [number, number]; // mang kich thuoc x,y (width, height)
  viewBox?: [number, number]; // mang kich thuoc x,y (width, height)
  className?: string;
  style?: React.CSSProperties;
  options?: {
    color: string;
    backgroundColor: string;
  };
};
export const Calendar1Icon: React.FC<IconModel> = (props) => {
  return (
    <SvgCommon {...props}>
      <path
        d="M6 2H3C1.89543 2 1 2.89543 1 4V16C1 17.1046 1.89543 18 3 18H17C18.1046 18 19 17.1046 19 16V4C19 2.89543 18.1046 2 17 2H6ZM6 2V0M6 2V4M14 0V2V4"
        stroke={props.color}
        strokeWidth="2"
      />
      <rect x="3.69922" y="5.69995" width="2.6" height="2.6" rx="1.3" fill="#BDC6D7" />
      <rect x="3.69922" y="9.19995" width="2.6" height="2.6" rx="1.3" fill={props.color} />
      <rect x="3.69922" y="12.7" width="2.6" height="2.6" rx="1.3" fill={props.color} />
      <rect x="13.6992" y="5.69995" width="2.6" height="2.6" rx="1.3" fill={props.color} />
      <rect x="13.6992" y="9.19995" width="2.6" height="2.6" rx="1.3" fill={props.color} />
      <rect x="13.6992" y="12.7" width="2.6" height="2.6" rx="1.3" fill="#BDC6D7" />
      <rect x="8.69922" y="5.69995" width="2.6" height="2.6" rx="1.3" fill={props.color} />
      <rect x="8.69922" y="9.19995" width="2.6" height="2.6" rx="1.3" fill={props.color} />
      <rect x="8.69922" y="12.7" width="2.6" height="2.6" rx="1.3" fill="#BDC6D7" />
    </SvgCommon>
  );
};

Calendar1Icon.defaultProps = {
  size: [20, 20],
  viewBox: [20, 20],
};
