import ImageUpload from "@Components/ImageUpload";
import Loading from "@Components/Loading";
import {
  Action,
  AuthCustomerProfileOutputModel,
  FetchAction,
  FetchReducer,
  FormUploadImageModel,
  UploadedImageModel,
} from "@Models";
import { CustomerService } from "@Services";
import { checkLinkImage } from "@Utils";
import React, { useEffect, useReducer, useState } from "react";
import { Form } from "react-final-form";
import { v4 as uuidv4 } from "uuid";
import LeftNavigation from "../LeftNavigation";
import "./Identity.scss";

function reducer(state: FetchReducer<AuthCustomerProfileOutputModel>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        fetching: false,
        data: action.payload,
      };
    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload };
    default:
      return { ...state };
  }
}

const Identity: React.FC = () => {
  const [visibleBtn, setVisibleBtn] = useState<boolean>(false);
  const [state, dispatch] = useReducer(reducer, {
    data: [],
    fetching: false,
    failMessage: "",
  });

  useEffect(() => {
    let cancel = false;
    const fetchData = async () => {
      dispatch({ type: Action.FETCH, payload: {} });
      try {
        const result = await CustomerService.getAuthProfile();
        if (!cancel)
          dispatch({
            type: Action.SUCCESS,
            payload: result.data,
          });
      } catch (err) {
        if (!cancel)
          dispatch({
            type: Action.FAILED,
            payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
          });
      }
    };

    fetchData();

    return () => {
      cancel = true;
    };
  }, []);

  const bindingData = state.data;

  const [identityFrontImage, setIdentityFrontImage] = useState<
    Array<FormUploadImageModel & { file: any }>
  >([]);

  const [identityBackImage, setIdentityBackImage] = useState<
    Array<FormUploadImageModel & { file: any }>
  >([]);

  useEffect(() => {
    setIdentityFrontImage(
      bindingData.idCardFront
        ? [
            {
              id: "",
              src: `${checkLinkImage(bindingData.idCardFront)}`,
              alt: "",
              file: null,
            },
          ]
        : []
    );
    setIdentityBackImage(
      bindingData.idCardBack
        ? [
            {
              id: "",
              src: `${checkLinkImage(bindingData.idCardBack)}`,
              alt: "",
              file: null,
            },
          ]
        : []
    );

    if (bindingData.idCardBack === "" || bindingData.idCardFront === "") {
      setVisibleBtn(true);
    }
  }, [bindingData]);

  const uploadIdentity = async () => {
    try {
      const formData = new FormData();

      formData.append("imgs", identityFrontImage[0].file);
      formData.append("imgs", identityBackImage[0].file);
      if (identityFrontImage[0].file && identityBackImage[0].file) {
        await CustomerService.uploadIdentity(formData);
      } else {
      }

      window.location.reload();
    } catch (error) {}
  };

  useEffect(() => {
    if (identityFrontImage.length === 0 || identityBackImage.length === 0) {
      setVisibleBtn(true);
    }
  }, [identityFrontImage, identityBackImage]);

  return (
    <div className="cus-identity">
      <div className="left">
        <LeftNavigation />
      </div>
      {state.data.customer ? (
        <div className="right">
          <Form onSubmit={async () => uploadIdentity()}>
            {({ handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className="card-group">
                    <div className="title">Hình ảnh CMND/CCCD</div>
                    <div className="des">
                      {bindingData.idCardFront || bindingData.idCardBack
                        ? "Đã cập nhật chứng minh nhân dân/ căn cước công dân của bạn"
                        : " Tải hình ảnh chứng minh nhân dân/ căn cước công dân của bạn lên"}
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <div>
                        <ImageUpload
                          title="Hình ảnh CMND/CCCD"
                          className="card"
                          max={1}
                          fileList={identityFrontImage}
                          onChange={(data: UploadedImageModel) => {
                            setIdentityFrontImage([
                              {
                                id: uuidv4(),
                                src: data.fileStr,
                                alt: data.alt || "",
                                file: data.file,
                              },
                            ]);
                            setVisibleBtn(false);
                          }}
                          onDelete={() => {
                            setIdentityFrontImage([]);
                            setVisibleBtn(true);
                          }}
                          config={{ uploadModal: true }}
                        />
                        <div className="text-face">Mặt trước</div>
                      </div>
                      <div>
                        <ImageUpload
                          className="card"
                          title="Hình ảnh CMND/CCCD"
                          max={1}
                          fileList={identityBackImage}
                          onChange={(data: UploadedImageModel) => {
                            setIdentityBackImage([
                              {
                                id: uuidv4(),
                                src: data.fileStr,
                                alt: data.alt || "",
                                file: data.file,
                              },
                            ]);
                            setVisibleBtn(false);
                          }}
                          onDelete={() => {
                            setIdentityBackImage([]);
                            setVisibleBtn(true);
                          }}
                          config={{ uploadModal: true }}
                        />
                        <div className="text-face">Mặt sau</div>
                      </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                      <button
                        className={!visibleBtn ? "btn-confirm-style " : "hide"}
                        onClick={(e) => {
                          e.preventDefault();
                          uploadIdentity();
                        }}>
                        Lưu
                      </button>
                    </div>
                  </div>
                </form>
              );
            }}
          </Form>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Identity;
