import Avt from "@Assets/images/avt.png";
import Logo from "@Assets/images/logo.png";
import Notification from "@Components/Notification";
import PushNotification from "@Components/PushNotification";
import { APP_ROUTES, DataLanguageHeader, STORAGE, SubTab, TEXT_HOLDER } from "@Constants";
import { AuthService, CustomerService } from "@Services";
import { checkLinkImage } from "@Utils";
import {
  ClearRounded as ClearRoundedIcon,
  Menu as MenuIcon,
  MenuOpen as MenuOpenIcon,
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  MenuItem,
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Drawer as MuiDrawer,
  Popover,
  Select,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { CSSObject, Theme, ThemeProvider, createTheme, styled } from "@mui/material/styles";
import { AxiosError } from "axios";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import SubNavbar from "./SubNavbar";
import "./index.scss";
import moduleList, { DEFAULT_COLOR } from "./routes";
import _debounce from "lodash/debounce";
import { useTranslation } from "react-i18next";

// const drawerWidth = 230;

const openedMixin = (theme: Theme): CSSObject => ({
  [theme.breakpoints.up("md")]: {
    width: 164,
  },
  [theme.breakpoints.up("lg")]: {
    width: 230,
  },
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  ...theme.mixins.toolbar,
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    // marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - 164px)`,
      marginLeft: 164,
    },
    [theme.breakpoints.up("lg")]: {
      width: `calc(100% - 230px)`,
      marginLeft: 230,
    },
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    // width: drawerWidth,
    [theme.breakpoints.up("md")]: {
      width: 164,
    },
    [theme.breakpoints.up("lg")]: {
      width: 230,
    },
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  })
);

const LayoutComponent: React.FC = (props) => {
  const { t, i18n } = useTranslation(["translation"]);
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = useState<boolean>(false);
  const [anchorUserEl, setAnchorUserEl] = useState<HTMLElement | null>(null);
  const [profile, setProfile] = useState<any>();
  const [notiActivated, setNotiActivated] = useState<{
    message: string;
    status: boolean;
  }>({
    message: "",
    status: false,
  });

  const [dataLanguage, setDataLanguage] = useState<any>(DataLanguageHeader[0]);

  const theme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: "1.3rem",
            lineHeight: "1.9rem",
            color: " #FFFFFF",
            backgroundColor: "rgba(0,0,0,0.75)",
            fontFamily: "SVN-Gotham",
            fontWeight: 400,
            boxShadow: "0rem 0.4rem 1.2rem rgba(111, 125, 145, 0.16)",
            borderRadius: "0.4rem",
          },
        },
      },
    },
  });

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorUserEl(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorUserEl(null);
  };

  const deActiveToken = async () => {
    await AuthService.deActiveTokenDevice({
      token: localStorage.getItem("firebaseToken"),
    });
  };

  const onLogout = () => {
    deActiveToken();
    //  localStorage.clear();
    localStorage.removeItem("accessToken");
    window.location.reload();
    history.push(APP_ROUTES.SIGNIN);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // const currentRoute = moduleList.find((module) => {
  //   const childRoutes = module.subRoutes.map((s) => s.path);
  //   const paths = [module.path, ...childRoutes];
  //   return paths.some((p) => history.location.pathname.includes(p));
  // });

  const currentRoute = React.useMemo(() => {
    const routes = moduleList.map(({ path }) => path).reverse();
    const selected = routes.find((route) => location.pathname.includes(route));
    return selected;
  }, [location.pathname]);

  const selectedRoute = React.useMemo(() => {
    const selected = moduleList.find((route) => currentRoute === route.path);
    return selected;
  }, [currentRoute]);

  //set image avatar and if token expired => clear cache
  useEffect(() => {
    const fetchData = async () => {
      await CustomerService.getAuthProfile()
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            setProfile(res.data);
          }
        })
        .catch((err: AxiosError) => {
          if (err.response?.status === 401) {
            if (err.response?.data.message === "SESSION_EXPIRED") {
              setNotiActivated({ message: "SESSION_EXPIRED", status: true });
            } else {
              setNotiActivated({ message: "UNAUTHORIZED", status: true });
            }
          }
        });
    };
    fetchData();

    //translation
    const languageKey = localStorage.getItem(STORAGE.LOCAL_LANGUAGE);
    if (languageKey) {
      const dataStoreLanguage = DataLanguageHeader.find((i) => i.code === languageKey);
      setDataLanguage(dataStoreLanguage);
    }
  }, []);

  const setDefaultImage = (e: any) => {
    e.target.src = Avt;
  };

  const checkURL = SubTab.filter(
    (s) =>
      profile?.role?.dataAccessPermissions?.some((d: any) => d.source === s.source) ||
      profile?.customer?.some((d: any) => d.source === s.source)
  ).filter((s) => profile?.role?.permissions.includes(s.permission));

  const checkPermissionDashBoard = SubTab.some((s) =>
    profile?.role?.permissions.includes(s.permission)
  );

  useEffect(() => {
    if (checkPermissionDashBoard && checkURL) {
      history.push(checkURL[0].path + "-" + checkURL[0].source);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname === APP_ROUTES.DASHBOARD + "-" + checkURL?.[0]?.source]);

  return (
    <Box sx={{ display: "flex" }}>
      {/* <CssBaseline /> */}
      <AppBar position="fixed" open={open} className="app-bar">
        <Toolbar className="app-toolbar">
          <div className="app-toolbar-left">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: "36px",
                ...(open && { display: "none !important" }),
              }}>
              <MenuIcon style={{ fontSize: "2.8rem", color: "#6C778D" }} />
            </IconButton>
            <Typography
              variant="h6"
              noWrap
              component="div"
              className="title"
              style={{ paddingLeft: open ? "3rem" : "" }}>
              {t(selectedRoute?.title || TEXT_HOLDER)}
            </Typography>
          </div>
          <div className="app-toolbar-right">
            <div className="language">
              <Select
                className="language-select"
                variant="standard"
                disableUnderline
                value={dataLanguage.id}
                displayEmpty
                MenuProps={{
                  className: "select-menu-list select-menu-sale-org",
                  anchorOrigin: {
                    vertical: 40,
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}>
                {DataLanguageHeader.map((i, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={i.id}
                      onClick={_debounce(() => {
                        setDataLanguage(i);
                        localStorage.setItem(STORAGE.LOCAL_LANGUAGE, i.code);
                        i18n.changeLanguage(i.code);
                        window.location.reload();
                      }, 300)}>
                      <div className="language-content">
                        <img className="img-right" src={i.icon} alt="right" />
                        <span style={{ paddingLeft: "1rem" }}>{i.label}</span>
                      </div>
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <div className="notification">
              <PushNotification />
            </div>
            <div className="menu-user">
              <img
                src={checkLinkImage(profile?.avatar) || Avt}
                onClick={handleOpenUserMenu}
                alt="user-menu"
                onError={setDefaultImage}
              />
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}>
          <img className="DrawerHeader_img" src={Logo} alt="greenfeed-logo" />
          <IconButton onClick={handleDrawerClose}>
            <MenuOpenIcon style={{ fontSize: "2.8rem", color: "#6C778D" }} />
          </IconButton>
        </DrawerHeader>
        <ul className="nav-list">
          {moduleList.map(({ label, defaultIcon, activeIcon, path, subRoutes, permission }) => {
            const selected = currentRoute === path;

            if (
              permission &&
              Array.isArray(permission) &&
              !profile?.role?.permissions.some((per: any) => permission.includes(per))
            ) {
              return null;
            } else if (
              permission &&
              !Array.isArray(permission) &&
              !profile?.role?.permissions.includes(permission)
            ) {
              return null;
            }
            return (
              <Link
                key={label}
                to={subRoutes === undefined ? path : path + "-" + profile?.customer?.[0]?.source}
                replace>
                <li
                  className={`${"nav-item"} ${path === APP_ROUTES.SETTING ? "away" : ""}`}
                  style={{ padding: open ? "0.4rem" : "" }}>
                  {open ? (
                    <button
                      role="tab"
                      className={selected ? "btn-icon btn--shiny" : "btn-icon btn-hover"}
                      aria-selected={selected}
                      style={{ padding: open ? "0.9rem" : "" }}>
                      <span>{selected ? activeIcon : defaultIcon}</span>
                    </button>
                  ) : (
                    <ThemeProvider theme={theme}>
                      <Tooltip arrow placement="right" title={<span>{t(label)}</span>}>
                        <button
                          role="tab"
                          className={selected ? "btn-icon btn--shiny" : "btn-icon btn-hover"}
                          aria-selected={selected}>
                          <span>{selected ? activeIcon : defaultIcon}</span>
                        </button>
                      </Tooltip>
                    </ThemeProvider>
                  )}
                  <div
                    className="label"
                    style={{
                      color: selected ? "#0A6836" : DEFAULT_COLOR,
                      fontWeight: selected ? "bold" : "normal",
                    }}>
                    {t(label)}
                  </div>
                </li>
              </Link>
            );
          })}
        </ul>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          background: "#F0F3F8",
          height: "100vh",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        }}>
        <DrawerHeader />
        {(profile?.customer?.length > 0
          ? profile?.customer?.length > 1
          : profile?.role?.dataAccessPermissions?.length > 1) &&
          !!selectedRoute?.subRoutes?.length &&
          selectedRoute?.subRoutes?.length > 1 && (
            <SubNavbar
              routes={selectedRoute.subRoutes}
              source={
                profile.customer
                  ? profile.customer[0]?.source
                  : profile?.role?.dataAccessPermissions[0]?.source
              }
            />
          )}

        {profile?.customer?.length <= 1 &&
          profile?.role?.dataAccessPermissions?.length <= 1 &&
          selectedRoute?.path === APP_ROUTES.DASHBOARD && (
            <div className="sub-tab-menu">
              {(() => {
                let checkSubtab = SubTab.filter(
                  (f) => f.source === location.pathname.slice(-4)
                ).map(({ label, path, source, permission }) => {
                  const classes = clsx(
                    "nav-item",
                    path + "-" + source === location.pathname && "tab-green-style btn--shiny"
                  );

                  return (
                    <Link key={label} to={path + "-" + source}>
                      <div className={classes}>
                        <span className="title">{t(label)}</span>
                      </div>
                    </Link>
                  );
                });
                checkSubtab = _.compact(checkSubtab);

                return checkSubtab?.length > 0 && checkSubtab;
              })()}
            </div>
          )}

        <div className="layout__content__body">{props.children}</div>
      </Box>
      <Popover
        open={Boolean(anchorUserEl)}
        anchorEl={anchorUserEl}
        onClose={handleCloseUserMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}>
        <div className="app-toolbar-right">
          <button
            style={{ fontFamily: "SVN-Gotham" }}
            className="btn-white"
            onClick={(e) => {
              e.preventDefault();
              localStorage.setItem("policyKey", "show");

              onLogout();
            }}>
            Đăng xuất
          </button>
        </div>
      </Popover>

      <Notification
        visible={notiActivated.status}
        title={
          notiActivated.message === "SESSION_EXPIRED"
            ? t("YOUR_LOGIN_SESSION_EXPIRED")
            : t("YOUR_ACCOUNT_DISABLED")
        }
        subTitle={
          notiActivated.message === "SESSION_EXPIRED" ? (
            <span>{t("LOGIN_AGAIN")}</span>
          ) : (
            <span
              dangerouslySetInnerHTML={{
                __html: t("CONTACT_STAFF_GF_ACTIVE_ACCOUNT"),
              }}
            />
          )
        }
        icon={
          <div className={`notification-icon-box red`}>
            <ClearRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
          </div>
        }
        actions={
          <button
            className="btn-confirm-style"
            onClick={() => {
              localStorage.removeItem("accessToken");
              window.location.reload();
              history.push(APP_ROUTES.SIGNIN);
            }}>
            {notiActivated.message === "SESSION_EXPIRED" ? t("CLOSE") : t("GO_BACK")}
          </button>
        }
        onClose={() => {
          localStorage.removeItem("accessToken");
          window.location.reload();
          history.push(APP_ROUTES.SIGNIN);
        }}
      />
    </Box>
  );
};
export default LayoutComponent;
