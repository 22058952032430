import { IconComponent } from "@Core/Icons";

export const ArrowDownIcon: React.FC<IconComponent> = (props) => {
  return (
    <svg
      width="30"
      height="17"
      viewBox="-7.5 -9 5 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.71005 6.8297L10.9501 2.5897C11.0438 2.49674 11.1182 2.38613 11.1689 2.26428C11.2197 2.14242 11.2458 2.01171 11.2458 1.8797C11.2458 1.74769 11.2197 1.61698 11.1689 1.49512C11.1182 1.37326 11.0438 1.26266 10.9501 1.1697C10.7627 0.983448 10.5092 0.878906 10.2451 0.878906C9.98087 0.878906 9.72741 0.983448 9.54005 1.1697L6.00005 4.7097L2.46005 1.1697C2.27269 0.983447 2.01924 0.878906 1.75505 0.878906C1.49086 0.878906 1.23741 0.983447 1.05005 1.1697C0.95737 1.26314 0.884045 1.37395 0.83428 1.49579C0.784515 1.61763 0.759291 1.74809 0.760052 1.8797C0.759291 2.0113 0.784515 2.14177 0.83428 2.2636C0.884045 2.38544 0.95737 2.49626 1.05005 2.5897L5.29005 6.8297C5.38301 6.92343 5.49362 6.99782 5.61547 7.04859C5.73733 7.09936 5.86804 7.1255 6.00005 7.1255C6.13206 7.1255 6.26277 7.09936 6.38463 7.04859C6.50649 6.99782 6.61709 6.92343 6.71005 6.8297Z"
        fill={props.color}
      />
    </svg>
  );
};
