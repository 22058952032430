//import { CurrencyOptions } from "@Const";
import Avt from "@Assets/images/avt.png";
import ProductPig from "@Assets/images/mock/pig.png";
import ProductCcu from "@Assets/images/mock/product-ccu.png";
import Product from "@Assets/images/mock/product.png";
import { BASE_LINK_IMAGE } from "@Constants";
import moment from "moment";

export const loadCallback = <T extends (...P: any[]) => any>(
  cb?: T,
  ...data: Parameters<T>
): ReturnType<T> => {
  return cb && cb(...data);
};

/**
 * Hiện giá trị theo định dạng tiền tệ của US
 * @param number kiểu dữ liệu number
 */
export function currencyFormat(number: number) {
  // output: 5,000,000
  // return new Intl.NumberFormat("en-US").format(number);

  // output: 5.0000.000
  return new Intl.NumberFormat("en-DE").format(Math.round(number));
}

export function getFirstChar(str: string): string {
  return str.charAt(0);
}

export function convertTagString(
  tagStr: string | undefined | null,
  config?: {
    seperator?: string;
  }
): string[] {
  if (!tagStr) return [];

  const seperator = config?.seperator || "|";
  let tags: string[] = tagStr.split(seperator);

  tags = tags.filter((tag) => {
    return !!tag.trim();
  });

  return tags;
}

export function loadToPage(path: string) {
  window.location.href = path;
}

export function capitalizeFirstLetter(str: string): string {
  return getFirstChar(str).toUpperCase() + str.slice(1);
}

// export function currencyUnitFormat(
//   value: number,
//   currency: string,
//   hideSymbol?: boolean
// ) {
//   const foundCur = CurrencyOptions.find((e) => e.id === currency);
//   return foundCur
//     ? new Intl.NumberFormat(foundCur?.locale || "en-US", {
//         style: !hideSymbol ? "currency" : "decimal",
//         currency: !hideSymbol ? currency : undefined,
//         minimumFractionDigits: value % 1 === 0 ? 0 : 2,
//       }).format(value)
//     : "0";
// }

// export function getCurrencySymbol(currency: string) {
//   const value = currencyUnitFormat(0, currency);
//   return value.replace(/\d|\.|,/g, "").trim();
// }

export const listener = (type: string, handler: Function, target: any = window) => {
  target.addEventListener(type, handler, { passive: false });
  return () => {
    target?.removeEventListener(type, handler);
  };
};

export function checkLinkImage(link: string) {
  if (link === undefined || link === null) {
    return;
  }
  return BASE_LINK_IMAGE + link;
}

export const renderErrorImageItem = (value?: any) => {
  const setDefaultImageFeed = (e: any) => {
    e.target.src = Product;
  };

  const setDefaultImageFarm = (e: any) => {
    e.target.src = ProductPig;
  };

  const setDefaultImageCcu = (e: any) => {
    e.target.src = ProductCcu;
  };

  const setDefaultImage = (e: any) => {
    e.target.src = Avt;
  };

  switch (value) {
    case "feed":
      return setDefaultImageFeed;
    case "farm":
      return setDefaultImageFarm;
    case "ccu":
      return setDefaultImageCcu;
    default:
      return setDefaultImage;
  }
};

export const renderImageProNull = (value?: any) => {
  switch (value) {
    case "feed":
      return Product;
    case "farm":
      return ProductPig;
    case "ccu":
      return ProductCcu;
    case "vet":
      return Product;
    default:
      return Avt;
  }
};

export function YearList() {
  const fullYear: any = [];

  for (let year = new Date().getFullYear(); year >= 2015; year--) {
    fullYear.push(year);
  }

  return fullYear;
}

export const getWeek = (date: Date): number => {
  // Copy date to avoid modifying the original
  const newDate = new Date(date.getTime());
  newDate.setHours(0, 0, 0, 0);

  // Thursday in the current week decides the year
  newDate.setDate(newDate.getDate() + 3 - ((newDate.getDay() + 6) % 7));

  // January 4 is always in week 1
  const week1 = new Date(newDate.getFullYear(), 0, 4);

  // Adjust to Thursday in week 1 and count number of weeks from date to week1
  return (
    1 +
    Math.round(
      ((newDate.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7
    )
  );
};

export const getStartOfWeek = (year: number, week: number): Date => {
  const simple = new Date(year, 0, 1 + (week - 1) * 7);
  const dow = simple.getDay();
  const ISOweekStart = simple;
  if (dow <= 4) {
    ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
  } else {
    ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
  }
  return ISOweekStart;
};

export const getAllWeeksOfYear = (
  year: number
): Array<{ week: number; startDate: Date; endDate: Date }> => {
  const weeks: Array<{ week: number; startDate: Date; endDate: Date }> = [];
  let week = 1;
  let startDate = getStartOfWeek(year, week);

  while (startDate.getFullYear() === year) {
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 6);
    weeks.push({ week, startDate: new Date(startDate), endDate: new Date(endDate) });
    week++;
    startDate = getStartOfWeek(year, week);
  }

  return weeks;
};

export const getAllDaysOfWeek = (year: number, week: number): Array<Date> => {
  const startOfWeek = getStartOfWeek(year, week);
  const daysOfWeek: Array<Date> = [];

  for (let i = 0; i < 7; i++) {
    const day = new Date(startOfWeek);
    day.setDate(startOfWeek.getDate() + i);
    daysOfWeek.push(day);
  }

  return daysOfWeek;
};

export const dateFormat = (date?: any, hasTime?: boolean, format?: string) => {
  if (!date) return "";
  if (hasTime) return moment(date).format("DD/MM/YYYY, HH:mm");
  if (format) return moment(date).format(format);
  return moment(date).format("DD/MM/YYYY");
};

export const formattedDate = (date?: any) => {
  if (!date) return "";
  const [day, month, year] = date?.split("/");
  return `${year}-${month}-${day}`;
};

export const getFirstNumberOfStr = (str: string) => {
  const firstNumberMatch = str.match(/\d+/);
  const firstNumber = firstNumberMatch ? Number(firstNumberMatch[0]) : 0;
  return firstNumber;
};

export const preventCharacter = (e: any) => {
  const { ctrlKey, key } = e;
  if (/[0-9]|Arrow|Backspace|Delete/.test(key) || (ctrlKey && /^a|c|v|x$/.test(key))) {
    return true;
  }
  e.preventDefault();
  return true;
};

export function convertTv(alias: string, spaceTo?: string) {
  let str = alias || "";
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/ + /g, " ");
  str = str.trim();
  return str.replace(/\s+/g, (s) => spaceTo || s);
}
