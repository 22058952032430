import { IconBase, IconComponent } from "@Core/Icons";

export const InterestedIcon1: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        d="M13.5599 23.235L13.5579 23.2332C13.1252 22.8548 12.5741 22.6432 11.9999 22.6432C11.4257 22.6432 10.8747 22.8548 10.442 23.2332L10.4399 23.235L4.12855 28.7977V3.60112H2.72855V30.3478V31.8978L3.8914 30.8729L11.3636 24.287C11.3639 24.2867 11.3642 24.2865 11.3646 24.2862C11.5478 24.1264 11.7728 24.0432 11.9999 24.0432C12.2271 24.0432 12.4521 24.1264 12.6353 24.2862C12.6356 24.2865 12.6359 24.2867 12.6363 24.287L20.1085 30.8729L21.0342 29.8226L13.5599 23.235ZM0.700077 0.700005L23.3 0.700161L23.2999 34.1963C23.2999 34.1967 23.2999 34.1972 23.2999 34.1977C23.2985 34.4217 23.2341 34.6377 23.1181 34.8184C23.0021 34.9991 22.8412 35.1345 22.6598 35.2127L22.6429 35.22L22.6263 35.2282C22.5242 35.2788 22.4142 35.3027 22.3045 35.2998L22.2929 35.2994L22.2813 35.2995C22.1527 35.3003 22.0244 35.2745 21.9034 35.2226C21.7822 35.1706 21.6698 35.0931 21.5734 34.9927L21.5532 34.9716L21.5313 34.9524L12.4627 26.9607L12 26.5529L11.5371 26.9607L2.36577 35.0423L2.36575 35.0423L2.36084 35.0467C2.21221 35.1802 2.03422 35.2628 1.84906 35.2898C1.66409 35.3168 1.47461 35.2879 1.30185 35.2044C1.12869 35.1206 0.976859 34.984 0.868234 34.8058C0.759445 34.6275 0.699986 34.417 0.7 34.1996L0.700077 0.700005Z"
        fill="#ECF7ED"
        stroke="#41AD49"
        strokeWidth="1.4"
      />
      <path
        d="M19.1961 13.3003L19.2106 13.3023L19.2252 13.3036C19.2745 13.3077 19.3205 13.3258 19.358 13.3544C19.39 13.3789 19.4143 13.4101 19.4297 13.4445C19.4362 13.4799 19.4339 13.5163 19.4229 13.5509C19.4099 13.5915 19.3851 13.6289 19.3499 13.6581L19.3372 13.6687L19.3251 13.6802L16.2456 16.6084L16.0505 16.794L16.098 17.059L16.8436 21.2171L16.8436 21.2171L16.8449 21.224C16.853 21.2658 16.8489 21.309 16.8326 21.349C16.8162 21.3891 16.788 21.4251 16.7499 21.4519L16.7478 21.4534C16.7056 21.4834 16.6536 21.5002 16.5993 21.5L16.596 21.5C16.5607 21.5001 16.5265 21.4912 16.4968 21.4748L16.4904 21.4712L16.4838 21.4678L12.681 19.5059L12.4526 19.3881L12.2239 19.5052L8.40617 21.4598L8.40616 21.4598L8.40317 21.4613C8.36216 21.4827 8.31522 21.4927 8.2678 21.4896C8.22039 21.4865 8.17558 21.4705 8.13834 21.4443L7.8511 21.8536L8.13873 21.4446C8.10061 21.4178 8.07238 21.3818 8.05605 21.3417C8.03976 21.3017 8.03559 21.2585 8.04369 21.2167L8.04374 21.2167L8.04499 21.2098L8.79064 17.0517L8.83817 16.7866L8.64303 16.6011L5.56694 13.6761C5.53702 13.6461 5.51663 13.6098 5.5068 13.5712C5.49717 13.5333 5.49777 13.4938 5.50859 13.4562C5.5222 13.418 5.54647 13.3829 5.58002 13.3553C5.61496 13.3265 5.65839 13.3071 5.70561 13.3005L5.70561 13.3005L5.70823 13.3002L9.95099 12.6852L10.2088 12.6479L10.3259 12.4152L12.2273 8.63777L12.2273 8.63778L12.2291 8.63417C12.2481 8.59565 12.2784 8.56184 12.3178 8.53761C12.3573 8.51333 12.4037 8.5 12.4518 8.5C12.4998 8.5 12.5463 8.51333 12.5857 8.53761C12.6251 8.56184 12.6554 8.59565 12.6744 8.63417L12.6744 8.63417L12.6761 8.63742L14.5775 12.4222L14.6948 12.6556L14.9534 12.6927L19.1961 13.3003Z"
        stroke="#41AD49"
      />
    </IconBase>
  );
};
