import React from "react";
import LeftBottomImage from "@Assets/images/404/left-bot.jpg";
import LeftTopImage from "@Assets/images/404/left-top.jpg";
import RightImage from "@Assets/images/404/right-bot.jpg";
import NotFoundImg from "@Assets/images/403.png";
import "./index.scss";
import { APP_ROUTES } from "@Constants";
import { useHistory } from "react-router-dom";

const AccessDenied: React.FC = () => {
  const history = useHistory();

  return (
    <div className="denied">
      <div className="denied__left">
        <img className="img-left" src={LeftTopImage} alt="leftTop" />
        <img className="img-left" src={LeftBottomImage} alt="leftBot" />
      </div>
      <div className="denied__content">
        <div className="title">Bạn không có quyền truy cập</div>
        <div className="des">Vui lòng liên hệ quản trị viên để có quyền truy cập</div>
        <img style={{ width: "48.8rem", height: "26rem" }} src={NotFoundImg} alt="" />
        <button
          type="submit"
          className="btn-confirm-style"
          onClick={() => history.push(APP_ROUTES.HOME)}>
          Quay lại trang chủ
        </button>
      </div>
      <img className="img-right" src={RightImage} alt="right" />
    </div>
  );
};

export default AccessDenied;
