import { APP_ROUTES } from "@Constants";
import BlockAccount from "@Containers/SignInForm/BlockAccount/BlockAccount";
import { RouteProps } from "react-router-dom";
import OTPInput from "./OTPInput/OTPInput";

export const appRoutesAuth: Array<RouteProps> = [
  {
    exact: true,
    path: APP_ROUTES.VERIFY_OTP,
    component: OTPInput,
  },
  {
    exact: true,
    path: APP_ROUTES.IS_BLOCKED,
    component: BlockAccount,
  },
];
