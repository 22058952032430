import React from "react";
import { IconBase, IconComponent } from "@Core/Icons";

export const UnselectedStarIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <circle
        cx="9.47049"
        cy="9.19608"
        r="8.53922"
        fill="white"
        stroke={props.color}
        strokeWidth="1.31373"
      />
      <path
        d="M8.88754 5.06536C9.13265 4.5928 9.80863 4.5928 10.0537 5.06536L11.0462 6.97886C11.1383 7.15637 11.3062 7.28228 11.5024 7.32091L13.5905 7.73207C14.0908 7.83057 14.293 8.43662 13.9521 8.81575L12.4559 10.4798C12.3287 10.6212 12.2691 10.8107 12.2924 10.9994L12.5373 12.984C12.607 13.5489 11.9742 13.9291 11.5082 13.6022L9.84782 12.4377C9.62143 12.2789 9.31985 12.2789 9.09346 12.4377L7.43307 13.6022C6.96707 13.9291 6.33425 13.5489 6.40397 12.984L6.64888 10.9994C6.67217 10.8107 6.61254 10.6212 6.48541 10.4798L4.98919 8.81575C4.6483 8.43662 4.85049 7.83057 5.35074 7.73207L7.43886 7.32091C7.63507 7.28228 7.80299 7.15637 7.89506 6.97886L8.88754 5.06536Z"
        stroke={props.color}
        strokeWidth="1.57647"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconBase>
  );
};
