/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-useless-fragment */
import IconBuy from "@Assets/images/icons/ic_buy.png";
import Cancel from "@Assets/images/icons/ic_cancel.png";
import IconClose from "@Assets/images/icons/ic_close.png";
import Create from "@Assets/images/icons/ic_create.png";
import Decrease from "@Assets/images/icons/ic_decrease.png";
import Increase from "@Assets/images/icons/ic_increase.png";
import DOList from "@Components/DO";
import Loading from "@Components/Loading";
import Notification from "@Components/Notification";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import { APP_ROUTES, ReasonStatus, TEXT_ASTERISK, TEXT_HOLDER, UpdateHistory } from "@Constants";
import {
  ItemPermission,
  OrderDetailModel,
  OrderPermission,
  OrderStatus,
  Reason,
  SourceType,
} from "@Models";
import { CustomerService, OrderService } from "@Services";
import {
  PermissionUtil,
  StatusUIUtil,
  checkLinkImage,
  currencyFormat,
  renderErrorImageItem,
  renderImageProNull,
} from "@Utils";
import {
  ArrowDropDown as ArrowDropDownIcon,
  Clear as ClearIcon,
  PriorityHighRounded as PriorityHighRoundedIcon,
  ShareOutlined as ShareOutlinedIcon,
} from "@mui/icons-material";
import GridViewIcon from "@mui/icons-material/GridView";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import _debounce from "lodash/debounce";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Bill from "./Bill";
import "./OrderDetail.scss";

const OrderDetailVet: React.FC = () => {
  const { t } = useTranslation(["translation"]);
  const history = useHistory();
  const mounted = useRef(false);
  const { enqueueSnackbar } = useSnackbar();
  const { orderId }: { orderId: string } = useParams();
  const [order, setOrder] = useState<any>();
  const [showCancel, setShowCancel] = useState<boolean>(false);
  const [cancelValue, setCancelValue] = useState<string>(Reason.NOT_RESPONSE);
  const [reason, setReason] = useState<string>("");
  const [submitCancel, setSubmitCancel] = useState<boolean>(false);
  const [openListDO, setOpenListDO] = useState<number>(0);
  const location = useLocation<any>();
  const [profile, setProfile] = useState<any>([]);
  const [notiCheckCusDiv, setNotiCheckCusDiv] = useState<boolean>(false);
  const [openBill, setOpenBill] = useState<boolean>(false);
  const submitRef = useRef<any | null>(null);

  const theme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: "1.3rem",
            lineHeight: "1.9rem",
            color: " #FFFFFF",
            backgroundColor: "#312F30",
            fontFamily: "SVN-Gotham",
            fontWeight: 400,
            boxShadow: "0rem 0.4rem 1.2rem rgba(111, 125, 145, 0.16)",
            borderRadius: "0.4rem",
          },
        },
      },
    },
  });

  const loadProfileData = _debounce(async () => {
    const response = await CustomerService.getAuthProfile();
    setProfile(response.data);
  }, 100);

  useEffect(() => {
    loadProfileData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkCustomerDiv =
    profile?.customer?.find(
      (customerDiv: any) =>
        customerDiv.source === SourceType.FEED ||
        customerDiv.source === SourceType.FARM ||
        customerDiv.source === SourceType.VET
    ).customerDivision.length > 0;

  const orderById: () => Promise<void> = async () => {
    try {
      const response = await OrderService.getOrderById(orderId);
      if (mounted.current) {
        setOrder(() => response.data.data as OrderDetailModel);
      }
    } catch (error) {}
  };

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    orderById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkSource = order?.orderType === SourceType.VET;

  const status = Array.from(
    new Set(
      order?.orders.map((o: any) => {
        return o.status;
      })
    )
  );

  const cancelOrderVet = async () => {
    if (submitRef.current) {
      submitRef.current.disabled = true;
    }
    try {
      await OrderService.cancelOrder({
        reasonStatus: cancelValue,
        orderGroupId: +orderId,
        reason: reason,
        source: SourceType.VET,
      });
      enqueueSnackbar(t("ORDER_CANCELLED_SUCCESSFULLY"), {
        variant: "success",
      });
      history.push(APP_ROUTES.ORDER_VET);
    } catch (error) {
      enqueueSnackbar(t("CANCEL_ORDER_FAILED"), {
        variant: "error",
      });
      setSubmitCancel(false);
      submitRef.current.disabled = false;
    }
  };

  const gridSize: any = 12 / (order?.orders?.length + 2);

  //permission
  const checkPermissionViewPrice = PermissionUtil.check(
    profile?.role?.permissions,
    ItemPermission.MANAGE_ITEM_VIEW_PRICE,
    true
  );

  const checkPermissionCancelOrder = PermissionUtil.check(
    profile?.role?.permissions,
    OrderPermission.MANAGE_ORDER_CANCEL,
    true
  );
  const checkPermissionOrderCreate = PermissionUtil.check(
    profile?.role?.permissions,
    OrderPermission.MANAGE_ORDER_ADD,
    true
  );
  const checkPermissionOrderUpdate = PermissionUtil.check(
    profile?.role?.permissions,
    OrderPermission.MANAGE_ORDER_UPDATE,
    true
  );

  return (
    <Dialog fullScreen open={true}>
      <div className="order-detail">
        <SimpleModalHeader
          title={t("ORDER_DETAIL")}
          onClose={() => {
            order?.orderType === SourceType.VET
              ? history.push({
                  pathname: APP_ROUTES.ORDER_VET,
                  state: {
                    startTime: location.state?.startTime,
                    endTime: location.state?.endTime,
                    status: location.state?.status,
                  },
                })
              : history.push(APP_ROUTES.ORDER_VET);
          }}
        />
        <div className="order-detail-container">
          {!!order ? (
            <>
              <div className="content">
                <div className="order-detail-info">
                  <div className="col">
                    <div className="label">{t("ORDER_ID")}</div>#{order.code}
                  </div>
                  <div className="col">
                    <div className="label">{t("ORDER_TIME")}</div>
                    {moment(order.createdAt).format("DD/MM/YYYY - HH:mm")}
                  </div>
                  <div className="col">
                    <div className="label">{t("ORDER_PLACER")}</div>{" "}
                    {order.createdBy?.account?.code && order.createdBy?.account?.code + " - "}
                    {order.createdBy.fullName}
                  </div>
                  <div className="col col-status">
                    <div className="label">{t("STATUS")}</div>
                    {StatusUIUtil.renderBgStatus(order.status)}
                  </div>
                  <Grid item xs={3} justifyContent="flex-end" display="flex">
                    <button className="btn-cancel-style " onClick={() => setOpenBill(true)}>
                      <ShareOutlinedIcon fontSize="large" />
                      &ensp;{t("SHARE")}
                    </button>
                    {checkSource &&
                      (status.length !== 1 ? (
                        <Select
                          variant="outlined"
                          className="btn-green-style btn--shiny"
                          renderValue={() => <span>{t("OPTIONS")}</span>}
                          displayEmpty
                          startAdornment={
                            <InputAdornment position="start">
                              <GridViewIcon />
                            </InputAdornment>
                          }
                          inputProps={{ readOnly: false }}
                          MenuProps={{
                            className: "select-menu-list",
                            anchorOrigin: {
                              vertical: 60,
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                          }}>
                          {order?.orders.map((o: any) => {
                            return (() => {
                              switch (o.status) {
                                case !OrderStatus.CONFIRMED && !OrderStatus.CREATED:
                                  return (
                                    <>
                                      {o.canCancelOrUpdate === true && (
                                        <>
                                          <MenuItem
                                            onClick={() => {
                                              checkPermissionCancelOrder
                                                ? setShowCancel(true)
                                                : enqueueSnackbar(t("ACCESS_DENIED"), {
                                                    preventDuplicate: true,
                                                    variant: "error",
                                                  });
                                            }}>
                                            {t("CANCEL_ORDER")}
                                          </MenuItem>
                                          <MenuItem
                                            onClick={() => {
                                              if (checkPermissionOrderUpdate) {
                                                if (!checkCustomerDiv) {
                                                  setNotiCheckCusDiv(true);
                                                } else {
                                                  history.push(
                                                    APP_ROUTES.UPDATE_ORDER_VET.replace(
                                                      ":orderId",
                                                      order.id
                                                    )
                                                  );
                                                }
                                              } else {
                                                enqueueSnackbar(t("ACCESS_DENIED"), {
                                                  preventDuplicate: true,
                                                  variant: "error",
                                                });
                                              }
                                            }}>
                                            {t("EDIT_ORDER")}
                                          </MenuItem>
                                        </>
                                      )}
                                    </>
                                  );
                                case OrderStatus.PROCESSING:
                                  return (
                                    <MenuItem
                                      onClick={() => {
                                        if (checkPermissionOrderCreate) {
                                        } else {
                                          enqueueSnackbar(t("ACCESS_DENIED"), {
                                            preventDuplicate: true,
                                            variant: "error",
                                          });
                                        }

                                        if (!checkCustomerDiv) {
                                          setNotiCheckCusDiv(true);
                                        } else {
                                          history.push(
                                            APP_ROUTES.CREATE_FEED_ID.replace(":orderId", order.id)
                                          );
                                        }
                                      }}>
                                      {t("RESET_ORDER")}
                                    </MenuItem>
                                  );
                                case OrderStatus.COMPLETED:
                                  return (
                                    <MenuItem
                                      onClick={() => {
                                        if (checkPermissionOrderCreate) {
                                          if (!checkCustomerDiv) {
                                            setNotiCheckCusDiv(true);
                                          } else {
                                            history.push(
                                              APP_ROUTES.CREATE_FEED_ID.replace(
                                                ":orderId",
                                                order.id
                                              )
                                            );
                                          }
                                        } else {
                                          enqueueSnackbar(t("ACCESS_DENIED"), {
                                            preventDuplicate: true,
                                            variant: "error",
                                          });
                                        }
                                      }}>
                                      {t("RESET_ORDER")}
                                    </MenuItem>
                                  );
                                case OrderStatus.CREATED:
                                  return (
                                    <>
                                      {o.canCancelOrUpdate === true && (
                                        <MenuItem
                                          onClick={() => {
                                            if (checkPermissionOrderUpdate) {
                                              if (!checkCustomerDiv) {
                                                setNotiCheckCusDiv(true);
                                              } else {
                                                history.push(
                                                  APP_ROUTES.UPDATE_ORDER_VET.replace(
                                                    ":orderId",
                                                    order.id
                                                  )
                                                );
                                              }
                                            } else {
                                              enqueueSnackbar(t("ACCESS_DENIED"), {
                                                preventDuplicate: true,
                                                variant: "error",
                                              });
                                            }
                                          }}>
                                          {t("EDIT_ORDER")}
                                        </MenuItem>
                                      )}
                                    </>
                                  );
                                case OrderStatus.CONFIRMED:
                                  return (
                                    <>
                                      {o.canCancelOrUpdate === true && (
                                        <MenuItem
                                          onClick={() => {
                                            checkPermissionCancelOrder
                                              ? setShowCancel(true)
                                              : enqueueSnackbar(t("ACCESS_DENIED"), {
                                                  preventDuplicate: true,
                                                  variant: "error",
                                                });
                                          }}>
                                          {t("CANCEL_ORDER")}
                                        </MenuItem>
                                      )}
                                    </>
                                  );
                                default:
                                  return;
                              }
                            })();
                          })}
                        </Select>
                      ) : (
                        order?.orders.map((o: any, index: number) => {
                          if (status[index])
                            return (() => {
                              switch (o.status) {
                                case OrderStatus.CONFIRMED:
                                  return (
                                    <>
                                      {o.canCancelOrUpdate === true && (
                                        <button
                                          className="btn-confirm-style"
                                          onClick={() => {
                                            checkPermissionCancelOrder
                                              ? setShowCancel(true)
                                              : enqueueSnackbar(t("ACCESS_DENIED"), {
                                                  preventDuplicate: true,
                                                  variant: "error",
                                                });
                                          }}>
                                          <img src={IconClose} alt="icon-close" />
                                          {t("CANCEL_ORDER")}
                                        </button>
                                      )}
                                    </>
                                  );
                                case OrderStatus.PROCESSING:
                                  return (
                                    <button
                                      className="btn-confirm-style"
                                      onClick={() => {
                                        if (checkPermissionOrderCreate) {
                                          if (!checkCustomerDiv) {
                                            setNotiCheckCusDiv(true);
                                          } else {
                                            history.push(
                                              APP_ROUTES.CREATE_FEED_ID.replace(
                                                ":orderId",
                                                order.id
                                              )
                                            );
                                          }
                                        } else {
                                          enqueueSnackbar(t("ACCESS_DENIED"), {
                                            preventDuplicate: true,
                                            variant: "error",
                                          });
                                        }
                                      }}>
                                      <img src={IconBuy} alt="icon-buy" />
                                      {t("RESET_ORDER")}
                                    </button>
                                  );
                                case OrderStatus.COMPLETED:
                                  return (
                                    <button
                                      className="btn-confirm-style"
                                      onClick={() => {
                                        if (checkPermissionOrderCreate) {
                                          if (!checkCustomerDiv) {
                                            setNotiCheckCusDiv(true);
                                          } else {
                                            history.push(
                                              APP_ROUTES.CREATE_FEED_ID.replace(
                                                ":orderId",
                                                order.id
                                              )
                                            );
                                          }
                                        } else {
                                          enqueueSnackbar(t("ACCESS_DENIED"), {
                                            preventDuplicate: true,
                                            variant: "error",
                                          });
                                        }
                                      }}>
                                      <img src={IconBuy} alt="icon-buy" />
                                      {t("RESET_ORDER")}
                                    </button>
                                  );
                                case OrderStatus.CANCELLED:
                                  return (
                                    <button
                                      className="btn-confirm-style"
                                      onClick={() => {
                                        if (checkPermissionOrderCreate) {
                                          if (!checkCustomerDiv) {
                                            setNotiCheckCusDiv(true);
                                          } else {
                                            history.push(
                                              APP_ROUTES.CREATE_FEED_ID.replace(
                                                ":orderId",
                                                order.id
                                              )
                                            );
                                          }
                                        } else {
                                          enqueueSnackbar(t("ACCESS_DENIED"), {
                                            preventDuplicate: true,
                                            variant: "error",
                                          });
                                        }
                                      }}>
                                      <img src={IconBuy} alt="icon-buy" />
                                      {t("RESET_ORDER")}
                                    </button>
                                  );
                                case OrderStatus.CREATED:
                                  return (
                                    <>
                                      {o.canCancelOrUpdate === true && (
                                        <Select
                                          variant="outlined"
                                          className="btn-green-style btn--shiny"
                                          renderValue={() => (
                                            <span style={{ fontFamily: "SVN-Gotham" }}>
                                              {t("OPTIONS")}
                                            </span>
                                          )}
                                          displayEmpty
                                          startAdornment={
                                            <InputAdornment position="start">
                                              <GridViewIcon />
                                            </InputAdornment>
                                          }
                                          inputProps={{ readOnly: false }}
                                          MenuProps={{
                                            className: "select-menu-list",
                                            anchorOrigin: {
                                              vertical: 60,
                                              horizontal: "left",
                                            },
                                            transformOrigin: {
                                              vertical: "top",
                                              horizontal: "left",
                                            },
                                          }}>
                                          <MenuItem
                                            onClick={() => {
                                              if (checkPermissionOrderUpdate) {
                                                if (!checkCustomerDiv) {
                                                  setNotiCheckCusDiv(true);
                                                } else {
                                                  history.push(
                                                    APP_ROUTES.UPDATE_ORDER_VET.replace(
                                                      ":orderId",
                                                      order.id
                                                    )
                                                  );
                                                }
                                              } else {
                                                enqueueSnackbar(t("ACCESS_DENIED"), {
                                                  preventDuplicate: true,
                                                  variant: "error",
                                                });
                                              }
                                            }}>
                                            {t("EDIT_ORDER")}
                                          </MenuItem>
                                          <MenuItem
                                            onClick={() => {
                                              checkPermissionCancelOrder
                                                ? setShowCancel(true)
                                                : enqueueSnackbar(t("ACCESS_DENIED"), {
                                                    preventDuplicate: true,
                                                    variant: "error",
                                                  });
                                            }}>
                                            {t("CANCEL_ORDER")}
                                          </MenuItem>
                                        </Select>
                                      )}
                                    </>
                                  );
                                default:
                                  return;
                              }
                            })();
                        })
                      ))}
                  </Grid>
                </div>

                <div className={!checkSource ? "list list-farm" : "list"}>
                  {order.orders.map((g: any) => {
                    return (
                      <>
                        <div className="order-detail-header">
                          <div className="name">
                            {!checkSource && t("BREED")}
                            {checkSource && g.division?.description} ({g.orderlines.length})
                          </div>
                          <div className="price">{t("UNIT_PRICE")}</div>
                          {!checkSource && <div className="quan">{t("QUANTITY")}</div>}
                          {checkSource && (
                            <div className="order-quan">{t("REFERENCE_QUANTITY")}</div>
                          )}
                          {checkSource && (
                            <div className="expect-quan">{t("CONFIRMED_QUANTITY")}</div>
                          )}
                          {checkSource && (
                            <div className="actual-quan">{t("RECEIVED_QUANTITY")}</div>
                          )}
                          {!checkSource && <div className="weight">{t("TOTAL_WEIGHT")}</div>}
                          {checkSource && <div className="unit">{t("WEIGHT")}</div>}
                          {!checkSource && (
                            <div className="unit">{t("STOCK_FEE_EXCESS_PORTION")}</div>
                          )}
                          <div className="total">{t("TOTAL_PRICE")}</div>
                          <div className="chip">{StatusUIUtil.renderBgStatus(g.status)}</div>
                        </div>
                        {g.orderlines.map((o: any) => {
                          return (
                            <div
                              className="item"
                              style={{
                                borderLeft: `3px solid ${
                                  o.item?.color ? o.item?.color : "transparent"
                                }`,
                              }}>
                              <div className="name">
                                <img
                                  src={
                                    checkLinkImage(o.item.images?.picture) ||
                                    renderImageProNull(order?.orderType)
                                  }
                                  alt={o.item.shortName}
                                  onError={renderErrorImageItem(order?.orderType)}
                                />
                                <div>
                                  <span style={{ color: o.item?.color ?? "#0a6836" }}>
                                    {o.item.shortName}
                                  </span>
                                  <div className="code">{o.item.name}</div>
                                  {checkSource && (
                                    <div
                                      style={{
                                        fontSize: "1.4rem",
                                        color:
                                          o?.note !== "" &&
                                          o?.note !== null &&
                                          o?.note !== undefined
                                            ? "#B8292F"
                                            : "#5A5959",
                                      }}>
                                      <b style={{ color: "#5A5959" }}>{t("NOTES")}:</b>{" "}
                                      {o?.note || TEXT_HOLDER}
                                    </div>
                                  )}
                                  {!checkSource &&
                                    o.doOrderline?.deliveryOrders !== 0 &&
                                    o.doOrderline !== null && (
                                      <button
                                        className="btn-ship"
                                        onClick={() => {
                                          setOpenListDO(o.id);
                                        }}>
                                        {t("DELIVERED")} ({o.doOrderline?.deliveryOrders})
                                      </button>
                                    )}
                                </div>
                              </div>
                              <div className="price">
                                {checkPermissionViewPrice ? currencyFormat(o.price) : TEXT_ASTERISK}
                                &ensp;đ/
                                <span style={{ color: "#41ad49", fontWeight: "bold" }}>
                                  {!checkSource ? "kg" : o.saleUnit}
                                </span>
                              </div>
                              {!checkSource && (
                                <div className="quan">
                                  {o.orderQuantity ? parseFloat(o.orderQuantity) : TEXT_HOLDER}
                                </div>
                              )}
                              {checkSource && (
                                <div className="order-quan">
                                  {o.orderQuantity ? parseFloat(o.orderQuantity) : TEXT_HOLDER}
                                </div>
                              )}
                              {checkSource && (
                                <div className="expect-quan">
                                  {o.expectQuantity ? parseFloat(o.expectQuantity) : TEXT_HOLDER}
                                </div>
                              )}
                              {checkSource && (
                                <div className="actual-quan">
                                  {o.actualQuantity ? parseFloat(o.actualQuantity) : TEXT_HOLDER}
                                </div>
                              )}
                              {!checkSource && (
                                <div className="weight">
                                  {o.dimension}&ensp;&ensp;{o.baseUoM}
                                </div>
                              )}
                              {checkSource && (
                                <div className="unit">
                                  {currencyFormat(+o.dimension)} {o.item.baseUoM}
                                </div>
                              )}
                              {!checkSource && (
                                <div className="unit">
                                  {(() => {
                                    if (o.attachValue && o.prices?.priceType && +o.attachValue) {
                                      if (o.prices.priceType === "fixed") {
                                        return (
                                          (checkPermissionViewPrice
                                            ? currencyFormat(o.attachValue)
                                            : TEXT_ASTERISK) + " đ/con"
                                        );
                                      } else {
                                        return (
                                          (checkPermissionViewPrice
                                            ? currencyFormat(o.attachValue)
                                            : TEXT_ASTERISK) + " đ/kg"
                                        );
                                      }
                                    } else if (!o.attachValue || !o.prices?.priceType) {
                                      return TEXT_HOLDER;
                                    } else return TEXT_HOLDER;
                                  })()}
                                </div>
                              )}
                              <div className="total">
                                {checkPermissionViewPrice
                                  ? currencyFormat(o.subTotal)
                                  : TEXT_ASTERISK}
                                &ensp;đ
                              </div>
                              <div className="chip">
                                {checkSource &&
                                  o.expectQuantity !== null &&
                                  g.status === OrderStatus.CONFIRMED &&
                                  g.status === OrderStatus.PROCESSING &&
                                  (() => {
                                    if (
                                      +o.expectQuantity > +o.orderQuantity &&
                                      +o.orderQuantity !== 0
                                    ) {
                                      return <img src={Increase} alt="Increase" />;
                                    } else if (
                                      +o.expectQuantity < +o.orderQuantity &&
                                      +o.expectQuantity !== 0
                                    ) {
                                      return <img src={Decrease} alt="Decrease" />;
                                    } else if (+o.expectQuantity !== 0 && +o.orderQuantity === 0) {
                                      return <img src={Create} alt="Create" />;
                                    } else if (+o.expectQuantity === 0 && +o.orderQuantity !== 0) {
                                      return <img src={Cancel} alt="Cancel" />;
                                    } else return <></>;
                                  })()}
                                {checkSource &&
                                  o.actualQuantity !== null &&
                                  g.status === OrderStatus.COMPLETED &&
                                  (() => {
                                    if (
                                      +o.actualQuantity > +o.orderQuantity &&
                                      +o.orderQuantity !== 0
                                    ) {
                                      return <img src={Increase} alt="Increase" />;
                                    } else if (
                                      +o.actualQuantity < +o.orderQuantity &&
                                      +o.actualQuantity !== 0
                                    ) {
                                      return <img src={Decrease} alt="Decrease" />;
                                    } else if (+o.actualQuantity !== 0 && +o.orderQuantity === 0) {
                                      return <img src={Create} alt="Create" />;
                                    } else if (+o.actualQuantity === 0 && +o.orderQuantity !== 0) {
                                      return <img src={Cancel} alt="Cancel" />;
                                    } else return <></>;
                                  })()}
                              </div>
                            </div>
                          );
                        })}
                        <Divider />
                        <Grid container className="ship" spacing={1}>
                          {/* <Grid item xs={2}>
                            <div className="label">Tham chiếu</div>
                            {g.orderGFId || TEXT_HOLDER}
                          </Grid> */}
                          <Grid item xs={2}>
                            <div className="label">{t("DELIVERY_DATE")}</div>
                            {g.requiredDate}
                          </Grid>
                          <Grid item xs={2}>
                            <div className="label">{t("VEHICLE_NUMBER")}</div>
                            {g.vehicleNumber || TEXT_HOLDER}
                          </Grid>
                          <Grid item xs={3}>
                            <div className="label">{t("DELIVERY_ADDRESS")}</div>
                            <ThemeProvider theme={theme}>
                              <Tooltip arrow title={<span>{g.address || TEXT_HOLDER}</span>}>
                                <span>{g.address || TEXT_HOLDER}</span>
                              </Tooltip>
                            </ThemeProvider>
                          </Grid>
                          <Grid item xs={3}>
                            <div className="label">{t("RECEIVING_ADDRESS")}</div>
                            {g.salesOffice?.description || TEXT_HOLDER}
                          </Grid>
                          <Grid item xs={2}>
                            <div className="label">{t("NOTES")}</div>
                            <div className="order-note">
                              <ThemeProvider theme={theme}>
                                <Tooltip arrow title={<span>{g.note || TEXT_HOLDER}</span>}>
                                  <span
                                    style={{
                                      color:
                                        g.note !== "" && g.note !== null && g.note !== undefined
                                          ? "#B8292F"
                                          : "#5A5959",
                                    }}>
                                    {g.note || TEXT_HOLDER}
                                  </span>
                                </Tooltip>
                              </ThemeProvider>
                            </div>
                          </Grid>
                        </Grid>
                      </>
                    );
                  })}
                </div>
              </div>
              <div className="footer">
                <div className="total-gr">
                  <div style={{ width: "40rem" }}>
                    {order.orders.some((f: any) => f.invoice !== null) &&
                      order.orders.some((f: any) => f.invoice?.invoiceKey !== "") && (
                        <>
                          <div className="title">{t("LOOKUP_CODE")}</div>
                          {order.orders
                            .filter((f: any) => f.invoice && f.invoice.invoiceKey !== "")
                            .map((i: any) => {
                              return (
                                <div style={{ display: "flex" }}>
                                  <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={i.invoice?.invoiceUrl}
                                    style={{ color: "#41AD49", cursor: "pointer" }}>
                                    #{i.invoice?.arNumber}
                                    <br />
                                    <br />
                                  </a>
                                  &ensp;
                                  <div>
                                    &#8226;&ensp;{t("LOOKUP_CODE")}&ensp;
                                    <span style={{ fontWeight: "bold" }}>
                                      {i.invoice?.invoiceKey}
                                    </span>
                                    <br />
                                    <br />
                                  </div>
                                </div>
                              );
                            })}
                        </>
                      )}
                  </div>
                  <Grid
                    container
                    className="summary"
                    style={{ width: order?.orders?.length > 1 ? "55vw" : "30vw" }}>
                    <Grid item xs={gridSize}>
                      <div style={{ color: "transparent" }}>{t("ESTIMATED")}</div>
                      <div>{t("TOTAL_WEIGHT")}</div>
                      <div>{t("COMMERCIAL_DISCOUNT")}</div>
                      <div>{t("WITHHOLDING_TAX")}</div>
                      <div>{t("WITHHOLDING_PIP")}</div>
                      <div>{t("VAT")}</div>
                      <div className="text-bold">{t("TOTAL")}</div>
                    </Grid>
                    {order?.orders?.length > 1 &&
                      order?.orders.map((o: any) => {
                        return (
                          <Grid item xs={gridSize} className="right">
                            <div className="right-label">{o.division.description}</div>
                            <div>
                              {currencyFormat(
                                o.orderlines.reduce((sum: any, item: any) => {
                                  return (sum += +item.dimension);
                                }, 0)
                              )}{" "}
                              kg
                            </div>
                            <div>
                              {checkPermissionViewPrice
                                ? currencyFormat(parseInt(o.preDiscount))
                                : TEXT_ASTERISK}{" "}
                              đ
                            </div>
                            <div>
                              {checkPermissionViewPrice
                                ? currencyFormat(parseInt(o.commerceDiscount))
                                : TEXT_ASTERISK}{" "}
                              đ
                            </div>
                            <div>
                              {checkPermissionViewPrice
                                ? currencyFormat(parseInt(o.personalVat))
                                : TEXT_ASTERISK}{" "}
                              đ
                            </div>
                            <div>
                              {checkPermissionViewPrice
                                ? currencyFormat(parseInt(o.vat))
                                : TEXT_ASTERISK}{" "}
                              đ
                            </div>
                            <div className="text-bold" style={{ color: "#0A6836" }}>
                              {checkPermissionViewPrice
                                ? currencyFormat(+o.totalPay)
                                : TEXT_ASTERISK}{" "}
                              đ
                            </div>
                          </Grid>
                        );
                      })}
                    <Grid item xs={gridSize} className="right">
                      {order?.orders?.length > 1 ? (
                        <div className="right-label">{t("MASTER_ORDER")}</div>
                      ) : (
                        <div style={{ color: "transparent" }}>{t("MASTER_ORDER")}</div>
                      )}
                      <div>
                        {currencyFormat(
                          order.orders.reduce((sum: any, item: any) => {
                            const a = item.orderlines.reduce((sum: any, line: any) => {
                              sum += +line.dimension;
                              return sum;
                            }, 0);
                            return (sum += a);
                          }, 0)
                        )}{" "}
                        kg
                      </div>
                      <div>
                        {checkPermissionViewPrice
                          ? currencyFormat(parseInt(order.preDiscount))
                          : TEXT_ASTERISK}{" "}
                        đ
                      </div>
                      <div>
                        {checkPermissionViewPrice
                          ? currencyFormat(parseInt(order.commerceDiscount))
                          : TEXT_ASTERISK}{" "}
                        đ
                      </div>
                      <div>
                        {checkPermissionViewPrice
                          ? currencyFormat(parseInt(order.personalVat))
                          : TEXT_ASTERISK}{" "}
                        đ
                      </div>
                      <div>
                        {checkPermissionViewPrice
                          ? currencyFormat(parseInt(order.totalVat))
                          : TEXT_ASTERISK}{" "}
                        đ
                      </div>
                      <div className="text-bold" style={{ color: "#0A6836" }}>
                        {checkPermissionViewPrice
                          ? currencyFormat(+order.totalAmount)
                          : TEXT_ASTERISK}{" "}
                        đ
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <Accordion
                  // defaultExpanded={true}
                  disabled
                  className="update-history">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon style={{ color: "#272B2F" }} />}
                    className="title">
                    {t("UPDATE_HISTORY")}
                  </AccordionSummary>
                  <Divider style={{ marginBottom: "1.2rem" }} />
                  <AccordionDetails className="label">
                    <Grid container>
                      <Grid item xs={3}>
                        {t("TIME")}
                      </Grid>
                      <Grid item xs={3}>
                        {t("ACTION")}
                      </Grid>
                      <Grid item xs={2}>
                        {t("IMPLEMENT")}
                      </Grid>
                      <Grid item xs={4}>
                        {t("DETAIL")}
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                  {/* <div className="list-update"> */}
                  {UpdateHistory.map((u) => {
                    return (
                      <AccordionDetails key={u.id} className="item">
                        <Grid container>
                          <Grid item xs={3}>
                            {u.time}
                          </Grid>
                          <Grid item xs={3}>
                            {u.update}
                          </Grid>
                          <Grid item xs={2}>
                            {u.name}
                          </Grid>
                          <Grid item xs={4}>
                            {u.update}
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    );
                  })}
                  {/* </div> */}
                </Accordion>
              </div>
            </>
          ) : (
            <Loading style={{ margin: "2rem 0", height: "80vh" }} />
          )}
        </div>
      </div>
      <Dialog maxWidth="md" open={showCancel}>
        <div className="dialog-cancel">
          <div className="title">
            <div>{t("CANCEL_ORDER")}</div>
            <ClearIcon
              style={{ fontSize: "3rem", cursor: "pointer" }}
              onClick={() => {
                setShowCancel(false);
              }}
            />
          </div>
          <RadioGroup
            name="controlled-radio-buttons-group"
            value={cancelValue}
            onChange={(e) => {
              setCancelValue(e.target.value);
            }}>
            {ReasonStatus.map((r) => {
              return (
                <FormControlLabel
                  key={r.id}
                  value={r.value}
                  control={<Radio color="success" />}
                  label={t(r.label)}
                  className="text"
                />
              );
            })}
            <div className="label">{t("REASON")}</div>
            <TextField
              fullWidth
              placeholder={t("INPUT_BY_NAME", { NAME: t("REASON") })}
              className="form-textarea-field"
              multiline
              rows={3}
              variant="outlined"
              color="success"
              inputProps={{ className: "input" }}
              value={reason}
              onChange={(e) => {
                setReason(e.target.value);
              }}
            />
          </RadioGroup>
          <div className="btn-group">
            <button
              className="btn-cancel btn__text-cancel"
              onClick={() => {
                setShowCancel(false);
              }}>
              {t("NO")}
            </button>
            <button
              className="btn-confirm-style"
              style={{ marginLeft: "2.2rem" }}
              onClick={() => {
                setShowCancel(false);
                setSubmitCancel(true);
              }}>
              {t("CANCEL_ORDER")}
            </button>
          </div>
        </div>
      </Dialog>

      <Notification
        visible={submitCancel}
        title={t("CANCEL_ORDER")}
        subTitle={
          <span>
            {t("CANNOT_RECOVER_CANCELED_ORDER")}
            <br />
            {t("ARE_YOU_SURE_YOU_WANT_CANCEL_ORDER_BY_NAME", {
              NAME: `#${order?.code}`,
            })}
          </span>
        }
        icon={
          <div className={`notification-icon-box red`}>
            <PriorityHighRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
          </div>
        }
        actions={
          <>
            <button
              className="btn-confirm-style"
              style={{
                background: "#fff",
                color: "#0A6836",
                border: "0.1rem solid #0A6836",
                width: "20.6rem",
                marginRight: "2rem",
              }}
              onClick={() => {
                setSubmitCancel(false);
              }}>
              {t("NO")}
            </button>
            <button
              ref={submitRef}
              className="btn-confirm-style"
              style={{
                width: "20.6rem",
                marginLeft: "2rem",
              }}
              onClick={() => {
                cancelOrderVet();
              }}>
              {t("CONFIRM")}
            </button>
          </>
        }
        onClose={() => {
          setSubmitCancel(false);
        }}
      />
      {openListDO !== 0 && <DOList closeForm={() => setOpenListDO(0)} id={openListDO} />}

      <Notification
        visible={notiCheckCusDiv}
        title={t("NOTIFICATION")}
        subTitle={
          <span
            dangerouslySetInnerHTML={{
              __html: t("CONTACT_STAFF_GF_ACTIVE_ACCOUNT"),
            }}
          />
        }
        icon={
          <div className={`notification-icon-box yellow`}>
            <PriorityHighRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
          </div>
        }
        actions={
          <>
            <button
              className="btn-confirm-style"
              style={{
                width: "20.6rem",
                marginLeft: "2rem",
              }}
              onClick={() => {
                setNotiCheckCusDiv(false);
              }}>
              {t("CLOSE")}
            </button>
          </>
        }
        onClose={() => setNotiCheckCusDiv(false)}
      />

      <Dialog
        maxWidth="lg"
        open={openBill}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            // width: "427px",
            boxShadow: "none",
            borderRadius: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
        onClose={() => {
          setOpenBill(false);
        }}>
        <Bill billData={order} />
      </Dialog>
    </Dialog>
  );
};

export default OrderDetailVet;
