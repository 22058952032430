import { IconBase, IconComponent } from "@Core/Icons";

export const InterestedIcon2: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        d="M23.3 0.700161L24 0.700166M23.3 0.700161L23.3 0.000163496L24 0.700166M23.3 0.700161L0.70012 0.700005L0.700043 34.1996C0.700029 34.417 0.759488 34.6275 0.868277 34.8058C0.976901 34.984 1.12873 35.1206 1.3019 35.2044C1.47465 35.2879 1.66414 35.3168 1.84911 35.2898C2.03426 35.2628 2.21226 35.1802 2.36088 35.0467L2.36579 35.0423L2.36581 35.0423L11.5372 26.9607L12 26.5529L12.4628 26.9607L21.5313 34.9524L21.5532 34.9716L21.5734 34.9927C21.6698 35.0931 21.7823 35.1706 21.9034 35.2226C22.0244 35.2745 22.1528 35.3003 22.2814 35.2995L22.293 35.2994L22.3046 35.2998C22.4142 35.3027 22.5243 35.2788 22.6264 35.2282L22.6429 35.22L22.6598 35.2127C22.8413 35.1345 23.0021 34.9991 23.1181 34.8184C23.2341 34.6378 23.2985 34.4219 23.2999 34.198C23.2999 34.1974 23.2999 34.1968 23.2999 34.1963L23.3 0.700161ZM24 0.700166L23.9999 34.1996L24 0.700166ZM13.56 23.235L13.5579 23.2332C13.1252 22.8548 12.5742 22.6432 12 22.6432C11.4258 22.6432 10.8747 22.8548 10.442 23.2332L10.44 23.235L4.12859 28.7977V3.60112H2.7286V30.3478V31.8978L3.89144 30.8729L11.3637 24.287C11.3639 24.2868 11.3641 24.2866 11.3644 24.2864C11.5476 24.1265 11.7727 24.0432 12 24.0432C12.2272 24.0432 12.4523 24.1265 12.6356 24.2864C12.6358 24.2866 12.636 24.2868 12.6363 24.287L20.1085 30.8729L21.0342 29.8226L13.56 23.235Z"
        fill="#ECF7ED"
        stroke="#41AD49"
        strokeWidth="1.4"
      />
      <path
        d="M19.9083 13.2958C19.8612 13.1619 19.7755 13.0443 19.6617 12.9572C19.5479 12.8702 19.4108 12.8174 19.267 12.8053L15.0243 12.1977L13.1229 8.41296C13.0618 8.28919 12.9665 8.18481 12.8478 8.11177C12.7291 8.03874 12.5918 8 12.4518 8C12.3117 8 12.1744 8.03874 12.0558 8.11177C11.9371 8.18481 11.8417 8.28919 11.7807 8.41296L9.87927 12.1904L5.63651 12.8053C5.49851 12.8246 5.36877 12.8814 5.26201 12.9694C5.15525 13.0574 5.07575 13.173 5.03253 13.3031C4.99297 13.4303 4.98942 13.5656 5.02226 13.6946C5.0551 13.8235 5.1231 13.9413 5.21895 14.0352L8.29849 16.9634L7.55284 21.1215C7.52622 21.2588 7.54015 21.4006 7.59301 21.5303C7.64586 21.6601 7.73541 21.7722 7.8511 21.8536C7.96385 21.9327 8.09684 21.9794 8.23514 21.9885C8.37344 21.9975 8.51157 21.9686 8.63403 21.9048L12.4518 19.9502L16.2546 21.9121C16.3592 21.9701 16.4774 22.0004 16.5976 22C16.7555 22.0005 16.9096 21.9518 17.0375 21.8609C17.1532 21.7795 17.2428 21.6674 17.2956 21.5377C17.3485 21.4079 17.3624 21.2661 17.3358 21.1288L16.5901 16.9707L19.6697 14.0425C19.7773 13.953 19.8569 13.8353 19.8991 13.7031C19.9414 13.5709 19.9445 13.4297 19.9083 13.2958Z"
        fill="url(#paint0_linear_16903_60821)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_16903_60821"
          x1="12.4667"
          y1="8"
          x2="12.4667"
          y2="22"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#0A6836" />
          <stop offset="1" stop-color="#41AD49" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
