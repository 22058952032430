/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react-hooks/exhaustive-deps */
import urlSound from "@Assets/audio/gf-noti.mp3";
import Product from "@Assets/images/mock/pig.png";
import Ord from "@Assets/images/ordnull.png";
import {
  Action,
  AddressModal,
  FetchAction,
  ItemPermission,
  OrderDetailOutputModel,
  PaginateFetchReducer,
  ProductOutputModel,
} from "@Models";
import { CustomerService, OrderService, ProductService } from "@Services";
import { OrderUtil, PermissionUtil, checkLinkImage, currencyFormat } from "@Utils";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DeleteOutline as DeleteOutlineIcon, Search as SearchIcon } from "@mui/icons-material";
import { Dialog, Divider, Grid, InputAdornment, MenuItem, Select, TextField } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { AxiosError } from "axios";
import { vi } from "date-fns/locale";
import _ from "lodash";
import _debounce from "lodash/debounce";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import InfiniteScroll from "react-infinite-scroller";
import { useHistory } from "react-router-dom";
// import { DetachIcon } from "@Components/Icons/DetachIcon";
import { EventIcon } from "@Components/Icons/EventIcon";
import Loading from "@Components/Loading";
import Notification from "@Components/Notification";
import ProductItem from "@Components/ProductItem";
import QuantityBtnGroup from "@Components/QuantityBtnGroup";
import ResultOrder from "@Components/ResultOrder";
import ShippingAddress from "@Components/ShippingAddress";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import { APP_ROUTES, ConditionQuantity, TEXT_ASTERISK, TEXT_HOLDER } from "@Constants";
import "./OrderModalFarm.scss";
import { useTranslation } from "react-i18next";

function reducer(state: PaginateFetchReducer<ProductOutputModel>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: [...state.data, ...action.payload.data] as ProductOutputModel[],
      };
    case Action.RESET:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload.data,
      };

    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload };

    default:
      return { ...state };
  }
}

type OrderProduct = OrderDetailOutputModel & ProductOutputModel;

const OrderModalFarm: React.FC = () => {
  const { t } = useTranslation(["translation"]);
  //notification sound
  const [audio] = useState<any>(new Audio(urlSound));
  const [playing, setPlaying] = useState<boolean>(false);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing]);

  useEffect(() => {
    audio.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
    };
  }, []);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const mounted = useRef(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const { enqueueSnackbar } = useSnackbar();
  const [notiOrder, setNotiOrder] = useState<{
    visible: boolean;
    newOrder: boolean;
  }>({
    visible: false,
    newOrder: false,
  });
  const [notiOrderNull, setNotiOrderNull] = useState<boolean>(false);
  const [ordDraft, setOrdDraft] = useState<boolean>(false);
  const [interestedSuccess, setInterestedSuccess] = useState<boolean>(false);

  const [state, dispatch] = useReducer(reducer, {
    page: 0,
    totalPages: 0,
    limit: 20,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
  });
  const [dataOrd, setDataOrd] = useState<any>({});

  const [profile, setProfile] = useState<any>([]);

  const loadProfileData = _debounce(async () => {
    const response = await CustomerService.getAuthProfile();
    setProfile(response.data);
  }, 100);

  useEffect(() => {
    loadProfileData();
  }, []);

  const cusDivFarm = profile.customer?.find((cus: any) => cus.source === "farm");

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const fetchData = async (query: any, isReset: boolean = false) => {
    if (state.fetching) return;
    dispatch({ type: Action.FETCH, payload: {} });
    try {
      const result = await ProductService.filterProducts(query);
      if (isReset)
        dispatch({
          type: Action.RESET,
          payload: result.data,
        });
      else if (mounted.current)
        dispatch({
          type: Action.SUCCESS,
          payload: result.data,
        });
    } catch (err) {
      if (mounted)
        dispatch({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
    }
  };

  useEffect(() => {
    fetchData(
      {
        page: 1,
        limit: 20,
        searchValue: searchValue,
        source: "farm",
      },
      true
    );
  }, [searchValue, interestedSuccess]);

  const hasMore = state.data.length < state.totalRecords && state.page < state.totalPages;

  // const [customerAddress, setCustomerAddress] = useState<string>("");

  const [proFarm, setProFarm] = useState<
    {
      item: OrderProduct;
      quantity: number;
      dimension: any;
      date?: any;
      orderAddress?: string;
      note?: string;
    }[]
  >([]);

  const onUpdateProdVarDimension = (
    index: number,
    variantId: number,
    quantity: number,
    dimen: any,
    addedProduct?: OrderProduct,
    orderDate?: any,
    ordAddress?: any,
    note?: string
  ) => {
    const idx = proFarm.findIndex((item) => item.item.item.id === variantId);

    let data = [...proFarm];
    if (idx !== -1) {
      data[index] = {
        ...data[index],
        quantity: quantity || 1,
        dimension: dimen,
        date: orderDate,
        orderAddress: ordAddress || "",
        note: note || "",
      };
    } else {
      // thêm mới
      if (!addedProduct) {
        return;
      }

      data.push({
        item: addedProduct,
        quantity,
        dimension: dimen,
        date: orderDate,
        orderAddress: ordAddress,
        note: note,
      });
    }

    data = data.filter((item) => item.quantity >= 1);

    setProFarm(data);
  };

  const total = proFarm.reduce((sum, val) => {
    const valDimen = Array.isArray(val.dimension)
      ? !+val.dimension[1]
        ? val.dimension[0]
        : val.dimension[1]
      : +val.item.item.dimension;

    const valPriceDimen = +val.item.dimension;

    switch (val.item.priceType) {
      case "bs":
        return sum + val.quantity * val.item.value * valDimen;
      case "fixed":
        return (
          sum + (valDimen * val.item.value + parseFloat(val.item.additionValue)) * val.quantity
        );
      default:
        return (
          sum +
          valPriceDimen * val.item.value * val.quantity +
          Math.max(0, valDimen - valPriceDimen) * val.quantity * +val.item.additionValue
        );
    }
  }, 0);

  const [checkError, setCheckError] = useState<boolean>(false);
  const checkDate = proFarm.filter((item) => item.date === undefined)?.length;
  const checkAddress = proFarm.filter((item) => item.orderAddress === "")?.length;

  const submitForm = async (values: any) => {
    if (checkError) {
      return;
    }

    const validProDim = proFarm.filter((item) => item.quantity);
    if (!validProDim.length) {
      setNotiOrderNull(true);
    }
    let total = 0;
    let subTotal = 0;
    const orderDetails = validProDim.map((item) => {
      const valDimention = Array.isArray(item.dimension)
        ? !+item.dimension[1]
          ? item.dimension[0]
          : item.dimension[1]
        : +item.dimension;

      const valDimentionToShow = Array.isArray(item.dimension)
        ? !+item.dimension[0]
          ? item.dimension[1]
          : item.dimension[0]
        : 0;

      const valPriceDimen = +item.item.dimension;

      switch (item.item.priceType) {
        case "bs":
          subTotal = valDimention * item.quantity * item.item.value;
          break;
        case "fixed":
          subTotal =
            (valDimention * item.item.value + parseFloat(item.item.additionValue)) * item.quantity;
          break;
        case "increment":
          subTotal =
            valPriceDimen * item.item.value * item.quantity +
            Math.max(0, valDimention - valPriceDimen) * item.quantity * +item.item.additionValue;
          break;
        default:
      }

      total += subTotal;
      return {
        itemId: item.item.item.id,
        divisionId: item.item.item.division.id,
        discount: 0,
        quantity: item.quantity,
        unitPrice: checkPermissionViewPrice ? item.item.value : -1,
        attachValue: checkPermissionViewPrice ? item.item.additionValue : -1,
        note: item.note,
        address: item.orderAddress,
        requiredDate: moment(item.date || new Date()).format("YYYY-MM-DD"),
        saleUnit: item.item.item.saleUnit,
        baseUoM: item.item.item.baseUoM,
        dimension: valDimention * item.quantity,
        dimensionToShow: +valDimentionToShow,
        subTotal: checkPermissionViewPrice ? subTotal : -1,
      };
    });

    const data: Parameters<typeof OrderService.createOrder>[0] = {
      customerId: cusDivFarm.id,
      creatorId: cusDivFarm.id,
      salesOrgId: cusDivFarm.customerDivision[0].salesOrg.id,
      distributionChannelId: cusDivFarm.customerDivision[0].distributionChannel.id,
      address: cusDivFarm.address,
      currency: "cash",
      totalDiscount: 0,
      vat: 0,
      orderAmount: checkPermissionViewPrice ? total : -1,
      isDraft: ordDraft,
      source: "farm",
      orderDetails: orderDetails,
      requiredDate: moment(values.requiredDate || new Date()).format("YYYY-MM-DD"),
    } as any;

    OrderService.createOrder(data as any)
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          if (ordDraft === true) {
            history.push(APP_ROUTES.ORDER_FARM);
          } else {
            setNotiOrder({ visible: true, newOrder: true });
            setDataOrd(res.data.data);
            setPlaying(true);
            setLoading(false);
          }
        } else {
          setLoading(false);
          // setNotiOrder({ visible: true, newOrder: false });
        }
      })
      .catch((err: AxiosError) => {
        setLoading(false);
        if (err.response?.status === 400) {
          enqueueSnackbar(t("INVALID_NAME", { NAME: t("QUANTITY") }), {
            variant: "error",
          });
        } else {
          setNotiOrder({ visible: true, newOrder: false });
        }
      });
  };

  const onSearch = _.debounce((value) => {
    setSearchValue(value);
  }, 1000);

  const setDefaultImage = (e: any) => {
    e.target.src = Product;
  };

  const saveAddress = async (val: string) => {
    await CustomerService.createAddress({
      customerId: cusDivFarm.id,
      name: cusDivFarm?.fullName,
      phoneNumber: cusDivFarm?.phoneNumber,
      address: val,
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          enqueueSnackbar(t("ADDRESS_CREATION_SUCCESSFUL"), {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        enqueueSnackbar(t("ADDRESS_CREATION_FAILED"), {
          variant: "error",
        });
      });
  };

  const updateProductInterested = async (id: number, interested: boolean) => {
    try {
      const res = await ProductService.updateProductInterested({
        itemId: id,
        interested: interested,
        source: "farm",
      });
      if (res && (res.status === 200 || res.status === 201)) {
        setInterestedSuccess(!interestedSuccess);
      }
    } catch (error: any) {}
  };

  //permission
  const checkPermissionViewPrice = PermissionUtil.check(
    profile?.role?.permissions,
    ItemPermission.MANAGE_ITEM_VIEW_PRICE,
    true
  );

  return (
    <Dialog fullScreen open={true}>
      <div className="order-modal-farm">
        <SimpleModalHeader
          title={t("CREATE_ORDER_FARM")}
          onClose={() => {
            history.push(APP_ROUTES.ORDER_FARM);
          }}
        />
        <div className="order-form">
          <Grid container>
            <Grid item xs={9} className="order-form-left">
              <Form onSubmit={async (values) => submitForm(values)}>
                {({ handleSubmit }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <div className="product-order">
                        <div className="product-order-header">
                          <div className="prod">{t("PRODUCT")}</div>
                          <div className="quan">{t("QUANTITY")}</div>
                          <div className="dimen">{t("WEIGHT_RANGE")}</div>
                          <div className="unit">{t("TOTAL_WEIGHT")}</div>
                          <div className="price">{t("UNIT_PRICE")}</div>
                          <div className="additionValue">{t("STOCK_FEE_EXCESS_PORTION")}</div>
                          <div className="pay">{t("TOTAL_PRICE")}</div>
                          <div className="ic-del"></div>
                        </div>
                        <Divider style={{ marginBottom: "3.3rem" }} />
                        <div className="product-order-list">
                          {!!proFarm.length &&
                            proFarm.map((val, index) => {
                              return (
                                <>
                                  <div className="product-order-card">
                                    <div className="prod">
                                      <img
                                        src={
                                          checkLinkImage(val.item.item.images?.picture as any) ||
                                          Product
                                        }
                                        alt="product"
                                        onError={setDefaultImage}
                                      />
                                      <div className="name">
                                        {val.item.item.name}
                                        <div className="code">{val.item.item.code}</div>
                                      </div>
                                    </div>

                                    <div className="quan">
                                      <QuantityBtnGroup
                                        disableMinusBtn={val.quantity <= 1}
                                        quantity={val.quantity}
                                        onChangeQuantity={(value) => {
                                          onUpdateProdVarDimension(
                                            index,
                                            val.item.item.id,
                                            value,
                                            val.dimension,
                                            val.item,
                                            val.date,
                                            val.orderAddress,
                                            val.note
                                          );
                                        }}
                                        style={{ maxWidth: "12rem", maxHeight: "3.5rem" }}
                                      />
                                    </div>

                                    {Array.isArray(val.item.item.dimension) === true ? (
                                      <div className="dimen-cond">
                                        {Array.isArray(val.item.item.dimension) && (
                                          <>
                                            <Select
                                              defaultValue={
                                                val.dimension
                                                  ? val.dimension.toString()
                                                  : val.item.item.dimension[0].toString()
                                              }
                                              // value={valueWeight.toString()}
                                              onChange={(e) => {
                                                onUpdateProdVarDimension(
                                                  index,
                                                  val.item.item.id,
                                                  val.quantity,
                                                  e.target.value.split(","),
                                                  val.item,
                                                  val.date,
                                                  val.orderAddress,
                                                  val.note
                                                );
                                              }}
                                              displayEmpty
                                              variant="standard"
                                              color="success"
                                              fullWidth
                                              MenuProps={{
                                                className: "select-menu-list item-weight",
                                                anchorOrigin: {
                                                  vertical: 30,
                                                  horizontal: "left",
                                                },
                                                transformOrigin: {
                                                  vertical: "top",
                                                  horizontal: "left",
                                                },
                                              }}>
                                              {val.item.item.dimension.map((d) => {
                                                return (
                                                  <MenuItem
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "column",
                                                      alignItems: "flex-start",
                                                    }}
                                                    value={d.toString()}>
                                                    {(() => {
                                                      if (d[1] === "null") {
                                                        return "trên " + d[0];
                                                      } else if (d[0] === "null") {
                                                        return "dưới " + d[1];
                                                      } else {
                                                        return d[0] + " - " + d[1];
                                                      }
                                                    })()}
                                                    &#160;kg
                                                  </MenuItem>
                                                );
                                              })}
                                            </Select>
                                            {val.item.item.conditionQuantity !== null && (
                                              <div
                                                className={
                                                  OrderUtil.checkQuantityOrderFarm(
                                                    val.quantity,
                                                    val.item.item.conditionQuantity as any
                                                  )
                                                    ? "err-quantity"
                                                    : "err-quantity err"
                                                }>
                                                {t("REQUIRED_QUANTITY")}&nbsp;
                                                <span style={{ textTransform: "lowercase" }}>
                                                  {
                                                    ConditionQuantity.find(
                                                      (f: any) =>
                                                        val.item.item.conditionQuantity?.replace(
                                                          /\d/g,
                                                          ""
                                                        ) === f.value
                                                    )?.title
                                                  }
                                                </span>
                                                &nbsp;
                                                {val.item.item.conditionQuantity?.match(
                                                  /[0-9]+/g
                                                )?.[1]
                                                  ? val.item.item.conditionQuantity?.match(
                                                      /[0-9]+/g
                                                    )?.[0] +
                                                    "-" +
                                                    val.item.item.conditionQuantity?.match(
                                                      /[0-9]+/g
                                                    )?.[1]
                                                  : val.item.item.conditionQuantity?.match(
                                                      /[0-9]+/g
                                                    )?.[0]}
                                              </div>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    ) : (
                                      <div className="dimen">
                                        {val.item.item.dimension}
                                        <span style={{ color: "#41AD49" }}>
                                          &#160;{val.item.item.baseUoM}
                                        </span>
                                        {val.item.item.conditionQuantity !== null && (
                                          <div
                                            className={
                                              OrderUtil.checkQuantityOrderFarm(
                                                val.quantity,
                                                val.item.item.conditionQuantity as any
                                              )
                                                ? "err-quantity"
                                                : "err-quantity err"
                                            }>
                                            {t("REQUIRED_QUANTITY")}&nbsp;
                                            <span style={{ textTransform: "lowercase" }}>
                                              {
                                                ConditionQuantity.find(
                                                  (f: any) =>
                                                    val.item.item.conditionQuantity?.replace(
                                                      /\d/g,
                                                      ""
                                                    ) === f.value
                                                )?.title
                                              }
                                            </span>
                                            &nbsp;
                                            {val.item.item.conditionQuantity?.match(/[0-9]+/g)?.[1]
                                              ? val.item.item.conditionQuantity?.match(
                                                  /[0-9]+/g
                                                )?.[0] +
                                                "-" +
                                                val.item.item.conditionQuantity?.match(
                                                  /[0-9]+/g
                                                )?.[1]
                                              : val.item.item.conditionQuantity?.match(
                                                  /[0-9]+/g
                                                )?.[0]}
                                          </div>
                                        )}
                                      </div>
                                    )}
                                    <div className="unit">
                                      {Array.isArray(val.dimension)
                                        ? !+val.dimension[1]
                                          ? currencyFormat(val.dimension[0] * val.quantity)
                                          : currencyFormat(val.dimension[1] * val.quantity)
                                        : currencyFormat(+val.dimension * val.quantity)}
                                      {/* {val.dimension * val.quantity} */}

                                      <span style={{ color: "#41AD49" }}>
                                        &#160;kg
                                        {/* {val.item.item.baseUoM} */}
                                      </span>
                                    </div>
                                    <div className="price text">
                                      {checkPermissionViewPrice
                                        ? currencyFormat(val.item.value)
                                        : TEXT_ASTERISK}{" "}
                                      đ/
                                      <span>{val.item.baseUoM}</span>
                                    </div>
                                    <div className="additionValue">
                                      {checkPermissionViewPrice
                                        ? +val.item.additionValue !== 0
                                          ? currencyFormat(+val.item.additionValue)
                                          : TEXT_HOLDER
                                        : TEXT_ASTERISK}{" "}
                                      đ/
                                      {(() => {
                                        if (+val.item.additionValue !== 0) {
                                          if (val.item.priceType === "increment") {
                                            return (
                                              <span
                                                style={{
                                                  color: "#41AD49",
                                                  textTransform: "lowercase",
                                                }}>
                                                {val.item.item.baseUoM}
                                              </span>
                                            );
                                          }
                                          return (
                                            <span
                                              style={{
                                                color: "#41AD49",
                                                textTransform: "lowercase",
                                              }}>
                                              {val.item.item.saleUnit}
                                            </span>
                                          );
                                        }
                                      })()}
                                    </div>

                                    <div className="pay">
                                      {(() => {
                                        const valDimen = Array.isArray(val.dimension)
                                          ? !+val.dimension[1]
                                            ? val.dimension[0]
                                            : val.dimension[1]
                                          : +val.dimension;

                                        const valPriceDimen = +val.item.dimension;

                                        switch (val.item.priceType) {
                                          case "bs":
                                            return (
                                              <>
                                                {checkPermissionViewPrice
                                                  ? currencyFormat(
                                                      val.quantity * valDimen * val.item.value
                                                    )
                                                  : TEXT_ASTERISK}
                                              </>
                                            );
                                          case "fixed":
                                            return (
                                              <>
                                                {checkPermissionViewPrice
                                                  ? currencyFormat(
                                                      (valDimen * val.item.value +
                                                        parseFloat(val.item.additionValue)) *
                                                        val.quantity
                                                    )
                                                  : TEXT_ASTERISK}
                                              </>
                                            );
                                          default:
                                            return (
                                              <>
                                                {checkPermissionViewPrice
                                                  ? currencyFormat(
                                                      valPriceDimen *
                                                        val.item.value *
                                                        val.quantity +
                                                        Math.max(0, valDimen - valPriceDimen) *
                                                          val.quantity *
                                                          parseFloat(val.item.additionValue)
                                                    )
                                                  : TEXT_ASTERISK}
                                              </>
                                            );
                                        }
                                      })()}
                                      &ensp;đ
                                    </div>
                                    <div
                                      className="ic-del"
                                      onClick={() => {
                                        onUpdateProdVarDimension(
                                          index,
                                          val.item.item.id,
                                          -1,
                                          val.dimension
                                        );
                                      }}>
                                      <DeleteOutlineIcon
                                        style={{
                                          fontSize: "2.2rem",
                                          marginLeft: "3rem",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="product-order-shipping">
                                    <div className="date">
                                      <Field name="requiredDate">
                                        {({ input, meta, ...rest }) => {
                                          return (
                                            <MuiPickersUtilsProvider
                                              locale={vi}
                                              utils={DateFnsUtils}>
                                              <KeyboardDatePicker
                                                okLabel={t("SELECT")}
                                                cancelLabel={t("CANCEL")}
                                                fullWidth
                                                disableToolbar
                                                disablePast
                                                inputVariant="standard"
                                                format="dd-MM-yyyy"
                                                placeholder={t("DELIVERY_DATE")}
                                                className="form-text-field date"
                                                value={val.date || null}
                                                onChange={(e) => {
                                                  input.onChange(e);
                                                  onUpdateProdVarDimension(
                                                    index,
                                                    val.item.item.id,
                                                    val.quantity,
                                                    val.dimension,
                                                    val.item,
                                                    e,
                                                    val.orderAddress,
                                                    val.note
                                                  );
                                                }}
                                                InputProps={{ readOnly: true }}
                                                inputProps={{ className: "input" }}
                                                keyboardIcon={
                                                  <EventIcon
                                                    color="#41AD49"
                                                    size={[22, 22]}
                                                    viewBox={[22, 22]}
                                                  />
                                                }
                                                helperText={meta.touched ? meta.error : ""}
                                                error={meta.error && meta.touched}
                                              />
                                            </MuiPickersUtilsProvider>
                                          );
                                        }}
                                      </Field>
                                      <div
                                        style={{
                                          color: "#e53935",
                                          fontSize: "1.4rem",
                                        }}>
                                        {checkError &&
                                          val.date === undefined &&
                                          t("SELECT_BY_NAME", {
                                            NAME: t("DELIVERY_DATE"),
                                          })}
                                      </div>
                                    </div>
                                    <Field name="note">
                                      {({ input, meta, ...rest }) => {
                                        return (
                                          <TextField
                                            {...input}
                                            {...rest}
                                            fullWidth
                                            placeholder={t("NOTES")}
                                            className="form-text-field form-text-field-note"
                                            inputProps={{ className: "input" }}
                                            variant="standard"
                                            autoComplete="off"
                                            value={val.note}
                                            onChange={(e) => {
                                              input.onChange(e);
                                              onUpdateProdVarDimension(
                                                index,
                                                val.item.item.id,
                                                val.quantity,
                                                val.dimension,
                                                val.item,
                                                val.date,
                                                val.orderAddress,
                                                e.target.value
                                              );
                                            }}
                                            helperText={meta.touched ? meta.error : ""}
                                            error={meta.error && meta.touched}
                                            onKeyDown={(event) => {
                                              if (event.key === "Enter") {
                                                event.preventDefault();
                                                return false;
                                              }
                                            }}
                                          />
                                        );
                                      }}
                                    </Field>
                                    <Field name="address">
                                      {({ input, meta, ...rest }) => {
                                        return (
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              width: "35%",
                                            }}>
                                            <ShippingAddress<AddressModal>
                                              source="farm"
                                              handleSaveAddress={saveAddress}
                                              className={"form-text-field addr"}
                                              placeholder={t("SELECT_BY_NAME", {
                                                NAME: t("DELIVERY_ADDRESS"),
                                              })}
                                              getOptionLabel={(opt) =>
                                                opt.address || (opt.inputValue as any)
                                              }
                                              onChange={(value) => {
                                                onUpdateProdVarDimension(
                                                  index,
                                                  val.item.item.id,
                                                  val.quantity,
                                                  val.dimension,
                                                  val.item,
                                                  val.date,
                                                  value?.address || value?.inputValue,
                                                  val.note
                                                );
                                              }}
                                              renderOption={(p) => (
                                                <div
                                                  className="item-addr"
                                                  style={{
                                                    fontSize: "1.6rem",
                                                    fontFamily: "SVN-Gotham",
                                                    color: p.title ? "#41AD49" : "",
                                                    borderBottom: "0.1rem solid #EFEFEF",
                                                    padding: "1rem 2rem",
                                                    width: "100%",
                                                  }}>
                                                  {p.title ? p.title : p.address}
                                                </div>
                                              )}
                                              actionConfig={{
                                                action: CustomerService.getAddress,
                                              }}
                                              dropdownBoxMaxHeight="24rem"
                                            />
                                            <div
                                              style={{
                                                color: "#e53935",
                                                fontSize: "1.4rem",
                                                marginLeft: "5rem",
                                              }}>
                                              {checkError &&
                                                val.orderAddress === "" &&
                                                t("SELECT_BY_NAME", {
                                                  NAME: t("DELIVERY_ADDRESS"),
                                                })}
                                            </div>
                                          </div>
                                        );
                                      }}
                                    </Field>
                                  </div>
                                  {/* <div className="detach-more">
                                    <DetachIcon
                                      color="#41AD49"
                                      size={[21, 20]}
                                      viewBox={[20, 12]}
                                    />
                                    <div
                                      className="text"
                                      onClick={() =>
                                        onDetachOrder(val.item.item.id, 1, val.dimension, val.item)
                                      }>
                                      Tách dòng
                                    </div>
                                  </div> */}
                                </>
                              );
                            })}
                        </div>
                      </div>
                      <div className="footer">
                        <div className="payment">
                          <Grid container justifyContent="space-between">
                            <Grid item xs={7} className="info-order"></Grid>
                            <Grid item xs={4} className="total">
                              <div className="row">
                                {t("ESTIMATED")}
                                <div className="price">
                                  {checkPermissionViewPrice ? currencyFormat(total) : TEXT_ASTERISK}{" "}
                                  đ
                                </div>
                              </div>
                              <div className="row">
                                {t("SHIPPING_FEE")}
                                <div className="price">0 đ</div>
                              </div>
                              <div className="row">
                                {t("TAX")}
                                <div className="price">0 đ</div>
                              </div>
                              <div className="row">
                                {t("TOTAL")}
                                <div className="price price-color">
                                  {checkPermissionViewPrice ? currencyFormat(total) : TEXT_ASTERISK}{" "}
                                  đ
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        <div
                          className={proFarm.length ? "show" : "hide"}
                          onClick={() => {
                            !proFarm.length && setNotiOrderNull(false);
                          }}>
                          <button
                            disabled={!proFarm.length}
                            onClick={() => {
                              if (checkDate > 0 || checkAddress > 0) {
                                setCheckError(true);
                              } else {
                                setCheckError(false);
                                setOrdDraft(true);
                                setLoading(true);
                              }
                            }}
                            type="submit"
                            className="btn-confirm-style btn-border"
                            style={{ marginLeft: "4rem" }}>
                            {t("SAVE_CART")}
                          </button>
                          <button
                            disabled={!proFarm.length}
                            onClick={() => {
                              if (checkDate > 0 || checkAddress > 0) {
                                setCheckError(true);
                              } else {
                                setCheckError(false);
                                setLoading(true);
                              }
                            }}
                            type="submit"
                            className=" btn-confirm-style"
                            style={{ marginLeft: "4rem" }}>
                            {t("PLACE_ORDER")}
                          </button>
                        </div>
                      </div>
                    </form>
                  );
                }}
              </Form>
            </Grid>

            <Grid item xs={3} className="order-form-right">
              <TextField
                variant="outlined"
                color="success"
                placeholder={t("SEARCH_BY_NAME_PRODUCT_CODE")}
                className={"search-border-style"}
                inputProps={{ className: "input" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="large" />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  onSearch(e.target.value);
                }}
              />
              <div className="col-text">{t("PIG_PRICE_TEMPORARY")}</div>
              <div className="product-list">
                {state.fetching && searchValue && <Loading />}
                {state.data.length > 0 && (
                  <InfiniteScroll
                    pageStart={0}
                    loadMore={() =>
                      fetchData({
                        page: state.page + 1,
                        limit: 20,
                        source: "farm",
                        searchValue,
                      })
                    }
                    hasMore={hasMore}
                    useWindow={false}>
                    {state.data.map((pro: any, index: number) => {
                      const act = proFarm.find((item) => item.item.item.id === pro.item.id);
                      const checkDimen = Array.isArray(pro.item.dimension);

                      return (
                        <ProductItem
                          class={act}
                          key={pro.item.code + index}
                          id={pro.item.id}
                          onClick={() => {
                            if (act) {
                              return;
                            }
                            onUpdateProdVarDimension(
                              index,
                              pro.item.id,
                              1,
                              checkDimen ? pro.item.dimension[0] : pro.item.dimension,
                              pro
                            );
                          }}
                          image={pro.item.images?.picture}
                          shortName={pro.item.name}
                          name={pro.item.code}
                          value={
                            checkPermissionViewPrice ? currencyFormat(pro.value) : TEXT_ASTERISK
                          }
                          baseUoM={pro.baseUoM}
                          source="farm"
                          priceType={pro.priceType}
                          farmBaseUoM={pro.item.baseUoM}
                          farmSaleUnit={pro.item.saleUnit}
                          additionalValue={currencyFormat(pro.additionValue)}
                          interestedItem={pro.item.interestedItem}
                          updateProductInterested={(interested: boolean) =>
                            updateProductInterested(pro.item.id, interested)
                          }
                        />
                      );
                    })}
                  </InfiniteScroll>
                )}
              </div>
            </Grid>
          </Grid>
        </div>

        <ResultOrder
          visible={notiOrder.visible}
          newOrder={notiOrder.newOrder}
          dataOrd={dataOrd}
          setNotiOrder={() => setNotiOrder({ visible: false, newOrder: false })}
          source="farm"
        />

        {loading && (
          <Backdrop
            sx={{ color: "red", opacity: 0.5, zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}>
            <Loading />
          </Backdrop>
        )}

        <Notification
          visible={notiOrderNull}
          title={t("EMPTY_ORDER")}
          subTitle={t("ORDER_EMPTY_ADD_PRODUCT")}
          icon={
            <div className={`notification-icon-box`}>
              <img src={Ord} alt="ord-nulll" style={{ objectFit: "contain", width: "9.2rem" }} />
            </div>
          }
          actions={
            <button
              className="btn-confirm-style"
              style={{
                width: "20.6rem",
                marginRight: "2rem",
              }}
              onClick={() => {
                setNotiOrderNull(false);
              }}>
              {t("GO_BACK")}
            </button>
          }
          onClose={() => {
            setNotiOrderNull(false);
          }}
        />
      </div>
    </Dialog>
  );
};

export default OrderModalFarm;
