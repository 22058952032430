/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react-hooks/exhaustive-deps */
import urlSound from "@Assets/audio/gf-noti.mp3";
import ProductImage from "@Assets/images/mock/pig.png";
import { EventIcon } from "@Components/Icons/EventIcon";
import { MapIcon } from "@Components/Icons/MapIcon";
import Loading from "@Components/Loading";
import Notification from "@Components/Notification";
import ProductItem from "@Components/ProductItem";
import QuantityBtnGroup from "@Components/QuantityBtnGroup";
import ResultOrder from "@Components/ResultOrder";
import ShippingAddress from "@Components/ShippingAddress";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import { APP_ROUTES, ConditionQuantity, TEXT_ASTERISK, TEXT_HOLDER } from "@Constants";
import {
  Action,
  AddressModal,
  FetchAction,
  ItemPermission,
  OrderDetailOutputModel,
  PaginateFetchReducer,
  ProductOutputModel,
} from "@Models";
import { CustomerService, OrderService, ProductService } from "@Services";
import { OrderUtil, PermissionUtil, checkLinkImage, currencyFormat } from "@Utils";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  DeleteOutline as DeleteOutlineIcon,
  PriorityHighRounded as PriorityHighRoundedIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { Dialog, Divider, Grid, InputAdornment, MenuItem, Select, TextField } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { AxiosError } from "axios";
import { vi } from "date-fns/locale";
import _ from "lodash";
import _debounce from "lodash/debounce";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import InfiniteScroll from "react-infinite-scroller";
import { useHistory, useParams } from "react-router-dom";
import "../OrderModalFarm.scss";
import { useTranslation } from "react-i18next";

function reducer(state: PaginateFetchReducer<ProductOutputModel>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: [...state.data, ...action.payload.data] as ProductOutputModel[],
      };
    case Action.RESET:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload.data,
      };

    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload };

    default:
      return { ...state };
  }
}

type OrderProduct = OrderDetailOutputModel & ProductOutputModel;

const OrderDraftFarm: React.FC = () => {
  const { t } = useTranslation(["translation"]);
  //notification sound
  const [audio] = useState<any>(new Audio(urlSound));
  const [playing, setPlaying] = useState<boolean>(false);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing]);

  useEffect(() => {
    audio.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
    };
  }, []);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const mounted = useRef(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const { enqueueSnackbar } = useSnackbar();
  const [notiOrder, setNotiOrder] = useState<{
    visible: boolean;
    newOrder: boolean;
  }>({
    visible: false,
    newOrder: false,
  });
  const [notiOrderCancel, setNotiOrderCancel] = useState<boolean>(false);
  const [interestedSuccess, setInterestedSuccess] = useState<boolean>(false);

  const [state, dispatch] = useReducer(reducer, {
    page: 0,
    totalPages: 0,
    limit: 20,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
  });
  const [dataOrd, setDataOrd] = useState<any>({});

  const [profile, setProfile] = useState<any>([]);
  const [ordDraft, setOrdDraft] = useState<boolean>(false);
  const { orderId }: { orderId: string } = useParams();
  const [order, setOrder] = useState<any>();

  const loadProfileData = _debounce(async () => {
    const response = await CustomerService.getAuthProfile();
    setProfile(response.data);
  }, 100);

  useEffect(() => {
    loadProfileData();
  }, []);

  const orderById: () => Promise<void> = async () => {
    try {
      const response = await OrderService.getOrderById(orderId);
      if (mounted.current) {
        setOrder(() => response.data.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    orderById();
  }, []);

  const idCus = profile.customer?.find((cus: any) => cus.source === "farm")?.id;
  const cusName = profile.customer?.find((cus: any) => cus.source === "farm")?.fullName;
  const cusPhoneNumber = profile.customer?.find((cus: any) => cus.source === "farm")?.phoneNumber;

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const fetchData = async (query: any, isReset: boolean = false) => {
    if (state.fetching) return;
    dispatch({ type: Action.FETCH, payload: {} });
    try {
      const result = await ProductService.filterProducts(query);
      if (isReset)
        dispatch({
          type: Action.RESET,
          payload: result.data,
        });
      else if (mounted.current)
        dispatch({
          type: Action.SUCCESS,
          payload: result.data,
        });
    } catch (err) {
      if (mounted)
        dispatch({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
    }
  };

  useEffect(() => {
    fetchData(
      {
        page: 1,
        limit: 20,
        searchValue: searchValue,
        source: "farm",
      },
      true
    );
  }, [searchValue, interestedSuccess]);

  const hasMore = state.data.length < state.totalRecords && state.page < state.totalPages;

  const [proFarm, setProFarm] = useState<
    {
      item: OrderProduct;
      quantity: number;
      dimension: number;
      date?: any;
      orderAddress?: string;
      note?: string;
    }[]
  >([]);

  let sub = 0;
  const ordDetail = order?.orders
    .filter((o: any) => o.childOrders.length === 0)
    .map((o: any) => {
      return o.orderlines.map((d: any) => {
        const valPriceDimen = +d.prices.dimension;
        switch (d.prices.priceType) {
          case "bs":
            sub = +d.dimension * d.price;
            break;
          case "fixed":
            sub = (+d.dimension * d.price + +d.prices.additionValue) * +d.orderQuantity;
            break;
          case "increment":
            sub =
              valPriceDimen * d.price * +d.orderQuantity +
              Math.max(0, +d.dimension / +d.orderQuantity - valPriceDimen) *
                +d.orderQuantity *
                +d.prices.additionValue;
            break;
          default:
        }

        return {
          orderId: o.id,
          itemId: d.item?.id,
          divisionId: o.division.id,
          discount: 0,
          quantity: +d.orderQuantity,
          addedProduct: o.orderlines,
          unitPrice: d.price,
          orderAddress: o.address,
          note: d.note,
          date: o.requiredDate,
          priceType: d.prices.priceType,
          attachValue: +d.prices.additionValue,
          saleUnit: d.item.saleUnit,
          baseUoM: d.item.baseUoM,
          dimensionToShow: d.dimensionToShow,
          value: d.price,
          dimension: +d.dimension / +d.orderQuantity,
          // subTotal: +d.orderQuantity * d.price,
          subTotal: sub,
        };
      });
    });

  const [orderDetail, setOrderDetail] = useState<any>([]);

  const [ordDetailValue, setOrdDetailValue] = useState<any>([]);

  useEffect(() => {
    order && setOrderDetail([].concat(...ordDetail));
    setOrdDetailValue(
      order?.orders.map((o: any) => {
        return o.orderlines.map((d: any) => {
          return d;
        });
      })
    );
  }, [order]);

  const onUpdateProdVarDimension = (
    index: number,
    variantId: number,
    quantity: number,
    dimen: any,
    addedProduct?: OrderProduct,
    orderDate?: any,
    ordAddress?: any,
    note?: string
  ) => {
    const idx = proFarm.findIndex((item) => item.item.item.id === variantId);

    let data = [...proFarm];
    if (idx !== -1) {
      data[index] = {
        ...data[index],
        quantity: quantity || 1,
        dimension: dimen,
        date: orderDate || new Date(),
        orderAddress: ordAddress || "",
        note: note || "",
      };
    } else {
      // thêm mới
      if (!addedProduct) {
        return;
      }

      data.push({
        item: addedProduct,
        quantity,
        dimension: dimen,
        date: orderDate,
        orderAddress: ordAddress,
        note: note,
      });
    }
    data = data.filter((item) => item.quantity >= 1);

    setProFarm(data);
  };

  const onUpdateProdVarQuantityDetail = (
    index: number,
    variantId: number,
    quantity: number,
    addedProduct?: any,
    dimension?: number,
    orderDate?: any,
    ordAddress?: any,
    note?: string,
    total?: number
  ) => {
    const idx = [].concat(...orderDetail).findIndex((item: any) => item.itemId === variantId);
    let data = [].concat(...orderDetail) as any;

    // update
    if (quantity === -1) {
      data.splice(idx, 1);
      ordDetailValue.splice(idx, 1);
      setOrdDetailValue(ordDetailValue);
    } else {
      data[index] = {
        ...data[index],
        quantity: quantity || 1,
        dimension: dimension,
        date: orderDate || new Date(),
        orderAddress: ordAddress || "",
        note: note || "",
      };
    }

    setOrderDetail(data);
  };

  const total = proFarm.reduce((sum, val) => {
    const valDimen = Array.isArray(val.dimension)
      ? !+val.dimension[1]
        ? val.dimension[0]
        : val.dimension[1]
      : +val.dimension;

    const valPriceDimen = +val.item.dimension;

    switch (val.item.priceType) {
      case "bs":
        return sum + val.quantity * valDimen * val.item.value;
      case "fixed":
        return (
          sum + (valDimen * val.item.value + parseFloat(val.item.additionValue)) * val.quantity
        );
      default:
        return (
          sum +
          valPriceDimen * val.item.value * val.quantity +
          Math.max(0, valDimen - valPriceDimen) * val.quantity * +val.item.additionValue
        );
    }
  }, 0);

  const totalDetail = orderDetail.reduce((sum: number, val: any) => {
    const valDimen = Array.isArray(val.dimension)
      ? !+val.dimension[1]
        ? val.dimension[0] * val.quantity
        : val.dimension[1] * val.quantity
      : +val.dimension;

    const valPriceDimen = +val.addedProduct?.[0]?.prices?.dimension;

    switch (val.priceType) {
      case "bs":
        return sum + valDimen * +val.value * val.quantity;
      case "fixed":
        return sum + (+valDimen * val.value + val.attachValue) * val.quantity;
      default:
        return (
          sum +
          valPriceDimen * +val.value * val.quantity +
          Math.max(0, valDimen - valPriceDimen) *
            val.quantity *
            +val.addedProduct?.[0]?.prices.additionValue
        );
    }
  }, 0);

  const [checkError, setCheckError] = useState<boolean>(false);
  const checkDate =
    proFarm.filter((item) => item.date === undefined)?.length +
    orderDetail.filter((item: any) => item.date === undefined)?.length;
  const checkAddress =
    proFarm.filter((item) => item.orderAddress === "")?.length +
    orderDetail.filter((item: any) => item.orderAddress === "")?.length;

  const submitForm = async (values: any) => {
    if (checkError) {
      return;
    }
    const validProDim = proFarm.filter((item) => item.quantity);
    if (!validProDim.length) {
      // setNotiOrderNull(true);
    }
    let total = 0;
    let subTotal = 0;

    const orderDetails = validProDim.map((item) => {
      const valDimention = Array.isArray(item.dimension)
        ? !+item.dimension[1]
          ? item.dimension[0]
          : item.dimension[1]
        : +item.item.item.dimension;

      const valDimentionToShow = Array.isArray(item.dimension)
        ? !+item.dimension[0]
          ? item.dimension[1]
          : item.dimension[0]
        : 0;

      const valPriceDimen = +item.item.dimension;

      switch (item.item.priceType) {
        case "bs":
          subTotal = item.quantity * valDimention * item.item.value;
          break;
        case "fixed":
          subTotal =
            valDimention * item.item.value + parseFloat(item.item.additionValue) * item.quantity;
          break;
        case "increment":
          subTotal =
            valPriceDimen * item.item.value * item.quantity +
            Math.max(0, valDimention - valPriceDimen) * item.quantity * +item.item.additionValue;
          break;
        default:
      }

      total += subTotal;

      return {
        itemId: item.item.item.id,
        divisionId: item.item.item.division.id,
        discount: 0,
        quantity: item.quantity,
        unitPrice: item.item.value,
        saleUnit: item.item.item.saleUnit,
        attachValue: item.item.additionValue,
        baseUoM: item.item.item.baseUoM,
        dimension: valDimention * item.quantity,
        dimensionToShow: +valDimentionToShow,
        subTotal: subTotal,
        address: item.orderAddress,
        requiredDate: moment(item.date || new Date()).format("YYYY-MM-DD"),
        note: item.note,
      };
    });

    const validProDetailDim = orderDetail.filter((item: any) => item.quantity);

    let totalDetail = 0;
    let sub = 0;
    const orderDt = validProDetailDim.map((item: any) => {
      const valDimention = Array.isArray(item.dimension)
        ? !+item.dimension[1]
          ? item.dimension[0] * item.quantity
          : item.dimension[1] * item.quantity
        : +item.dimension * item.quantity;

      const valDimentionToShow = Array.isArray(item.dimension)
        ? !+item.dimension[0]
          ? item.dimension[1]
          : item.dimension[0]
        : item.dimensionToShow;

      const valPriceDimen = +item.addedProduct?.[0]?.prices?.dimension;

      switch (item.addedProduct[0].prices.priceType) {
        case "bs":
          sub = valDimention * item.value;
          break;
        case "fixed":
          sub = valDimention * item.value + parseFloat(item.attachValue) * item.quantity;
          break;
        case "increment":
          sub =
            valPriceDimen * item.value * item.quantity +
            Math.max(0, valDimention - valPriceDimen) *
              item.quantity *
              +item.addedProduct?.[0]?.prices.additionValue;
          break;
        default:
      }

      totalDetail += sub;

      return {
        itemId: item.addedProduct[0].item.id,
        divisionId: item.divisionId,
        discount: 0,
        quantity: item.quantity,
        unitPrice: item.value,
        saleUnit: item.addedProduct[0].item.saleUnit,
        attachValue: item.attachValue,
        baseUoM: item.addedProduct[0].item.baseUoM,
        dimension: valDimention,
        dimensionToShow: valDimentionToShow,
        subTotal: sub,
        address: item.orderAddress,
        requiredDate: moment(item.date || new Date()).format("YYYY-MM-DD"),
        note: item.note,
      };
    });

    const data: Parameters<typeof OrderService.updateOrderDraft>[0] = {
      // ...values,
      customerId: order.orders[0].customer !== null ? order.orders[0].customer.id : undefined,
      leadId: order.orders[0].customer !== null ? undefined : order.orders[0].lead.id,
      creatorId: order.createdBy.id,
      salesOrgId: order.orders[0].salesOrg.id,
      distributionChannelId:
        order.orders[0].customer !== null ? order.orders[0].distributionChannel.id : 4,
      address: order.orders[0].customer !== null ? order.orders[0].customer.address : "",
      currency: "cash",
      totalDiscount: 0,
      vat: 0,
      orderAmount: total + totalDetail,
      source: "farm",
      orderDetails: orderDt.concat(orderDetails),
      isDraft: ordDraft,
      draftId: +orderId,
      requiredDate: moment(values.requiredDate || new Date()).format("YYYY-MM-DD"),
    } as any;

    OrderService.updateOrderDraft(data as any)
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          if (ordDraft === true) {
            history.push(APP_ROUTES.ORDER_FARM);
          } else {
            setNotiOrder({ visible: true, newOrder: true });
            setDataOrd(res.data.data.data);
            setPlaying(true);
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      })
      .catch((err: AxiosError) => {
        setLoading(false);
        if (err.response?.status === 400) {
          enqueueSnackbar(t("INVALID_NAME", { NAME: t("QUANTITY") }), {
            variant: "error",
          });
        } else {
          setNotiOrder({ visible: true, newOrder: false });
        }
      });
  };

  const onSearch = _.debounce((value) => {
    setSearchValue(value);
  }, 1000);

  const productImageOnErrorHandler = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = ProductImage;
    event.currentTarget.className = "error";
  };

  const saveAddress = async (val: string) => {
    await CustomerService.createAddress({
      customerId: idCus,
      name: cusName,
      phoneNumber: cusPhoneNumber,
      address: val,
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          enqueueSnackbar(t("ADDRESS_CREATION_SUCCESSFUL"), {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        enqueueSnackbar(t("ADDRESS_CREATION_FAILED"), {
          variant: "error",
        });
      });
  };

  const deleteOrderDraft = async () => {
    try {
      await OrderService.deleteOrderDraft({
        orderGroupId: +orderId,
        source: "farm",
        customerId: order.createdBy.id,
      });
      enqueueSnackbar(t("DELETE_CART_SUCCESSFULLY"), {
        variant: "success",
      });
      history.push(APP_ROUTES.ORDER_FARM);
    } catch (error) {
      enqueueSnackbar(t("DELETE_CART_FAILED"), {
        variant: "error",
      });
    }
  };

  const updateProductInterested = async (id: number, interested: boolean) => {
    try {
      const res = await ProductService.updateProductInterested({
        itemId: id,
        interested: interested,
        source: "farm",
      });
      if (res && (res.status === 200 || res.status === 201)) {
        setInterestedSuccess(!interestedSuccess);
      }
    } catch (error: any) {}
  };

  //permission
  const checkPermissionViewPrice = PermissionUtil.check(
    profile?.role?.permissions,
    ItemPermission.MANAGE_ITEM_VIEW_PRICE,
    true
  );

  return (
    <Dialog fullScreen open={true}>
      <div className="order-modal-farm">
        <SimpleModalHeader
          title={t("CREATE_ORDER_FARM")}
          onClose={() => {
            history.push(APP_ROUTES.ORDER_FARM);
          }}
        />
        <div className="order-form">
          <Grid container>
            <Grid item xs={9} className="order-form-left">
              <Form onSubmit={async (values) => submitForm(values)}>
                {({ handleSubmit }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <div className="product-order product-order-farm">
                        <div className="product-order-header">
                          <div className="prod">{t("PRODUCT")}</div>
                          <div className="quan">{t("QUANTITY")}</div>
                          <div className="dimen">{t("WEIGHT_RANGE")}</div>
                          <div className="unit">{t("TOTAL_WEIGHT")}</div>
                          <div className="price">{t("UNIT_PRICE")}</div>
                          <div className="additionValue">{t("STOCK_FEE_EXCESS_PORTION")}</div>
                          <div className="pay">{t("TOTAL_PRICE")}</div>
                          <div className="ic-del"></div>
                        </div>
                        <Divider style={{ marginBottom: "3.3rem" }} />
                        <div className="product-order-list">
                          {orderDetail &&
                            orderDetail.map((d: any, idx: number) => {
                              const orderDetailRender = orderDetail.reduce(
                                (result: object | null, od: any) => {
                                  const orderL = d.addedProduct.find(
                                    (odl: any) => odl.item.id === d.itemId
                                  );
                                  if (orderL) {
                                    result = orderL;
                                  }
                                  return result;
                                },
                                null
                              );

                              return (
                                <>
                                  <div key={idx} className="product-order-card">
                                    <div className="prod">
                                      <img
                                        src={
                                          checkLinkImage(orderDetailRender.item.images?.picture) ||
                                          ProductImage
                                        }
                                        alt="product"
                                        onError={productImageOnErrorHandler}
                                      />
                                      <div className="name">
                                        {orderDetailRender["item"]["name"]}
                                        <div className="code">
                                          {orderDetailRender["item"]["code"]}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="quan">
                                      <QuantityBtnGroup
                                        disableMinusBtn={d.quantity <= 1}
                                        quantity={d.quantity}
                                        onChangeQuantity={(value) => {
                                          onUpdateProdVarQuantityDetail(
                                            idx,
                                            d.itemId,
                                            value,
                                            d.addedProduct,
                                            d.dimension,
                                            d.date,
                                            d.orderAddress,
                                            d.note
                                          );
                                        }}
                                        style={{ maxWidth: "12rem", maxHeight: "3.5rem" }}
                                      />
                                    </div>

                                    {Array.isArray(orderDetailRender["item"]["dimension"]) ===
                                    true ? (
                                      <div className="dimen-cond">
                                        {Array.isArray(orderDetailRender["item"]["dimension"]) && (
                                          <>
                                            <Select
                                              defaultValue={orderDetailRender.item.dimension
                                                .find((f: any) =>
                                                  f[0] !== "null"
                                                    ? +f[0] === +d.dimensionToShow
                                                    : +f[1] === +d.dimensionToShow
                                                )
                                                ?.toString()}
                                              onChange={(e) => {
                                                e.target.value.split(",");
                                                onUpdateProdVarQuantityDetail(
                                                  idx,
                                                  d.itemId,
                                                  d.quantity,
                                                  d.addedProduct,
                                                  e.target.value.split(","),
                                                  d.requiredDate,
                                                  d.orderAddress,
                                                  d.note
                                                );
                                              }}
                                              displayEmpty
                                              variant="standard"
                                              color="success"
                                              fullWidth
                                              MenuProps={{
                                                className: "select-menu-list item-weight",
                                                anchorOrigin: {
                                                  vertical: 30,
                                                  horizontal: "left",
                                                },
                                                transformOrigin: {
                                                  vertical: "top",
                                                  horizontal: "left",
                                                },
                                              }}>
                                              {orderDetailRender["item"]["dimension"].map((d) => {
                                                return (
                                                  <MenuItem
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "column",
                                                      alignItems: "flex-start",
                                                    }}
                                                    value={d.toString()}>
                                                    {(() => {
                                                      if (d[1] === "null") {
                                                        return "trên " + d[0];
                                                      } else if (d[0] === "null") {
                                                        return "dưới " + d[1];
                                                      } else {
                                                        return d[0] + " - " + d[1];
                                                      }
                                                    })()}
                                                    &#160;kg
                                                  </MenuItem>
                                                );
                                              })}
                                            </Select>
                                            {orderDetailRender["item"]["conditionQuantity"] !==
                                              null && (
                                              <div
                                                className={
                                                  OrderUtil.checkQuantityOrderFarm(
                                                    d.quantity,
                                                    orderDetailRender["item"][
                                                      "conditionQuantity"
                                                    ] as any
                                                  )
                                                    ? "err-quantity"
                                                    : "err-quantity err"
                                                }>
                                                {t("REQUIRED_QUANTITY")}&nbsp;
                                                <span style={{ textTransform: "lowercase" }}>
                                                  {
                                                    ConditionQuantity.find(
                                                      (f: any) =>
                                                        orderDetailRender["item"][
                                                          "conditionQuantity"
                                                        ]?.replace(/\d/g, "") === f.value
                                                    )?.title
                                                  }
                                                </span>
                                                &nbsp;
                                                {orderDetailRender["item"][
                                                  "conditionQuantity"
                                                ]?.match(/[0-9]+/g)?.[1]
                                                  ? orderDetailRender["item"][
                                                      "conditionQuantity"
                                                    ]?.match(/[0-9]+/g)?.[0] +
                                                    "-" +
                                                    orderDetailRender["item"][
                                                      "conditionQuantity"
                                                    ]?.match(/[0-9]+/g)?.[1]
                                                  : orderDetailRender["item"][
                                                      "conditionQuantity"
                                                    ]?.match(/[0-9]+/g)?.[0]}
                                              </div>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    ) : (
                                      <div className="dimen">
                                        {+orderDetailRender["item"]["dimension"]}
                                        <span style={{ color: "#41AD49" }}>
                                          &#160;{orderDetailRender["item"]["baseUoM"]}
                                        </span>
                                        {orderDetailRender["item"]["conditionQuantity"] !==
                                          null && (
                                          <div
                                            className={
                                              OrderUtil.checkQuantityOrderFarm(
                                                d.quantity,
                                                orderDetailRender["item"][
                                                  "conditionQuantity"
                                                ] as any
                                              )
                                                ? "err-quantity"
                                                : "err-quantity err"
                                            }>
                                            {t("REQUIRED_QUANTITY")}&nbsp;
                                            <span style={{ textTransform: "lowercase" }}>
                                              {
                                                ConditionQuantity.find(
                                                  (f: any) =>
                                                    orderDetailRender["item"][
                                                      "conditionQuantity"
                                                    ]?.replace(/\d/g, "") === f.value
                                                )?.title
                                              }
                                            </span>
                                            &nbsp;
                                            {orderDetailRender["item"]["conditionQuantity"]?.match(
                                              /[0-9]+/g
                                            )?.[1]
                                              ? orderDetailRender["item"][
                                                  "conditionQuantity"
                                                ]?.match(/[0-9]+/g)?.[0] +
                                                "-" +
                                                orderDetailRender["item"][
                                                  "conditionQuantity"
                                                ]?.match(/[0-9]+/g)?.[1]
                                              : orderDetailRender["item"][
                                                  "conditionQuantity"
                                                ]?.match(/[0-9]+/g)?.[0]}
                                          </div>
                                        )}
                                      </div>
                                    )}
                                    <div className="unit">
                                      {Array.isArray(d.dimension)
                                        ? !+d.dimension[1]
                                          ? currencyFormat(d.dimension[0] * d.quantity)
                                          : currencyFormat(d.dimension[1] * d.quantity)
                                        : currencyFormat(+d.dimension * d.quantity)}

                                      <span style={{ color: "#41AD49" }}>
                                        &#160;kg
                                        {/* {orderDetailRender["item"]["baseUoM"]} */}
                                      </span>
                                    </div>
                                    <div className="price text">
                                      {checkPermissionViewPrice
                                        ? currencyFormat(orderDetailRender["price"])
                                        : TEXT_ASTERISK}{" "}
                                      đ/
                                      <span>{d.baseUoM}</span>
                                    </div>
                                    <div className="additionValue">
                                      {(() => {
                                        if (+orderDetailRender.prices?.additionValue) {
                                          if (orderDetailRender.prices?.priceType === "fixed") {
                                            return (
                                              <span>
                                                {(checkPermissionViewPrice
                                                  ? currencyFormat(
                                                      orderDetailRender.prices?.additionValue
                                                    )
                                                  : TEXT_ASTERISK) + " đ/"}
                                                <span style={{ color: "#41ad49" }}>
                                                  {orderDetailRender.saleUnit}
                                                </span>
                                              </span>
                                            );
                                          } else if (
                                            orderDetailRender.prices?.priceType === "increment"
                                          ) {
                                            return (
                                              <span>
                                                {(checkPermissionViewPrice
                                                  ? currencyFormat(
                                                      orderDetailRender.prices?.additionValue
                                                    )
                                                  : TEXT_ASTERISK) + " đ/"}
                                                <span style={{ color: "#41ad49" }}>
                                                  {orderDetailRender.baseUoM}
                                                </span>
                                              </span>
                                            );
                                          } else if (orderDetailRender.prices?.priceType === "bs") {
                                            return (
                                              <span>
                                                {(checkPermissionViewPrice
                                                  ? currencyFormat(
                                                      orderDetailRender.prices?.additionValue
                                                    )
                                                  : TEXT_ASTERISK) + " đ"}
                                              </span>
                                            );
                                          }
                                        } else return TEXT_HOLDER;
                                      })()}
                                    </div>

                                    <div className="pay">
                                      {(() => {
                                        const valDimen = Array.isArray(d.dimension)
                                          ? !+d.dimension[1]
                                            ? d.dimension[0]
                                            : d.dimension[1]
                                          : d.dimension;

                                        const valPriceDimen =
                                          +d.addedProduct?.[0]?.prices.dimension;

                                        switch (orderDetailRender["prices"]["priceType"]) {
                                          case "bs":
                                            return (
                                              <>
                                                {checkPermissionViewPrice
                                                  ? currencyFormat(
                                                      valDimen *
                                                        +orderDetailRender["price"] *
                                                        d.quantity
                                                    )
                                                  : TEXT_ASTERISK}
                                              </>
                                            );
                                          case "fixed":
                                            return (
                                              <>
                                                {checkPermissionViewPrice
                                                  ? currencyFormat(
                                                      (valDimen * +orderDetailRender["price"] +
                                                        parseFloat(
                                                          orderDetailRender["prices"][
                                                            "additionValue"
                                                          ]
                                                        )) *
                                                        d.quantity
                                                    )
                                                  : TEXT_ASTERISK}
                                              </>
                                            );
                                          default:
                                            return (
                                              <>
                                                {checkPermissionViewPrice
                                                  ? currencyFormat(
                                                      valPriceDimen *
                                                        +orderDetailRender["price"] *
                                                        d.quantity +
                                                        Math.max(0, valDimen - valPriceDimen) *
                                                          d.quantity *
                                                          +d.addedProduct?.[0]?.prices.additionValue
                                                    )
                                                  : TEXT_ASTERISK}
                                              </>
                                            );
                                        }
                                      })()}
                                      &ensp;đ
                                    </div>
                                    <div
                                      className="ic-del"
                                      onClick={() => {
                                        onUpdateProdVarQuantityDetail(idx, d.itemId, -1);
                                      }}>
                                      <DeleteOutlineIcon
                                        style={{
                                          fontSize: "2.2rem",
                                          marginLeft: "3rem",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="product-order-shipping">
                                    <div className="date">
                                      <Field name="requiredDate">
                                        {({ input, meta, ...rest }) => {
                                          return (
                                            <MuiPickersUtilsProvider
                                              locale={vi}
                                              utils={DateFnsUtils}>
                                              <KeyboardDatePicker
                                                okLabel={t("SELECT")}
                                                cancelLabel={t("CANCEL")}
                                                fullWidth
                                                disableToolbar
                                                disablePast
                                                inputVariant="standard"
                                                format="dd-MM-yyyy"
                                                className="form-text-field date"
                                                value={d.date || new Date()}
                                                onChange={(e) => {
                                                  onUpdateProdVarQuantityDetail(
                                                    idx,
                                                    d.itemId,
                                                    d.quantity,
                                                    d.addedProduct,
                                                    d.dimension,
                                                    e,
                                                    d.orderAddress,
                                                    d.note
                                                  );
                                                  input.onChange(e);
                                                }}
                                                InputProps={{ readOnly: true }}
                                                inputProps={{ className: "input" }}
                                                keyboardIcon={
                                                  <EventIcon
                                                    color="#41AD49"
                                                    size={[22, 22]}
                                                    viewBox={[22, 22]}
                                                  />
                                                }
                                                helperText={meta.touched ? meta.error : ""}
                                                error={meta.error && meta.touched}
                                              />
                                            </MuiPickersUtilsProvider>
                                          );
                                        }}
                                      </Field>
                                      <div
                                        style={{
                                          color: "#e53935",
                                          fontSize: "1.4rem",
                                        }}>
                                        {checkError &&
                                          d.date === undefined &&
                                          t("SELECT_BY_NAME", {
                                            NAME: t("DELIVERY_DATE"),
                                          })}
                                      </div>
                                    </div>
                                    <Field name="note">
                                      {({ input, meta, ...rest }) => {
                                        return (
                                          <TextField
                                            {...input}
                                            {...rest}
                                            fullWidth
                                            placeholder={t("NOTES")}
                                            className="form-text-field form-text-field-note"
                                            inputProps={{ className: "input" }}
                                            variant="standard"
                                            value={d.note}
                                            onChange={(e) => {
                                              onUpdateProdVarQuantityDetail(
                                                idx,
                                                d.itemId,
                                                d.quantity,
                                                d.addedProduct,
                                                d.dimension,
                                                d.date,
                                                d.orderAddress,
                                                e.target.value
                                              );
                                              input.onChange(e);
                                            }}
                                            helperText={meta.touched ? meta.error : ""}
                                            error={meta.error && meta.touched}
                                            onKeyDown={(event) => {
                                              if (event.key === "Enter") {
                                                event.preventDefault();
                                                return false;
                                              }
                                            }}
                                          />
                                        );
                                      }}
                                    </Field>
                                    <Field name="address">
                                      {({ input, meta, ...rest }) => {
                                        return (
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              width: "35%",
                                            }}>
                                            {order?.orders[0].customer !== null ? (
                                              <ShippingAddress<AddressModal>
                                                value={d.orderAddress}
                                                source="farm"
                                                handleSaveAddress={saveAddress}
                                                className={"form-text-field addr"}
                                                placeholder={t("SELECT_BY_NAME", {
                                                  NAME: t("DELIVERY_ADDRESS"),
                                                })}
                                                getOptionLabel={(opt) =>
                                                  typeof opt === "string"
                                                    ? opt
                                                    : opt.address || (opt.inputValue as any)
                                                }
                                                onChange={(value) => {
                                                  onUpdateProdVarQuantityDetail(
                                                    idx,
                                                    d.itemId,
                                                    d.quantity,
                                                    d.addedProduct,
                                                    d.dimension,
                                                    d.date,
                                                    value?.address || value?.inputValue,
                                                    d.note
                                                  );
                                                }}
                                                renderOption={(p) => (
                                                  <div
                                                    className="item-addr"
                                                    style={{
                                                      fontSize: "1.6rem",
                                                      fontFamily: "SVN-Gotham",
                                                      color: p.title ? "#41AD49" : "",
                                                      borderBottom: "0.1rem solid #EFEFEF",
                                                      padding: "1rem 2rem",
                                                      width: "100%",
                                                    }}>
                                                    {p.title ? p.title : p.address}
                                                  </div>
                                                )}
                                                actionConfig={{
                                                  action: CustomerService.getAddress,
                                                }}
                                                dropdownBoxMaxHeight="24rem"
                                              />
                                            ) : (
                                              <TextField
                                                {...input}
                                                {...rest}
                                                fullWidth
                                                placeholder={t("INPUT_BY_NAME", {
                                                  NAME: t("DELIVERY_ADDRESS"),
                                                })}
                                                className="form-text-field addr form-text-field-addr"
                                                inputProps={{ className: "input" }}
                                                variant="standard"
                                                value={d.orderAddress}
                                                onChange={(e) => {
                                                  input.onChange(e.target.value);
                                                  onUpdateProdVarQuantityDetail(
                                                    idx,
                                                    d.itemId,
                                                    d.quantity,
                                                    d.addedProduct,
                                                    d.dimension,
                                                    d.requiredDate,
                                                    e.target.value,
                                                    d.note
                                                  );
                                                }}
                                                helperText={meta.touched ? meta.error : ""}
                                                error={meta.error && meta.touched}
                                                onKeyDown={(event) => {
                                                  if (event.key === "Enter") {
                                                    event.preventDefault();
                                                    return false;
                                                  }
                                                }}
                                                autoComplete="off"
                                                InputProps={{
                                                  startAdornment: (
                                                    <InputAdornment position="start">
                                                      <MapIcon
                                                        color="#41AD49"
                                                        size={[22, 22]}
                                                        viewBox={[10, 20]}
                                                      />
                                                    </InputAdornment>
                                                  ),
                                                }}
                                              />
                                            )}
                                            <div
                                              style={{
                                                color: "#e53935",
                                                fontSize: "1.4rem",
                                                marginLeft: "5rem",
                                              }}>
                                              {checkError &&
                                                d.orderAddress === "" &&
                                                t("SELECT_BY_NAME", {
                                                  NAME: t("DELIVERY_ADDRESS"),
                                                })}
                                            </div>
                                          </div>
                                        );
                                      }}
                                    </Field>
                                  </div>
                                </>
                              );
                            })}
                          {!!proFarm.length &&
                            proFarm.map((val, index) => {
                              return (
                                <>
                                  <div className="product-order-card">
                                    <div className="prod">
                                      <img
                                        src={
                                          checkLinkImage(val.item.item.images?.picture as any) ||
                                          ProductImage
                                        }
                                        alt="product"
                                      />
                                      <div className="name">
                                        {val.item.item.name}
                                        <div className="code">{val.item.item.code}</div>
                                      </div>
                                    </div>
                                    <div className="quan">
                                      <QuantityBtnGroup
                                        disableMinusBtn={val.quantity <= 1}
                                        quantity={val.quantity}
                                        onChangeQuantity={(value) => {
                                          onUpdateProdVarDimension(
                                            index,
                                            val.item.item.id,
                                            value,
                                            val.dimension,
                                            val.item,
                                            val.date,
                                            val.orderAddress,
                                            val.note
                                            //val.dimension
                                          );
                                        }}
                                        style={{ maxWidth: "12rem", maxHeight: "3.5rem" }}
                                      />
                                    </div>

                                    {Array.isArray(val.item.item.dimension) === true ? (
                                      <div className="dimen-cond">
                                        {Array.isArray(val.item.item.dimension) && (
                                          <>
                                            <Select
                                              defaultValue={val.item.item.dimension[0]?.toString()}
                                              onChange={(e) => {
                                                onUpdateProdVarDimension(
                                                  index,
                                                  val.item.item.id,
                                                  val.quantity,
                                                  e.target.value.split(","),
                                                  val.item,
                                                  val.date,
                                                  val.orderAddress
                                                );
                                              }}
                                              displayEmpty
                                              variant="standard"
                                              color="success"
                                              fullWidth
                                              MenuProps={{
                                                className: "select-menu-list item-weight",
                                                anchorOrigin: {
                                                  vertical: 30,
                                                  horizontal: "left",
                                                },
                                                transformOrigin: {
                                                  vertical: "top",
                                                  horizontal: "left",
                                                },
                                              }}>
                                              {val.item.item.dimension.map((d) => {
                                                return (
                                                  <MenuItem
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "column",
                                                      alignItems: "flex-start",
                                                    }}
                                                    value={d.toString()}>
                                                    {(() => {
                                                      if (d[1] === "null") {
                                                        return "trên " + d[0];
                                                      } else if (d[0] === "null") {
                                                        return "dưới " + d[1];
                                                      } else {
                                                        return d[0] + " - " + d[1];
                                                      }
                                                    })()}
                                                    &#160;kg
                                                  </MenuItem>
                                                );
                                              })}
                                            </Select>
                                            {val.item.item.conditionQuantity !== null && (
                                              <div
                                                className={
                                                  OrderUtil.checkQuantityOrderFarm(
                                                    val.quantity,
                                                    val.item.item.conditionQuantity as any
                                                  )
                                                    ? "err-quantity"
                                                    : "err-quantity err"
                                                }>
                                                {t("REQUIRED_QUANTITY")}&nbsp;
                                                <span style={{ textTransform: "lowercase" }}>
                                                  {
                                                    ConditionQuantity.find(
                                                      (f: any) =>
                                                        val.item.item.conditionQuantity?.replace(
                                                          /\d/g,
                                                          ""
                                                        ) === f.value
                                                    )?.title
                                                  }
                                                </span>
                                                &nbsp;
                                                {val.item.item.conditionQuantity?.match(
                                                  /[0-9]+/g
                                                )?.[1]
                                                  ? val.item.item.conditionQuantity?.match(
                                                      /[0-9]+/g
                                                    )?.[0] +
                                                    "-" +
                                                    val.item.item.conditionQuantity?.match(
                                                      /[0-9]+/g
                                                    )?.[1]
                                                  : val.item.item.conditionQuantity?.match(
                                                      /[0-9]+/g
                                                    )?.[0]}
                                              </div>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    ) : (
                                      <div className="dimen">
                                        {val.item.item.dimension}
                                        <span style={{ color: "#41AD49" }}>
                                          &#160;{val.item.item.baseUoM}
                                        </span>
                                        {val.item.item.conditionQuantity !== null && (
                                          <div
                                            className={
                                              OrderUtil.checkQuantityOrderFarm(
                                                val.quantity,
                                                val.item.item.conditionQuantity as any
                                              )
                                                ? "err-quantity"
                                                : "err-quantity err"
                                            }>
                                            {t("REQUIRED_QUANTITY")}&nbsp;
                                            <span style={{ textTransform: "lowercase" }}>
                                              {
                                                ConditionQuantity.find(
                                                  (f: any) =>
                                                    val.item.item.conditionQuantity?.replace(
                                                      /\d/g,
                                                      ""
                                                    ) === f.value
                                                )?.title
                                              }
                                            </span>
                                            &nbsp;
                                            {val.item.item.conditionQuantity?.match(/[0-9]+/g)?.[1]
                                              ? val.item.item.conditionQuantity?.match(
                                                  /[0-9]+/g
                                                )?.[0] +
                                                "-" +
                                                val.item.item.conditionQuantity?.match(
                                                  /[0-9]+/g
                                                )?.[1]
                                              : val.item.item.conditionQuantity?.match(
                                                  /[0-9]+/g
                                                )?.[0]}
                                          </div>
                                        )}
                                      </div>
                                    )}
                                    <div className="unit">
                                      {Array.isArray(val.dimension)
                                        ? !+val.dimension[1]
                                          ? currencyFormat(val.dimension[0] * val.quantity)
                                          : currencyFormat(val.dimension[1] * val.quantity)
                                        : currencyFormat(+val.dimension * val.quantity)}
                                      <span style={{ color: "#41AD49" }}>
                                        &#160;kg
                                        {/* {val.item.item.baseUoM} */}
                                      </span>
                                    </div>
                                    <div className="price text">
                                      {checkPermissionViewPrice
                                        ? currencyFormat(val.item.value)
                                        : TEXT_ASTERISK}{" "}
                                      đ/
                                      <span>{val.item.baseUoM}</span>
                                    </div>
                                    <div className="additionValue">
                                      {(() => {
                                        if (+val.item.additionValue) {
                                          if (val.item.priceType === "fixed") {
                                            return (
                                              <span>
                                                {(checkPermissionViewPrice
                                                  ? currencyFormat(+val.item.additionValue)
                                                  : TEXT_ASTERISK) + " đ/"}
                                                <span style={{ color: "#41ad49" }}>
                                                  {val.item.item.saleUnit}
                                                </span>
                                              </span>
                                            );
                                          } else if (val.item.priceType === "increment") {
                                            return (
                                              <span>
                                                {(checkPermissionViewPrice
                                                  ? currencyFormat(+val.item.additionValue)
                                                  : TEXT_ASTERISK) + " đ/"}
                                                <span style={{ color: "#41ad49" }}>
                                                  {val.item.baseUoM}
                                                </span>
                                              </span>
                                            );
                                          } else if (val.item.priceType === "bs") {
                                            return (
                                              <span>
                                                {(checkPermissionViewPrice
                                                  ? currencyFormat(+val.item.additionValue)
                                                  : TEXT_ASTERISK) + " đ"}
                                              </span>
                                            );
                                          }
                                        } else return TEXT_HOLDER;
                                      })()}
                                    </div>

                                    <div className="pay">
                                      {(() => {
                                        const valDimen = Array.isArray(val.dimension)
                                          ? !+val.dimension[1]
                                            ? val.dimension[0]
                                            : val.dimension[1]
                                          : +val.dimension;

                                        const valPriceDimen = +val.item.dimension;

                                        switch (val.item.priceType) {
                                          case "bs":
                                            return (
                                              <>
                                                {checkPermissionViewPrice
                                                  ? currencyFormat(
                                                      val.quantity * valDimen * val.item.value
                                                    )
                                                  : TEXT_ASTERISK}
                                              </>
                                            );
                                          case "fixed":
                                            return (
                                              <>
                                                {checkPermissionViewPrice
                                                  ? currencyFormat(
                                                      (valDimen * val.item.value +
                                                        parseFloat(val.item.additionValue)) *
                                                        val.quantity
                                                    )
                                                  : TEXT_ASTERISK}
                                              </>
                                            );
                                          default:
                                            return (
                                              <>
                                                {checkPermissionViewPrice
                                                  ? currencyFormat(
                                                      valPriceDimen *
                                                        val.item.value *
                                                        val.quantity +
                                                        Math.max(0, valDimen - valPriceDimen) *
                                                          val.quantity *
                                                          parseFloat(val.item.additionValue)
                                                    )
                                                  : TEXT_ASTERISK}
                                              </>
                                            );
                                        }
                                      })()}
                                      &ensp;đ
                                    </div>
                                    <div
                                      className="ic-del"
                                      onClick={() => {
                                        onUpdateProdVarDimension(
                                          index,
                                          val.item.item.id,
                                          -1,
                                          val.dimension
                                        );
                                      }}>
                                      <DeleteOutlineIcon
                                        style={{
                                          fontSize: "2.2rem",
                                          marginLeft: "5rem",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="product-order-shipping">
                                    <div className="date">
                                      <Field name="requiredDate">
                                        {({ input, meta, ...rest }) => {
                                          return (
                                            <MuiPickersUtilsProvider
                                              locale={vi}
                                              utils={DateFnsUtils}>
                                              <KeyboardDatePicker
                                                okLabel={t("SELECT")}
                                                cancelLabel={t("CANCEL")}
                                                fullWidth
                                                disableToolbar
                                                disablePast
                                                inputVariant="standard"
                                                format="dd-MM-yyyy"
                                                className="form-text-field date"
                                                value={val.date || new Date()}
                                                onChange={(e) => {
                                                  onUpdateProdVarDimension(
                                                    index,
                                                    val.item.item.id,
                                                    val.quantity,
                                                    val.dimension,
                                                    val.item,
                                                    e,
                                                    val.orderAddress,
                                                    val.note
                                                  );
                                                  input.onChange(e);
                                                }}
                                                InputProps={{ readOnly: true }}
                                                inputProps={{ className: "input" }}
                                                keyboardIcon={
                                                  <EventIcon
                                                    color="#41AD49"
                                                    size={[22, 22]}
                                                    viewBox={[22, 22]}
                                                  />
                                                }
                                                helperText={meta.touched ? meta.error : ""}
                                                error={meta.error && meta.touched}
                                              />
                                            </MuiPickersUtilsProvider>
                                          );
                                        }}
                                      </Field>
                                      <div
                                        style={{
                                          color: "#e53935",
                                          fontSize: "1.4rem",
                                        }}>
                                        {checkError &&
                                          val.date === undefined &&
                                          t("SELECT_BY_NAME", {
                                            NAME: t("DELIVERY_DATE"),
                                          })}
                                      </div>
                                    </div>
                                    <Field name="note">
                                      {({ input, meta, ...rest }) => {
                                        return (
                                          <TextField
                                            {...input}
                                            {...rest}
                                            fullWidth
                                            placeholder={t("NOTES")}
                                            className="form-text-field form-text-field-note"
                                            inputProps={{ className: "input" }}
                                            variant="standard"
                                            value={val.note}
                                            onChange={(e) => {
                                              onUpdateProdVarDimension(
                                                index,
                                                val.item.item.id,
                                                val.quantity,
                                                val.dimension,
                                                val.item,
                                                e,
                                                val.orderAddress,
                                                e.target.value
                                              );
                                              input.onChange(e);
                                            }}
                                            helperText={meta.touched ? meta.error : ""}
                                            error={meta.error && meta.touched}
                                            onKeyDown={(event) => {
                                              if (event.key === "Enter") {
                                                event.preventDefault();
                                                return false;
                                              }
                                            }}
                                          />
                                        );
                                      }}
                                    </Field>
                                    <Field name="address">
                                      {({ input, meta, ...rest }) => {
                                        return (
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              width: "35%",
                                            }}>
                                            {order?.orders[0].customer !== null ? (
                                              <ShippingAddress<AddressModal>
                                                source="farm"
                                                handleSaveAddress={saveAddress}
                                                className={"form-text-field addr"}
                                                placeholder={t("SELECT_BY_NAME", {
                                                  NAME: t("DELIVERY_ADDRESS"),
                                                })}
                                                getOptionLabel={(opt) =>
                                                  opt.address || (opt.inputValue as any)
                                                }
                                                onChange={(value) => {
                                                  onUpdateProdVarDimension(
                                                    index,
                                                    val.item.item.id,
                                                    val.quantity,
                                                    val.dimension,
                                                    val.item,
                                                    val.date,
                                                    value?.address || value?.inputValue,
                                                    val.note
                                                  );
                                                }}
                                                renderOption={(p) => (
                                                  <div
                                                    className="item-addr"
                                                    style={{
                                                      fontSize: "1.6rem",
                                                      fontFamily: "SVN-Gotham",
                                                      color: p.title ? "#41AD49" : "",
                                                      borderBottom: "0.1rem solid #EFEFEF",
                                                      padding: "1rem 2rem",
                                                      width: "100%",
                                                    }}>
                                                    {p.title ? p.title : p.address}
                                                  </div>
                                                )}
                                                actionConfig={{
                                                  action: CustomerService.getAddress,
                                                }}
                                                dropdownBoxMaxHeight="24rem"
                                              />
                                            ) : (
                                              <TextField
                                                {...input}
                                                {...rest}
                                                fullWidth
                                                placeholder={t("INPUT_BY_NAME", {
                                                  NAME: t("DELIVERY_ADDRESS"),
                                                })}
                                                className="form-text-field addr form-text-field-addr"
                                                inputProps={{ className: "input" }}
                                                variant="standard"
                                                value={val.orderAddress}
                                                onChange={(e) => {
                                                  input.onChange(e.target.value);
                                                  onUpdateProdVarDimension(
                                                    index,
                                                    val.item.item.id,
                                                    val.quantity,
                                                    val.dimension,
                                                    val.item,
                                                    val.date,
                                                    e.target.value,
                                                    val.note
                                                  );
                                                }}
                                                helperText={meta.touched ? meta.error : ""}
                                                error={meta.error && meta.touched}
                                                onKeyDown={(event) => {
                                                  if (event.key === "Enter") {
                                                    event.preventDefault();
                                                    return false;
                                                  }
                                                }}
                                                autoComplete="off"
                                                InputProps={{
                                                  startAdornment: (
                                                    <InputAdornment position="start">
                                                      <MapIcon
                                                        color="#41AD49"
                                                        size={[22, 22]}
                                                        viewBox={[10, 20]}
                                                      />
                                                    </InputAdornment>
                                                  ),
                                                }}
                                              />
                                            )}
                                            <div
                                              style={{
                                                color: "#e53935",
                                                fontSize: "1.4rem",
                                                marginLeft: "5rem",
                                              }}>
                                              {checkError &&
                                                val.orderAddress === "" &&
                                                t("SELECT_BY_NAME", {
                                                  NAME: t("DELIVERY_ADDRESS"),
                                                })}
                                            </div>
                                          </div>
                                        );
                                      }}
                                    </Field>
                                  </div>
                                </>
                              );
                            })}
                        </div>
                      </div>
                      <div className="footer">
                        <div className="payment">
                          <Grid container justifyContent="space-between">
                            <Grid item xs={7} className="info-order"></Grid>
                            <Grid item xs={4} className="total">
                              <div className="row">
                                {t("ESTIMATED")}
                                <div className="price">
                                  {checkPermissionViewPrice
                                    ? currencyFormat(total + totalDetail)
                                    : TEXT_ASTERISK}{" "}
                                  đ
                                </div>
                              </div>
                              <div className="row">
                                {t("SHIPPING_FEE")}
                                <div className="price">0 đ</div>
                              </div>
                              <div className="row">
                                {t("TAX")}
                                <div className="price">0 đ</div>
                              </div>
                              <div className="row">
                                {t("TOTAL")}
                                <div className="price price-color">
                                  {checkPermissionViewPrice
                                    ? currencyFormat(total + totalDetail)
                                    : TEXT_ASTERISK}{" "}
                                  đ
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        <div className={orderDetail?.length || proFarm.length ? "show" : "hide"}>
                          <div
                            className="btn-cancel-style btn__text-cancel"
                            onClick={() => {
                              setNotiOrderCancel(true);
                            }}>
                            {t("CANCEL")}
                          </div>
                          <button
                            disabled={!orderDetail?.length && !proFarm.length}
                            onClick={() => {
                              if (checkDate > 0 || checkAddress > 0) {
                                setCheckError(true);
                              } else {
                                setCheckError(false);
                                setOrdDraft(true);
                                setLoading(true);
                              }
                            }}
                            type="submit"
                            className="btn-confirm-style btn-border"
                            style={{ marginLeft: "4rem" }}>
                            {t("SAVE_CART")}
                          </button>
                          <button
                            disabled={!orderDetail?.length && !proFarm.length}
                            onClick={() => {
                              if (checkDate > 0 || checkAddress > 0) {
                                setCheckError(true);
                              } else {
                                setCheckError(false);
                                setLoading(true);
                              }
                            }}
                            type="submit"
                            className=" btn-confirm-style"
                            style={{ marginLeft: "4rem" }}>
                            {t("PLACE_ORDER")}
                          </button>
                        </div>
                      </div>
                    </form>
                  );
                }}
              </Form>
            </Grid>

            <Grid item xs={3} className="order-form-right">
              <TextField
                variant="outlined"
                color="success"
                placeholder={t("SEARCH_BY_NAME_PRODUCT_CODE")}
                className={"search-border-style"}
                inputProps={{ className: "input" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="large" />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  onSearch(e.target.value);
                }}
              />
              <div className="col-text">{t("PIG_PRICE_TEMPORARY")}</div>
              <div className="product-list">
                {state.fetching && searchValue && <Loading />}
                {state.data.length > 0 && (
                  <InfiniteScroll
                    pageStart={0}
                    loadMore={() =>
                      fetchData({
                        page: state.page + 1,
                        limit: 20,
                        source: "farm",
                        searchValue,
                      })
                    }
                    hasMore={hasMore}
                    useWindow={false}>
                    {state.data?.map((pro: any, index: number) => {
                      const act = proFarm.find((item) => item.item.item.id === pro.item.id);
                      const actOrd = []
                        .concat(...orderDetail)
                        .find((item: any) => item.itemId === pro.item.id);

                      const checkDimen = Array.isArray(pro.item.dimension);
                      return (
                        <ProductItem
                          class={act || actOrd}
                          key={pro.item.code + index}
                          id={pro.item.id}
                          onClick={() => {
                            if (act || actOrd) {
                              return;
                            }
                            onUpdateProdVarDimension(
                              index,
                              pro.item.id,
                              1,
                              checkDimen ? pro.item.dimension[0] : pro.item.dimension,
                              pro
                            );
                          }}
                          image={pro.item.images?.picture}
                          shortName={pro.item.name}
                          name={pro.item.code}
                          value={
                            checkPermissionViewPrice ? currencyFormat(pro.value) : TEXT_ASTERISK
                          }
                          baseUoM={pro.baseUoM}
                          source="farm"
                          farmBaseUoM={pro.item.baseUoM}
                          farmSaleUnit={pro.item.saleUnit}
                          additionalValue={currencyFormat(pro.additionValue)}
                          interestedItem={pro.item.interestedItem}
                          updateProductInterested={(interested: boolean) =>
                            updateProductInterested(pro.item.id, interested)
                          }
                        />
                      );
                    })}
                  </InfiniteScroll>
                )}
              </div>
            </Grid>
          </Grid>
        </div>

        <ResultOrder
          visible={notiOrder.visible && ordDraft === false}
          newOrder={notiOrder.newOrder}
          dataOrd={dataOrd}
          setNotiOrder={() => setNotiOrder({ visible: false, newOrder: false })}
          source="farm"
        />

        {loading && (
          <Backdrop
            sx={{ color: "red", opacity: 0.5, zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}>
            <Loading />
          </Backdrop>
        )}

        <Notification
          visible={order && orderDetail.length < 1 && proFarm.length === 0 ? true : notiOrderCancel}
          title={t("CANCEL_ORDER")}
          subTitle={
            <span dangerouslySetInnerHTML={{ __html: t("ARE_YOU_SURE_YOU_WANT_CANCEL_CART") }} />
          }
          icon={
            <div className={`notification-icon-box yellow`}>
              <PriorityHighRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
            </div>
          }
          actions={
            <>
              <button
                className="btn-confirm-style"
                style={{
                  background: "#fff",
                  color: "#0A6836",
                  border: "0.1rem solid #0A6836",
                  width: "20.6rem",
                  marginRight: "2rem",
                }}
                onClick={() => {
                  history.push(APP_ROUTES.ORDER_FARM);
                }}>
                {t("CANCEL")}
              </button>
              <button
                className="btn-confirm-style"
                style={{
                  width: "20.6rem",
                  marginLeft: "2rem",
                }}
                onClick={() => {
                  deleteOrderDraft();
                }}>
                {t("CONFIRM")}
              </button>
            </>
          }
          onClose={() => {
            setNotiOrderCancel(false);
          }}
        />
      </div>
    </Dialog>
  );
};

export default OrderDraftFarm;
