export const PermissionUtil = {
  check,
};

function check(data: any, permission: string | string[], takeAll: boolean) {
  if (!data || !data.length) return false;

  if (Array.isArray(permission)) {
    if (takeAll) {
      return permission.every((per: any) => data.includes(per));
    } else {
      return permission.some((per: any) => data.includes(per));
    }
  } else {
    return data.includes(permission);
  }
}
