import Axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosPromise } from "axios";
import { BASE_API_DOMAIN, BASE_API_DOMAIN_LOGIN, STORAGE } from "@Constants";

class Request {
  private axiosInstance: AxiosInstance;
  readonly defaultHeaders: any = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  constructor(apiDomain?: string) {
    let headers: any = this.defaultHeaders;
    headers = { ...headers, ...this.getAuthHeader() };

    const axiosRequestConfig: AxiosRequestConfig = {
      baseURL: apiDomain || BASE_API_DOMAIN,
      timeout: 30000,
      headers,
    };
    const axiosInstance: AxiosInstance = Axios.create(axiosRequestConfig);

    this.axiosInstance = axiosInstance;
  }

  updateHeader() {
    let headersConfig: any = {
      ...this.defaultHeaders,
      ...this.getAuthHeader(),
    };

    this.axiosInstance.defaults.headers = headersConfig;
  }

  getAuthHeader() {
    let Authorization: string = "";
    let token = localStorage.getItem("accessToken");
    if (token) {
      Authorization = `Bearer ${token}`;
    }

    return { Authorization };
  }

  // setAuthHeader(token: string) {
  //   let Authorization: string = "Bearer ".concat(token);

  //   this.axiosInstance.defaults.headers.Authorization = Authorization;
  // }

  // removeAuthHeader() {
  //   this.axiosInstance.defaults.headers.Authorization = "";
  // }

  setHeaders() {
    let headersConfig = {
      "Content-Type": "application/json",
    } as any;
    return (this.axiosInstance.defaults.headers = headersConfig);
  }

  get(input: { url: string; query?: any }, headers: any = {}): Promise<AxiosResponse<any>> {
    return this.send({
      method: "get",
      url: input.url,
      params: input.query || {},
      data: {},
      headers,
    });
  }

  post(
    input: { url: string; data: any; query?: any },
    headers: any = {}
  ): Promise<AxiosResponse<any>> {
    return this.send({
      method: "post",
      url: input.url,
      params: input.query || {},
      data: input.data,
      headers,
    });
  }

  put(
    input: { url: string; data?: any; query?: any },
    headers: any = {}
  ): Promise<AxiosResponse<any>> {
    return this.send({
      method: "put",
      url: input.url,
      params: input.query || {},
      data: input.data,
      headers,
    });
  }

  delete(
    input: { url: string; data?: any; query?: any },
    headers: any = {}
  ): Promise<AxiosResponse<any>> {
    return this.send({
      method: "delete",
      url: input.url,
      params: input.query || {},
      data: input.data || {},
      headers,
    });
  }

  patch(
    input: { url: string; data: any; query?: any },
    headers: any = {}
  ): Promise<AxiosResponse<any>> {
    return this.send({
      method: "patch",
      url: input.url,
      params: input.query || {},
      data: input.data,
      headers,
    });
  }

  head(input: { url: string; query?: any }, headers: any = {}): Promise<AxiosResponse<any>> {
    return this.send({
      method: "head",
      url: input.url,
      params: input.query || {},
      headers,
    });
  }

  send(options: AxiosRequestConfig, hiddenServerError?: boolean): AxiosPromise {
    const params: any = {
      ...options,
      headers: {
        ...this.axiosInstance.defaults.headers,
        ...options.headers,
        "x-language": localStorage.getItem(STORAGE.LOCAL_LANGUAGE),
      },
    };

    // add Authorization to header as defaults
    if (!params.headers.Authorization) {
      this.updateHeader();
      params.headers.Authorization = this.getAuthHeader().Authorization;
    }

    // remove undefined value
    for (const header in params.headers) {
      if (params.headers.hasOwnProperty(header)) {
        if (params.headers[header] === undefined) {
          delete params.headers[header];
        }
      }
    }

    return this.axiosInstance(params)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw error;
      });
  }
}

export const RequestUtil = new Request();
export const RequestUtilV2 = new Request(BASE_API_DOMAIN_LOGIN);
