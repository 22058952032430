import { IconBase, IconComponent } from "@Core/Icons";

export const LabIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <g clipPath="url(#clip0_20684_58768)">
        <path
          d="M5.43775 22C5.31314 21.9664 5.18658 21.9379 5.06353 21.8984C3.74439 21.4773 3.16783 19.9801 3.86783 18.759C4.95103 16.8687 6.04674 14.9855 7.13697 13.0992C7.93775 11.7141 8.74088 10.3305 9.53502 8.9418C9.60455 8.81992 9.64518 8.66328 9.64596 8.52266C9.65338 6.81719 9.65064 5.11133 9.65064 3.40586V3.17852H8.48502V2H15.5171V3.16484H14.3389V3.38633C14.3389 5.11797 14.337 6.85 14.3432 8.58164C14.3436 8.69766 14.3803 8.82656 14.4385 8.92734C16.3003 12.1559 18.1628 15.3836 20.0354 18.6055C20.3838 19.2047 20.5381 19.8219 20.3171 20.4898C20.053 21.2887 19.501 21.7855 18.671 21.9621C18.6342 21.9699 18.5999 21.9871 18.5643 22H5.43775ZM11.9799 20.8281C14.0569 20.8281 16.1338 20.8297 18.2108 20.8273C19.0409 20.8262 19.5018 20.0441 19.0881 19.3238C18.0522 17.5187 17.0061 15.7195 15.9674 13.916C15.9147 13.8242 15.8569 13.7941 15.7526 13.7941C13.246 13.7973 10.7393 13.7973 8.23228 13.7937C8.11588 13.7937 8.05885 13.8367 8.0065 13.932C7.85572 14.2055 7.69596 14.4738 7.53971 14.7441C6.66744 16.2531 5.79557 17.7625 4.92135 19.2703C4.78697 19.5023 4.6983 19.7406 4.75103 20.0125C4.84791 20.5141 5.23463 20.827 5.76822 20.8273C7.83853 20.8289 9.90924 20.8281 11.9796 20.8281H11.9799ZM15.2065 12.6156C15.162 12.534 15.1292 12.4723 15.0944 12.4117C14.4827 11.3535 13.8686 10.2965 13.2628 9.23516C13.2053 9.13438 13.171 9.00508 13.1706 8.88867C13.1651 7.06641 13.1667 5.24414 13.1667 3.42188V3.18477H10.8225V3.42852C10.8225 5.23789 10.8245 7.04688 10.8182 8.85625C10.8178 8.98477 10.7799 9.12734 10.7167 9.23906C10.1116 10.3008 9.49674 11.3574 8.88541 12.4156C8.85064 12.4758 8.82096 12.5387 8.78072 12.6156H15.2069H15.2065Z"
          fill={props.color}
          stroke={props.color}
          strokeWidth="0.5"
        />
        <path
          d="M11.2145 16.6881C11.2145 15.7139 12.0047 14.9264 12.9778 14.9307C13.9383 14.935 14.7274 15.7268 14.7278 16.6873C14.7282 17.6545 13.9286 18.4494 12.959 18.4451C11.9961 18.4412 11.2145 17.6537 11.2141 16.6881H11.2145ZM12.3868 16.6885C12.3872 17.0108 12.6497 17.2737 12.9696 17.2729C13.2852 17.2721 13.5567 17.0002 13.5559 16.6862C13.5551 16.3717 13.2833 16.1018 12.9672 16.1022C12.6465 16.1026 12.386 16.3654 12.3868 16.6885Z"
          fill={props.color}
        />
        <path
          d="M7.89381 16.4982C7.89147 16.063 8.23326 15.7146 8.6649 15.7114C9.09303 15.7083 9.44967 16.0587 9.45397 16.4861C9.45826 16.9111 9.10397 17.27 8.67701 17.2728C8.24498 17.2759 7.89615 16.9306 7.8942 16.4978L7.89381 16.4982Z"
          fill={props.color}
        />
        <path
          d="M8.67547 18.8267C8.67898 18.3943 9.02859 18.0509 9.4614 18.0552C9.88875 18.0595 10.2419 18.4201 10.2352 18.8447C10.2286 19.2724 9.87078 19.6212 9.44304 19.6165C9.01218 19.6119 8.67156 19.2615 8.67508 18.8263L8.67547 18.8267Z"
          fill={props.color}
        />
        <path
          d="M15.7075 18.8447C15.7044 18.4091 16.0446 18.0595 16.4759 18.0552C16.904 18.0509 17.2614 18.3994 17.2677 18.8271C17.2739 19.2517 16.92 19.6122 16.4935 19.6165C16.0614 19.6208 15.7111 19.2767 15.7079 18.8443L15.7075 18.8447Z"
          fill={props.color}
        />
      </g>
      <defs>
        <clipPath id="clip0_20684_58768">
          <rect width="24" height="24" fill={props.color} />
        </clipPath>
      </defs>
    </IconBase>
  );
};
