import { REQUEST_URL } from "@Constants";
import { PaginationModel, PaginationQueryModel, SubAccountInputModel } from "@Models";
import { RequestUtil } from "@Utils";

export const CustomerService = {
  changePassword,
  getCustomerProfile,
  getAuthProfile,
  uploadIdentity,
  uploadImage,
  getAllDebt,
  getAddress,
  createAddress,

  getAllSubAccount,
  getSubAccountById,
  createSubAccount,
  updateSubAccount,
  reviewSubAccount,
  changeSubAccountStatus,
  changeSubAccountProvision,

  getCustomerPolicy,
  acceptCustomerPolicy,
};

function changePassword(data: { oldPassword: string; newPassword: string }) {
  return RequestUtil.put({
    url: REQUEST_URL.CHANGE_PW,
    data,
  });
}

function getCustomerProfile() {
  const url = REQUEST_URL.CUSTOMER_PROFILE;
  return RequestUtil.get({ url });
}

function getAuthProfile() {
  const url = REQUEST_URL.AUTH_PROFILE;
  return RequestUtil.get({ url });
}

function uploadIdentity(formData: FormData) {
  const url = REQUEST_URL.CUSTOMER_IDENTITY;
  return RequestUtil.put({ url, data: formData });
}

function uploadImage(type: string, formData: FormData) {
  const url = REQUEST_URL.CUSTOMER_UPLOADIMAGE.replace("{type}", type);
  return RequestUtil.put({ url, data: formData });
}

function getAllDebt(
  query: PaginationQueryModel & {
    from?: string;
    to?: string;
    source: string;
    searchValue?: string;
  }
) {
  const url = REQUEST_URL.DEBT;

  return RequestUtil.get({ url, query });
}

function getAddress(
  query: PaginationModel & {
    source: number;
    searchValue?: string;
  }
) {
  const url = REQUEST_URL.GET_ADDRESS;

  return RequestUtil.get({ url, query });
}

function createAddress(data: {
  customerId: number;
  name: string;
  phoneNumber: string;
  country?: string | null;
  province?: string | null;
  district?: string | null;
  ward?: string | null;
  address: string;
}) {
  const url = REQUEST_URL.CREATE_ADDRESS;

  return RequestUtil.post({ url, data });
}

//sub-account
function getAllSubAccount(
  query: PaginationModel & {
    strSearch?: string;
    status?: boolean;
  }
) {
  const url = REQUEST_URL.SUB_ACCOUNT_GET_ALL;

  return RequestUtil.get({ url, query });
}

function getSubAccountById(id: string) {
  const url = REQUEST_URL.SUB_ACCOUNT_GET_DETAIL.replace("{id}", id);

  return RequestUtil.get({ url });
}

function createSubAccount(data: SubAccountInputModel) {
  const url = REQUEST_URL.SUB_ACCOUNT_CREATE;

  return RequestUtil.post({ url, data });
}

function updateSubAccount(id: string, data: SubAccountInputModel) {
  const url = REQUEST_URL.SUB_ACCOUNT_UPDATE.replace("{id}", id);

  return RequestUtil.put({ url, data });
}

function reviewSubAccount(
  id: string,
  data: {
    review: string;
    reason: string;
  }
) {
  const url = REQUEST_URL.SUB_ACCOUNT_REVIEW.replace("{id}", id);

  return RequestUtil.post({ url, data });
}

function changeSubAccountStatus(data: { accountId: number; isActive: boolean }) {
  const url = REQUEST_URL.SUB_ACCOUNT_CHANGE_STATUS;
  return RequestUtil.put({ url, data: data });
}

function changeSubAccountProvision(id: string, data: { status: string }) {
  const url = REQUEST_URL.SUB_ACCOUNT_PROVISION.replace("{id}", id);
  return RequestUtil.put({ url, data: data });
}

function getCustomerPolicy() {
  const url = REQUEST_URL.POLICY_GET;

  return RequestUtil.get({ url });
}

function acceptCustomerPolicy() {
  const url = REQUEST_URL.POLICY_ACCEPT;

  return RequestUtil.put({ url });
}
