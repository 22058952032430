import { REQUEST_URL } from "@Constants";
import { PaginationQueryModel } from "@Models";
import { RequestUtil } from "@Utils";

export const ShippingService = {
  getAllShipping: (
    query: PaginationQueryModel & {
      searchValue?: string;
      customerId: string;
      divisionIds: any;
      source: string;
    }
  ) => {
    const url = REQUEST_URL.SHIPPING;

    return RequestUtil.get({ url, query: query });
  },

  createShipping: (data: any) => {
    const url = REQUEST_URL.SHIPPING;

    return RequestUtil.post({ url, data });
  },

  updateShipping: (id: string, data: any) => {
    const url = `${REQUEST_URL.SHIPPING}/${id}`;

    return RequestUtil.put({ url, data });
  },

  getSalesOffice: (data: PaginationQueryModel & { customerId: number; divisionIds: any }) => {
    const url = REQUEST_URL.SALES_OFFICE;
    return RequestUtil.get({ url, query: data });
  },
};
