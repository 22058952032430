import React, { useEffect, useState } from "react";
import { Remove as RemoveIcon } from "@mui/icons-material";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "./index.scss";
import { Moment } from "moment";
import { Calendar1Icon } from "../Icons/Calendar1";
import "moment/locale/vi";
import moment from "moment";

type P_Props = {
  startDate: any;
  endDate: any;
  onChangeDate: (value: any[]) => void;
  font?: string;
  borderRadius?: any;
  color?: string;
  today?: boolean;
};

const RangePicker: React.FC<P_Props> = ({
  startDate,
  endDate,
  onChangeDate,
  borderRadius,
  font,
  color,
  today,
}) => {
  const [value, setValue] = useState<{
    startDate: Moment;
    endDate: Moment;
  }>({
    startDate: startDate,
    endDate: endDate || "",
  });

  const [focusedInput, setFocusedInput] = useState(null);

  const handleDatesChange = ({ startDate, endDate }: any) => {
    setValue(() => ({
      startDate,
      endDate,
    }));
    onChangeDate([value.startDate, endDate]);
  };

  useEffect(() => {
    today &&
      setValue({
        startDate: moment().set({ hours: 23, minutes: 59, seconds: 59 }),
        endDate: moment().set({ hours: 23, minutes: 59, seconds: 59 }),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [today === true]);

  return (
    <div
      style={{
        fontFamily: font ? font : "SVN-Gotham",
        color: color ? color : "#44444f",
      }}
      className={`date-range ${borderRadius ? "borderDateRange" : ""}`}>
      <DateRangePicker
        customInputIcon={<Calendar1Icon color="#ffffff" size={[20, 20]} viewBox={[20, 20]} />}
        startDate={value.startDate}
        startDateId="startDate"
        endDate={value.endDate}
        isOutsideRange={() => false}
        minimumNights={0}
        endDateId="endDate"
        onDatesChange={handleDatesChange}
        focusedInput={focusedInput}
        onFocusChange={(focusedInput: any) => setFocusedInput(focusedInput)}
        displayFormat="ll"
        customArrowIcon={<RemoveIcon />}
      />
    </div>
  );
};

export default React.memo(RangePicker);
