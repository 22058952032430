/* eslint-disable react-hooks/exhaustive-deps */
import urlSound from "@Assets/audio/gf-noti.mp3";
import Product from "@Assets/images/mock/product.png";
import ProNull from "@Assets/images/nopro.png";
import ItemImg from "@Assets/images/stroke.svg";
import { EventIcon } from "@Components/Icons/EventIcon";
import Loading from "@Components/Loading";
import Notification from "@Components/Notification";
import ProductItem from "@Components/ProductItem";
import QuantityBtnGroup from "@Components/QuantityBtnGroup";
import ResultOrder from "@Components/ResultOrder";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import { APP_ROUTES, TEXT_ASTERISK } from "@Constants";
import {
  Action,
  FetchAction,
  ItemPermission,
  OrderCreateModel,
  PaginateFetchReducer,
  ProductOutputModel,
  SourceType,
} from "@Models";
import { CustomerService, OrderService, ProductService, ShippingService } from "@Services";
import { checkLinkImage, currencyFormat, PermissionUtil } from "@Utils";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  ArrowLeftSharp as ArrowLeftSharpIcon,
  ArrowRightSharp as ArrowRightSharpIcon,
  DeleteOutline as DeleteOutlineIcon,
  PriorityHighRounded as PriorityHighRoundedIcon,
  Search as SearchIcon,
  WarningRounded as WarningRoundedIcon,
} from "@mui/icons-material";
import {
  Dialog,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { vi } from "date-fns/locale";
import _, { parseInt } from "lodash";
import _debounce from "lodash/debounce";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import InfiniteScroll from "react-infinite-scroller";
import { useHistory, useLocation, useParams } from "react-router-dom";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "../../OrderModal.scss";
import { useTranslation } from "react-i18next";

SwiperCore.use([Navigation]);

function reducer(state: PaginateFetchReducer<ProductOutputModel>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: [...state.data, ...action.payload.data],
        loading: false,
      };
    case Action.RESET:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload.data,
        loading: false,
      };
    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload };
    default:
      return { ...state };
  }
}

type OrderProduct = OrderCreateModel & ProductOutputModel;

const OrderDraftVet: React.FC = () => {
  const { t } = useTranslation(["translation"]);
  //notification sound
  const [audio] = useState<any>(new Audio(urlSound));
  const [playing, setPlaying] = useState<boolean>(false);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing]);

  useEffect(() => {
    audio.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
    };
  }, []);

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const mounted = useRef(false);
  const submitRef = useRef<any | null>(null);
  const [searchValue, setSearchValue] = useState<string>("");
  const location = useLocation<any>();
  const [notiOrder, setNotiOrder] = useState<{
    visible: boolean;
    newOrder: boolean;
  }>({
    visible: false,
    newOrder: false,
  });
  const [notiOrderCancel, setNotiOrderCancel] = useState<boolean>(false);
  const [shipping, setShipping] = useState<any>();
  const [salesOffice, setSalesOffice] = useState<any>();
  const [salesOfficeId, setSalesOfficeId] = useState<string>("");
  const [shippingAddress, setShippingAddress] = useState<string>("");
  const [interestedSuccess, setInterestedSuccess] = useState<boolean>(false);

  const [state, dispatch] = useReducer(reducer, {
    page: 0,
    totalPages: 0,
    limit: 0,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
    loading: true,
  });

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const [itemGroupId, setItemGroupId] = useState<string>("");
  const [ordDraft, setOrdDraft] = useState<boolean>(false);

  const fetchData = async (query: any, isReset: boolean = false) => {
    if (state.fetching) return;
    dispatch({ type: Action.FETCH, payload: {} });

    try {
      const result = await ProductService.filterProducts(query);
      if (isReset)
        dispatch({
          type: Action.RESET,
          payload: result.data,
        });
      else if (mounted.current)
        dispatch({
          type: Action.SUCCESS,
          payload: result.data,
        });
    } catch (err) {
      if (mounted)
        dispatch({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
    }
  };

  useEffect(() => {
    fetchData(
      {
        page: 1,
        limit: 20,
        searchValue: searchValue,
        source: SourceType.VET,
        itemGroupId: itemGroupId ? itemGroupId : undefined,
      },
      true
    );
  }, [searchValue, itemGroupId, interestedSuccess]);

  const onSearch = _.debounce((value) => {
    setSearchValue(value);
  }, 500);

  const hasMore = state.data.length < state.totalRecords && state.page < state.totalPages;

  const [selectedVars, setSelectedVars] = useState<{ item: OrderProduct; quantity: number }[]>([]);

  const [dataOrd, setDataOrd] = useState<any>({});

  const [profile, setProfile] = useState<any>([]);

  const loadProfileData = _debounce(async () => {
    const response = await CustomerService.getAuthProfile();
    setProfile(response.data);
  }, 100);

  useEffect(() => {
    loadProfileData();
  }, []);

  const idCus = profile.customer?.find((cus: any) => cus.source === SourceType.VET)?.id;

  /*Dat lai don hang*/
  const { orderId }: { orderId: string } = useParams();
  const [order, setOrder] = useState<any>();

  const orderById: () => Promise<void> = async () => {
    try {
      const response = await OrderService.getOrderById(orderId);
      if (mounted.current) {
        setOrder(() => response.data.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    orderId && orderById();
  }, []);

  const [orderDetail, setOrderDetail] = useState<any>([]);
  const [ordDetailValue, setOrdDetailValue] = useState<any>([]);

  const listItem = order?.orders.map((o: any) => {
    return o.orderlines.map((d: any) => {
      return {
        itemId: d.item.id,
      };
    });
  });

  const [itemById, setItemById] = useState<any>([]);

  const loadListItemById = async () => {
    const response = await ProductService.getItemById({
      source: SourceType.VET,
      itemIds: `${[].concat(...listItem).map((a: any) => {
        return a.itemId;
      })}`,
      customerId: idCus,
    });
    setItemById(response.data);
  };

  useEffect(() => {
    orderId && order && orderDetail && listItem && loadListItemById();
  }, [orderDetail]);

  const ordDetail = order?.orders.map((o: any) => {
    return o.orderlines.map((d: any) => {
      const checkItem = itemById.some((f: any) => f.item.id === d.item.id);
      return {
        itemId: checkItem
          ? itemById.find((f: any) => f.item.id === d.item.id)?.item?.id
          : d.item?.id,
        divisionId: checkItem
          ? itemById.find((f: any) => f.item.id === d.item.id)?.item.division?.id
          : o.division?.id,
        discount: 0,
        quantity: +d.orderQuantity,
        unitPrice: checkItem
          ? +itemById.find((f: any) => f.item.id === d.item.id)?.value
          : +d.price,
        attachValue: checkItem
          ? itemById.find((f: any) => f.item.id === d.item.id)?.additionValue
          : d.additionValue,
        saleUnit: checkItem
          ? itemById.find((f: any) => f.item.id === d.item.id)?.baseUoM
          : d.saleUnit,
        baseUoM: checkItem
          ? itemById.find((f: any) => f.item.id === d.item.id)?.item.baseUoM
          : d.baseUoM,
        dimension: checkItem
          ? +itemById.find((f: any) => f.item.id === d.item.id)?.item.dimension * +d.orderQuantity
          : +d.dimension * +d.orderQuantity,
        subTotal: checkItem
          ? +d.orderQuantity * +itemById.find((f: any) => f.item.id === d.item.id)?.value
          : +d.orderQuantity * d.price,
        available: checkItem ? true : false,
      };
    });
  });

  useEffect(() => {
    order && setOrderDetail([].concat(...ordDetail));
    setOrdDetailValue(
      order?.orders.map((o: any) => {
        return o.orderlines.map((d: any) => {
          return d;
        });
      })
    );
  }, [order]);

  useEffect(() => {
    order && itemById && setOrderDetail([].concat(...ordDetail));
  }, [itemById.length > 0]);

  const onUpdateProdVarQuantityDetail = (
    variantId: number,
    quantity: number,
    dimension?: number
  ) => {
    const idx = [].concat(...orderDetail).findIndex((item: any) => item.itemId === variantId);
    let data = [].concat(...orderDetail) as any;

    // update
    if (quantity === -1) {
      data.splice(idx, 1);
      ordDetailValue.splice(idx, 1);
      setOrdDetailValue(ordDetailValue);
    } else {
      data[idx] = {
        ...data[idx],
        quantity: quantity,
        dimension: dimension,
      };
    }
    // data = data.filter((item: any) => item.quantity >= 1);
    setOrderDetail(data);
  };

  const totalDetail = orderDetail
    .filter((item: any) => item.available === true)
    .reduce((sum: number, item: any) => {
      sum += +item.unitPrice * item.quantity;
      return sum;
    }, 0);

  /*------------------------------------- */

  const onUpdateProdVarQuantity = (
    variantId: number,
    quantity: number,
    addedProduct?: OrderProduct,
    addNew: boolean = false
  ) => {
    const idx = selectedVars.findIndex((item) => item.item.item.id === variantId);
    const actOrd = [].concat(...orderDetail).find((item: any) => item.itemId === variantId);

    let data = [...selectedVars];
    if (idx !== -1) {
      if (addNew) {
        return;
      }
      // update

      data[idx] = {
        ...data[idx],
        quantity: quantity || 1,
      };
    } else {
      // thêm mới
      if (!addedProduct) {
        return;
      }
      if (actOrd) {
        return;
      }

      data.push({ item: addedProduct, quantity });
    }
    data = data.filter((item) => item.quantity >= 1);

    setSelectedVars(data);
  };

  const total = selectedVars.reduce((sum, item) => {
    sum += item.item.value * item.quantity;
    return sum;
  }, 0);

  const submitForm = async (values: any) => {
    if (submitRef.current) {
      submitRef.current.disabled = true;
    }
    const validProducts = selectedVars.filter((item) => item.quantity);
    if (!validProducts.length) {
      // setNotiOrderNull(true);
    }
    let total = 0;
    const orderDetails = validProducts.map((item) => {
      total += item.item.value * item.quantity;

      return {
        itemId: item.item.item.id,
        divisionId: item.item.item.division.id,
        discount: 0,
        quantity: item.quantity,
        unitPrice: item.item.value,
        attachValue: item.item.additionValue,
        saleUnit: item.item.baseUoM,
        baseUoM: item.item.item.baseUoM,
        dimension: +item.item.item.dimension * item.quantity,
        subTotal: item.quantity * item.item.value,
      };
    });

    const data: Parameters<typeof OrderService.updateOrderDraft>[0] = {
      ...values,
      customerId: idCus,
      creatorId: idCus,
      salesOrgId: profile.customer[0].customerDivision[0].salesOrg?.id,
      shippingAddress: shippingAddress ? shippingAddress : undefined,
      salesOfficeId: salesOfficeId ? salesOfficeId : undefined,
      distributionChannelId: profile.customer[0].customerDivision[0].distributionChannel?.id,
      address: profile.customer[0].address,
      currency: "cash",
      totalDiscount: 0,
      vat: 0,
      orderAmount: total + totalDetail,
      source: SourceType.VET,
      orderDetails: orderId
        ? orderDetails.concat(orderDetail.filter((item: any) => item.available === true))
        : orderDetails,
      isDraft: ordDraft,
      draftId: +orderId,
      requiredDate: moment(values.requiredDate || new Date()).format("YYYY-MM-DD"),
    } as any;

    OrderService.updateOrderDraft(data as any)
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          if (ordDraft === true) {
            history.push(APP_ROUTES.ORDER_VET);
          } else {
            setNotiOrder({ visible: true, newOrder: true });
            setDataOrd(res.data.data);
            setPlaying(true);
            setLoading(false);
          }
        } else {
          setLoading(false);
          // setNotiOrder({ visible: true, newOrder: false });
        }
      })
      .catch((error) => {
        setLoading(false);
        setNotiOrder({ visible: true, newOrder: false });
        submitRef.current.disabled = false;
      });
  };

  const setDefaultImage = (e: any) => {
    e.target.src = Product;
  };

  const [loading, setLoading] = useState<boolean>(false);

  const deleteOrderDraft = async () => {
    try {
      await OrderService.deleteOrderDraft({
        orderGroupId: +orderId,
        source: SourceType.VET,
        customerId: order.createdBy.id,
      });
      enqueueSnackbar(t("DELETE_CART_SUCCESSFULLY"), {
        variant: "success",
      });
      history.push(APP_ROUTES.ORDER_VET);
    } catch (error) {
      enqueueSnackbar(t("DELETE_CART_FAILED"), {
        variant: "error",
      });
    }
  };

  const divisionIds = Array.from(
    new Set(
      selectedVars
        .map((i) => {
          return i.item.item.division.id;
        })
        .concat(
          orderDetail.map((d: any) => {
            return d.divisionId;
          })
        )
    )
  );

  const getSalesOffice: () => Promise<void> = async () => {
    try {
      const response = await ShippingService.getSalesOffice({
        page: 1,
        limit: 100,
        customerId: order?.orders[0].customer?.id,
        divisionIds: divisionIds.toString(),
      });
      if (mounted.current) {
        setSalesOffice(() => response.data.data);
      }
    } catch (error) {}
  };

  const getShipping: () => Promise<void> = async () => {
    try {
      const response = await ShippingService.getAllShipping({
        page: 1,
        limit: 100,
        customerId: order?.orders[0].customer?.id,
        divisionIds: divisionIds.toString(),
        source: SourceType.VET,
      });
      if (mounted.current) {
        setShipping(() => response.data.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    divisionIds.length > 0 && order?.orders[0].customer?.id && getSalesOffice();
    divisionIds.length > 0 && order?.orders[0].customer?.id && getShipping();
  }, [order?.orders[0].customer?.id, selectedVars, order, divisionIds.length > 0]);

  const shipppingAddressId = shipping?.find((f: any) => f.address === order?.orders[0].address)?.id;

  useEffect(() => {
    order?.orders[0].salesOffice && setSalesOfficeId(order?.orders[0].salesOffice?.id);
    order?.orders[0].address && setShippingAddress(shipppingAddressId ? shipppingAddressId : "");
  }, [order, shipping]);

  const totalWeight =
    selectedVars?.reduce((sum, item) => {
      return sum + +item.item.item.dimension * item.quantity;
    }, 0) +
    orderDetail?.reduce((sum: any, item: any) => {
      return sum + +item.dimension;
    }, 0);

  const updateProductInterested = async (id: number, interested: boolean) => {
    try {
      const res = await ProductService.updateProductInterested({
        itemId: id,
        interested: interested,
        source: SourceType.VET,
      });
      if (res && (res.status === 200 || res.status === 201)) {
        setInterestedSuccess(!interestedSuccess);
      }
    } catch (error: any) {}
  };

  //permission
  const checkPermissionViewPrice = PermissionUtil.check(
    profile?.role?.permissions,
    ItemPermission.MANAGE_ITEM_VIEW_PRICE,
    true
  );

  return (
    <Dialog fullScreen open={true}>
      <div className="order-modal">
        <SimpleModalHeader
          title={t("CREATE_ORDER_VET")}
          onClose={() => {
            history.push({
              pathname: APP_ROUTES.ORDER_VET,
              state: {
                startTime: location.state?.startTime,
                endTime: location.state?.endTime,
                status: location.state?.status,
              },
            });
          }}
        />
        <div className="order-form">
          <Grid container>
            <Grid item xs={9} className="order-form-left">
              <Form
                onSubmit={async (values) => submitForm(values)}
                initialValues={order?.orders[0]}>
                {({ handleSubmit }) => {
                  return (
                    <form onSubmit={handleSubmit} id="order-form">
                      <div className="product-order">
                        <div className="product-order-header">
                          <div className="prod">{t("PRODUCT")}</div>
                          <div className="price">{t("UNIT_PRICE")}</div>
                          <div className="quan">{t("QUANTITY")}</div>
                          <div className="unit">{t("WEIGHT")}</div>
                          <div className="pay">{t("TOTAL_PRICE")}</div>
                          <div className="ic-del"></div>
                        </div>
                        <Divider style={{ marginBottom: "3.3rem" }} />
                        <div className="product-order-list">
                          {Object.keys(itemById).length === 0 ? (
                            <Loading style={{ height: "40vh" }} />
                          ) : (
                            orderDetail &&
                            orderDetail.map((d: any, idx: number) => {
                              const orderDetailRender = order.orders?.reduce(
                                (result: object | null, od: any) => {
                                  const orderL = od.orderlines.find(
                                    (odl: any) =>
                                      odl.item.id === d.itemId && od.division.id === d.divisionId
                                  );
                                  if (orderL) {
                                    result = orderL;
                                  }
                                  return result;
                                },
                                null
                              );
                              const checkItem = itemById.some(
                                (f: any) => f.item.id === orderDetailRender.item.id
                              );
                              return (
                                <div key={idx} style={{ paddingBottom: "2.4rem" }}>
                                  <div
                                    className="product-order-card"
                                    style={{
                                      opacity: !checkItem ? 0.5 : 1,
                                    }}>
                                    <div className="prod">
                                      <img
                                        src={
                                          checkLinkImage(orderDetailRender.item?.images?.picture) ||
                                          Product
                                        }
                                        alt="product"
                                        onError={setDefaultImage}
                                      />
                                      <div className="name">
                                        {orderDetailRender["item"]["shortName"]}

                                        <div className="code">
                                          {orderDetailRender["item"]["name"]}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="price text">
                                      {checkPermissionViewPrice
                                        ? currencyFormat(d.unitPrice)
                                        : TEXT_ASTERISK}{" "}
                                      đ/
                                      <span>{d.saleUnit}</span>
                                    </div>
                                    <div className="quan">
                                      <QuantityBtnGroup
                                        disableMinusBtn={d.quantity <= 1}
                                        quantity={d.quantity}
                                        onChangeQuantity={(value) => {
                                          onUpdateProdVarQuantityDetail(
                                            d.itemId,
                                            value,
                                            +orderDetailRender["item"]["dimension"] * value
                                          );
                                        }}
                                        style={{ maxWidth: "12rem", maxHeight: "3.5rem" }}
                                      />
                                    </div>
                                    <div className="unit">
                                      {d.dimension}{" "}
                                      <span style={{ textTransform: "lowercase" }}>
                                        {d.baseUoM}
                                      </span>
                                    </div>
                                    <div className="pay">
                                      {checkPermissionViewPrice
                                        ? currencyFormat(d.quantity * d.unitPrice)
                                        : TEXT_ASTERISK}
                                      &ensp;đ
                                    </div>
                                    <div
                                      className="ic-del"
                                      onClick={() => {
                                        onUpdateProdVarQuantityDetail(d.itemId, -1);
                                      }}>
                                      <DeleteOutlineIcon
                                        style={{
                                          fontSize: "2.2rem",
                                          marginLeft: "3rem",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </div>
                                  </div>
                                  {!checkItem && (
                                    <div className="card-null ">
                                      <WarningRoundedIcon />
                                      &ensp;{t("PRODUCT_NO_LONGER_AVAILABLE")}
                                    </div>
                                  )}
                                </div>
                              );
                            })
                          )}

                          {!!selectedVars.length &&
                            selectedVars.map((val, idx: number) => {
                              return (
                                <div
                                  key={idx}
                                  className="product-order-card"
                                  style={{ paddingBottom: "2.4rem" }}>
                                  <div className="prod">
                                    <img
                                      src={
                                        checkLinkImage(val.item?.item?.images?.picture as any) ||
                                        Product
                                      }
                                      alt="product"
                                      onError={setDefaultImage}
                                    />
                                    <div className="name">
                                      {val.item?.item?.shortName}
                                      {/* &ensp;-&ensp;
                                      <span style={{ textTransform: "lowercase" }}>
                                        {val.item?.item?.dimension}
                                        {val.item?.item?.baseUoM}
                                      </span> */}
                                      <div className="code">{val.item?.item?.name}</div>
                                    </div>
                                  </div>
                                  <div className="price text">
                                    {checkPermissionViewPrice
                                      ? currencyFormat(val.item?.value)
                                      : TEXT_ASTERISK}{" "}
                                    đ/
                                    <span>{val.item?.baseUoM}</span>
                                  </div>
                                  <div className="quan">
                                    <QuantityBtnGroup
                                      disableMinusBtn={val.quantity <= 1}
                                      quantity={val.quantity}
                                      onChangeQuantity={(value) => {
                                        onUpdateProdVarQuantity(val.item?.item.id, value);
                                      }}
                                      style={{ maxWidth: "12rem", maxHeight: "3.5rem" }}
                                    />
                                  </div>
                                  <div className="unit">
                                    {parseFloat(val.item?.item?.dimension.toString()) *
                                      val.quantity}{" "}
                                    <span style={{ textTransform: "lowercase" }}>
                                      {val.item?.item?.baseUoM}
                                    </span>
                                  </div>
                                  <div className="pay">
                                    {checkPermissionViewPrice
                                      ? currencyFormat(val.quantity * val.item?.value)
                                      : TEXT_ASTERISK}
                                    &ensp;đ
                                  </div>
                                  <div
                                    className="ic-del"
                                    onClick={() => {
                                      onUpdateProdVarQuantity(val.item?.item.id, -1);
                                    }}>
                                    <DeleteOutlineIcon
                                      style={{
                                        fontSize: "2.2rem",
                                        marginLeft: "3rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                      <div className="footer">
                        <div className="payment">
                          <Grid
                            container
                            justifyContent="space-between"
                            alignItems="flex-end"
                            spacing={6}>
                            <Grid item xs={3} className="info-order">
                              <div className="form-item">
                                <div className="form-label">{t("DELIVERY_DATE")}</div>
                                <Field
                                  name="requiredDate"
                                  // validate={FormUtil.Rule.required(
                                  //   "Bạn vẫn chưa nhập Chọn ngày giao hàng"
                                  // )}
                                >
                                  {({ input, meta, ...rest }) => {
                                    return (
                                      <MuiPickersUtilsProvider locale={vi} utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                          okLabel={t("SELECT")}
                                          cancelLabel={t("CANCEL")}
                                          fullWidth
                                          disablePast
                                          disableToolbar
                                          inputVariant="outlined"
                                          format="dd-MM-yyyy"
                                          className="form-text-field date"
                                          value={input.value || new Date()}
                                          onChange={(value) => {
                                            if (value) {
                                              input.onChange(value);
                                            }
                                          }}
                                          InputProps={{ readOnly: true }}
                                          inputProps={{ className: "input" }}
                                          keyboardIcon={
                                            <EventIcon
                                              color="#41AD49"
                                              size={[22, 22]}
                                              viewBox={[22, 22]}
                                            />
                                          }
                                          helperText={meta.touched ? meta.error : ""}
                                          error={meta.error && meta.touched}
                                        />
                                      </MuiPickersUtilsProvider>
                                    );
                                  }}
                                </Field>
                              </div>
                              <div className="form-item" style={{ width: "100%" }}>
                                <div className="form-label">{t("DELIVERY_ADDRESS")}</div>
                                <Select
                                  value={shippingAddress}
                                  onChange={(e) => {
                                    setShippingAddress(e.target.value);
                                  }}
                                  fullWidth
                                  input={
                                    <OutlinedInput className="form-text-field" color="success" />
                                  }
                                  variant="outlined"
                                  className="form-select-field"
                                  renderValue={
                                    shippingAddress !== ""
                                      ? undefined
                                      : () => (
                                          <span style={{ color: "#9e9e9e" }}>
                                            -{" "}
                                            {t("SELECT_BY_NAME", {
                                              NAME: t("DELIVERY_ADDRESS"),
                                            })}
                                          </span>
                                        )
                                  }
                                  displayEmpty
                                  MenuProps={{
                                    className: "select-menu-list",
                                  }}>
                                  {shipping?.length > 0 &&
                                    shipping.map((ship: any, index: number) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={ship.id}
                                          style={{
                                            borderBottom: "0.1rem solid #EFEFEF",
                                            whiteSpace: "normal",
                                          }}>
                                          {ship.address}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              </div>
                            </Grid>
                            <Grid item xs={3} className="info-order">
                              <div className="form-item">
                                <div className="form-label">{t("VEHICLE_NUMBER")}</div>
                                <Field name="vehicleNumber">
                                  {({ input, meta, ...rest }) => {
                                    return (
                                      <TextField
                                        {...input}
                                        {...rest}
                                        placeholder={t("INPUT_BY_NAME", {
                                          NAME: t("VEHICLE_NUMBER"),
                                        })}
                                        color="success"
                                        className="form-text-field"
                                        inputProps={{ className: "input" }}
                                        helperText={meta.touched ? meta.error : ""}
                                        error={meta.error && meta.touched}
                                        onKeyDown={(event) => {
                                          if (event.key === "Enter") {
                                            event.preventDefault();
                                            return false;
                                          }
                                        }}
                                      />
                                    );
                                  }}
                                </Field>
                              </div>
                              <div className="form-item" style={{ width: "100%" }}>
                                <div className="form-label">{t("RECEIVING_ADDRESS")}</div>
                                <Select
                                  value={salesOfficeId}
                                  onChange={(e) => {
                                    setSalesOfficeId(e.target.value);
                                  }}
                                  fullWidth
                                  input={
                                    <OutlinedInput className="form-text-field" color="success" />
                                  }
                                  variant="outlined"
                                  className="form-select-field"
                                  renderValue={
                                    salesOfficeId !== ""
                                      ? undefined
                                      : () => (
                                          <span style={{ color: "#9e9e9e" }}>
                                            -{" "}
                                            {t("SELECT_BY_NAME", {
                                              NAME: t("RECEIVING_ADDRESS"),
                                            })}
                                          </span>
                                        )
                                  }
                                  displayEmpty
                                  MenuProps={{
                                    className: "select-menu-list",
                                  }}>
                                  {salesOffice?.length > 0 &&
                                    salesOffice.map((office: any, index: number) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={office.id}
                                          style={{
                                            borderBottom: "0.1rem solid #EFEFEF",
                                          }}>
                                          {office.description}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              </div>
                            </Grid>
                            <Grid item xs={6} className="total">
                              <div className="form-item" style={{ width: "100%" }}>
                                <div className="form-label">{t("NOTES")}</div>
                                <Field name="note">
                                  {({ input, meta, ...rest }) => {
                                    return (
                                      <TextField
                                        {...input}
                                        {...rest}
                                        fullWidth
                                        placeholder={t("INPUT_BY_NAME", {
                                          NAME: t("NOTES"),
                                        })}
                                        className="form-text-field"
                                        inputProps={{ className: "input" }}
                                        variant="outlined"
                                        onChange={(e) => input.onChange(e.target.value)}
                                        helperText={meta.touched ? meta.error : ""}
                                        error={meta.error && meta.touched}
                                        onKeyDown={(event) => {
                                          if (event.key === "Enter") {
                                            event.preventDefault();
                                            return false;
                                          }
                                        }}
                                      />
                                    );
                                  }}
                                </Field>
                              </div>
                              <div className="row">
                                {t("TOTAL_WEIGHT")}
                                <div className="price">{currencyFormat(totalWeight)} kg</div>
                              </div>
                              <div className="row">
                                {t("TOTAL")}
                                <div className="price price-color">
                                  {checkPermissionViewPrice
                                    ? currencyFormat(total + totalDetail)
                                    : TEXT_ASTERISK}{" "}
                                  đ
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        <div
                          className={orderDetail?.length || selectedVars.length ? "show" : "hide"}>
                          <div
                            className="btn-cancel-style btn__text-cancel"
                            onClick={() => {
                              setNotiOrderCancel(true);
                            }}>
                            {t("CANCEL_CART")}
                          </div>
                          <button
                            disabled={!orderDetail?.length && !selectedVars.length}
                            onClick={() => {
                              setOrdDraft(true);
                            }}
                            ref={submitRef}
                            type="submit"
                            className="btn-confirm-style btn-border"
                            style={{ marginLeft: "4rem" }}>
                            {t("SAVE_CART")}
                          </button>
                          <button
                            disabled={!orderDetail?.length && !selectedVars.length}
                            onClick={() => {
                              setLoading(true);
                              setOrdDraft(false);
                            }}
                            ref={submitRef}
                            type="submit"
                            className=" btn-confirm-style"
                            style={{ marginLeft: "4rem" }}>
                            {t("PLACE_ORDER")}
                          </button>
                        </div>
                      </div>
                    </form>
                  );
                }}
              </Form>
            </Grid>

            <Grid item xs={3} className="order-form-right">
              <TextField
                variant="outlined"
                color="success"
                placeholder={t("SEARCH_BY_NAME_PRODUCT_CODE")}
                className={"search-border-style"}
                inputProps={{ className: "input" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="large" />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  onSearch(e.target.value);
                }}
              />
              {/* {state.data.reduce((previous: any, current: any) => {
              if (current.item.itemGroup?.id && !previous.includes(current.item.itemGroup?.id)) {
                previous.push(current.item.itemGroup.id);
              }
              return previous;
            }, []).length > 1 } */}

              {state.itemGroups && state.itemGroups.length > 1 && (
                <div className="gr-item">
                  <Swiper
                    slidesPerView={3}
                    spaceBetween={1}
                    navigation={{
                      nextEl: ".swiper-button-next",
                      prevEl: ".swiper-button-prev",
                    }}>
                    {[
                      { id: "", name: t("ALL") },
                      { id: "0", name: t("INTEREST"), image: ItemImg },
                      ...state.itemGroups,
                    ].map((cate: any) => {
                      return (
                        <SwiperSlide>
                          <div
                            className={
                              cate?.id === itemGroupId ? "btn-gr__item active" : "btn-gr__item"
                            }
                            onClick={() => {
                              setItemGroupId(cate.id);
                            }}>
                            {cate.id !== "" && (
                              <img
                                src={cate.id !== "0" ? checkLinkImage(cate?.image) : cate?.image}
                                alt={"icon"}
                              />
                            )}
                            <div className="label">{cate?.name.toLowerCase()}</div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                  <button className="swiper-button-prev button-prev">
                    <ArrowLeftSharpIcon style={{ color: "#5A5959", fontSize: "2rem" }} />
                  </button>
                  <button className="swiper-button-next button-next">
                    <ArrowRightSharpIcon style={{ color: "#5A5959", fontSize: "2rem" }} />
                  </button>
                </div>
              )}

              {state.loading ? (
                <Loading style={{ margin: "2rem 0", height: "60vh" }} />
              ) : (
                <div className="product-list">
                  {state.fetching && searchValue && <Loading />}
                  {state.fetching && itemGroupId && <Loading />}
                  {state.data?.length > 0 ? (
                    <InfiniteScroll
                      pageStart={0}
                      loadMore={() =>
                        fetchData({
                          page: state.page + 1,
                          limit: 20,
                          source: SourceType.VET,
                          searchValue,
                          itemGroupId:
                            itemGroupId && !isNaN(+itemGroupId) ? parseInt(itemGroupId) : undefined,
                        })
                      }
                      hasMore={hasMore}
                      useWindow={false}>
                      {state.data.map((pro: any, index: number) => {
                        const act = selectedVars.find((item) => item.item?.item.id === pro.item.id);
                        const actOrd = []
                          .concat(...orderDetail)
                          .find((item: any) => item.itemId === pro.item.id);
                        return (
                          <ProductItem
                            class={act || actOrd}
                            key={pro.item.code + index}
                            id={pro.item.id}
                            onClick={() => onUpdateProdVarQuantity(pro.item.id, 1, pro, true)}
                            image={pro.item.images?.picture}
                            shortName={pro.item.shortName}
                            name={pro.item.name}
                            value={
                              checkPermissionViewPrice ? currencyFormat(pro.value) : TEXT_ASTERISK
                            }
                            baseUoM={pro.baseUoM}
                            source={SourceType.VET}
                            interestedItem={pro.item.interestedItem}
                            updateProductInterested={(interested: boolean) =>
                              updateProductInterested(pro.item.id, interested)
                            }
                          />
                        );
                      })}
                    </InfiniteScroll>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}>
                      <img src={ProNull} alt="search" width={190} height={178} />
                      <div
                        style={{
                          fontSize: "1.8rem",
                          fontWeight: "bold",
                          color: "#536380",
                          lineHeight: "2.7rem",
                        }}>
                        {t("PRODUCT_NOT_FOUND")}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </Grid>
          </Grid>
        </div>

        <ResultOrder
          visible={notiOrder.visible}
          newOrder={notiOrder.newOrder}
          dataOrd={dataOrd.data}
          setNotiOrder={() => setNotiOrder({ visible: false, newOrder: false })}
          source={SourceType.VET}
        />

        {loading && (
          <Backdrop
            sx={{ color: "red", opacity: 0.5, zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}>
            <Loading />
          </Backdrop>
        )}

        <Notification
          visible={
            order && orderDetail.length < 1 && selectedVars.length === 0 ? true : notiOrderCancel
          }
          title={t("DELETE_CART")}
          subTitle={
            <span dangerouslySetInnerHTML={{ __html: t("ARE_YOU_SURE_YOU_WANT_CANCEL_CART") }} />
          }
          icon={
            <div className={`notification-icon-box yellow`}>
              <PriorityHighRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
            </div>
          }
          actions={
            <>
              <button
                className="btn-confirm-style"
                style={{
                  background: "#fff",
                  color: "#0A6836",
                  border: "0.1rem solid #0A6836",
                  width: "20.6rem",
                  marginRight: "2rem",
                }}
                onClick={() => {
                  history.push(APP_ROUTES.ORDER_VET);
                }}>
                {t("CANCEL")}
              </button>
              <button
                className="btn-confirm-style"
                style={{
                  width: "20.6rem",
                  marginLeft: "2rem",
                }}
                onClick={() => {
                  deleteOrderDraft();
                }}>
                {t("AGREE")}
              </button>
            </>
          }
          onClose={() => {
            setNotiOrderCancel(false);
          }}
        />
      </div>
    </Dialog>
  );
};

export default OrderDraftVet;
