import Banner from "@Assets/images/mock/image-forget.png";
import LogoTitle from "@Assets/images/mock/logo-title-white.png";
import Notification from "@Components/Notification";
import { APP_ROUTES } from "@Constants";
import { AuthService } from "@Services";
import { FormUtil, loadCallback } from "@Utils";
import { ClearRounded as ClearRoundedIcon } from "@mui/icons-material";
import { TextField } from "@mui/material";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { useHistory } from "react-router-dom";
import "./index.scss";
import { useTranslation } from "react-i18next";

const ForgetPassword: React.FC = () => {
  const { t } = useTranslation(["translation"]);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [submitError, setSubmitError] = useState<boolean>(false);
  const [notiActivated, setNotiActivated] = useState<{ check: boolean; type: string }>({
    check: false,
    type: "",
  });

  let submit: any;
  const submitForm = React.useCallback(async (values) => {
    try {
      await AuthService.forgetPassword({
        user: values.user,
        platform: "webCustomer",
      })
        .then((res) => {
          if (res && (res.status === 200 || res.status === 201)) {
            localStorage.setItem("requestToken", res.data.requestToken);
            localStorage.setItem("phoneNumber", res.data.phoneNumber);
            history.push({
              pathname: APP_ROUTES.VERIFY_OTP,
              state: { pathUrl: APP_ROUTES.RESETPASSWORD, user: values.user },
            });
          }
        })
        .catch((err: AxiosError) => {
          switch (err.response?.data.message) {
            case "ACCOUNT_NOT_ACTIVATED":
              setNotiActivated({ check: true, type: "active" });
              break;
            case "ACCOUNT_IS_NOT_ALLOWED_TO_RECEIVE_OTP":
              setNotiActivated({ check: true, type: "otp" });
              break;
            default:
              enqueueSnackbar("Đã xảy ra lỗi!", {
                variant: "error",
              });
              break;
          }
        });
    } catch (error: any) {
      const errors: Record<string, unknown> = {};
      if (error.message) errors.user = "Tài khoản không tồn tại";
      return errors;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="forgetpw-form">
      <img src={Banner} alt="greenfeed-img" />
      <div className="forgetpw-form__content">
        <div className="forgetpw-form__content__logo">
          <img src={LogoTitle} alt="greenfeed-logo" />
        </div>
        <div className="title">Quên mật khẩu</div>
        <div className="description">
          Vui lòng nhập tài khoản <br /> chúng tôi sẽ giúp quý khách đặt lại mật khẩu.
        </div>
        <Form
          initialValuesEqual={() => true}
          onSubmit={submitForm}
          render={({ handleSubmit }) => {
            submit = handleSubmit;
            return (
              <form onSubmit={handleSubmit} className="forgetpw-form__content__form">
                <div className="forgetpw-form__label">Nhập tài khoản</div>
                <Field name="user" validate={FormUtil.Rule.required("Nhập tài khoản")}>
                  {({ input, meta, ...rest }) => {
                    return (
                      <TextField
                        {...input}
                        {...rest}
                        fullWidth
                        type="text"
                        autoComplete="text"
                        placeholder={"Nhập tài khoản..."}
                        className={"text-border-style"}
                        inputProps={{ className: "input" }}
                        variant="outlined"
                        helperText={submitError === true ? meta.error || meta.submitError : ""}
                        error={submitError === true ? meta.error || meta.submitError : ""}
                        onChange={(e) => {
                          input.onChange(e.target.value);
                          setSubmitError(true);
                        }}
                        onBlur={(e) => {
                          input.onChange(e.target.value.replace(/\s/g, ""));
                          setSubmitError(true);
                        }}
                      />
                    );
                  }}
                </Field>
                <button
                  className="btn-confirm-style"
                  onClick={(e) => {
                    e.preventDefault();
                    loadCallback(submit, e);
                  }}>
                  Đặt lại mật khẩu
                </button>
              </form>
            );
          }}
        />
        <div className="forgetpw-form__auth">
          <a href={APP_ROUTES.SIGNUP}>Đăng ký thông tin</a>
          &#160;hoặc&#160;
          <a href={APP_ROUTES.SIGNIN}>Đăng nhập</a>
        </div>
        <div className="text">
          Điều khoản sử dụng và bảo mật
          <br />
          Phiên bản 1.2.21 • Bản quyền thuộc về
          <a
            style={{ fontWeight: 700 }}
            rel="noreferrer"
            href={"https://www.greenfeed.com.vn/"}
            target="_blank">
            {" "}
            GREENFEED VIETNAM
          </a>
        </div>
      </div>

      <Notification
        visible={notiActivated.check}
        title={
          notiActivated.type === "active"
            ? "Tài khoản chưa được kích hoạt"
            : "Khôi phục mật khẩu không được phép thực hiện"
        }
        subTitle={
          notiActivated.type === "active" ? (
            <span
              dangerouslySetInnerHTML={{
                __html: t("CONTACT_STAFF_GF_ACTIVE_ACCOUNT"),
              }}
            />
          ) : (
            <span
              dangerouslySetInnerHTML={{
                __html: t("CONTACT_STAFF_GF_RESET_PASSWORD"),
              }}
            />
          )
        }
        icon={
          <div className={`notification-icon-box red`}>
            <ClearRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
          </div>
        }
        actions={
          <button
            className="btn-confirm-style"
            onClick={() => {
              notiActivated.type === "active"
                ? setNotiActivated({ check: false, type: "" })
                : history.push(APP_ROUTES.SIGNIN);
            }}>
            {"Quay lại màn hình đăng nhập"}
          </button>
        }
        onClose={() => {
          setNotiActivated({ check: false, type: "" });
        }}
      />
    </div>
  );
};

export default ForgetPassword;
