import { IconBase, IconComponent } from "@Core/Icons";

export const OrderIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        d="M21.063 18.899H6.42341C5.26837 18.899 4.33203 19.9691 4.33203 21.2891M4.33203 21.2891C4.33203 22.6092 5.26837 23.6793 6.42341 23.6793H21.063V2.16797H6.42341C5.26837 2.16797 4.33203 3.23807 4.33203 4.55811V21.2891Z"
        stroke={props.color}
        strokeWidth="2.39014"
      />
      <path
        d="M10.832 12.5668C10.832 12.5668 10.832 13.7851 12.7672 13.7851M12.7672 13.7851C15.1939 13.7851 15.1939 10.8681 12.7672 10.4344C10.3406 10.0006 10.3406 7.08361 12.7672 7.08361M12.7672 13.7851V15.1262M12.7672 7.08361C12.9585 7.06964 13.151 7.09067 13.3326 7.14542C13.5143 7.20017 13.6812 7.28745 13.8229 7.4018C13.9646 7.51615 14.0781 7.65513 14.1563 7.81001C14.2345 7.96489 14.2757 8.13235 14.2773 8.30193M12.7672 7.08361V5.73828"
        stroke={props.color}
        strokeWidth="1.91211"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconBase>
  );
};
