import { MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./index.scss";

type Props = {
  formId: string;
  showDeleteButton: boolean;
  handleDeleteAction?: (...args: any[]) => void;
  handleCancelAction?: (...args: any[]) => void;
  buttonSelect?: boolean;
  title?: string;
  titleDel?: string;
  children?: React.ReactNode;
};

const ModalFooter: React.FC<Props> = ({
  handleCancelAction,
  showDeleteButton,
  handleDeleteAction,
  formId,
  buttonSelect,
  title,
  titleDel,
  children,
}) => {
  const { t } = useTranslation(["translation", "order"]);
  const [value, setValue] = useState<string>("Cấp tài khoản và mật khẩu");
  return (
    <div
      className="modal-footer"
      style={{ justifyContent: showDeleteButton ? "space-between" : "flex-end" }}>
      {showDeleteButton && handleDeleteAction && (
        <button
          type="button"
          className="btn-delete"
          onClick={() => {
            handleDeleteAction();
          }}>
          {titleDel ? titleDel : "Xóa"}
        </button>
      )}
      {handleCancelAction && (
        <button
          type="button"
          className="btn-cancel btn__text-cancel"
          onClick={() => {
            handleCancelAction();
          }}>
          {t("CANCEL")}
        </button>
      )}
      <div className="modal-footer__group">
        {children}
        {buttonSelect ? (
          <Select
            value={value}
            variant="outlined"
            className="btn-green-style"
            onChange={(e) => setValue(e.target.value)}
            displayEmpty
            MenuProps={{
              className: "select-menu-list",
              anchorOrigin: {
                vertical: 60,
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            }}>
            <MenuItem style={{ fontSize: "1.4rem" }} value="Cấp tài khoản và mật khẩu">
              <input
                value="Cấp tài khoản và mật khẩu"
                aria-label="submit supplier form"
                form={formId}
                type="submit"
              />
            </MenuItem>
            <MenuItem style={{ fontSize: "1.4rem" }} value="Cấp tài khoản">
              <input
                value="Cấp tài khoản"
                aria-label="submit supplier form"
                form={formId}
                type="submit"
              />
            </MenuItem>
          </Select>
        ) : (
          <input
            value={title ? title : "Lưu"}
            aria-label="submit supplier form"
            form={formId}
            type="submit"
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(ModalFooter);
