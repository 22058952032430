/* eslint-disable react-hooks/exhaustive-deps */
import Search from "@Assets/images/icons/img-search.png";
import Ord from "@Assets/images/ordnull.png";
import ItemImg from "@Assets/images/stroke.svg";
import BtnGroup from "@Components/BtnGroup";
import EmptyList from "@Components/EmptyList";
import { InterestedIcon1 } from "@Components/Icons/InterestedIcon1";
import { InterestedIcon2 } from "@Components/Icons/InterestedIcon2";
import { InterestedIcon3 } from "@Components/Icons/InterestedIcon3";
import { InterestedIcon4 } from "@Components/Icons/InterestedIcon4";
import Loading from "@Components/Loading";
import Notification from "@Components/Notification";
import { APP_ROUTES, ConditionQuantity, TEXT_ASTERISK, TEXT_HOLDER } from "@Constants";
import {
  Action,
  CustomerOutputModel,
  FetchAction,
  ItemPermission,
  PaginateFetchReducer,
  ProductOutputModel,
  SourceType,
} from "@Models";
import { CustomerService, ProductService } from "@Services";
import {
  checkLinkImage,
  currencyFormat,
  PermissionUtil,
  renderErrorImageItem,
  renderImageProNull,
} from "@Utils";
import {
  Clear as ClearIcon,
  ClearRounded as ClearRoundedIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { Grid, InputAdornment, TextField } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { useHistory } from "react-router-dom";
import "./index.scss";

type P_Props = {
  sourceType: string;
};

function reducer(state: PaginateFetchReducer<ProductOutputModel>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        loading: false,
        data: [...state.data, ...action.payload.data] as ProductOutputModel[],
      };
    case Action.RESET:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        loading: false,
        data: action.payload.data,
      };

    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload };

    default:
      return { ...state };
  }
}
const ProductListing: React.FC<P_Props> = ({ sourceType }) => {
  const { t } = useTranslation(["translation", "product"]);
  const mounted = useRef(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [visibleSearch, setVisibleSearch] = useState<boolean>(false);
  const [itemGroupId, setItemGroupId] = useState<string>("");
  const [ordDetail, setOrderDetail] = useState<string>("");
  const [product, setProduct] = useState<any>(null);
  const [notiActivated, setNotiActivated] = useState<{
    message: string;
    status: boolean;
  }>({
    message: "",
    status: false,
  });
  const [interestedSuccess, setInterestedSuccess] = useState<boolean>(false);

  const history = useHistory();
  const [profile, setProfile] = useState<any>([]);

  const loadProfileData = _.debounce(async () => {
    const response = await CustomerService.getAuthProfile();
    setProfile(response.data);
  }, 100);

  useEffect(() => {
    loadProfileData();
    //eslint-disable-next-line
  }, []);

  const [state, dispatch] = useReducer(reducer, {
    page: 0,
    totalPages: 0,
    limit: 20,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
    loading: true,
  });

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const fetchData = async (query: any, isReset: boolean = false) => {
    if (state.fetching) return;
    dispatch({ type: Action.FETCH, payload: {} });

    try {
      const result = await ProductService.getListProduct(query);

      if (isReset)
        dispatch({
          type: Action.RESET,
          payload: result.data,
        });
      else if (mounted.current)
        dispatch({
          type: Action.SUCCESS,
          payload: result.data,
        });
    } catch (err: any) {
      if (err.response?.status === 401) {
        if (err.response?.data.message === "SESSION_EXPIRED") {
          setNotiActivated({ message: "SESSION_EXPIRED", status: true });
        } else {
          setNotiActivated({ message: "UNAUTHORIZED", status: true });
        }
      }
      if (mounted)
        dispatch({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
    }
  };

  useEffect(() => {
    fetchData(
      {
        page: 1,
        limit: 20,
        searchValue: searchValue,
        source: sourceType,
        itemGroupId: itemGroupId ? itemGroupId : undefined,
      },
      true
    );
  }, [searchValue, sourceType, itemGroupId]);
  const hasMore = state.data.length < state.totalRecords && state.page < state.totalPages;

  const onSearch = _.debounce((value) => {
    setSearchValue(value);

    value ? setVisibleSearch(true) : setVisibleSearch(false);
  }, 300);

  const getProductById: () => Promise<void> = async () => {
    try {
      const response = await ProductService.getProductById(ordDetail);
      if (mounted.current) {
        setProduct(() => response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (ordDetail !== "") {
      getProductById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordDetail]);

  const updateProductInterested = async (id: number, interested: boolean) => {
    try {
      const res = await ProductService.updateProductInterested({
        itemId: id,
        interested: interested,
        source: sourceType,
      });
      if (res && (res.status === 200 || res.status === 201)) {
        setInterestedSuccess(!interestedSuccess);
        await fetchData(
          {
            page: 1,
            limit: 20,
            searchValue: searchValue,
            source: sourceType,
            itemGroupId: itemGroupId ? itemGroupId : undefined,
          },
          true
        );
        getProductById();
      }
    } catch (error: any) {}
  };

  const checkSource =
    sourceType === SourceType.FEED ||
    sourceType === SourceType.VET ||
    sourceType === SourceType.LAB;

  //permission
  const checkPermissionViewPrice = PermissionUtil.check(
    profile?.role?.permissions,
    ItemPermission.MANAGE_ITEM_VIEW_PRICE,
    true
  );

  return (
    <div className="product-listing">
      <div className="content">
        <div className="header">
          <div className="title">
            {t("ALL")} ({state.totalRecords})
          </div>
          <TextField
            placeholder={t("SEARCH_BY_NAME_PRODUCT_CODE")}
            variant="outlined"
            className={"search-style"}
            inputProps={{ className: "input" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="large" />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              onSearch(e.target.value);
            }}
          />
        </div>
        {state.loading === true ? (
          <Loading style={{ marginTop: "30rem" }} />
        ) : (
          <>
            {state.itemGroups && state.itemGroups.length > 1 && sourceType !== SourceType.LAB && (
              <div className="gr-item">
                <BtnGroup<CustomerOutputModel>
                  list={[
                    { id: "", name: t("ALL") },
                    { id: "0", name: t("INTEREST"), image: ItemImg } as any,
                    ...state.itemGroups,
                    // { id: "null", name: "Khác" },
                  ]}
                  activeId={itemGroupId ? itemGroupId : ""}
                  onClick={(cate: any) => {
                    setItemGroupId(cate.id);
                  }}
                  renderBtnLabel={(cate: any) => {
                    if (cate.id === "") {
                      return (
                        <div className="btn-gr__item">
                          <div className="label">{t("ALL")}</div>
                        </div>
                      );
                    }

                    if (cate.id === "0") {
                      <div className="btn-gr__item">
                        <img src={ItemImg} alt="iconItem" />
                        <div className="label">{t("INTEREST")}</div>
                      </div>;
                    }
                    // if (cate.id === "null") {
                    //   return <div className="label">Khác</div>;
                    // }

                    return (
                      <div
                        key={cate.id}
                        className={
                          cate?.id === itemGroupId
                            ? "btn-gr__item active"
                            : "btn-gr__item btn-gr__item-hover"
                        }>
                        <img
                          src={cate.id !== "0" ? checkLinkImage(cate?.image) : cate?.image}
                          alt={"icon"}
                        />
                        <div className="label">{cate?.name.toLowerCase()}</div>
                      </div>
                    );
                  }}
                  className="product-listing__mobile-filter__group-cate"
                />
              </div>
            )}
            <div className="list">
              <div className="list-left" style={{ width: ordDetail !== "" ? "45%" : "100%" }}>
                <div className="list-header">
                  <div
                    className={checkSource ? "id" : "id-farm"}
                    style={{ width: ordDetail && "35%" }}>
                    {t("PRODUCT_CODE")}
                  </div>
                  <div
                    className={checkSource ? "product" : "product-farm"}
                    style={{ width: ordDetail && "65%" }}>
                    {t("product:PRODUCT_NAME")}
                  </div>
                  {!ordDetail && (
                    <>
                      {checkSource && <div className="unit">{t("product:UNIT_MEASUREMENT")}</div>}
                      <div className={checkSource ? "price" : "price-farm"}>
                        {t("product:SALE_PRICE")}
                      </div>
                    </>
                  )}
                </div>
                {state.loading || (state.fetching && searchValue) ? (
                  <Loading style={{ margin: "2rem 0", height: "60vh" }} />
                ) : state.data.length > 0 ? (
                  <InfiniteScroll
                    pageStart={0}
                    loadMore={() =>
                      fetchData({
                        page: state.page + 1,
                        limit: 20,
                        source: sourceType,
                        searchValue: searchValue,
                        itemGroupId: itemGroupId ? itemGroupId : undefined,
                      })
                    }
                    hasMore={hasMore}
                    useWindow={false}>
                    {state.data.map((pro: any, index: number) => {
                      const act = ordDetail === pro.id;
                      return (
                        <div
                          key={pro.code + index}
                          className={act ? "item act" : "item"}
                          style={{
                            borderLeft: `3px solid ${pro?.color ? pro?.color : "transparent"}`,
                          }}
                          onClick={() => {
                            setOrderDetail(pro.id);
                          }}>
                          <div className="icon-interested">
                            {(() => {
                              if (pro?.interestedItem) {
                                if (
                                  pro?.interestedItem?.stared === false &&
                                  pro?.interestedItem?.bought > 0
                                ) {
                                  return (
                                    <div onClick={() => updateProductInterested(pro?.id, true)}>
                                      <InterestedIcon1
                                        color="#41AD49"
                                        size={[24, 24]}
                                        viewBox={[36, 36]}
                                      />
                                    </div>
                                  );
                                } else if (
                                  pro?.interestedItem?.stared === true &&
                                  pro?.interestedItem?.bought > 0
                                ) {
                                  return (
                                    <div onClick={() => updateProductInterested(pro?.id, false)}>
                                      <InterestedIcon2
                                        color="#41AD49"
                                        size={[24, 24]}
                                        viewBox={[36, 36]}
                                      />
                                    </div>
                                  );
                                } else if (
                                  pro?.interestedItem?.stared === false &&
                                  pro?.interestedItem?.bought === 0
                                ) {
                                  return (
                                    <div
                                      className="icon-star"
                                      onClick={() => updateProductInterested(pro?.id, true)}>
                                      <InterestedIcon4
                                        color="#41AD49"
                                        size={[13, 13]}
                                        viewBox={[15, 14]}
                                      />
                                    </div>
                                  );
                                } else if (
                                  pro?.interestedItem?.stared === true &&
                                  pro?.interestedItem?.bought === 0
                                ) {
                                  return (
                                    <div
                                      className="icon-star"
                                      onClick={() => updateProductInterested(pro?.id, false)}>
                                      <InterestedIcon3
                                        color="#41AD49"
                                        size={[13, 13]}
                                        viewBox={[15, 14]}
                                      />
                                    </div>
                                  );
                                }
                              } else
                                return (
                                  <div
                                    className="icon-star"
                                    onClick={() => updateProductInterested(pro?.id, true)}>
                                    <InterestedIcon4
                                      color="#41AD49"
                                      size={[13, 13]}
                                      viewBox={[15, 14]}
                                    />
                                  </div>
                                );
                            })()}
                          </div>
                          <div
                            className={checkSource ? "id" : "id-farm"}
                            style={{ width: ordDetail && "35%" }}>
                            {(sourceType === "farm" ? pro.code : pro.shortName) || TEXT_HOLDER}
                          </div>
                          <div
                            className={checkSource ? "product" : "product-farm"}
                            style={{
                              width: ordDetail && "65%",
                              color: pro?.color ?? "#272b2f",
                            }}>
                            <img
                              src={
                                checkLinkImage(pro.images?.picture) ||
                                renderImageProNull(sourceType)
                              }
                              alt={pro.shortName}
                              onError={renderErrorImageItem(sourceType)}
                            />
                            {pro.name}
                          </div>
                          {!ordDetail && (
                            <>
                              {checkSource && (
                                <div className="unit">{pro.dimension + " " + pro.baseUoM}</div>
                              )}
                              <div className={checkSource ? "price" : "price-farm"}>
                                {checkPermissionViewPrice
                                  ? currencyFormat(pro?.price.value)
                                  : TEXT_ASTERISK}{" "}
                                đ/{pro?.price.baseUoM}
                              </div>
                            </>
                          )}
                        </div>
                      );
                    })}
                  </InfiniteScroll>
                ) : (
                  <div className="list-null">
                    {visibleSearch === true ? (
                      <>
                        <img src={Search} alt="search" />
                        <br />
                        {t("NO_RESULT_FOUND")}
                      </>
                    ) : (
                      <EmptyList img={Ord} title={t("product:NO_PRODUCT_AVAILABLE")} />
                    )}
                  </div>
                )}
              </div>
              {ordDetail && product && (
                <div className="view">
                  <div className="view-header">
                    <div className="title">{t("product:PRODUCT_DETAIL")}</div>
                    <div
                      onClick={() => {
                        setOrderDetail("");
                      }}
                      style={{ cursor: "pointer" }}>
                      <ClearIcon style={{ fontSize: "2.2rem" }} />
                    </div>
                  </div>
                  <div style={{ position: "absolute", right: "3.5rem", cursor: "pointer" }}>
                    {(() => {
                      if (product?.interestedItem) {
                        if (
                          product?.interestedItem?.stared === false &&
                          product?.interestedItem?.bought > 0
                        ) {
                          return (
                            <div onClick={() => updateProductInterested(product?.id, true)}>
                              <InterestedIcon1 color="#41AD49" size={[30, 30]} viewBox={[14, 36]} />
                            </div>
                          );
                        } else if (
                          product?.interestedItem?.stared === true &&
                          product?.interestedItem?.bought > 0
                        ) {
                          return (
                            <div onClick={() => updateProductInterested(product?.id, false)}>
                              <InterestedIcon2 color="#41AD49" size={[30, 30]} viewBox={[14, 36]} />
                            </div>
                          );
                        } else if (
                          product?.interestedItem?.stared === false &&
                          product?.interestedItem?.bought === 0
                        ) {
                          return (
                            <div
                              className="icon-star"
                              style={{ marginTop: "1.5rem" }}
                              onClick={() => updateProductInterested(product?.id, true)}>
                              <InterestedIcon4 color="#41AD49" size={[20, 20]} viewBox={[15, 14]} />
                            </div>
                          );
                        } else if (
                          product?.interestedItem?.stared === true &&
                          product?.interestedItem?.bought === 0
                        ) {
                          return (
                            <div
                              className="icon-star"
                              style={{ marginTop: "1.5rem" }}
                              onClick={() => updateProductInterested(product?.id, false)}>
                              <InterestedIcon3 color="#41AD49" size={[20, 20]} viewBox={[15, 14]} />
                            </div>
                          );
                        }
                      } else
                        return (
                          <div
                            className="icon-star"
                            style={{ marginTop: "1.5rem" }}
                            onClick={() => updateProductInterested(product?.id, true)}>
                            <InterestedIcon4 color="#41AD49" size={[20, 20]} viewBox={[15, 14]} />
                          </div>
                        );
                    })()}
                  </div>
                  <Grid container className="view-detail" spacing={7}>
                    <Grid item xs={4}>
                      <img
                        src={
                          checkLinkImage(product.image?.picture) || renderImageProNull(sourceType)
                        }
                        alt={product.shortName}
                        onError={renderErrorImageItem(sourceType)}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <div className="name">
                        {sourceType === "farm" ? product.code : product.shortName}
                      </div>
                      <Grid container alignItems="center">
                        <Grid item xs={5} style={{ color: "#838283" }}>
                          <div>{t("UNIT_PRICE")}</div>
                          <br />
                          <div>{t("product:PRODUCT_NAME")}</div>
                          <br />
                          <div>{t("product:PRODUCT_CATEGORY_NAME")}</div>
                          <br />
                          <div>{t("product:GROUP_NAME")}</div>
                          <br />
                          <div>
                            {sourceType === "farm"
                              ? t("product:SALES_UNIT")
                              : t("product:SALES_COMPANY")}
                          </div>
                          <br />
                          {sourceType === "farm" && product.conditionQuantity && (
                            <>
                              <div>{t("product:ORDER_CONDITION")}</div>
                              <br />
                            </>
                          )}
                          {!checkSource && (
                            <>
                              <div>{t("STOCK_FEE_EXCESS_PORTION")}</div>
                              <br />
                            </>
                          )}
                          <div>
                            {sourceType === "farm" ? t("WEIGHT_RANGE") : t("product:EXCHANGE")}
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={7}
                          style={{ color: "#5A5959", fontSize: "1.8rem", fontWeight: 500 }}>
                          <div className="price">
                            {checkPermissionViewPrice
                              ? currencyFormat(product.price?.value)
                              : TEXT_ASTERISK}
                            &ensp;
                            <sup>đ/{product.price?.baseUoM}</sup>
                          </div>
                          <br />
                          <div>{product.name || TEXT_HOLDER}</div>
                          <br />
                          <div>{product.division?.description || TEXT_HOLDER}</div>
                          <br />
                          {sourceType === SourceType.FEED || sourceType === SourceType.VET ? (
                            <div className="item-grs">
                              {product.itemGroupType?.image && (
                                <img
                                  src={checkLinkImage(product.itemGroupType?.image)}
                                  alt={product.itemGroupType?.name}
                                />
                              )}
                              {product.itemGroupType?.name || TEXT_HOLDER}
                            </div>
                          ) : (
                            <div className="item-grs">{TEXT_HOLDER} </div>
                          )}
                          <br />
                          <div>
                            {(checkPermissionViewPrice ? product.saleUnit : TEXT_ASTERISK) + "/đ"}
                          </div>
                          <br />
                          {sourceType === SourceType.FARM && product.conditionQuantity && (
                            <>
                              <div>
                                {
                                  ConditionQuantity.find(
                                    (f: any) =>
                                      product.conditionQuantity?.replace(/\d/g, "") === f.value
                                  )?.title
                                }
                                &nbsp;
                                {product.conditionQuantity?.match(/[0-9]+/g)?.[1]
                                  ? product.conditionQuantity?.match(/[0-9]+/g)?.[0] +
                                    " - " +
                                    product.conditionQuantity?.match(/[0-9]+/g)?.[1]
                                  : product.conditionQuantity?.match(/[0-9]+/g)?.[0]}
                                <br />
                              </div>
                              <br />
                            </>
                          )}
                          {!checkSource && (
                            <>
                              <div>
                                {+product.price.additionValue !== 0
                                  ? (checkPermissionViewPrice
                                      ? currencyFormat(+product.price.additionValue)
                                      : TEXT_ASTERISK) + "/đ"
                                  : TEXT_HOLDER}
                              </div>
                              <br />
                            </>
                          )}
                          <div>
                            {Array.isArray(product.dimension)
                              ? product.dimension.map((d: any, idx: number) => {
                                  return (
                                    <div key={idx}>
                                      <span style={{ display: "flex", flexDirection: "column" }}>
                                        &#8226;&ensp;
                                        {(() => {
                                          if (d[1] === "null") {
                                            return "trên " + d[0];
                                          } else if (d[0] === "null") {
                                            return "dưới " + d[1];
                                          } else {
                                            return d[0] + " - " + d[1];
                                          }
                                        })()}{" "}
                                        &#160;kg
                                      </span>
                                      <br />
                                    </div>
                                  );
                                })
                              : product.dimension + " " + product.baseUoM}{" "}
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              )}
            </div>
          </>
        )}
      </div>

      <Notification
        visible={notiActivated.status}
        title={
          notiActivated.message === "SESSION_EXPIRED"
            ? t("YOUR_LOGIN_SESSION_EXPIRED")
            : t("YOUR_ACCOUNT_DISABLED")
        }
        subTitle={
          notiActivated.message === "SESSION_EXPIRED" ? (
            <span>{t("LOGIN_AGAIN")}</span>
          ) : (
            <span
              dangerouslySetInnerHTML={{
                __html: t("CONTACT_STAFF_GF_ACTIVE_ACCOUNT"),
              }}
            />
          )
        }
        icon={
          <div className={`notification-icon-box red`}>
            <ClearRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
          </div>
        }
        actions={
          <button
            className="btn-confirm-style"
            onClick={() => {
              localStorage.removeItem("accessToken");
              window.location.reload();
              history.push(APP_ROUTES.SIGNIN);
            }}>
            {notiActivated.message === "SESSION_EXPIRED" ? t("CLOSE") : t("GO_BACK")}
          </button>
        }
        onClose={() => {
          localStorage.removeItem("accessToken");
          window.location.reload();
          history.push(APP_ROUTES.SIGNIN);
        }}
      />
    </div>
  );
};

export default ProductListing;
